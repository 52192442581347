import { Component, Input } from '@angular/core';
import { Menu } from '../../models/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() menuArray: Menu[] = [];
  @Input() arrowImage: string = '';

  isCollapsed: boolean = false;

  collapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  constructor(private router: Router) {}

  navigateToDashboard(i: number) {
    if (i === 0) {
      this.router.navigate(['/']);
    }
  }
}
