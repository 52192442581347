export interface GridRequest<
  A extends string,
  B extends string,
  C extends string,
  D extends string,
> {
  from?: number;
  to?: number;
  page: number;
  pageSize: number;
  sort?: Record<A, AnyoSortDirection | undefined>;
  filters?: Record<B, string[] | undefined>;
  searchQuery?: Record<C, string | undefined>;
  dateFilters?: Record<D, DateRange | undefined>;
  globalSearch?: string[];
  export: boolean;
}

export interface DateRange {
  from: Date;
  to: Date;
}

export enum AnyoSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
