<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/circles/moderators']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text pl-2">Add New Moderator</h1>
  </div>
</div>
<nz-spin [nzSpinning]="moderatorSpinner" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div [nzGutter]="16" nz-row nzAlign="middle">
        <div nz-col nzSpan="6">
          <label class="form-label required">Moderator Name</label>
          <input
            [(ngModel)]="moderatorName"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="name"
          />
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Moderator Email</label>
          <input
            [(ngModel)]="moderatorEmail"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="email"
          />
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Moderator Contact Number</label>
          <input
            [(ngModel)]="contactNo"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="contact no"
          />
        </div>
      </div>
      <div [nzGutter]="16" nz-row nzAlign="middle">
        <div nz-col nzSpan="10">
          <label class="form-label required">Description</label>
          <textarea
            [(ngModel)]="moderatorDescription"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="description"
          ></textarea>
        </div>
        <div nz-col nzSpan="6">
          <span><b>Upload Moderator Profile</b></span>
          <br />
          <input
            (change)="uploadModeratorProfilePic($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
          />
        </div>
        <div nz-col nzSpan="4">
          <label class="form-label required">IsActive</label>
          <nz-switch [(ngModel)]="isActive"></nz-switch>
        </div>
      </div>
      <nz-divider nzText="Qualifications"></nz-divider>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button (click)="addQualification()" nz-button nzType="primary">
            Add Qualification
          </button>
        </div>
      </div>
      <div
        *ngFor="let qualification of qualifications; index as i"
        class="mt-3"
        nz-row
        nzAlign="middle"
      >
        <div nz-col nzSpan="6">
          <label class="form-label required">{{
            "Qualification " + (i + 1)
          }}</label>
          <input
            [(ngModel)]="qualification.value"
            class="w-[90%] mr-1"
            nz-input
            nzSize="large"
            placeholder="qualification"
          />
        </div>
        <div nz-col nzSpan="6">
          <span
            nz-icon
            (click)="deleteQualification(i)"
            nzType="delete"
            nzTheme="outline"
          ></span>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col>
          <button (click)="saveModerator()" nz-button nzType="primary">
            Save Moderator
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
