import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagePostsComponent } from './pages/manage-posts/manage-posts.component';
import { AddPostComponent } from './pages/add-post/add-post.component';
import { ModerationEventsTableComponent } from './components/moderation-events-table/moderation-events-table.component';

const routes: Routes = [
  {
    path: '',
    component: ManagePostsComponent,
  },
  {
    path: ':id/circle/:circleId/comments',
    component: ManagePostsComponent,
  },
  {
    path: 'user/:userId',
    component: ManagePostsComponent,
  },
  {
    path: 'add',
    component: AddPostComponent,
  },
  {
    path: ':postId/circle/:circleId/comment/add',
    component: AddPostComponent,
  },
  {
    path: ':postId',
    component: ModerationEventsTableComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostsRoutingModule {}
