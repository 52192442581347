import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import cronstrue from 'cronstrue';
import { v4 } from 'uuid';
import { TalkGridItem } from 'projects/app-core/src/app/talks/models/TalkGridResponse';
import { AnyoTableComponent } from '../../../models/AnyoTableComponent';
import { TalkGridRequest } from 'projects/app-core/src/app/talks/models/TalkGridRequest';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from '../../../services/toastr.service';
import { NetworkUtilsService } from 'projects/app-core/src/service/network-utils.service';
import { TalksService } from 'projects/app-core/src/app/talks/services/talks.service';
import { IAnyoError } from '../../../models/error/errorResponse';

export interface ITalkGridItem extends TalkGridItem {
  goLiveAllowed: boolean;
}

@Component({
  selector: 'app-list-talks',
  templateUrl: './list-talks.component.html',
  styleUrl: './list-talks.component.scss',
})
export class ListTalksComponent extends AnyoTableComponent<
  ITalkGridItem,
  TalkGridRequest
> {
  constructor(
    private route: Router,
    protected override auth: AuthService,
    protected toast: ToastService,
    api: NetworkUtilsService,
    private talkService: TalksService,
  ) {
    super(auth, toast);
  }

  DATE_FORMAT = 'DD/MM/yyyy hh:mm a';

  async goLive(id: string) {
    const liveWindowId = v4();
    localStorage.setItem('liveWindowId', liveWindowId);
    window.open(`/talks/single/live/${id}`, liveWindowId);
    // await this.route.navigate([`/talks/single/live/${id}`]);
  }

  async getData() {
    this.pageLoading = true;
    this.talkService.getTalksGrid(this.gridRequest).subscribe({
      next: (value) => {
        this.data = value.data.map((item) => {
          const gridItem = item as ITalkGridItem;
          gridItem.nextLiveOn = moment(item.nextLiveOn, 'x').toDate();
          gridItem.goLiveAllowed = item.experts
            .map((expert) => expert.userId)
            .includes(this.user!.userId!);
          return gridItem;
        });
        this.pageSize = value.pageSize;
        this.pageNo = value.pageNum;
        this.totalCount = value.totalRecords;
        this.pageLoading = false;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
        this.pageLoading = false;
      },
    });
  }

  async joinLive(id: string) {
    window.open(`/talks/single/live/${id}?watchOnly=true`, '_blank');
    // await this.route.navigate(['/talks/single/live', id], {queryParams: {watchOnly: true}});
  }

  formatDate(date: Date | string) {
    return moment(date).format(this.DATE_FORMAT);
  }

  getFrequency(cron: string) {
    return cronstrue.toString(cron);
  }
}
