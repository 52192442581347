import { Component } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import {
  TherapyPlansService,
  TherapyPlanUpdateRequest,
} from '../../../services/therapy-plans.service';
import { MasterdataService } from '../../../services/masterdata.service';
import * as moment from 'moment';
import {
  ChargeObject,
  SessionNameMapping,
  TherapyPaymentType,
} from '../../../models/TherapyPlans';
import { lastValueFrom } from 'rxjs';
import { TherapistExpertLevel } from 'projects/app-core/src/models/TherapistExpertLevel';
import { v4 as uuidv4 } from 'uuid';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-edit-therapy-plan',
  templateUrl: './edit-therapy-plan.component.html',
  styleUrl: './edit-therapy-plan.component.scss',
})
export class EditTherapyPlanComponent extends AnyoComponent {
  constructor(
    protected override auth: AuthService,
    protected toastService: ToastService,
    private fb: NonNullableFormBuilder,
    private therapyPlanService: TherapyPlansService,
    private masterDataService: MasterdataService,
    private fileService: FileUtilsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super(auth);
    this.therapyPlanForm = fb.group({
      order: [0, this.nonZeroValidator],
      maxPauseDays: [0],
      internalPlanName: ['', Validators.required],
      canPause: [false, Validators.required],
      activeFrom: [moment().toDate(), Validators.required],
      activeUpto: [],
      active: [true],
      planName: ['', Validators.required],
      planDescription: ['', Validators.required],
      paymentType: ['RETAIL_PAID', Validators.required],
      noOfSessions: [1, this.nonZeroValidator],
      validityDays: [30, this.nonZeroValidator],
      perSessionTimeInMin: [60, this.nonZeroValidator],
    });
    this.disableForm();
  }

  disableForm() {
    this.therapyPlanForm.disable();
    this.editable = false;
  }

  enableForm() {
    this.therapyPlanForm.enable();
    this.editable = true;
  }

  nonZeroValidator: ValidatorFn = (control: AbstractControl) => {
    if (control.value === 0) {
      return { nonZero: true };
    }
    return null;
  };

  override async ready(user?: IAnyoUser | undefined): Promise<void> {
    if (user) {
      const masterData = await lastValueFrom(
        this.masterDataService.masterDataList(),
      );
      this.therapistExpertLevels = masterData.therapistExpertLevels;
      this.expertLevels = this.therapistExpertLevels.map((value) => value.data);
      this.expertLevels.forEach((value) => {
        this.charges[value] = [
          {
            currency: 'INR',
            amount: 0,
            currencySymbol: '₹',
            markupAmount: 0,
            savingText: 0,
            perSessionAmount: 0,
          },
        ];
      });

      this.getTherapyPlan();
    }
    return;
  }

  editable = false;
  therapyPlanId = '';
  oldImage = '';
  therapyPlanForm: FormGroup;
  expertLevels: string[] = [];
  therapistExpertLevels: TherapistExpertLevel[] = [];
  planImage: File | undefined;
  noOfSessions: number = 3;
  sessionNameMapping: {
    name: string;
    description: string;
  }[] = [];
  paymentType: TherapyPaymentType = TherapyPaymentType.RETAIL_PAID;
  charges: Record<
    string,
    {
      currency: string;
      amount: number;
      currencySymbol: string;
      markupAmount: number;
      savingText: number;
      perSessionAmount: number;
    }[]
  > = {};

  getTherapyPlan() {
    const id = this.activatedRoute.snapshot.params['id'];
    this.therapyPlanId = id;
    this.therapyPlanService.getPlan(id).subscribe({
      next: (value) => {
        this.oldImage = value.planImage;
        this.therapyPlanForm.setValue({
          order: value.order || 0,
          maxPauseDays: value.maxPauseDays,
          internalPlanName: value.internalPlanName,
          canPause: value.canPause,
          activeFrom: moment(value.activeFrom).toDate(),
          activeUpto: value.activeUpto
            ? moment(value.activeUpto).toDate()
            : moment().toDate(),
          active: value.active,
          planName: value.planName,
          planDescription: value.planDescription,
          paymentType: value.paymentType,
          noOfSessions: value.noOfSessions,
          validityDays: value.validityDays,
          perSessionTimeInMin: value.perSessionTimeInMin,
        });

        for (let i = 1; i < value.noOfSessions + 1; i++) {
          const data = {
            name: value.sessionNameMapping![i].name,
            description: value.sessionNameMapping![i].description,
          };
          this.sessionNameMapping.push(data);
        }
        for (const level in value.charges) {
          const data = this.therapistExpertLevels.find(
            (data) => data.m_id === level,
          );
          this.charges[data?.data as string] = value.charges[level];
        }
      },
      error: (error) => {
        this.pageLoading = false;
        this.toastService.showError(error.error.message);
      },
    });
  }

  uploadImages($event: Event) {
    const fileInput = $event.target as HTMLInputElement;
    const file = fileInput.files;
    if (file) {
      this.planImage = file[0];
    }
  }

  async updatePlan() {
    if (
      this.therapyPlanForm.get('canPause') &&
      this.therapyPlanForm.get('canPause')?.value &&
      !this.therapyPlanForm.get('canPause')?.value
    ) {
      this.toastService.showError('Please enter max pause days');
      return;
    }
    if (!this.validateSessionNameMapping()) {
      this.toastService.showError('Session name mapping are wrong');
      return;
    }
    if (!this.validatePlanCharges()) {
      this.toastService.showError('Session charges  are wrong');
      return;
    }
    this.pageLoading = true;
    const mappedCharges: Record<string, ChargeObject[]> = {};
    Object.keys(this.charges).forEach((key) => {
      const expertLevelId = this.therapistExpertLevels.find(
        (value) => value.data === key,
      )?.m_id;
      if (expertLevelId) {
        mappedCharges[expertLevelId] = this.charges[key];
      }
    });

    const mappedSessionNameMapping: Record<string, SessionNameMapping> = {};
    Object.keys(this.sessionNameMapping).forEach((_, index) => {
      mappedSessionNameMapping[index + 1] = this.sessionNameMapping[index];
    });
    if (this.therapyPlanForm.valid) {
      const updateTherapyPlanRequest: TherapyPlanUpdateRequest = {
        order: this.therapyPlanForm.get('order')?.value,
        internalPlanName: this.therapyPlanForm.get('internalPlanName')?.value,
        canPause: this.therapyPlanForm.get('canPause')?.value,
        activeFrom: Number(
          moment(this.therapyPlanForm.get('activeFrom')?.value, 'x'),
        ),
        activeUpto: Number(
          moment(this.therapyPlanForm.get('activeUpto')?.value, 'x'),
        ),
        planName: this.therapyPlanForm.get('planName')?.value,
        planDescription: this.therapyPlanForm.get('planDescription')?.value,
        paymentType: this.therapyPlanForm.get('paymentType')?.value,
        noOfSessions: this.therapyPlanForm.get('noOfSessions')?.value,
        validityDays: this.therapyPlanForm.get('validityDays')?.value,
        perSessionTimeInMin: this.therapyPlanForm.get('perSessionTimeInMin')
          ?.value,
        sessionNameMapping: mappedSessionNameMapping,
        charges: mappedCharges as unknown as Record<string, ChargeObject[]>,
        maxPauseDays: this.therapyPlanForm.get('maxPauseDays')?.value,
        planImage: this.oldImage,
        active: this.therapyPlanForm.get('active')?.value,
      };
      if (this.planImage) {
        try {
          const uuid = uuidv4().replace(/-/g, '');
          const extension = this.planImage.name.split('.').pop();
          const filePath = `planImages/${uuid}.${extension}`;
          const uploadUrl = await lastValueFrom(
            this.fileService.generateUploadUrl(
              AnyoS3Buckets.CDN_BUCKET,
              filePath,
              this.planImage.type,
            ),
          );
          this.toastService.showSuccess(`Uploading Plan Image`);
          await lastValueFrom(
            this.fileService.uploadFile(this.planImage, uploadUrl.url),
          );
          this.toastService.showSuccess(`Uploading Plan Image completed`);
          updateTherapyPlanRequest.planImage = filePath;
        } catch (e) {
          this.toastService.showError(`Uploading Plan Image failed`);
        }
      }
      this.therapyPlanService
        .updatePlan(this.therapyPlanId, updateTherapyPlanRequest)
        .subscribe({
          next: () => {
            this.pageLoading = false;
            this.toastService.showSuccess(
              `Therapy plan ${updateTherapyPlanRequest.planName} updated successfully`,
            );
            this.router.navigate(['/therapy-plans/all']);
          },
          error: (error) => {
            this.pageLoading = false;
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
    } else {
      Object.values(this.therapyPlanForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  addSessions() {
    this.sessionNameMapping.push({
      name: `Session ${this.sessionNameMapping.length + 1} `,
      description: ' ',
    });
    this.therapyPlanForm.patchValue({
      noOfSessions: this.sessionNameMapping.length,
    });
  }

  deleteSessions(index: number) {
    this.sessionNameMapping.splice(index, 1);
    for (let i = index; i < this.sessionNameMapping.length; i++) {
      this.sessionNameMapping[i].name = `Session ${i + 1}`;
    }
    this.therapyPlanForm.patchValue({
      noOfSessions: this.sessionNameMapping.length,
    });
  }

  validateSessionNameMapping(): boolean {
    for (let i = 0; i < this.sessionNameMapping.length; i++) {
      if (!this.sessionNameMapping[i].name) {
        return false;
      }
    }
    return true;
  }

  validatePlanCharges(): boolean {
    for (const level in this.charges) {
      for (let i = 0; i < this.charges[level].length; i++) {
        if (!this.charges[level][i].amount) {
          return false;
        }
      }
    }
    return true;
  }
}
