import { Component } from '@angular/core';
import { RolesPermissionService } from '../../../services/roles-permission.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/app/models/error/errorResponse';
import { AddNewAdminUserRequest } from '../../../models/addNewAdminUserRequest';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrl: './add-admin.component.scss',
})
export class AddAdminComponent {
  adminSpining = false;
  nameString = '';
  emailString = '';
  isActive = true;

  constructor(
    private rolesAndPermissionService: RolesPermissionService,
    private toastrService: ToastService,
  ) {}

  saveAdmin() {
    this.adminSpining = true;
    const request: AddNewAdminUserRequest = {
      name: this.nameString,
      email: this.emailString,
      active: this.isActive,
    };
    this.rolesAndPermissionService.createAdmin(request).subscribe({
      next: (value) => {
        this.adminSpining = false;
        this.toastrService.showSuccess('Successfully Added');
      },
      error: (value) => {
        this.adminSpining = false;
        const errorBody = value.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
}
