import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface CirclePostsGridRequest
  extends GridRequest<PostSort, PostFilter, PostSearch, PostDateFilter> {
  tab?: PostTab;
}
export enum PostSort {
  LAST_COMMENT_AT = 'LAST_COMMENT_AT',
  LAST_REACTION_AT = 'LAST_REACTION_AT',
}
export enum PostTab {
  ALL = 'ALL',
  MODERATION_REQUIRED = 'MODERATION_REQUIRED',
  STARRED = 'STARRED',
  FLAGGED = 'FLAGGED',
  BLOCKED_POSTS = 'BLOCKED_POSTS',
}
export enum PostFilter {
  CIRCLE_ID = 'CIRCLE_ID',
  USER_ID = 'USER_ID',
  PARENT_POST_ID = 'PARENT_POST_ID',
  TOPICS = 'TOPICS',
  MODERATION_REQUIRED = 'MODERATION_REQUIRED',
  ONLY_VISIBLE_POSTS = 'ONLY_VISIBLE_POSTS',
  BLOCKED_POSTS = 'BLOCKED_POSTS',
  TAGS = 'TAGS',
  HASHTAG = 'HASHTAG',
  ADMIN_TAGS = 'ADMIN_TAGS',
}
export enum PostSearch {
  CONTENT = 'CONTENT',
  HASHTAG = 'HASHTAG',
}
export enum PostDateFilter {
  CREATED_DATE = 'CREATED_DATE',
}
