<div class="adminuicontainer flex min-h-screen flex-row flex-start">
  <div class="flex main-menu border-r-2 border-solid-grey">
    <app-menu [menuArray]="menu"></app-menu>
  </div>
  <div class="main-content min-h-[100%] flex flex-col flex-start w-full">
    <div>
      <app-header-adminui
        [user]="user"
        [domainName]="domainNameMessage"
      ></app-header-adminui>
    </div>
    <div class="contentadminui min-h-[92%] w-full md:p-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
