<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text headerlistener-text md:break-keep">
        Manage Talks
      </h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text sub-headerlistener-text">
        {{ totalCount }}
      </h1>
    </div>
  </div>
  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui custom-btn-primary-listener gap-1"
    [routerLink]="['/talks/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Add Talks</span>
    </div>
  </div>
</div>
<nz-spin [nzSpinning]="pageLoading">
  <nz-table
    #talksTable
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
    [nzBordered]="true"
    [nzData]="data"
    [nzPageIndex]="currentPageNo"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageSize]="pageSize"
    [nzScroll]="{ y: '500px' }"
    [nzShowPagination]="true"
    [nzShowSizeChanger]="true"
    [nzTitle]="tableHead"
    [nzTotal]="totalCount"
    class="mt-5"
    nzFrontPagination="false"
    nzTableLayout="fixed"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>Category</th>
        <th>Experts</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let talk of talksTable.data">
        <td>
          {{ talk.name }}
          <div *ngIf="talk.mode == 'RECURRING'">
            {{ getFrequency(talk.cronText!) }}
            <br />
            Next Live: {{ formatDate(talk.nextLiveOn) }}
          </div>

          <br />
          <nz-tag nzColor="cyan">{{ talk.mode }}</nz-tag>
        </td>
        <td>
          {{ talk.category }}
        </td>
        <td>
          <nz-tag
            class="mt-1"
            *ngFor="let expert of talk.experts"
            nzColor="purple"
            >{{ expert.name }}</nz-tag
          >
        </td>
        <td>
          <div class="flex items-center justify-evenly">
            <span nz-tooltip nzTooltipTitle="View therapist">
              <button
                [routerLink]="['/talks/edit/' + talk.id]"
                nz-button
                nzType="primary"
                nzShape="circle"
              >
                <span nz-icon nzType="eye" nzTheme="outline"></span>
              </button>
            </span>
          </div>
          <button
            (click)="joinLive(talk.id)"
            *ngIf="talk.live"
            nz-button
            nzType="primary"
          >
            Join Live
          </button>
          <button
            (click)="goLive(talk.id)"
            *ngIf="!talk.live && talk.goLiveAllowed"
            nz-button
            nzType="primary"
          >
            Go Live
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Talks</span>
    </div>
    <div nz-col>
      <button (click)="resetFilter()" nz-button nzSize="small" nzType="primary">
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
