import { Component } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { MasterdataService } from '../../../services/masterdata.service';
import { RoutineService } from '../../../services/routine.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { lastValueFrom } from 'rxjs';
import { ContentService } from '../../../services/content.service';
import { IContent } from '../../../models/IContent';
import { Day } from '../../../models/IRoutine';
import { NewRoutineRequest } from '../../../models/newRoutineRequest';
import { Router } from '@angular/router';
import * as moment from 'moment';

interface SelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent {
  constructor(
    private auth: AuthService,
    private masterDataService: MasterdataService,
    private toastService: ToastService,
    private fileService: FileUtilsService,
    private contentService: ContentService,
    private routineService: RoutineService,
    private router: Router,
  ) {}

  selectedGroupString!: string;
  selectedDifficultyString!: string;
  selectedTimingString!: string;
  groups!: SelectOptions[] | undefined;
  difficultys!: SelectOptions[] | undefined;
  timings!: SelectOptions[] | undefined;
  routineName!: string;
  routineDescription!: string;
  categories: SelectOptions[] | undefined = [];
  genres: SelectOptions[] = [];
  contents: SelectOptions[] | undefined = [];
  routineSpinning!: boolean;
  masterData: MasterDataResponse | undefined;
  estimatedTime!: string;
  instructor!: string;
  moduleMediaFiles = new Map<number, File>();
  moduleThumbnailFiles = new Map<number, File>();
  private routineBannerFile: File | undefined;
  newRoutineId!: string;
  days: Day[] = [];
  selectedGenreString!: string;
  selectedCategoryString!: string;
  contentsMasterData!: Array<IContent>;

  addRoutine() {
    const genres = this.masterData?.genre.map((gen) => {
      return {
        label: gen.data,
        value: gen.data,
      };
    });
    this.days.push({
      genres: genres,
      selectedCategoryString: '',
      selectedGenreString: '',
      uid: '',
      dayNumber: this.days.length + 1,
      contentId: '',
      difficulty: '',
      uploadRequired: false,
    });
  }
  deleteRoutine(index: number | undefined) {
    if (index != undefined) {
      this.days.splice(index, 1);
      for (let i = index; i < this.days.length; i++) {
        this.days[i].dayNumber = i + 1;
      }
    }
  }

  loadMasterData() {
    this.masterDataService.masterDataList().subscribe((response) => {
      this.groups = response.routineGroup.map((group) => {
        return {
          label: group.data,
          value: group.m_id?.toString()!,
        };
      });
      this.difficultys = response.difficultyLevels.map((difficult) => {
        return {
          label: difficult.data,
          value: difficult.m_id?.toString()!,
        };
      });
      this.timings = response.routineTiming.map((timing) => {
        return {
          label: timing.data,
          value: timing.m_id?.toString()!,
        };
      });
      this.genres = response.genre.map((gen) => {
        return {
          label: gen.data,
          value: gen.data,
        };
      });
      this.masterData = response;
    });
  }

  genreChangeFn($event: string, index: number) {
    const value = this.masterData?.categories.filter(
      (data) => data.genre === $event,
    );
    if (index >= 0 && index < this.days.length) {
      this.days[index].categories = value?.map((data) => {
        return {
          label: data.category,
          value: data.category!,
        };
      });
    }
  }

  categoriesChangeFn($event: string, index: number) {
    const req = {
      category: $event,
    };
    this.routineService.getContentsByCategory(req).subscribe({
      next: (response) => {
        if (index >= 0 && index < this.days.length) {
          this.days[index].contents = response.map((contentData) => {
            return {
              label: contentData.practiceName,
              value: contentData._id?.toString()!,
            };
          });
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  contentChangeFn($event: string, index: number) {
    this.contentService.getContent($event).subscribe({
      next: (response) => {
        this.days[index].instructors = response.instructor
          .map((obj) => obj.name)
          .join(', ');
        const totalEstimatedTime = response.module.reduce(
          (prevValue, currentValue) => {
            const estimatedTotalTimeinSeconds = moment.duration(
              currentValue.estimatedTime,
              'seconds',
            );
            const estimatedTotalTime = estimatedTotalTimeinSeconds.asMinutes();
            return prevValue + estimatedTotalTime;
          },
          0,
        );
        this.days[index].estimatedTotalTime = totalEstimatedTime;
        this.days[index].about = response.about;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  async saveRoutine() {
    try {
      this.routineSpinning = true;
      const newRoutineRequest: NewRoutineRequest = {
        newRoutineId: this.newRoutineId,
        group: this.selectedGroupString,
        routineName: this.routineName,
        timing: this.selectedTimingString,
        banner: '',
        difficulty: this.selectedDifficultyString,
        about: this.routineDescription,
        days: [],
      };
      if (this.routineBannerFile) {
        const extension = this.routineBannerFile.name.split('.').pop();
        const filePlath = `routine/${this.newRoutineId}/routinebanner.${extension}`;
        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePlath,
            this.routineBannerFile.type,
          ),
        );
        this.toastService.showSuccess('Uploading Routine Banner');
        await lastValueFrom(
          this.fileService.uploadFile(this.routineBannerFile, uploadUrl.url),
        );
        newRoutineRequest.banner = `https://cdn.anyo.app/${filePlath}`;
        this.toastService.showSuccess('Uploading Routine Banner completed');
      }
      const days: Day[] = [];
      for (const value of this.days) {
        const day: Day = {
          uid: uuidv4(),
          dayNumber: value.dayNumber,
          contentId: value.contentId,
          difficulty: value.difficulty,
          uploadRequired: value.uploadRequired,
        };
        days.push(day);
      }
      newRoutineRequest.days = days;
      this.routineService.createRoutine(newRoutineRequest).subscribe({
        next: (value) => {
          this.toastService.showSuccess('Routine added successfully');
          this.routineSpinning = false;
          this.router.navigate(['/routine']);
        },
        error: (err) => {
          const errorBody = err.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
          this.routineSpinning = false;
        },
      });
    } catch (e) {
      console.log(e);
      this.toastService.showError(e + '');
      this.routineSpinning = false;
    }
  }

  uploadRoutineBanner($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.routineBannerFile = file;
    }
  }

  ngOnInit(): void {
    this.routineSpinning = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.loadMasterData();
        this.contentService.getNewContentId().subscribe({
          next: (response) => {
            this.newRoutineId = response.data;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });

        this.routineSpinning = false;
      }
    });
  }
}
