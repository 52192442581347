import { Component } from '@angular/core';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { RolesPermissionService } from '../../../services/roles-permission.service';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { AddRolesAndPermissionRequest } from '../../../models/addRolesAndPermissionRequest';
import { Permissions, Roles } from 'projects/app-core/src/auth/models/roles';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

interface UserData extends IAnyoUser {
  firebaseDetails: {
    roles: { color: string; value: string }[];
    additionalPermissions: { color: string; value: string }[];
  };
}

interface selectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrl: './manage.component.scss',
})
export class ManageComponent {
  displayAddAdmin = false;
  selectedUser!: UserData;
  userSpinner = false;
  listOfSelectedRoles: Roles[] = [];
  listOfSelectedPermission: Permissions[] = [];
  listOfRoles: selectOptions[] = [];
  listOfPermission: selectOptions[] = [];
  listOfData: UserData[] = [];

  isOkLoading = false;
  commonFlag = false;

  constructor(
    private auth: AuthService,
    private rolesPermissionService: RolesPermissionService,
    private toastrService: ToastService,
    private utils: UtilsService,
  ) {}

  listUser() {
    this.rolesPermissionService.listData().subscribe({
      next: (users) => {
        const data: UserData[] = users.map((userData) => {
          const additionalPermissions = userData.firebaseDetails
            ? userData.firebaseDetails.additionalPermissions.map(
                (permission) => ({
                  value: permission,
                  color: this.utils.getRandomColor(),
                }),
              )
            : [];
          const roles = userData.firebaseDetails
            ? userData.firebaseDetails.roles.map((role) => ({
                value: role,
                color: this.utils.getRandomColor(),
              }))
            : [];

          return {
            ...userData,
            firebaseDetails: {
              ...userData.firebaseDetails,
              additionalPermissions,
              roles,
            },
          };
        });
        this.listOfData = data;
        this.userSpinner = false;
      },
      error: (error) => {
        this.userSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit() {
    this.userSpinner = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.rolesPermissionService.rolesAndPermission().subscribe({
          next: (value) => {
            this.listOfPermission = value.permission.map((data) => {
              return {
                label: data.replace(/([A-Z])/g, ' $1').trim(),
                value: data,
              };
            });
            this.listOfRoles = value.roles.map((data) => {
              return {
                label: data.replace(/([A-Z])/g, ' $1').trim(),
                value: data,
              };
            });
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastrService.showAnyoErrorToast(errorBody.description);
          },
        });
        if (user?.customClaims?.roles.includes(Roles.SuperAdmin)) {
          this.displayAddAdmin = true;
        }
        this.listUser();
      }
    });
  }

  isVisible = false;

  showModal(data: UserData) {
    this.selectedUser = data;
    this.isVisible = true;
    this.listOfSelectedPermission =
      data.firebaseDetails.additionalPermissions.map(
        (data) => data.value,
      ) as Permissions[];
    this.listOfSelectedRoles = data.firebaseDetails.roles.map(
      (data) => data.value,
    ) as Roles[];
  }

  handleOk(): void {
    this.isOkLoading = true;
    const request: AddRolesAndPermissionRequest = {
      email: this.selectedUser.email!,
      roles:
        this.listOfSelectedRoles.length > 0 ? this.listOfSelectedRoles : [],
      permissions:
        this.listOfSelectedPermission.length > 0
          ? this.listOfSelectedPermission
          : [],
    };
    this.rolesPermissionService.addRolesAndPermission(request).subscribe({
      next: (value) => {
        this.isVisible = false;
        this.isOkLoading = false;
        this.toastrService.showSuccess('Successfully Roles Updated');
        this.listUser();
      },
      error: (value) => {
        this.isVisible = false;
        this.isOkLoading = false;
        const errorBody = value.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  enableDisableForUserInAllColl(data: UserData) {
    this.userSpinner = true;
    const request = {
      consoleUserEnableDisableFlag: !data.active,
    };
    this.rolesPermissionService
      .enableDisableCommonFlagForUser(request, data.userId!)
      .subscribe({
        next: (value) => {
          this.userSpinner = false;
          this.toastrService.showSuccess('Update Successfully');
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
          this.userSpinner = false;
        },
      });
  }
}
