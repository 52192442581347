import { GridRequest } from "projects/app-core/src/models/gridRequest";


export interface TherapyPlanSubscriptionsGridRequest
  extends GridRequest<
  TherapyPlanSubscriptionsSort,
  TherapyPlanSubscriptionsFilter,
  TherapyPlanSubscriptionsSearch,
  TherapyPlanSubscriptionsDateFilter
  > {}

export enum TherapyPlanSubscriptionsSort {
  THERAPY_CREATED_AT = "CREATED_AT",
  THERAPY_STATUS = "STATUS",
}
export enum TherapyPlanSubscriptionsSearch {
  PLAN_NAME = "PLAN_NAME",
  USER_NAME = "USER_NAME"

}

export enum TherapyPlanSubscriptionsDateFilter {
  CREATED_AT = "CREATED_AT",
  VALID_TILL = "VALID_TILL"
}

export enum TherapyPlanSubscriptionsFilter {
  VERTICAL = "VERTICAL",
  CONCERN = "CONCERN",
  USER_ID = "USER_ID",
  PARTNER_ID = "PARTNER_ID",
  PAYMENT_TYPE = "PAYMENT_TYPE",
  PLAN_NAME = "PLAN_NAME"
}
