import { Component } from '@angular/core';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { IContent } from '../../../models/IContent';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { ContentService } from '../../../services/content.service';
import { RoutineService } from '../../../services/routine.service';
import { Day } from '../../../models/IRoutine';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { UpdateRoutineRequest } from '../../../models/updateRoutineRequest';
import { lastValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

interface SelectOptions {
  label: string;
  value: string;
}

export interface ContentResponseForRoutine extends IContent {
  estimatedTotalTime: number;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent {
  selectedGroupString!: string;
  selectedDifficultyString!: string;
  selectedTimingString!: string;
  groups!: SelectOptions[] | undefined;
  difficultys!: SelectOptions[] | undefined;
  timings!: SelectOptions[] | undefined;
  routineName!: string;
  routineDescription!: string;
  categories: SelectOptions[] | undefined = [];
  genres: SelectOptions[] = [];
  contents: SelectOptions[] | undefined = [];
  routineSpinning!: boolean;
  masterData: MasterDataResponse | undefined;
  estimatedTime!: number;
  instructors!: string;
  routineBannerFile: File | undefined;
  routineId!: string;
  days: Day[] = [];
  selectedGenreString!: string;
  selectedCategoryString!: string;
  contentsMasterData!: Array<IContent>;
  selectedContentString!: string;
  viewMode: boolean = true;
  contentThumbnail!: string;
  contentBanner!: string;
  totalDaysEstimatedTime!: string;
  routineBannerImage!: string;

  constructor(
    private auth: AuthService,
    private masterDataService: MasterdataService,
    private toastService: ToastService,
    private fileService: FileUtilsService,
    private activatedRoute: ActivatedRoute,
    private routineService: RoutineService,
    private contentService: ContentService,
  ) {}

  editMode() {
    this.viewMode = false;
  }

  addRoutine() {
    const genres = this.masterData?.genre.map((gen) => {
      return {
        label: gen.data,
        value: gen.data,
      };
    });
    this.days.push({
      genres: genres,
      selectedCategoryString: '',
      selectedGenreString: '',
      uid: '',
      dayNumber: this.days.length + 1,
      contentId: '',
      difficulty: '',
      uploadRequired: false,
    });
  }
  deleteRoutine(index: number | undefined) {
    if (index != undefined) {
      this.days.splice(index, 1);
      for (let i = index; i < this.days.length; i++) {
        this.days[i].dayNumber = i + 1;
      }
    }
  }

  loadMasterData() {
    this.masterDataService.masterDataList().subscribe((response) => {
      this.groups = response.routineGroup.map((group) => {
        return {
          label: group.data,
          value: group.m_id?.toString()!,
        };
      });
      this.difficultys = response.difficultyLevels.map((difficult) => {
        return {
          label: difficult.data,
          value: difficult.m_id?.toString()!,
        };
      });
      this.timings = response.routineTiming.map((timing) => {
        return {
          label: timing.data,
          value: timing.m_id?.toString()!,
        };
      });
      this.genres = response.genre.map((gen) => {
        return {
          label: gen.data,
          value: gen.data,
        };
      });
      this.masterData = response;
    });
  }

  genreChangeFn($event: string, index: number) {
    const value = this.masterData?.categories.filter(
      (data) => data.genre === $event,
    );
    if (index >= 0 && index < this.days.length) {
      this.days[index].categories = value?.map((data) => {
        return {
          label: data.category,
          value: data.category!,
        };
      });
    }
  }

  categoriesChangeFn($event: string, index: number) {
    const req = {
      category: $event,
    };
    this.routineService.getContentsByCategory(req).subscribe({
      next: (response) => {
        if (index >= 0 && index < this.days.length) {
          this.days[index].contents = response.map((contentData) => {
            return {
              label: contentData.practiceName,
              value: contentData._id?.toString()!,
            };
          });
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  contentChangeFn($event: string, index: number) {
    this.contentService.getContent($event).subscribe({
      next: (response) => {
        this.days[index].instructors = response.instructor
          .map((data) => data.name)
          .join(', ');
        const totalEstimatedTime = response.module.reduce(
          (prevValue, currentValue) => {
            const estimatedTotalTimeinSeconds = moment.duration(
              currentValue.estimatedTime,
              'seconds',
            );
            const estimatedTotalTime = estimatedTotalTimeinSeconds.asMinutes();
            return prevValue + estimatedTotalTime;
          },
          0,
        );
        this.days[index].estimatedTotalTime = totalEstimatedTime;
        this.days[index].about = response.about;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  async updateRoutine() {
    try {
      this.routineSpinning = true;
      const updateRoutineRequest: UpdateRoutineRequest = {
        group: this.selectedGroupString,
        routineName: this.routineName,
        timing: this.selectedTimingString,
        banner: this.routineBannerImage,
        difficulty: this.selectedDifficultyString,
        about: this.routineDescription,
        days: [],
      };
      if (this.routineBannerFile) {
        const extension = this.routineBannerFile.name.split('.').pop();
        const filePlath = `routine/${this.routineId}/routinebanner.${extension}`;
        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePlath,
            this.routineBannerFile.type,
          ),
        );
        this.toastService.showSuccess('Uploading Routine Banner');
        await lastValueFrom(
          this.fileService.uploadFile(this.routineBannerFile, uploadUrl.url),
        );
        updateRoutineRequest.banner = `https://cdn.anyo.app/${filePlath}`;
        this.toastService.showSuccess('Uploading Routine Banner completed');
      }
      const days: Day[] = [];
      for (const value of this.days) {
        if (value.uid) {
          const day: Day = {
            uid: value.uid,
            dayNumber: value.dayNumber,
            contentId: value.contentId,
            difficulty: value.difficulty,
            uploadRequired: value.uploadRequired,
          };
          days.push(day);
        } else {
          const day: Day = {
            uid: uuidv4(),
            dayNumber: value.dayNumber,
            contentId: value.contentId,
            difficulty: value.difficulty,
            uploadRequired: value.uploadRequired,
          };
          days.push(day);
        }
      }
      updateRoutineRequest.days = days;
      this.routineService
        .updateRoutine(this.routineId, updateRoutineRequest)
        .subscribe({
          next: (value) => {
            this.toastService.showSuccess('Routine Updated successfully');
            this.routineSpinning = false;
            this.ngOnInit();
          },
          error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
            this.routineSpinning = false;
          },
        });
    } catch (e) {
      this.toastService.showError(e + '');
      this.routineSpinning = false;
    }
  }

  async getRoutine(id: string) {
    this.routineSpinning = true;
    this.routineService.getRoutine(id).subscribe({
      next: async (response) => {
        this.days = [];
        this.selectedGroupString = response.group;
        this.routineName = response.routineName;
        this.selectedTimingString = response.timing;
        this.selectedDifficultyString = response.difficulty;
        this.routineDescription = response.about;
        const totalDaysEstimatedTimeinSeconds = moment.duration(
          response.totalDaysEstimatedTime,
          'seconds',
        );
        const totalDaysEstimatedTime =
          totalDaysEstimatedTimeinSeconds.asMinutes();
        this.totalDaysEstimatedTime = totalDaysEstimatedTime.toString();
        this.routineBannerImage = response.banner;
        this.instructors = response.instructors.join(', ');

        const processDays = async () => {
          for (let i = 0; i < response.days.length; i++) {
            const content = response.days[i]
              .contentId as ContentResponseForRoutine;
            this.genreChangeFn(content.category?.genre!, i);
            const req = {
              category: content.category?.category!,
            };

            try {
              const contentsResponse = await lastValueFrom(
                this.routineService.getContentsByCategory(req),
              );
              const contents = contentsResponse?.map((contentData) => ({
                label: contentData.practiceName,
                value: contentData._id?.toString()!,
              }));
              const value = this.masterData?.categories.filter(
                (data) => data.genre === content.category?.genre,
              );
              const genres = this.masterData?.genre.map((gen) => {
                return {
                  label: gen.data,
                  value: gen.data,
                };
              });

              const categories = value?.map((data) => {
                return {
                  label: data.category,
                  value: data.category!,
                };
              });
              const estimatedTotalTimeinSeconds = moment.duration(
                content.estimatedTotalTime,
                'seconds',
              );
              const estimatedTotalTime =
                estimatedTotalTimeinSeconds.asMinutes();

              const dayData = {
                dayNumber: response.days[i].dayNumber,
                difficulty: response.days[i].difficulty,
                uploadRequired: response.days[i].uploadRequired,
                contentThumbnail: content.thumbnail,
                contentBanner: content.contentBanner,
                selectedCategoryString: content.category?.category!,
                selectedGenreString: content.category?.genre!,
                contentId: content._id?.toString()!,
                contents: contents,
                genres: genres,
                categories: categories,
                instructors: content.instructor
                  .map((data) => data.name)
                  .join(', '),
                estimatedTotalTime: estimatedTotalTime,
              };
              this.days.push(dayData);
            } catch (error: any) {
              const errorBody = error?.error as IAnyoError;
              this.toastService.showAnyoErrorToast(errorBody.description);
            }
          }

          this.routineSpinning = false;
        };

        processDays();
        this.routineSpinning = false;
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.routineSpinning = false;
      },
    });
  }

  uploadRoutineBanner($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.routineBannerFile = file;
    }
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        if (this.activatedRoute.snapshot.params) {
          const id = this.activatedRoute.snapshot.params['id'];
          this.routineId = id;
          this.getRoutine(id);
          this.loadMasterData();
        }
      }
    });
  }
}
