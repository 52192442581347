import { Component } from '@angular/core';
import { ModeratorEvents } from '../../model/ModeratorEvents';
import { PostsService } from '../../service/posts.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { MediaMeta, MediaType } from '../../model/CirclePost';
import { ToastService } from '../../../services/toastr.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

@Component({
  selector: 'app-moderation-events-table',
  templateUrl: './moderation-events-table.component.html',
  styleUrl: './moderation-events-table.component.scss',
})
export class ModerationEventsTableComponent {
  postsSpinner = false;
  tableData: ModeratorEvents[] = [];
  postsText = '';
  protected readonly MediaType = MediaType;

  constructor(
    private posts: PostsService,
    private auth: AuthService,
    private toastrService: ToastService,
    private activatedRoute: ActivatedRoute,
    public utils: UtilsService,
  ) {}

  media: MediaMeta[] | undefined;

  convertIsoToFormattedDate(isoDate: Date): string {
    return moment(isoDate).format('DD/MM/YYYY hh:mm A');
  }

  getPost(id: string) {
    this.posts.getPost(id).subscribe({
      next: (value) => {
        this.postsText = value.text;
        if (value.media.length > 0) {
          this.media = value.media;
        }
        if (value.moderationEvents.length > 0) {
          this.tableData = value.moderationEvents;
          value.moderationEvents.forEach((data) => {
            data.randomColor = this.utils.getRandomColor();
          });
        }
        this.toastrService.showSuccess('Post View');
        this.postsSpinner = false;
      },
      error: (value) => {
        this.postsSpinner = false;
      },
    });
  }

  visibleViewMedia = false;
  closeViewMedia() {
    this.visibleViewMedia = false;
  }

  openViewMedia() {
    this.visibleViewMedia = true;
  }

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.activatedRoute.params.subscribe((data) => {
          if (data['postId']) {
            this.getPost(data['postId']);
          }
        });
      }
    });
  }
}
