<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <!-- <img class="left-arrow" src="../../../../assets/icons/left-arrow.svg"> -->
      <h1 class="headeradminui-text md:break-keep">Manage Therapist</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Therapists</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">
        {{ activeCount }} Active Therapists
      </h1>
    </div>
  </div>
  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/therapist/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Add new therapist</span>
    </div>
  </div>
</div>
<div class="flex justify-end">
  <div class="w-[40%]">
    <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
      <input
        type="text"
        nz-input
        [(ngModel)]="globalSearchInput"
        placeholder="Search for therapist name,email,mobile no."
        (input)="onInputChange()"
      />
    </nz-input-group>
  </div>
</div>
<ng-template #suffixButton>
  <button
    nz-button
    nzType="primary"
    nzSize="small"
    nzSearch
    (click)="searchGlobal()"
  >
    Search
  </button>
</ng-template>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Therapists</span>
    </div>
    <div nz-col>
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        [disabled]="isButtonDisabled"
        (click)="resetFilter()"
      >
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSpinning]="therapistTableSpinner" nzTip="Fetching Therapists......">
  <nz-table
    #therapistTable
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzData]="tableData"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="therapistTableTotalCount"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="therapistTableCurrentPageNo"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>
          Therapists
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="therapistNameSearch"
            [(nzVisible)]="isTherapistNameSearch"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>
          Email ID
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="therapistEmailSearch"
            [(nzVisible)]="isTherapistEmailSearch"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>Last Active On</th>
        <th nzCustomFilter>
          Status
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="statusMenu"
            [(nzVisible)]="isStatusMenu"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>No of Sessions</th>
        <th>No of Clients</th>
        <th>Rating</th>
        <th nzWidth="25%">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of therapistTable.data">
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.firstName + " " + data.lastName }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.email }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ timeAgo(data.lastSeen) }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.status }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.noOfSessions }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.noOfClients }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }">
          {{ data.rating }}
        </td>
        <td [ngClass]="{ 'bg-gray-200': data.status == 'inactive' }" nzRight>
          <div class="flex items-center justify-evenly">
            <span nz-tooltip nzTooltipTitle="View therapist">
              <button
                [routerLink]="['/therapist/add/' + data.id]"
                nz-button
                nzType="primary"
                nzShape="circle"
              >
                <span nz-icon nzType="eye" nzTheme="outline"></span>
              </button>
            </span>
            <nz-switch
              [(ngModel)]="data.isAvailable"
              (click)="enableDisableTherapist(data)"
            ></nz-switch>
            <app-mark-unavailable-slot
              [therapistId]="data.id!"
              [therapistName]="data.firstName + ' ' + data.lastName"
            ></app-mark-unavailable-slot>
          </div>
        </td>
      </tr>

      <tr></tr>
    </tbody>
  </nz-table>
</nz-spin>
<nz-dropdown-menu #therapistNameSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="therapistNameColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
          (input)="onInputChange()"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="therapistNameSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
          [disabled]="isButtonDisabled"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #therapistEmailSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="therapistEmailColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
          (input)="onInputChange()"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="therapistEmailSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
          [disabled]="isButtonDisabled"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #statusMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Status</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="statusFilter($event)">
          <div *ngFor="let status of statusCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="status.value"
                [ngModel]="status.checked"
                >{{ status.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="listTherapists()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
