import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-listener',
  templateUrl: './card-listener.component.html',
  styleUrls: ['./card-listener.component.scss'],
})
export class CardListenerComponent {
  @Input() cardContent!: string;
  @Input() cardValue!: string;
}
