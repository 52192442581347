import { Component } from '@angular/core';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ContentService } from '../../../services/content.service';
import { TherapistService } from '../../../services/therapist.service';
import { AddNewCirclesRequest } from '../../../models/circles/newAddAnyoCircles';
import * as moment from 'moment';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { lastValueFrom } from 'rxjs';
import { CirclesService } from '../../../services/circles.service';
import { CircleMasterDataResponse } from '../../../models/circles/circleMasterDataResponse';
import { IAnyoTherapist } from '../../../models/IAnyoTherapist';
import { UpdateCircleExpertRequest } from '../../../models/circles/updateExpertRequest';
import { UpdateCircleModeratorRequest } from '../../../models/circles/updateModeratorRequest';
import { Router } from '@angular/router';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { CircleTopics } from '../../../models/circles/AnyoCircles';

interface selectOptions {
  label: string;
  value: string;
}

interface subTopics {
  key: string;
  value: string;
}

interface CircleExpert {
  expertId: string;
  id: string;
  listenerId: string;
  languages: string[];
  name: string;
  pic: File | undefined;
  avatarImage: string;
  description: string;
  qualifications: string;
  isActive: boolean;
}

interface CircleModerator {
  id: string;
  moderatorId: string;
  email: string;
  active: boolean;
  name: string;
  about: string;
  pic: File | undefined;
  avatarImage: string;
  qualifications: string;
  contactNo: string;
}

interface CircleTherapist {
  id: string;
  email: string;
  name: string;
  description: string;
  active: string;
  profilepic: string;
  languages: string[];
}

@Component({
  selector: 'app-add-circle',
  templateUrl: './add-circle.component.html',
  styleUrl: './add-circle.component.scss',
})
export class AddCircleComponent extends AnyoComponent {
  userInterestTopics: CircleTopics[] = [];
  selectedUserInterests: string[] = [];
  override async ready(user?: IAnyoUser | undefined) {
    this.circlesSpinning = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.contentService.getNewContentId().subscribe({
          next: (response) => {
            this.newId = response.data;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
        this.therapistService.therapistList().subscribe({
          next: (value) => {
            this.circleTherapists = value.map((data) => {
              return {
                label: data.firstName + data.lastName,
                value: data._id!,
              };
            });
            this.therapistList = value;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });

        this.circleService.getCircleMasterData().subscribe((response) => {
          this.circleExperts = response.circleExperts.map((data) => {
            return {
              label: data.name,
              value: data._id.toString(),
            };
          });
          this.listeners = response.listeners.map((data) => {
            return {
              label: data.name,
              value: data._id!.toString()!,
            };
          });

          this.circleModerators = response.circleModerators.map((data) => {
            return {
              label: data.name,
              value: data._id.toString(),
            };
          });

          this.masterData = response;
        });
      }
    });
    this.userInterestTopics = await this.circleService.getUserInterestsMeta();
    this.circlesSpinning = false;
  }
  circlesSpinning: boolean = true;
  circleName!: string;
  circleDescription!: string;
  subTopics: subTopics[] = [];
  selectedModerators: string[] = [];
  selectedTherapists: string[] = [];
  selectedExperts: string[] = [];
  moderators: CircleModerator[] = [];
  therapists: CircleTherapist[] = [];
  isActive: boolean = false;
  isAnonymous: boolean = false;
  experts: CircleExpert[] = [];
  newId!: string;
  shareMessage!: string;
  shareImageFile!: File;
  circleProfilePic!: File;
  listeners: selectOptions[] = [];
  masterData!: CircleMasterDataResponse;
  editCacheExperts: { [key: string]: { edit: boolean; data: CircleExpert } } =
    {};
  editCacheModerator: {
    [key: string]: { edit: boolean; data: CircleModerator };
  } = {};

  circleExperts: selectOptions[] = [];
  circleModerators: selectOptions[] = [];
  circleTherapists: selectOptions[] = [];
  therapistList: IAnyoTherapist[] = [];
  circleColor!: string;

  constructor(
    private toastService: ToastService,
    protected override auth: AuthService,
    private contentService: ContentService,
    private therapistService: TherapistService,
    private fileService: FileUtilsService,
    private circleService: CirclesService,
    private router: Router,
  ) {
    super(auth);
  }

  uploadCircleProfilePic($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.circleProfilePic = file;
    }
  }

  uploadCirclesSharePic($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.shareImageFile = file;
    }
  }

  addSubTopic() {
    this.subTopics.push({ value: '', key: '' });
  }

  deleteSubTopic(index: number) {
    if (index != undefined) {
      this.subTopics.splice(index, 1);
    }
  }

  generateKeyFromValue(value: string): string {
    const key = value.toUpperCase().replace(/\s+/g, '_');
    return key;
  }
  async saveCircle() {
    const updatedSubTopics = this.subTopics.map((data) => {
      return {
        key: this.generateKeyFromValue(data.value),
        value: data.value,
      };
    });

    const newCircleRequest: AddNewCirclesRequest = {
      name: this.circleName,
      description: this.circleDescription,
      shareImage: '',
      shareMessage: this.shareMessage,
      avatarImage: '',
      anonymous: this.isAnonymous,
      active: this.isActive,
      circleTopics: updatedSubTopics,
      moderatorIds: this.selectedModerators,
      expertIds: this.selectedExperts,
      therapistIds: this.selectedTherapists,
      circleColor: this.circleColor,
      userInterestTopics: this.userInterestTopics
        .filter((value) => this.selectedUserInterests.includes(value.key))
        .map((value) => {
          return {
            key: value.key,
            value: value.value,
          };
        }),
    };
    if (this.circleProfilePic) {
      const extension = this.circleProfilePic.name.split('.').pop();
      const filePlath = `circles/${this.newId}/profilepic.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePlath,
          this.circleProfilePic.type,
        ),
      );
      this.toastService.showSuccess('Uploading Circle Pic');
      await lastValueFrom(
        this.fileService.uploadFile(this.circleProfilePic, uploadUrl.url),
      );
      newCircleRequest.avatarImage = `https://cdn.anyo.app/${filePlath}`;
      this.toastService.showSuccess('Uploading Circle Pic completed');
    }
    if (this.shareImageFile) {
      const extension = this.shareImageFile.name.split('.').pop();
      const filePlath = `circles/${this.newId}/sharepic.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePlath,
          this.shareImageFile.type,
        ),
      );
      this.toastService.showSuccess('Uploading Share Image');
      await lastValueFrom(
        this.fileService.uploadFile(this.shareImageFile, uploadUrl.url),
      );
      newCircleRequest.shareImage = `https://cdn.anyo.app/${filePlath}`;
      this.toastService.showSuccess('Uploading Share Image completed');
    }
    this.circleService.createCircle(newCircleRequest).subscribe({
      next: () => {
        this.toastService.showSuccess('Circle Successfully Created');
        this.circlesSpinning = false;
        this.router.navigate(['/circles']);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.circlesSpinning = false;
      },
    });
  }

  populateExperts(selectedExpertIds: string[]): void {
    const selectedExperts = this.masterData.circleExperts.filter((expert) =>
      selectedExpertIds.includes(expert._id),
    );

    const data: CircleExpert[] = [];
    for (let i = 0; i < selectedExperts.length; i++) {
      const expert = selectedExperts[i];
      const listener = this.masterData.listeners.find(
        (data) => data._id === expert.listenerId,
      );
      data.push({
        id: `${i}`,
        expertId: expert._id,
        listenerId: expert.listenerId,
        description: expert.about,
        name: expert.name,
        pic: undefined,
        avatarImage: expert.avatarImage,
        qualifications:
          expert.qualifications == null ? '' : expert.qualifications.join(','),
        languages: listener!.languages!,
        isActive: expert.active,
      });
    }
    this.experts = data;
    this.setEditCacheExpert();
  }

  startEditExpert(id: string) {
    this.editCacheExperts[id].edit = true;
  }

  cancelEditExpert(id: string): void {
    const index = this.experts.findIndex((item) => item.id === id);

    // Check if the entry exists in editCache before accessing its properties
    if (this.editCacheExperts[id]) {
      this.editCacheExperts[id].data = { ...this.experts[index] };
      this.editCacheExperts[id].edit = false;
    }
  }

  async saveEditExpert(id: string) {
    this.circlesSpinning = true;
    const index = this.experts.findIndex((item) => item.id === id);
    // Check if the entry exists in editCache before accessing its properties
    if (this.editCacheExperts[id]) {
      Object.assign(this.experts[index], this.editCacheExperts[id].data);
      this.editCacheExperts[id].edit = false;
    }
    const expert = this.experts.find((item) => item.id === id);
    if (expert) {
      const updateCircleExpert: UpdateCircleExpertRequest = {
        listenerId: expert?.listenerId,
        about: expert?.description,
        avatarImage: expert?.avatarImage,
        qualifications: expert?.qualifications.split(','),
        isActive: expert.isActive,
      };
      if (expert.pic) {
        try {
          const extension = expert.pic.name.split('.').pop();
          const timestamp = moment().format('x');
          const filePath = `circles/${this.newId}/expertprofile_${timestamp}.${extension}`;
          const uploadUrl = await lastValueFrom(
            this.fileService.generateUploadUrl(
              AnyoS3Buckets.CDN_BUCKET,
              filePath,
              expert.pic.type,
            ),
          );
          this.toastService.showSuccess(`Uploading Expert Profile`);
          await lastValueFrom(
            this.fileService.uploadFile(expert.pic, uploadUrl.url),
          );
          this.toastService.showSuccess(`Uploading Expert Profile completed`);
          expert.avatarImage = `https://cdn.anyo.app/${filePath}`;
        } catch (e) {
          this.toastService.showError(e + '');
          throw e;
        }
      }
      this.circleService
        .updateExpert(updateCircleExpert, expert.expertId)
        .subscribe({
          next: () => {
            this.toastService.showSuccess('Expert Sucessfully Updated');
            this.circlesSpinning = false;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
            this.circlesSpinning = false;
          },
        });
    }
  }

  setEditCacheExpert(): void {
    this.experts.forEach((item) => {
      // Initialize the entry in editCache if it doesn't exist
      if (!this.editCacheExperts[item.id]) {
        this.editCacheExperts[item.id] = {
          edit: false,
          data: { ...item },
        };
      } else {
        // Update the existing entry's data property
        this.editCacheExperts[item.id].data = { ...item };
      }
    });
  }

  populateListener(listenerId: string, id: string) {
    const listener = this.masterData.listeners.find(
      (data) => data._id === listenerId,
    );
    if (listener) {
      this.editCacheExperts[id].data.name = listener.name;
    }
  }

  uploadExpertProfilePic($event: any, id: string) {
    const fileInput = $event.target;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      this.editCacheExperts[id].data.pic = file;
    }
  }

  uploadModeratorProfilePic($event: any, id: string) {
    const fileInput = $event.target;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      this.editCacheModerator[id].data.pic = file;
    }
  }

  cancelEditModerator(id: string) {
    const index = this.moderators.findIndex((item) => item.id === id);

    // Check if the entry exists in editCache before accessing its properties
    if (this.editCacheModerator[id]) {
      this.editCacheModerator[id].data = { ...this.moderators[index] };
      this.editCacheModerator[id].edit = false;
    }
  }
  startEditModerator(id: string) {
    this.editCacheModerator[id].edit = true;
  }

  setEditCacheModerator(): void {
    this.moderators.forEach((item) => {
      // Initialize the entry in editCache if it doesn't exist
      if (!this.editCacheModerator[item.id]) {
        this.editCacheModerator[item.id] = {
          edit: false,
          data: { ...item },
        };
      } else {
        // Update the existing entry's data property
        this.editCacheModerator[item.id].data = { ...item };
      }
    });
  }

  async saveEditModerator(id: string) {
    this.circlesSpinning = true;
    const index = this.moderators.findIndex((item) => item.id === id);
    // Check if the entry exists in editCache before accessing its properties
    if (this.editCacheModerator[id]) {
      Object.assign(this.moderators[index], this.editCacheModerator[id].data);
      this.editCacheModerator[id].edit = false;
      const moderator = this.moderators.find((data) => data.id === id);
      if (moderator) {
        const newCircleModeratorRequest: UpdateCircleModeratorRequest = {
          name: moderator?.name,
          about: moderator?.about,
          avatarImage: moderator?.avatarImage,
          active: moderator?.active,
          contactNo: moderator?.contactNo,
          qualifications: moderator.qualifications.split(','),
        };
        if (moderator.pic) {
          try {
            const extension = moderator.pic.name.split('.').pop();
            const timestamp = moment().format('x');
            const filePath = `circles/${this.newId}/moderatorprofile_${timestamp}.${extension}`;
            const uploadUrl = await lastValueFrom(
              this.fileService.generateUploadUrl(
                AnyoS3Buckets.CDN_BUCKET,
                filePath,
                moderator.pic.type,
              ),
            );
            this.toastService.showSuccess(`Uploading Moderator Profile`);
            await lastValueFrom(
              this.fileService.uploadFile(moderator.pic, uploadUrl.url),
            );
            this.toastService.showSuccess(
              `Uploading Moderator Profile completed`,
            );
            moderator.avatarImage = `https://cdn.anyo.app/${filePath}`;
          } catch (e) {
            console.log(e);
            this.toastService.showError(e + '');
            throw e;
          }
        }
        this.circleService
          .updateModerator(newCircleModeratorRequest, moderator.moderatorId)
          .subscribe({
            next: () => {
              this.toastService.showSuccess('Moderator Sucessfully Updated');
              this.circlesSpinning = false;
            },
            error: (error) => {
              const errorBody = error.error as IAnyoError;
              this.toastService.showAnyoErrorToast(errorBody.description);
              this.circlesSpinning = false;
            },
          });
      }
    }
  }

  populateModerators(selectedModeratorIds: string[]): void {
    const selectedModerators = this.masterData.circleModerators.filter(
      (moderator) => selectedModeratorIds.includes(moderator._id),
    );
    const data: CircleModerator[] = [];
    for (let i = 0; i < selectedModerators.length; i++) {
      const moderators = selectedModerators[i];
      data.push({
        id: `${i}`,
        moderatorId: moderators._id,
        email: moderators.email,
        active: moderators.active,
        name: moderators.name,
        about: moderators.about,
        avatarImage: moderators.avatarImage,
        pic: undefined,
        qualifications:
          moderators.qualifications == null
            ? ''
            : moderators.qualifications.join(','),
        contactNo: moderators.contactNo,
      });
    }
    this.moderators = data;
    this.setEditCacheModerator();
  }

  populateTherapists(selectedTherapistIds: string[]) {
    const selectedTherapists = this.therapistList.filter((therapist) =>
      selectedTherapistIds.includes(therapist._id!),
    );
    const data: CircleTherapist[] = [];
    for (let i = 0; i < selectedTherapists.length; i++) {
      const therapist = selectedTherapists[i];
      data.push({
        id: `${i}`,
        email: therapist.email,
        active: therapist.status,
        name: therapist.firstName + therapist.lastName,
        description: therapist.bio,
        profilepic: therapist.picture!,
        languages: therapist.languages,
      });
    }
    this.therapists = data;
  }
}
