import { Component } from '@angular/core';
import { AnyoTableComponent } from 'projects/app-core/src/app/models/AnyoTableComponent';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { TherapyPlanSubscriptionsFilter, TherapyPlanSubscriptionsGridRequest, TherapyPlanSubscriptionsSearch } from '../../../models/therapyPlanSubscribtionGridRequest';
import { TherapyPlansSubcriptionGridResponseData } from '../../../models/therapyPlansSubcriptionGridResponse';
import { ActivatedRoute } from '@angular/router';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';

interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-therapy-plans-subcription',
  templateUrl: './therapy-plans-subcription.component.html',
  styleUrl: './therapy-plans-subcription.component.scss'
})
export class TherapyPlansSubcriptionComponent extends AnyoTableComponent<TherapyPlansSubcriptionGridResponseData,TherapyPlanSubscriptionsGridRequest> {
  planId: string = ''
  userId: string = ''
  override async getData(): Promise<void> {
    this.fetchTherapyPlans()
  }

  override async ready(user?: IAnyoUser): Promise<void> {
    if (user) {
      this.activatedRoute.queryParams.subscribe((value) => {
        if (value) {
          this.planId = value['planName'];
          this.userId = value['userId'];
          console.log(this.planId)
        }
      });
      this.getData()
    } else {
      this.data = [];
    }
    return
  }

  constructor(
    protected override auth: AuthService,
    protected toast: ToastService,
    private therapyPlansService: TherapyPlansService,
    private activatedRoute: ActivatedRoute,
    public utils: UtilsService
  ) {
    super(auth, toast);
  }

  // grid
  therapyPlans: TherapyPlansSubcriptionGridResponseData[] = []
  totalRecords: number = 0
  paymentTypeCheckBoxOptions: CheckboxOptions[] = []
  page =  1
  validTillColumnFilterDate: Date[] = []
  purchasedAtColumnFilterDate: Date[] = []

  planNameColumnSearch = ''
  userNameColumnSearch = ''
  expandSet = new Set<string>();

  onExpandChange(id: string, checked: boolean) {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }


  validTillFilterDateChange($event: any[]){
    console.log($event)
    if ($event && $event.length == 2) {
      const startDate = moment($event[0]).startOf('day').toDate();
      const endDate = moment($event[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.gridRequest = {
        dateFilters: {
          VALID_TILL: {
            from: isToday
              ? moment($event[0]).startOf('day').toDate()
              : startDate,
            to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
          },
        },
        export: false,
        filters: undefined,
        from: undefined,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        searchQuery: undefined,
        to: undefined,
      };

    }
  }

  purchasedAtFilterDateChange($event: any[]){
    console.log($event)
    if ($event && $event.length == 2) {
      const startDate = moment($event[0]).startOf('day').toDate();
      const endDate = moment($event[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.gridRequest = {
        dateFilters: {
          CREATED_AT: {
            from: isToday
              ? moment($event[0]).startOf('day').toDate()
              : startDate,
            to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
          },
        },
        export: false,
        filters: undefined,
        from: undefined,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        searchQuery: undefined,
        to: undefined,
      };

    }
  }


  fetchTherapyPlans(){
    this.pageLoading = true
    if(this.planId){
      this.gridRequest.filters = {
        [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
        [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
        [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
        [TherapyPlanSubscriptionsFilter.USER_ID] : [],
        [TherapyPlanSubscriptionsFilter.CONCERN] : [],
        [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
      }
      this.gridRequest.filters.PLAN_NAME = [this.planId]
    }
    if(this.userId){
      this.gridRequest.filters = {
        [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
        [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
        [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
        [TherapyPlanSubscriptionsFilter.USER_ID] : [],
        [TherapyPlanSubscriptionsFilter.CONCERN] : [],
        [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
      }
      this.gridRequest.filters.USER_ID = [this.userId]
    }
    this.therapyPlansService.fetchTherapyPlansSubscription(this.gridRequest).subscribe({
      next: (value) => {
        value.data.forEach((data) => {
          data.paymentTypeColor = this.utils.getRandomColor();
          const sessionList = Object.keys(data.sessionDetails).map(key => {
            const session = data.sessionDetails[key];
            return { id: key, ...session };
        });
         data.sessionDetailslist = sessionList
        });
        this.therapyPlans = value.data
        this.paymentTypeCheckBoxOptions = []
        this.paymentTypeCheckBoxOptions = value.filterOptions.paymentTypes.map((data) => {
          if(this.gridRequest?.filters?.PAYMENT_TYPE?.includes(data)){
            return{
              label: this.utils.convertToTitleCase(data),
              value: data,
              checked: true
            }
          }else{
            return{
              label: this.utils.convertToTitleCase(data),
              value: data,
              checked: false
            }
          }
        })
        this.totalRecords = value.totalRecords
        this.pageLoading = false
      }, error: (err) => {
        this.toastService.showError(err.message);
        this.pageLoading = false;
      }
    })

  }

  searchByPlanName(){
    if(this.planNameColumnSearch){
      this.gridRequest.searchQuery = {
        [TherapyPlanSubscriptionsSearch.USER_NAME] : '',
        [TherapyPlanSubscriptionsSearch.PLAN_NAME]: ''
      }
      this.gridRequest.searchQuery.PLAN_NAME = this.planNameColumnSearch.trim()
      this.fetchTherapyPlans()
    }
  }

  // searchByUserName(){
  //   if(this.userNameColumnSearch){
  //     this.gridRequest.searchQuery = {
  //       [TherapyPlanSubscriptionsSearch.USER_NAME] : '',
  //       [TherapyPlanSubscriptionsSearch.PLAN_NAME]: ''
  //     }
  //     this.gridRequest.searchQuery.USER_NAME = this.userNameColumnSearch.trim()
  //     this.fetchTherapyPlans()
  //   }
  // }

  paymentTypeFilterChangeFn($event: any[]){
    this.gridRequest.filters = {
      [TherapyPlanSubscriptionsFilter.PAYMENT_TYPE]: [],
      [TherapyPlanSubscriptionsFilter.PARTNER_ID] : [],
      [TherapyPlanSubscriptionsFilter.PLAN_NAME] : [],
      [TherapyPlanSubscriptionsFilter.USER_ID] : [],
      [TherapyPlanSubscriptionsFilter.CONCERN] : [],
      [TherapyPlanSubscriptionsFilter.VERTICAL]:[],
    }
    if($event && $event.length){
      this.gridRequest.filters.PAYMENT_TYPE = $event
    }
  } 

  override async resetFilter() {
    this.gridRequest = {
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
    };
    this.paymentTypeCheckBoxOptions = []
    this.validTillColumnFilterDate = []
    this.purchasedAtColumnFilterDate = []
    this.planNameColumnSearch = ''
    this.userNameColumnSearch = ''
    this.fetchTherapyPlans();
  }

}
