import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { CustomHttpUrlEncodingCodec } from '../common/encoder';
import { environment } from '../../environments/environment';
import { CustomHttpUrlEncodingCodec } from 'projects/app-core/src/app/utils/encoder';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../app-core/src/service/network-utils.service';
import { NewContentRequest } from '../models/newContentRequest';
import Acknowledgement from '../../../../app-core/src/models/acknowledgement';
import { IContent } from '../models/IContent';
import { UpdateContentRequest } from '../pages/content/view/view.component';
import { ContentGridRequest } from '../models/contentGridRequest';
import { ContentGridResponse } from '../models/contentGridResponse';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  protected basePath = environment.serviceUrl;
  protected uploadBasePath = environment.contentServiceUrl;

  constructor(
    protected httpClient: HttpClient,
    private api: NetworkUtilsService,
  ) {}

  public uploadFile(
    id: string,
    category: string,
    index?: string,
    file?: any,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    console.log(queryParameters);
    queryParameters = queryParameters.set('index', String(index));

    return this.httpClient.request<Object>(
      'post',
      `${this.uploadBasePath}/files/upload/` + category + '/' + id,
      {
        body: file,
        headers: new HttpHeaders({
          Authorization: 'Bearer 64e5d35828a4251948fbe3e1',
          enctype: 'multipart/form-data',
        }),
        params: queryParameters,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  public deleteFile(
    url?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    queryParameters = queryParameters.set('url', String(url));

    return this.httpClient.request<Object>(
      'delete',
      `${this.uploadBasePath}/delete/bylink`,
      {
        headers: {
          Authorization: 'Bearer 64e5d35828a4251948fbe3e1',
        },
        params: queryParameters,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  public createContent(
    newContentRequest: NewContentRequest,
  ): Observable<Acknowledgement> {
    return this.api.post<Acknowledgement>(
      URL_DICT.adminNewContentPostHandler,
      newContentRequest,
    );
  }

  public listContent(gridRequest: ContentGridRequest) {
    return this.api.post<ContentGridResponse>(
      URL_DICT.contentGrid,
      gridRequest,
    );
  }

  public getContent(contentId: string) {
    return this.api.get<IContent>(
      URL_DICT.adminContentUrl + `/${String(contentId)}`,
    );
  }

  public updateContent(
    contentId: string,
    updateContentRequest: UpdateContentRequest,
  ) {
    return this.api.post<IContent>(
      URL_DICT.adminContentUrl + `/${String(contentId)}`,
      updateContentRequest,
    );
  }

  public deleteContent(
    id?: Object,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    queryParameters = queryParameters.set('id', String(id));

    return this.httpClient.request<Object>(
      'delete',
      `${this.basePath}/contents/${String(id)}`,
      {
        headers: {
          Authorization: 'Bearer 64e5d35828a4251948fbe3e1',
        },
        params: queryParameters,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  public deleteContentFiles(
    id?: Object,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    queryParameters = queryParameters.set('id', String(id));

    return this.httpClient.request<Object>(
      'post',
      `${this.uploadBasePath}/deletecontent/${String(id)}`,
      {
        headers: {
          Authorization: 'Bearer 64e5d35828a4251948fbe3e1',
        },
        params: queryParameters,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  public getNewContentId() {
    return this.api.get<{
      data: string;
    }>(URL_DICT.adminGetNewContentId);
  }
}
