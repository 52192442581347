import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from '../../services/toastr.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
})
export class SetupComponent {
  email!: string;
  firstLogin!: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastService,
  ) {}

  async getOtp() {
    try {
      let otp = {
        email: this.email,
        firstLogin: this.firstLogin,
        role: 'Listener',
      };
      this.authService.generateOtp(otp);
      this.toastrService.showSuccess('E-mail verified. OTP sent successfully');
      await this.router.navigate(['/login/otp-verify'], {
        queryParams: { email: encodeURIComponent(this.email) },
      });
    } catch (e: unknown) {
      this.toastrService.showAnyoErrorToast(e);
      console.log(e);
    }
  }
}
