import { Component } from '@angular/core';
import { UserProfileService } from '../../../services/user-profile.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  UserJourneyResp,
  UserTable,
} from '../../../models/user-profile/UserProfileSearchResponse';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { SessionsService } from 'projects/app-core/src/app/session/service/sessions.service';
import { SessionGridStatsData } from 'projects/app-core/src/app/session/models/sessionStatsData';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

type PanelData = any;

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrl: './search-user.component.scss',
})
export class SearchUserComponent {
  constructor(
    private userProfileService: UserProfileService,
    private toastrService: ToastService,
    public utils: UtilsService,
    private sessionService: SessionsService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}
  email: string = '';
  spinner: boolean = false;
  userProfileResp!: UserJourneyResp;
  searched: boolean = false;
  panels: {
    active: boolean;
    name: string;
    totalCount: number;
    data: PanelData[];
  }[] = [];
  currentPage: number = 1;
  pageSize: number = 10;
  paginatedData: {
    active: boolean;
    name: string;
    totalCount: number;
    data: PanelData[];
  }[] = [];

  generatePanels(userTableDetails: UserTable) {
    this.panels = Object.keys(userTableDetails).map((key) => {
      const data = userTableDetails[key as keyof UserTable];
      const isArray = Array.isArray(data) ? data : [data];
      return {
        active: false,
        name: key,
        data: isArray,
        totalCount: isArray.length,
      };
    });
    this.updatePaginatedData();
  }

  formatDate(epoch: string | Date | undefined) {
    this.utils.convertEpochToFormattedDate;
    return moment(epoch, 'x').format('DD:MM:YYYY');
  }

  getKeys(obj: PanelData): string[] {
    return obj ? Object.keys(obj) : [];
  }

  trackByName(index: number, panel: PanelData): string {
    return panel.name;
  }

  updatePaginatedData() {
    this.paginatedData;
    this.paginatedData = this.panels.map((panel) => {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return {
        ...panel,
        data: panel.data.slice(start, end),
      };
    });
  }

  isArrayOfObjects(value: any): boolean {
    return (
      Array.isArray(value) &&
      value.every((item) => typeof item === 'object' && !Array.isArray(item))
    );
  }

  activeChange($event: any) {
    this.currentPage = 1;
    this.updatePaginatedData();
  }

  changePage(page: number) {
    this.currentPage = page;
    this.updatePaginatedData();
  }

  therapyCards!: SessionGridStatsData;

  searchUser() {
    this.spinner = true;
    this.userProfileService
      .searchUserProfile({ emailOrCode: this.email.trim() })
      .subscribe({
        next: (value) => {
          this.userProfileResp = value;
          const therapyCard = this.sessionService.mapSessionGridStatsResp(
            value.userCardDetails.therapyCards,
          );
          this.therapyCards = therapyCard;
          this.userProfileResp.userCardDetails.userInterestsWithColor =
            value.userCardDetails.userInterests.map((data) => {
              return {
                data: data,
                color: this.utils.getRandomColor(),
              };
            });
          this.userProfileResp.userCardDetails.userLifestyleChoicesWithColor =
            value.userCardDetails.userLifestyleChoices.map((data) => {
              return {
                data: data,
                color: this.utils.getRandomColor(),
              };
            });
          this.userProfileResp.userCardDetails.circlesJoinedWithColor =
            value.userCardDetails.circlesJoined.map((data) => {
              return {
                data: data,
                color: this.utils.getRandomColor(),
              };
            });
          this.generatePanels(value.userTableDetails);
          console.log(this.panels);
          console.log(this.userProfileResp.userCardDetails);
          this.toastrService.showSuccess('Successfully Searched');
          this.spinner = false;
          this.searched = true;
        },
        error: (err) => {
          this.spinner = false;
          this.searched = false;
          const errorBody = err.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
        },
      });
  }

  visible = false;
  selectedNestedKeys: string[] = [];
  selectedRowKeys: any[] = [];
  title: string = '';

  open(nestedKeys: string[], selectedRowKeys: string[], title: string): void {
    this.selectedNestedKeys = nestedKeys;
    this.selectedRowKeys = selectedRowKeys;
    this.title = title;
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.activatedRoute.queryParams.subscribe((value) => {
          if (value['code']) {
            this.email = value['code'];
            this.searchUser();
          }
        });
      }
    });
  }
}
