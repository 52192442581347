import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { GetTherapistResponse, IAnyoTherapist } from '../models/IAnyoTherapist';
import { ICountResponse } from '../models/IcountResponse';
import { EnableDisableRequest } from '../models/enableDisableRequest';
import { TherapistGridResponse } from '../models/therapistGridResponse';
import { TherapistGridRequest } from '../models/therapistGridRequest';
import { MarkNotAvailableSlotForSelectedDayRequest } from '../models/therapistMarkNotAvailableSlotForSelectedDayRequest';
import { GroupedDateResponse } from '../models/markNotAvailableTImings';
import { UpdateTherapistAvailabilityRequest } from '../models/updateTherapistAvailabilityRequest';
import {
  TimeRangesForGivenDateOfTherapistRequest,
  TimeRangesResponse,
} from '../models/timeRangesRequestResponse';

@Injectable({
  providedIn: 'root',
})
export class TherapistService {
  protected basePath = environment.serviceUrl;
  constructor(
    protected httpClient: HttpClient,
    private api: NetworkUtilsService,
  ) {}

  public therapistCount() {
    return this.api.get<ICountResponse[]>(URL_DICT.therapist + 'count');
  }

  public therapistList() {
    return this.api.get<IAnyoTherapist[]>(URL_DICT.therapist);
  }

  public listTherapist(therapistGridRequest: TherapistGridRequest) {
    return this.api.post<TherapistGridResponse>(
      URL_DICT.therapist + 'grid/list',
      therapistGridRequest,
    );
  }

  public createTherapist(createRequest: IAnyoTherapist) {
    return this.api.post(URL_DICT.therapist, createRequest);
  }

  public getTherapist(therapistId: string) {
    return this.api.get<GetTherapistResponse>(
      URL_DICT.therapist + `${String(therapistId)}`,
    );
  }

  public updateTherapist(therapistId: string, updateRequest: IAnyoTherapist) {
    return this.api.post(
      URL_DICT.therapist + `update/${therapistId}`,
      updateRequest,
    );
  }

  public enableAndDisableTherapist(
    therapistId: string,
    enableAndDisableRequest: EnableDisableRequest,
  ) {
    return this.api.post(
      URL_DICT.therapist + `availability/${therapistId}`,
      enableAndDisableRequest,
    );
  }

  public getTimeRangesForSelectedDay(
    reqBody: TimeRangesForGivenDateOfTherapistRequest,
  ) {
    return this.api.post<TimeRangesResponse[]>(
      URL_DICT.adminGetTimeRanges,
      reqBody,
    );
  }

  public markNotAvailableSlot(
    reqBody: MarkNotAvailableSlotForSelectedDayRequest,
    therapistId: string,
  ) {
    return this.api.post(
      URL_DICT.adminMarkNotAvailableSlot + `/${therapistId}`,
      reqBody,
    );
  }

  public getDatesWithSlotForTherapist(therapistId: string) {
    return this.api.get<GroupedDateResponse[]>(
      URL_DICT.therapist + `not-available/${therapistId}`,
    );
  }

  public therapistAvailabilityUpdate(
    therapistAvailabilityUpdateRequest: UpdateTherapistAvailabilityRequest,
  ) {
    return this.api.post(
      URL_DICT.therapist + `set/availability`,
      therapistAvailabilityUpdateRequest,
    );
  }

  public getTherapistByEmail(email: string) {
    return this.api.get<IAnyoTherapist>(
      URL_DICT.getTherapistByEmailUrl + `${email}`,
    );
  }
}
