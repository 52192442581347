<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Routines</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Routines</h1>
    </div>
  </div>
  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/routine/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Add Routine</span>
    </div>
  </div>
</div>

<div class="custom-card">
  <div class="flex justify-between items-center mt-5 pt-5 pb-5 pr-14 pl-14">
    <nz-select
      class="w-[20%] h-[100%]"
      nzShowSearch
      nzAllowClear
      nzPlaceHolder="Select a Group"
      [(ngModel)]="groupSelectedString"
      (ngModelChange)="groupFilter($event)"
    >
      <nz-option
        *ngFor="let group of groupDropDown"
        [nzLabel]="group.label"
        [nzValue]="group.value"
      ></nz-option>
    </nz-select>
    <nz-select
      class="w-[20%] h-[100%]"
      nzShowSearch
      nzAllowClear
      nzPlaceHolder="Select a Status"
      [(ngModel)]="statusSelectedString"
      (ngModelChange)="statusFilter($event)"
    >
      <nz-option
        *ngFor="let status of statusDropDown"
        [nzLabel]="status.label"
        [nzValue]="status.value"
      ></nz-option>
    </nz-select>
    <div class="w-[30%]">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for routine By Routine Name"
        />
      </nz-input-group>
    </div>
    <div class="w-[10%]">
      <button
        class="w-full h-[40px]"
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="resetFilter()"
      >
        Clear filter
      </button>
    </div>
    <ng-template #suffixButton>
      <button
        nz-button
        nzType="primary"
        nzSize="small"
        nzSearch
        (click)="searchGlobal()"
      >
        Search
      </button>
    </ng-template>
  </div>
  <nz-spin
    [nzSpinning]="routineSpinner"
    nzTip="Fetching Routines...."
    [nzSize]="'large'"
  >
    <div
      class="flex flex-wrap justify-around items-center pr-4 pl-4 pb-5"
      *ngIf="routineData.length > 0; else emptyState"
    >
      <div *ngFor="let data of routineData" class="pt-5">
        <nz-card nzHoverable style="width: 400px" [nzCover]="coverTemplate">
          <nz-card-meta [nzTitle]="data.routineName"> </nz-card-meta>
          <nz-switch
            class="pt-4"
            [(ngModel)]="data.isAvailable"
            (click)="enableDisableRoutine(data)"
          ></nz-switch>
        </nz-card>
        <ng-template #coverTemplate>
          <img
            [routerLink]="['/routine/view/' + data._id]"
            class="h-60 object-cover"
            alt="example"
            [src]="data.banner"
          />
        </ng-template>
      </div>
    </div>
  </nz-spin>
  <div class="flex justify-end">
    <nz-pagination
      class="pt-5 pb-5 pr-7"
      [nzPageIndex]="routineTableCurrentPageNo"
      [nzTotal]="routineTableTotalCount"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      [nzPageSizeOptions]="pageSizeOptions"
      (nzPageIndexChange)="nzPageIndexChange($event)"
      (nzPageSizeChange)="nzPageSizeChange($event)"
    ></nz-pagination>
  </div>
</div>
<ng-template #emptyState>
  <nz-empty
    [nzNotFoundContent]="'No data available'"
    [nzNotFoundImage]="'simple'"
  ></nz-empty>
</ng-template>
