export enum ModeratorActions {
  VIEW_REPORT = 'VIEW_REPORT',
  VIEW_MEDIA = 'VIEW_MEDIA',
  VIEW_COMMENTS = ' VIEW_COMMENTS',
  VIEW_USER_PAST_POST = 'VIEW_USER_PAST_POST ',
  BLOCK_POST = 'BLOCK_POST',
  UNBLOCK_POST = 'UNBLOCK_POST',
  STAR_POST = 'STAR_POST',
  UNSTAR_POST = 'UNSTAR_POST',
  FLAG_POST = 'FLAG_POST',
  UNFLAG_POST = 'UNFLAG_POST',
  BLOCK_USER = 'BLOCK_USER',
  UNBLOCK_USER = 'UNBLOCK_USER',
  COPY_POST_ID = 'COPY_POST_ID',
  BLOCK_COMMENTS = 'BLOCK_COMMENTS',
  UNBLOCK_COMMENTS = 'UNBLOCK_COMMENTS',
  DISMISSED_REPORT = 'DISMISSED_REPORT',
  MODERATION_EVENTS = 'MODERATION_EVENTS',
  MODERATION_REQUIRED = 'MODERATION_REQUIRED',
}
