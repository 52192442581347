import { Component } from '@angular/core';
import { RoutineService } from '../../../services/routine.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  RoutineFilter,
  RoutineGridRequest,
} from '../../../models/routineGridRequest';
import { RoutineGridResponseData } from '../../../models/routineGridResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

interface commonSelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  totalCount: number = 0;
  activeCount: number = 0;
  routineTableCurrentPageNo: number = 1;
  routineTableTotalCount: number = 0;
  pageSize: number = 6;
  pageSizeOptions = [10, 20];
  routineTableQuery: RoutineGridRequest | undefined;
  routineData: RoutineGridResponseData[] = [];
  routineSpinner: boolean = false;
  globalSearchInput!: string;
  groupDropDown: commonSelectOptions[] = [];
  statusDropDown: commonSelectOptions[] = [];
  groupSelectedString!: string;
  statusSelectedString!: string;

  constructor(
    private auth: AuthService,
    private routineService: RoutineService,
    private toastrService: ToastService,
  ) {}

  resetFilter() {
    this.routineTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.routineTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.groupSelectedString = '';
    this.statusSelectedString = '';
    this.listRoutine();
  }

  nzPageIndexChange(page: number) {
    if (this.routineTableQuery) {
      this.routineTableQuery!.page = page;
      this.listRoutine();
    }
  }

  nzPageSizeChange(pageSize: number) {
    this.routineTableQuery!.pageSize = pageSize;
    this.listRoutine();
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.routineTableQuery?.globalSearch?.push(this.globalSearchInput.trim());
      this.listRoutine();
    }
  }

  groupFilter(groupId: string) {
    if (groupId && groupId.length) {
      if (!this.routineTableQuery!.filters) {
        this.routineTableQuery!.filters = {
          [RoutineFilter.ROUTINE_GROUP]: [],
          [RoutineFilter.ROUTINE_AVAILABILITY]: [],
        };
      }
      this.routineTableQuery!.filters!.ROUTINE_GROUP = [groupId];
      this.listRoutine();
    }
  }

  statusFilter(flag: string) {
    if (!this.routineTableQuery!.filters) {
      this.routineTableQuery!.filters = {
        [RoutineFilter.ROUTINE_GROUP]: [],
        [RoutineFilter.ROUTINE_AVAILABILITY]: [],
      };
    }
    this.routineTableQuery!.filters!.ROUTINE_AVAILABILITY = [flag];
    this.listRoutine();
  }

  listRoutine() {
    this.routineSpinner = true;
    this.routineService.routineList(this.routineTableQuery!).subscribe({
      next: (value) => {
        this.groupDropDown = value.filterOptions.group?.map((data) => {
          return {
            label: data.data,
            value: data.id,
          };
        });
        this.statusDropDown = value.filterOptions.availability.map((data) => {
          if (Boolean(data) == true) {
            return {
              label: 'active',
              value: data,
            };
          } else {
            return {
              label: 'inactive',
              value: data,
            };
          }
        });

        this.routineData = value.data;
        this.routineTableTotalCount = value.totalRecords;
        this.routineSpinner = false;
        this.totalCount = value.totalRecords;
      },
      error: (error) => {
        this.routineSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  enableDisableRoutine(data: RoutineGridResponseData) {
    const req = {
      isAvailable: !data.isAvailable,
    };
    this.routineService.enableDisableRoutine(data._id!, req).subscribe({
      next: (value) => {
        this.toastrService.showSuccess('Routine Availability Updated');
        this.listRoutine();
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.routineTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: undefined,
          to: undefined,
        };
        this.listRoutine();
      }
    });
  }
}
