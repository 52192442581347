import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
// import { AnyoTranslateService } from '../../services/anyo-translate.service';
// import { isoToDDMMYYHHMM } from '../../utils/utils';
// import { CookieService } from 'ngx-cookie-service';
// import { Router } from '@angular/router';
// import { UserStateService } from 'src/app/State/User/userstate.service';
// import { CacheService } from '../../services/cache.service';
// import { environment } from 'src/environments/environment';
// import { END } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-header-adminui',
  templateUrl: './header-adminui.component.html',
  styleUrls: ['./header-adminui.component.scss'],
})
export class HeaderAdminuiComponent implements OnInit {
  @Input() user: string = '';
  @Input() domainName: string = '';
  constructor(public auth: AuthService) {}
  ngOnInit(): void {}

  async logout() {
    await this.auth.signOut();
  }
}
