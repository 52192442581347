<div class="button-icon">
  <div [ngStyle]="{ background: background }" class="chatBorder">
    <div class="flex flex-row md:gap-2 source cursor-pointer justify-center">
      <div *ngIf="imageVisible">
        <img src="assets/icons/copy-link.svg" />

        <div>
          <label class="heading flex justify-center cursor-pointer">{{
            textButton
          }}</label>
        </div>
      </div>
      <div *ngIf="blockImage">
        <img src="assets/icons/disabled-white.svg" />
      </div>
      <div *ngIf="reportImage">
        <img src="assets/icons/info-white.svg" />
      </div>
      <div *ngIf="blockImage || reportImage || background">
        <label class="chatHeading flex justify-center cursor-pointer">{{
          chatButton
        }}</label>
      </div>
    </div>
  </div>
</div>
