import { Component, ViewChild } from '@angular/core';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { Permissions } from 'projects/app-core/src/auth/models/roles';
import { PostsService } from '../../service/posts.service';
import { lastValueFrom } from 'rxjs';
import { ToastService } from '../../../services/toastr.service';
import {
  CirclesPostsGridResponse,
  MediaMeta,
} from '../../model/postsGridResponse';
import { CirclePostsGridRequest, PostTab } from '../../model/postsGridRequest';
import { PostTableComponent } from '../../components/post-table/post-table.component';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { MediaType } from '../../model/createPostRequestAdmin';
import { ModeratorActions } from '../../moderatorAction';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

interface CirclePostsResponseData {
  id: string;
  circleName: string;
  circleId: string;
  reactionsCount: number;
  commentsCount: number;
  tags: string[];
  text: string;
  topics: string[];
  userName: string;
  userEmail: string;
  reactionId: string;
  lastReaction: Date;
  lastComment: Date;
}

interface SelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-manage-posts',
  templateUrl: './manage-posts.component.html',
  styleUrl: './manage-posts.component.scss',
})
export class ManagePostsComponent {
  selectedTab: number = 0;
  totalCount: number = 0;
  postData: CirclePostsResponseData[] = [];
  tableData: CirclesPostsGridResponse = {
    data: [],
    filterOptions: {
      circleTopics: [],
      tags: [],
    },
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };
  @ViewChild('moderatorRequiredTableComponent')
  moderatorRequiredTableComponent?: PostTableComponent;
  @ViewChild('allTableComponent')
  allTableComponent?: PostTableComponent;
  @ViewChild('flaggedTable')
  flaggedTable?: PostTableComponent;
  @ViewChild('starredTable')
  starredTable?: PostTableComponent;
  @ViewChild('blockedTable')
  blockedTable?: PostTableComponent;
  dateRange: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  selectedCirclesString = '';
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  globalSearchInput = '';
  postsSpinner = false;
  allowedActions: ModeratorActions[] = [
    ModeratorActions.VIEW_REPORT,
    ModeratorActions.VIEW_MEDIA,
    ModeratorActions.VIEW_COMMENTS,
    ModeratorActions.VIEW_USER_PAST_POST,
    ModeratorActions.MODERATION_EVENTS,
    ModeratorActions.MODERATION_REQUIRED,
    ModeratorActions.BLOCK_POST,
    ModeratorActions.BLOCK_USER,
    ModeratorActions.UNBLOCK_POST,
    ModeratorActions.UNBLOCK_USER,
    ModeratorActions.STAR_POST,
    ModeratorActions.UNSTAR_POST,
    ModeratorActions.FLAG_POST,
    ModeratorActions.UNFLAG_POST,
    ModeratorActions.COPY_POST_ID,
  ];
  displayAddPost = true;
  tabs = [
    PostTab.ALL,
    PostTab.MODERATION_REQUIRED,
    PostTab.STARRED,
    PostTab.FLAGGED,
    PostTab.BLOCKED_POSTS,
  ];
  postsTableQuery: CirclePostsGridRequest | undefined;
  circles: SelectOptions[] = [];
  postsId!: string;
  circleId!: string;
  userId!: string;
  postCommentTitle: string = 'Posts';
  viewCircleDropdown = false;
  postsText = '';
  protected readonly MediaType = MediaType;
  viewPost = false;
  whenDisplayManyCircles = false;

  constructor(
    private auth: AuthService,
    private posts: PostsService,
    private toastrService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public utils: UtilsService,
  ) {}

  disabledDate = (current: Date) => {
    return moment(current).isBefore(moment('01/12/2023', 'dd/MM/YYYY'));
  };

  navigatePages() {
    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.router.navigate([
          'posts/' + this.postsId + '/circle/' + this.circleId + '/comment/add',
        ]);
      } else {
        this.router.navigate(['/posts/add']);
      }
    });
  }

  async listPosts() {
    try {
      this.postsSpinner = true;
      const tableData = await lastValueFrom(
        this.posts.postsGrid(this.postsTableQuery!),
      );
      tableData.data.forEach((data) => {
        data.circleTopics = data.topics.map((topic) => ({
          topics: topic,
          randomColor: this.utils.getRandomColor(),
        }));
        data.alltags = data.tags.map((tag) => ({
          tag: tag,
          randomColor: this.utils.getRandomColor(),
        }));
      });

      this.tableData = tableData;

      if (this.moderatorRequiredTableComponent) {
        this.moderatorRequiredTableComponent.setSubTopicsFilterData(
          this.tableData.filterOptions,
        );
        this.moderatorRequiredTableComponent.setTagsFilterData(
          this.tableData.filterOptions,
        );
      }
      if (this.flaggedTable) {
        this.flaggedTable.setSubTopicsFilterData(this.tableData.filterOptions);
        this.flaggedTable.setTagsFilterData(this.tableData.filterOptions);
      }
      if (this.starredTable) {
        this.starredTable.setSubTopicsFilterData(this.tableData.filterOptions);
        this.starredTable.setTagsFilterData(this.tableData.filterOptions);
      }
      if (this.allTableComponent) {
        this.allTableComponent.setSubTopicsFilterData(
          this.tableData.filterOptions,
        );
        this.allTableComponent.setTagsFilterData(this.tableData.filterOptions);
      }
      if (this.blockedTable) {
        this.blockedTable.setSubTopicsFilterData(this.tableData.filterOptions);
        this.blockedTable.setTagsFilterData(this.tableData.filterOptions);
      }
      this.totalCount = this.tableData.totalRecords;
      this.postsSpinner = false;
    } catch (e) {
      this.postsSpinner = false;
      this.toastrService.showError('Something went wrong');
    }
  }

  async dateChange(result: Date[]) {
    if (this.dateRange && this.dateRange.length == 2) {
      const startDate = moment(this.dateRange[0]).startOf('day').toDate();
      const endDate = moment(this.dateRange[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.postsTableQuery = {
        dateFilters: {
          CREATED_DATE: {
            from: isToday ? moment().startOf('day').toDate() : startDate,
            to: isToday ? moment().endOf('day').toDate() : endDate,
          },
        },
        filters: {
          CIRCLE_ID: [],
          USER_ID: [],
          PARENT_POST_ID: [],
          TOPICS: [],
          MODERATION_REQUIRED: [],
          ONLY_VISIBLE_POSTS: [],
          BLOCKED_POSTS: [],
          TAGS: [],
          HASHTAG: [],
          ADMIN_TAGS: [],
        },
        export: false,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        tab: this.tabs[this.selectedTab],
      };

      const routeParams = this.activatedRoute.snapshot.params;

      if (Object.keys(routeParams).length > 0) {
        let userId = routeParams['userId'];
        if (userId) {
          this.userId = userId;
          this.postsTableQuery.filters!.USER_ID = [this.userId];
        }

        let value = routeParams['id'];
        this.circleId = routeParams['circleId'];
        if (value) {
          this.postsId = value;
          this.postsTableQuery.filters!.CIRCLE_ID = [this.circleId];
          this.postsTableQuery.filters!.PARENT_POST_ID = [this.postsId];
        }
      } else {
        this.circleId = this.selectedCirclesString;
        this.postsTableQuery.filters!.CIRCLE_ID = [this.circleId];
      }
    }
    await this.listPosts();
  }

  async subTopicsFilter($event: string[]) {
    if (!this.postsTableQuery!.filters) {
      this.postsTableQuery!.filters = {
        CIRCLE_ID: [this.selectedCirclesString],
        USER_ID: [],
        PARENT_POST_ID: [],
        TOPICS: [],
        MODERATION_REQUIRED: [],
        ONLY_VISIBLE_POSTS: [],
        BLOCKED_POSTS: [],
        TAGS: [],
        HASHTAG: [],
        ADMIN_TAGS: [],
      };
    }

    if ($event && $event.length) {
      this.postsTableQuery!.filters.TOPICS = $event;
      await this.listPosts();
    }
  }

  async tagsFilter($event: string[]) {
    if (!this.postsTableQuery!.filters) {
      this.postsTableQuery!.filters = {
        CIRCLE_ID: [this.selectedCirclesString],
        USER_ID: [],
        PARENT_POST_ID: [],
        TOPICS: [],
        MODERATION_REQUIRED: [],
        ONLY_VISIBLE_POSTS: [],
        BLOCKED_POSTS: [],
        TAGS: [],
        HASHTAG: [],
        ADMIN_TAGS: [],
      };
    }

    if ($event && $event.length) {
      this.postsTableQuery!.filters!.ADMIN_TAGS = $event;
      await this.listPosts();
    }
  }

  async lastCommentDateSort($event: string | null) {
    this.postsTableQuery = {
      dateFilters: undefined,
      filters: {
        CIRCLE_ID: [this.selectedCirclesString],
        USER_ID: [],
        PARENT_POST_ID: [],
        TOPICS: [],
        MODERATION_REQUIRED: [],
        ONLY_VISIBLE_POSTS: [],
        BLOCKED_POSTS: [],
        TAGS: [],
        HASHTAG: [],
        ADMIN_TAGS: [],
      },
      export: false,
      sort: {
        LAST_COMMENT_AT: undefined,
        LAST_REACTION_AT: undefined,
      },
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if ($event) {
      this.postsTableQuery!.sort!.LAST_COMMENT_AT =
        $event === 'ascend' ? AnyoSortDirection.ASC : AnyoSortDirection.DESC;
    }
    await this.listPosts();
  }

  async lastReactionDateSort($event: string | null) {
    this.postsTableQuery = {
      dateFilters: undefined,
      filters: {
        CIRCLE_ID: [this.selectedCirclesString],
        USER_ID: [],
        PARENT_POST_ID: [],
        TOPICS: [],
        MODERATION_REQUIRED: [],
        ONLY_VISIBLE_POSTS: [],
        BLOCKED_POSTS: [],
        TAGS: [],
        HASHTAG: [],
        ADMIN_TAGS: [],
      },
      export: false,
      sort: {
        LAST_COMMENT_AT: undefined,
        LAST_REACTION_AT: undefined,
      },
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if ($event) {
      this.postsTableQuery!.sort!.LAST_REACTION_AT =
        $event === 'ascend' ? AnyoSortDirection.ASC : AnyoSortDirection.DESC;
    }
    await this.listPosts();
  }

  async searchGlobal() {
    if (this.globalSearchInput.length) {
      this.postsTableQuery?.globalSearch?.push(this.globalSearchInput.trim());
      await this.listPosts();
    }
  }
  async resetFilter() {
    this.globalSearchInput = '';
    this.dateRange = [];
    this.postsTableQuery = {
      dateFilters: undefined,
      filters: {
        CIRCLE_ID: [],
        USER_ID: [],
        PARENT_POST_ID: [],
        TOPICS: [],
        MODERATION_REQUIRED: [],
        ONLY_VISIBLE_POSTS: [],
        BLOCKED_POSTS: [],
        TAGS: [],
        HASHTAG: [],
        ADMIN_TAGS: [],
      },
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };

    const routeParams = this.activatedRoute.snapshot.params;

    if (Object.keys(routeParams).length > 0) {
      let userId = routeParams['userId'];
      if (userId) {
        this.userId = userId;
        this.postsTableQuery.filters!.USER_ID = [this.userId];
        if (this.dateRange && this.dateRange.length == 2) {
          this.postsTableQuery.dateFilters = {
            CREATED_DATE: {
              from: moment(this.dateRange[0]).toDate(),
              to: moment(this.dateRange[1]).toDate(),
            },
          };
        }
      }

      let value = routeParams['id'];
      this.circleId = routeParams['circleId'];
      if (value) {
        this.postsId = value;
        this.postsTableQuery.filters!.CIRCLE_ID = [this.circleId];
        this.postsTableQuery.filters!.PARENT_POST_ID = [this.postsId];
        if (this.dateRange && this.dateRange.length == 2) {
          this.postsTableQuery.dateFilters = {
            CREATED_DATE: {
              from: moment(this.dateRange[0]).toDate(),
              to: moment(this.dateRange[1]).toDate(),
            },
          };
        }
      }
    } else {
      this.circleId = this.selectedCirclesString;
      this.postsTableQuery.filters!.CIRCLE_ID = [this.circleId];
    }

    if (this.moderatorRequiredTableComponent) {
      this.moderatorRequiredTableComponent!.resetValues();
    }
    if (this.allTableComponent) {
      this.allTableComponent!.resetValues();
    }
    if (this.starredTable) {
      this.starredTable!.resetValues();
    }
    if (this.flaggedTable) {
      this.flaggedTable!.resetValues();
    }
    if (this.blockedTable) {
      this.blockedTable!.resetValues();
    }

    await this.listPosts();
  }

  async tabChange($event: NzTabChangeEvent) {
    this.selectedTab = $event.index!;
    this.tableData = {
      data: [],
      filterOptions: {
        circleTopics: [],
        tags: [],
      },
      pageNum: 1,
      pageSize: 10,
      totalPages: 0,
      totalRecords: 0,
    };
    this.postsTableQuery = {
      dateFilters: undefined,
      filters: {
        CIRCLE_ID: [],
        USER_ID: [],
        PARENT_POST_ID: [],
        TOPICS: [],
        MODERATION_REQUIRED: [],
        ONLY_VISIBLE_POSTS: [],
        BLOCKED_POSTS: [],
        TAGS: [],
        HASHTAG: [],
        ADMIN_TAGS: [],
      },
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.dateRange && this.dateRange.length == 2) {
      this.postsTableQuery.dateFilters = {
        CREATED_DATE: {
          from: moment(this.dateRange[0]).toDate(),
          to: moment(this.dateRange[1]).toDate(),
        },
      };
    }

    this.globalSearchInput = '';
    const routeParams = this.activatedRoute.snapshot.params;

    if (Object.keys(routeParams).length > 0) {
      let userId = routeParams['userId'];
      if (userId) {
        this.userId = userId;
        this.postsTableQuery.filters!.USER_ID = [this.userId];
        if (this.dateRange && this.dateRange.length == 2) {
          this.postsTableQuery.dateFilters = {
            CREATED_DATE: {
              from: moment(this.dateRange[0]).toDate(),
              to: moment(this.dateRange[1]).toDate(),
            },
          };
        }
      }

      let value = routeParams['id'];
      this.circleId = routeParams['circleId'];
      if (value) {
        this.postsId = value;
        this.postsTableQuery.filters!.CIRCLE_ID = [this.circleId];
        this.postsTableQuery.filters!.PARENT_POST_ID = [this.postsId];
        if (this.dateRange && this.dateRange.length == 2) {
          this.postsTableQuery.dateFilters = {
            CREATED_DATE: {
              from: moment(this.dateRange[0]).toDate(),
              to: moment(this.dateRange[1]).toDate(),
            },
          };
        }
      }
    } else {
      this.circleId = this.selectedCirclesString;
      this.postsTableQuery.filters!.CIRCLE_ID = [this.circleId];
    }

    if (this.moderatorRequiredTableComponent) {
      this.moderatorRequiredTableComponent!.resetValues();
    }
    if (this.allTableComponent) {
      this.allTableComponent!.resetValues();
    }
    if (this.starredTable) {
      this.starredTable!.resetValues();
    }
    if (this.flaggedTable) {
      this.flaggedTable!.resetValues();
    }
    if (this.blockedTable) {
      this.blockedTable!.resetValues();
    }
    if ($event.index !== undefined) {
      if ($event.index == 0) {
        this.postsTableQuery!.tab = PostTab.ALL;
      }
      if ($event.index == 1) {
        this.postsTableQuery!.tab = PostTab.MODERATION_REQUIRED;
      }
      if ($event.index == 2) {
        this.postsTableQuery!.tab = PostTab.STARRED;
      }
      if ($event.index == 3) {
        this.postsTableQuery!.tab = PostTab.FLAGGED;
      }
      if ($event.index == 4) {
        this.postsTableQuery!.tab = PostTab.BLOCKED_POSTS;
      }
    }
    await this.listPosts();
  }

  async nzPageIndexChange(page: number) {
    if (this.postsTableQuery) {
      this.postsTableQuery!.page = page;
      await this.listPosts();
    }
  }

  async nzPageSizeChange(pageSize: number) {
    this.postsTableQuery!.pageSize = pageSize;
    await this.listPosts();
  }

  async listCirclePosts($event: string) {
    this.postsTableQuery = {
      sort: {
        LAST_COMMENT_AT: AnyoSortDirection.ASC,
        LAST_REACTION_AT: AnyoSortDirection.ASC,
      },
      dateFilters: undefined,
      export: false,
      filters: {
        CIRCLE_ID: [$event],
        USER_ID: [],
        PARENT_POST_ID: [],
        TOPICS: [],
        MODERATION_REQUIRED: [],
        ONLY_VISIBLE_POSTS: [],
        BLOCKED_POSTS: [],
        TAGS: [],
        HASHTAG: [],
        ADMIN_TAGS: [],
      },
      from: undefined,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      searchQuery: undefined,
      tab: this.tabs[this.selectedTab],
      to: undefined,
    };
    if (this.dateRange && this.dateRange.length == 2) {
      this.postsTableQuery.dateFilters = {
        CREATED_DATE: {
          from: moment(this.dateRange[0]).toDate(),
          to: moment(this.dateRange[1]).toDate(),
        },
      };
    }
    await this.listPosts();
  }

  ngOnInit() {
    this.viewCircleDropdown = true;
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        if (
          user?.customClaims?.additionalPermissions.includes(
            Permissions.AdminAddPost,
          )
        ) {
          this.displayAddPost = true;
          this.postsSpinner = false;
        }
        this.postsSpinner = false;
        this.posts.getCirclesForUser().subscribe({
          next: async (value) => {
            console.log(value.length);
            if (value.length <= 1) {
              this.whenDisplayManyCircles = true;
            }
            this.circles = value.map((data) => {
              return {
                label: data.name,
                value: data._id!,
              };
            });

            const routeParams = this.activatedRoute.snapshot.params;

            if (Object.keys(routeParams).length == 0) {
              this.postsTableQuery = {
                dateFilters: undefined,
                export: false,
                filters: {
                  CIRCLE_ID: [],
                  USER_ID: [],
                  PARENT_POST_ID: [],
                  TOPICS: [],
                  MODERATION_REQUIRED: [],
                  ONLY_VISIBLE_POSTS: [],
                  BLOCKED_POSTS: [],
                  TAGS: [],
                  HASHTAG: [],
                  ADMIN_TAGS: [],
                },
                from: undefined,
                globalSearch: [],
                page: 1,
                pageSize: this.pageSize,
                searchQuery: undefined,
                tab: PostTab.ALL,
                to: undefined,
              };
              this.postsTableQuery.filters!.CIRCLE_ID = [this.circles[0].value];
              this.selectedCirclesString = this.circles[0].value;
              await this.listPosts();
            }
          },
          error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toastrService.showAnyoErrorToast(errorBody.description);
            this.postsSpinner = false;
          },
        });
        this.postCommentTitle = 'Posts';
        this.postsTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: {
            CIRCLE_ID: [],
            USER_ID: [],
            PARENT_POST_ID: [],
            TOPICS: [],
            MODERATION_REQUIRED: [],
            ONLY_VISIBLE_POSTS: [],
            BLOCKED_POSTS: [],
            TAGS: [],
            HASHTAG: [],
            ADMIN_TAGS: [],
          },
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          tab: PostTab.ALL,
          to: undefined,
          sort: undefined,
        };
        const routeParams = this.activatedRoute.snapshot.params;

        if (Object.keys(routeParams).length == 0) {
          this.postsTableQuery.filters!.CIRCLE_ID = [
            this.selectedCirclesString,
          ];
        }

        this.activatedRoute.params.subscribe(async (params) => {
          this.postsId = params['id'];
          this.circleId = params['circleId'];
          if (params['id'] && params['circleId']) {
            this.viewPost = true;
            this.getPost(this.postsId);
            this.viewCircleDropdown = false;
            this.postCommentTitle = 'Comments';
            this.allowedActions = [
              ModeratorActions.MODERATION_EVENTS,
              ModeratorActions.BLOCK_COMMENTS,
              ModeratorActions.UNBLOCK_COMMENTS,
              // ModeratorActions.STAR_POST,
              // ModeratorActions.UNSTAR_POST,
              // ModeratorActions.FLAG_POST,
              // ModeratorActions.UNFLAG_POST,
              ModeratorActions.COPY_POST_ID,
            ];

            this.postsTableQuery!.filters!.CIRCLE_ID = [this.circleId];
            this.postsTableQuery!.filters!.PARENT_POST_ID = [this.postsId];
            await this.listPosts();
          }

          if (params['userId']) {
            this.viewPost = false;
            this.allowedActions = [
              ModeratorActions.VIEW_REPORT,
              ModeratorActions.VIEW_MEDIA,
              ModeratorActions.VIEW_COMMENTS,
              ModeratorActions.VIEW_USER_PAST_POST,
              ModeratorActions.BLOCK_POST,
              ModeratorActions.UNBLOCK_POST,
              ModeratorActions.BLOCK_USER,
              ModeratorActions.UNBLOCK_USER,
              ModeratorActions.STAR_POST,
              ModeratorActions.UNSTAR_POST,
              ModeratorActions.FLAG_POST,
              ModeratorActions.UNFLAG_POST,
              ModeratorActions.MODERATION_EVENTS,
              ModeratorActions.MODERATION_REQUIRED,
              ModeratorActions.COPY_POST_ID,
            ];
            this.viewCircleDropdown = false;
            this.displayAddPost = false;
            this.userId = params['userId'];
            this.postCommentTitle = 'User Past Post';
            this.postsTableQuery!.filters!.USER_ID = [this.userId];
            await this.listPosts();
          }
        });
      }
    });
  }

  media: MediaMeta[] | undefined;

  getPost(id: string) {
    this.posts.getPost(id).subscribe({
      next: (value) => {
        this.postsText = value.text;
        if (value.media.length > 0) {
          this.media = value.media;
        }
        this.toastrService.showSuccess('Post View');
        this.postsSpinner = false;
      },
      error: (value) => {
        this.postsSpinner = false;
      },
    });
  }

  visibleViewMedia = false;
  closeViewMedia() {
    this.visibleViewMedia = false;
  }

  openViewMedia() {
    this.visibleViewMedia = true;
  }
}
