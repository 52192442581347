<nz-spin [nzSpinning]="pageLoading">
  <nz-table
    #talkLogs
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
    [nzBordered]="true"
    [nzData]="data"
    [nzPageIndex]="currentPageNo"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageSize]="pageSize"
    [nzScroll]="{ y: '500px' }"
    [nzShowPagination]="true"
    [nzShowSizeChanger]="true"
    [nzTitle]="tableHead"
    [nzTotal]="totalCount"
    nzFrontPagination="false"
    nzTableLayout="fixed"
  >
    <thead>
      <tr>
        <th>
          Session Details<nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="talksName"
          >
            <span nz-icon nzTheme="outline" nzType="filter-o"></span>
          </nz-filter-trigger>
        </th>
        <th>
          Timing Details
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="startDateFilterMenu"
          >
            <span nz-icon nzTheme="outline" nzType="schedule"></span>
          </nz-filter-trigger>
        </th>
        <th>
          Host Details
          <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="HostDetails">
            <span nz-icon nzTheme="outline" nzType="filter-o"></span>
          </nz-filter-trigger>
        </th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of talkLogs.data">
        <td>
          <div class="p-2">
            Name: {{ log.talkName }}
            <br />
            ParticipantsCount: {{ log.participants.length }}
            <br />
            Live: {{ log.streamingSessionStarted || log.startStreaming }}
          </div>
        </td>
        <td>
          <div class="p-2">
            {{ formatDate(log.startTime) }}<br />
            {{ formatTime(log.startTime) }} - {{ formatTime(log.endTime)
            }}<br />
            Duration: {{ formatDuration(log.startTime, log.endTime) }}
          </div>
        </td>
        <td>
          {{ formatHostDetails(log.hostDetails) }}
        </td>
        <td>
          <div
            *ngIf="
              !(log.streamingSessionStarted && log.startStreaming) &&
              log.recordingDetails.playbackUrl
            "
            class="p-1"
            nz-row
          >
            <div nz-col class="flex gap-3">
              <button
                (click)="showModalFn(log.recordingDetails.playbackUrl)"
                nz-button
                nzType="primary"
              >
                Watch Session
              </button>
              <button
                (click)="copyToClipboard(log.recordingDetails.playbackUrl)"
                nz-button
              >
                Copy Playback Url
              </button>
            </div>
          </div>
          <div class="p-1" nz-row>
            <div nz-col>
              <nz-switch
                [(ngModel)]="log.visible"
                (ngModelChange)="markAsHidden(log)"
              ></nz-switch>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Talk Sessions</span>
    </div>
    <div nz-col>
      <button (click)="resetFilter()" nz-button nzSize="small" nzType="primary">
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-modal
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [(nzVisible)]="showPlaybackModal"
  nzTitle="The first Modal"
>
  <ng-container *nzModalContent>
    <div class="min-w-[50em] min-w-[10em]">
      <video controls id="playbackVideoElem"></video>
    </div>
  </ng-container>
</nz-modal>
<nz-dropdown-menu #HostDetails="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Host Details</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="hostDetailsFilterChangeFn($event)">
          <div *ngFor="let topic of experts" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="topic.value"
                [ngModel]="topic.checked"
                >{{ topic.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="getData()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #talksName="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Talk Names</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="talknameFilterChangeFn($event)">
          <div *ngFor="let topic of talksNames" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="topic.value"
                [ngModel]="topic.checked"
                >{{ topic.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="getData()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #startDateFilterMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="ng-row">
      <nz-range-picker
        [(ngModel)]="dateRangeColumn"
        (ngModelChange)="startTimeDateFilter($event)"
      ></nz-range-picker>
    </div>
    <div nz-row class="mt-5" nzJustify="end">
      <div nz-col class="mr-1">
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="getData()"
        >
          Ok
        </button>
      </div>
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
