import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  LocalStageStream,
  Stage,
  StageEvents,
  StageParticipantInfo,
  SubscribeType,
} from 'amazon-ivs-web-broadcast';
import { StageStrategy } from 'amazon-ivs-web-broadcast/dist/src/stage/v2/stage-strategy';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrl: './live.component.scss',
})
export class LiveComponent {
  @ViewChild('localVideo') localVideoElement!: ElementRef<HTMLVideoElement>;
  localCamera: MediaStream | undefined;
  localMic: MediaStream | undefined;
  cameraStageStream: LocalStageStream | undefined;
  micStageStream: LocalStageStream | undefined;
  stage: Stage | undefined;
  token: string | undefined;
  participants: { id: string; streams: MediaStreamTrack[] }[] = [];
  micMuted: boolean = false;
  cameraMuted: boolean = false;

  attachStageListeners(stage?: Stage) {
    stage?.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state) => {
      console.log('STAGE_CONNECTION_STATE_CHANGED', state);
    });
    stage?.on(
      StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED,
      (participant, streams) => {
        console.log('STAGE_PARTICIPANT_STREAMS_ADDED', participant, streams);
        // let streamsToDisplay = streams;
        // if (participant.isLocal) {
        //   // Ensure to exclude local audio streams, otherwise echo will occur
        //   streamsToDisplay = streams.filter(
        //     (stream) => stream.streamType === StreamType.VIDEO
        //   );
        // }
        // const videoEl = this.setupParticipant(participant.isLocal, participant.id)
        // streamsToDisplay.forEach((stream) =>
        //   (videoEl.srcObject as MediaStream).addTrack(stream.mediaStreamTrack)
        // );
        console.log('participant', participant);
        if (!participant.isLocal) {
          this.participants.push({
            id: participant.id,
            streams: streams.map((value) => value.mediaStreamTrack),
          });
          setTimeout(() => {
            const videoElement: HTMLVideoElement | null =
              document.querySelector(`#video-${participant.id}`);
            console.log('videoElement', videoElement);
            if (videoElement) {
              videoElement.srcObject = new MediaStream();
              streams.forEach((stream) => {
                (videoElement.srcObject as MediaStream)!.addTrack(
                  stream.mediaStreamTrack,
                );
              });
            }
          }, 10);
        }
      },
    );
    stage?.on(StageEvents.STAGE_PARTICIPANT_LEFT, (participant) => {
      console.log('STAGE_PARTICIPANT_LEFT', participant);
      const index = this.participants.findIndex(
        (value) => value.id == participant.id,
      );
      this.participants.splice(index, 1);
      this.teardownParticipant(participant.isLocal, participant.id);
    });
    stage?.on(
      StageEvents.STAGE_STREAM_MUTE_CHANGED,
      (participant: StageParticipantInfo, stream) => {
        console.log('STAGE_STREAM_MUTE_CHANGED', participant, stream);
        if (!participant.isLocal) {
          const index = this.participants.findIndex(
            (value) => value.id == participant.id,
          );
          if (index != -1) {
            this.participants[index].streams = [stream.mediaStreamTrack];
            setTimeout(() => {
              const videoElement: HTMLVideoElement | null =
                document.querySelector(`#video-${participant.id}`);
              console.log('videoElement', videoElement);
              if (videoElement) {
                videoElement.srcObject = new MediaStream();
                this.participants[index].streams.forEach((stream) => {
                  (videoElement.srcObject as MediaStream)!.addTrack(stream);
                });
              }
            }, 10);
          }
        }
      },
    );
  }

  async joinStage() {
    if (!this.token) {
      alert('Please input a token');
      return;
    }
    this.localCamera = await this.getCamera();
    this.localMic = await this.getMic();
    if (!this.localCamera || !this.localMic) {
      alert('Please allow permission for mic and camara');
      return;
    }
    this.cameraStageStream = new LocalStageStream(
      this.localCamera!.getVideoTracks()[0],
    );
    this.micStageStream = new LocalStageStream(
      this.localMic.getAudioTracks()[0],
    );
    if (this.cameraStageStream && this.micStageStream) {
      const strategy: StageStrategy = {
        stageStreamsToPublish: () => {
          return [this.cameraStageStream!, this.micStageStream!];
        },
        shouldPublishParticipant() {
          return true;
        },
        shouldSubscribeToParticipant() {
          return SubscribeType.AUDIO_VIDEO;
        },
      };
      this.stage = new Stage(this.token, strategy);
      await this.stage!.join();
      this.attachStageListeners(this.stage);
      if (this.localCamera) {
        this.localVideoElement.nativeElement.srcObject = new MediaStream();
        this.localVideoElement.nativeElement.srcObject.addTrack(
          this.localCamera!.getVideoTracks()[0],
        );
      }
    }
  }

  setupParticipant(isLocal: boolean, id: string) {
    const groupId = isLocal ? 'local-media' : 'remote-media';
    const groupContainer = document.getElementById(groupId);
    const participantContainerId = isLocal ? 'local' : id;
    const participantContainer = this.createContainer(participantContainerId);
    const videoEl = this.createVideoEl(participantContainerId);
    participantContainer.appendChild(videoEl);
    groupContainer?.appendChild(participantContainer);
    return videoEl as HTMLVideoElement;
  }

  createVideoEl(id: string) {
    const videoEl = document.createElement('video');
    videoEl.id = id;
    videoEl.autoplay = true;
    videoEl.playsInline = true;
    videoEl.srcObject = new MediaStream();
    return videoEl;
  }

  createContainer(id: string) {
    const participantContainer = document.createElement('div');
    participantContainer.id = id + '-container';

    return participantContainer;
  }

  async getCamera() {
    // Use Max Width and Height
    return navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
  }

  async getMic() {
    return navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
  }

  async leaveStage() {
    if (this.stage) {
      await this.stage.leave();
      this.localMic?.getTracks().forEach((track) => track.stop());
      this.localCamera?.getTracks().forEach((track) => track.stop());
      console.log('stage left');
    }
  }

  teardownParticipant(isLocal: boolean, id: string) {
    const groupId = isLocal ? 'local-media' : 'remote-media';
    const groupContainer = document.getElementById(groupId);
    const participantContainerId = isLocal ? 'local' : id;

    const participantDiv = document.getElementById(
      participantContainerId + '-container',
    );
    if (!participantDiv) {
      return;
    }
    groupContainer!.removeChild(participantDiv);
  }

  toggleAudio() {
    if (this.micStageStream) {
      this.micMuted = !this.micMuted;
      this.micStageStream.setMuted(this.micMuted);
    }
  }

  async toggleVideo() {
    if (this.cameraStageStream) {
      this.cameraMuted = !this.cameraMuted;
      this.cameraStageStream.setMuted(this.cameraMuted);
      if (this.cameraMuted) {
        this.localCamera?.getTracks().forEach((track) => track.stop());
      }
      if (!this.cameraMuted) {
        this.localCamera = await this.getCamera();
        if (this.localCamera) {
          this.localVideoElement.nativeElement.srcObject = new MediaStream();
          this.localVideoElement.nativeElement.srcObject.addTrack(
            this.localCamera!.getVideoTracks()[0],
          );
          this.cameraStageStream = new LocalStageStream(
            this.localCamera!.getVideoTracks()[0],
          );
        }
      }
    }
  }
}
