<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Events</span>
    </div>
    <div nz-col>
      <button
        class="btn-color"
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="resetFilter()"
      >
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<div class="max-w-1xl mx-auto bg-white p-4 rounded-lg shadow-lg">
  <div class="flex flex-wrap pb-4 justify-start">
    <h1 class="headeradminui-text pl-2">Manage Therapy Plans</h1>
  </div>
  <div class="flex flex-row justify-end gap-3 pb-4">
    <button nz-button nzType="primary" [routerLink]="['/therapy-plans/create']">
      <span nz-icon nzType="plus" nzTheme="outline"></span>
      Add new Plan
    </button>
    <button nz-button nzType="primary" [routerLink]="['/therapy-plans/list']">
      <span nz-icon nzType="link" nzTheme="outline"></span>
      Plan List
    </button>
  </div>
  <nz-spin [nzSpinning]="pageLoading">
    <nz-table
      #basicTable
      [nzTitle]="tableHead"
      [nzScroll]="{ y: '500px' }"
      [nzData]="therapyPlans"
      [nzShowPagination]="true"
      nzFrontPagination="false"
      [nzTotal]="totalRecords"
      [nzPageSize]="pageSize"
      [nzBordered]="true"
      [nzShowSizeChanger]="true"
      [nzPageSizeOptions]="pageSizeOptions"
      [nzPageIndex]="currentPageNo"
      nzTableLayout="fixed"
      (nzPageIndexChange)="nzPageIndexChange($event)"
      (nzPageSizeChange)="nzPageSizeChange($event)"
    >
      <thead>
        <tr>
          <th>
            Plan Name
            <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="planNameSearch"
            >
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
          </th>
          <th>
            Internal Plan Name
            <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="internalPlanNameSearch"
            >
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
          </th>
          <th nzWidth="15%">Description</th>
          <th>Active</th>
          <th>
            Active From
            <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="activeFromDateFilterMenu"
            >
              <span nz-icon nzTheme="outline" nzType="schedule"></span>
            </nz-filter-trigger>
          </th>
          <th>
            Payment Type
            <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="paymentTypeMenu"
            >
              <span nz-icon nzTheme="outline" nzType="filter-o"></span>
            </nz-filter-trigger>
          </th>
          <th>Can Pause</th>
          <th>Per Session Time In Min</th>
          <th>Validity Days</th>
          <th>No of Sessions</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td> <u (click)="openTherapyPlansSubscriptionPageByPlanName(data.id)">{{ data.planName }}</u></td>
          <td>{{ data.internalPlanName }}</td>
          <td>{{ data.planDescription }}</td>
          <td>{{ data.active ? "Yes" : "No" }}</td>
          <td>{{ utils.convertEpochToFormattedDate(data.activeFrom) }}</td>
          <td [align]="'left'">
            <nz-tag [nzColor]="data.paymentTypeColor">{{
              utils.convertToTitleCase(data.paymentType)
            }}</nz-tag>
          </td>
          <td>{{ data.canPause }}</td>
          <td>{{ data.perSessionTimeInMin }}</td>
          <td>{{ data.validityDays }}</td>
          <td>{{ data.noOfSessions }}</td>
          <td>
            <div class="flex items-center justify-evenly">
              <span nz-tooltip nzTooltipTitle="View therapist">
                <button
                  [routerLink]="['/therapy-plans/edit/' + data.id]"
                  nz-button
                  nzType="primary"
                  nzShape="circle"
                >
                  <span nz-icon nzType="eye" nzTheme="outline"></span>
                </button>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-spin>
</div>

<nz-dropdown-menu #paymentTypeMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Payment Type</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="paymentTypeFilterChangeFn($event)">
          <div *ngFor="let topic of paymentTypeCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="topic.value"
                [ngModel]="topic.checked"
                >{{ topic.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="fetchTherapyPlans()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #activeFromDateFilterMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="ng-row">
      <nz-range-picker
        [(ngModel)]="activeFromColumnFilterDate"
        (ngModelChange)="activeFromFilterDateChange($event)"
      ></nz-range-picker>
    </div>
    <div nz-row class="mt-5" nzJustify="end">
      <div nz-col class="mr-1">
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="fetchTherapyPlans()"
        >
          Ok
        </button>
      </div>
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          class="btn-color"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #planNameSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="planNameColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="searchByPlanName()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #internalPlanNameSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="internalPlanNameColumnSearch"
          nz-input
          placeholder="internal PlanName Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="searchByInternalPlanName()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>