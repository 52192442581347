<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<nz-spin [nzSpinning]="loading">
  <div class="max-w-1xl mx-auto bg-white p-8 rounded-lg shadow-lg">
    <div class="flex flex-row justify-end gap-3">
      <button nz-button nzType="primary" (click)="navigateToCreatePlanPage()">
        <span nz-icon nzType="plus" nzTheme="outline"></span>
        Add new Plan
      </button>
      <button nz-button nzType="primary" (click)="showLinkingModalFn()">
        <span nz-icon nzType="link" nzTheme="outline"></span>
        Associate Plan
      </button>
    </div>
    <div class="p-4">
      <div *ngFor="let vertical of plansMapped; let i = index" class="mb-8">
        <div
          class="text-xl font-bold mb-2 flex items-center justify-between bg-gray-200 p-3 rounded-md cursor-pointer hover:bg-gray-300 transition"
          (click)="toggleVertical(i)"
        >
          {{ vertical.verticalName }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 transform transition-transform"
            [ngClass]="{ 'rotate-180': isVerticalOpen[i] }"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1.707-10.293a1 1 0 00-1.414 0L10 8.586 8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 000-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div *ngIf="isVerticalOpen[i]" class="pl-4">
          <div
            *ngFor="let concern of vertical.concerns; let j = index"
            class="mb-4"
          >
            <div
              class="text-lg font-semibold mb-2 flex items-center justify-between bg-blue-100 p-3 rounded-md cursor-pointer hover:bg-blue-200 transition"
              (click)="toggleConcern(i, j)"
            >
              {{ concern.concernName }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 transform transition-transform"
                [ngClass]="{ 'rotate-180': isConcernOpen[i][j] }"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1.707-10.293a1 1 0 00-1.414 0L10 8.586 8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div
              *ngIf="isConcernOpen[i][j]"
              class="pl-4 flex overflow-x-auto space-x-4"
            >
              <div
                *ngFor="let plan of concern.plans"
                class="w-64 flex-shrink-0 p-4 border rounded-lg"
              >
                <div class="mb-2">
                  <img
                    *ngIf="plan.planImage"
                    [src]="plan.planImage"
                    alt="Plan Image"
                    class="w-full h-32 object-cover rounded-lg"
                  />
                </div>
                <div class="text-lg font-bold">{{ plan.planName }}</div>
                <div class="text-sm text-gray-600">
                  {{ plan.planDescription }}
                </div>
                <div class="mt-2">
                  <div>
                    <strong>Validity Days:</strong> {{ plan.validityDays }}
                  </div>
                  <div>
                    <strong>No of Sessions:</strong> {{ plan.noOfSessions }}
                  </div>
                  <div>
                    <strong>Per Session Timing:</strong>
                    {{ plan.perSessionTimeInMin }}
                  </div>

                  <div><strong>Charges:</strong></div>
                  <div class="ml-4">
                    <div *ngFor="let level of expertLevelArray">
                      <strong class="font-extrabold"
                        >{{ expertLevelMap[level] }} : </strong
                      >{{ plan.charges![level][0].currencySymbol
                      }}{{ plan.charges![level][0].amount }}
                    </div>
                  </div>
                </div>
                <br />
                <div class="flex flex-grow">
                  <button
                    class="w-full"
                    (click)="
                      unLinkPlan(
                        vertical.verticalId,
                        concern.concernId,
                        plan.id
                      )
                    "
                    nz-button
                    nzType="primary"
                    nzDanger
                  >
                    Un-Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
<nz-modal
  [(nzVisible)]="showLinkingModal"
  nzTitle="Link Plans"
  (nzOnCancel)="handleLinkingModalCancel()"
  (nzOnOk)="handleLinkingModalOk()"
>
  <ng-container *nzModalContent>
    <div class="flex flex-row w-full gap-3">
      <div>
        <span>Select Vertical</span>
        <nz-select
          nzPlaceHolder="Select Vertical"
          class="flex-col min-w-[200px]"
          [ngModel]="selectedVertical"
          (ngModelChange)="selectedVerticalFn($event)"
        >
          <nz-option
            *ngFor="let item of verticals"
            [nzValue]="item.id"
            [nzLabel]="item.name"
          ></nz-option>
        </nz-select>
      </div>
      <div>
        <span>Select Concerns</span>
        <nz-select
          nzPlaceHolder="Select Concerns"
          class="flex-col min-w-[200px]"
          nzMode="multiple"
          [ngModel]="selectedConcern"
          (ngModelChange)="selectedConcernsFn($event)"
        >
          <nz-option
            *ngFor="let item of concernsFiltered"
            [nzValue]="item.id"
            [nzLabel]="item.name"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <br />
    <div class="flex flex-row w-full gap-3">
      <div>
        <nz-select
          class="flex-col min-w-[200px]"
          [ngModel]="selectedPlan"
          nzMode="multiple"
          (ngModelChange)="selectedPlansFn($event)"
        >
          <nz-option
            nzPlaceHolder="Select Plan"
            *ngFor="let item of activePlans"
            [nzValue]="item.id"
            [nzLabel]="item.internalPlanName"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  </ng-container>
</nz-modal>
