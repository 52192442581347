<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Bytes Category</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Bytes Category</h1>
    </div>
  </div>
  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/bytes/category/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Add Bytes Category</span>
    </div>
  </div>
</div>
<div class="flex justify-end">
  <div class="w-[40%]">
    <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
      <input
        type="text"
        nz-input
        [(ngModel)]="globalSearchInput"
        placeholder="Search for name "
      />
    </nz-input-group>
  </div>
</div>
<ng-template #suffixButton>
  <button
    nz-button
    nzType="primary"
    nzSize="small"
    nzSearch
    (click)="searchGlobal()"
  >
    Search
  </button>
</ng-template>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Bytes Category</span>
    </div>
    <div nz-col>
      <button nz-button nzSize="small" nzType="primary" (click)="resetFilter()">
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-spin
  [nzSpinning]="bytesCategorySpinner"
  nzTip="Fetching Bytes Category......"
>
  <nz-table
    #bytesCategoryTable
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzData]="bytesCategoryData"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="bytesCategoryTableTotalCount"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="bytesCategoryTableCurrentPageNo"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>No of Background Images</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of bytesCategoryData">
        <td>{{ data.name }}</td>
        <td>{{ data.backgroundImagesCount }}</td>
        <td>{{ data.isActive ? "active" : "inactive" }}</td>
        <td nzRight>
          <div class="flex items-center justify-evenly">
            <span nz-tooltip nzTooltipTitle="View therapist">
              <button
                [routerLink]="['/bytes/category/add/' + data.id]"
                nz-button
                nzType="primary"
                nzShape="circle"
              >
                <span nz-icon nzType="eye" nzTheme="outline"></span>
              </button>
            </span>
            <nz-switch
              [(ngModel)]="data.isActive"
              (click)="enableDisableBytesCategory(data.isActive)"
            ></nz-switch>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
