import { GridRequest } from "projects/app-core/src/models/gridRequest";


export interface TherapyPlansGridRequest
  extends GridRequest<
  TherapyPlansSort,
  TherapyPlansFilter,
  TherapyPlansSearch,
  TherapyPlansDateFilter
  > {}

export enum TherapyPlansSort {
  THERAPY_CREATED_AT = "CREATED_AT",
  THERAPY_STATUS = "STATUS",
}
export enum TherapyPlansSearch {
  THERAPY_PLANS_NAME = "THERAPY_PLANS_NAME",
  THERAPY_PLANS_INTERNAL_NAME = "THERAPY_PLANS_INTERNAL_NAME"
}

export enum TherapyPlansDateFilter {
  THERAPY_PLANS_ACTIVE_FROM = "THERAPY_PLANS_ACTIVE_FROM",
}

export enum TherapyPlansFilter {
  THERAPY_PLANS_NAME = "THERAPY_PLANS_NAME",
  THERAPY_PLANS_PAYMENT_TYPE = "THERAPY_PLANS_PAYMENT_TYPE"
}
