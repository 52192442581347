import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface EventRegistrationGridRequest
  extends GridRequest<
    EventRegistrationSort,
    EventRegistrationFilter,
    EventRegistrationSearch,
    EventRegistrationDateFilter
  > {}
export enum EventRegistrationSort {
  REGISTRATION_DATE = 'REGISTRATION_DATE',
}
export enum EventRegistrationFilter {
  EVENT_NAME = 'EVENT_NAME',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
  HIDE_TEST_REGISTRATION = 'HIDE_TEST_REGISTRATION',
}
export enum EventRegistrationSearch {
  USER_EMAIL = 'USER_EMAIL',
}
export enum EventRegistrationDateFilter {
  REGISTRATION_DATE = 'REGISTRATION_DATE',
}
