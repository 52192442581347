import { GridResponse } from '../../../models/gridResponse';

export interface TalkGridResponse
  extends GridResponse<undefined, TalkGridItem> {}

export interface TalkGridItem {
  id: string;
  name: string;
  category: string;
  experts: {
    userId: string;
    id: string;
    name: string;
  }[];
  cronText?: string;
  live: boolean;
  lastLiveOn: string;
  nextLiveOn: string | Date;
  mode: TalkMode;
  visible: boolean;
  notificationDetails?: TalkNotificationDetails;
}

export interface TalkNotificationDetails {
  defaultTitle?: string;
  defaultBody?: string;
  defaultImage?: string;
  title?: string;
  imageUrl?: string;
  body?: string;
}

export enum TalkMode {
  ONCE = 'ONCE',
  RECURRING = 'RECURRING',
}
