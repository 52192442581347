import { AnyoComponent } from './AnyoComponent';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../services/toastr.service';
import { GridRequest } from '../../models/gridRequest';

import { IAnyoUser } from '../../auth/models/IAnyoUser';

function BaseClassDecorator(): ClassDecorator {
  return () => {}; // No-op decorator
}

@BaseClassDecorator()
export abstract class AnyoTableComponent<
  T,
  A extends GridRequest<string, string, string, string>,
> extends AnyoComponent {
  data: T[] = [];
  pageNo: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  pageSizeOptions: number[] = [10, 20.5, 100];
  currentPageNo: number = 1;
  dateRangeColumn: Date[] = [];
  gridRequest: A | GridRequest<string, string, string, string> = {
    page: this.pageNo,
    pageSize: this.pageSize,
    export: false,
    // Initialize other properties as needed
  };

  constructor(
    protected override auth: AuthService,
    protected toastService: ToastService,
  ) {
    super(auth);
    this.createInitialGridRequest();
  }
  async resetFilter() {
    this.dateRangeColumn = [];
    this.gridRequest = this.createInitialGridRequest();
    await this.getData();
  }

  abstract getData(): Promise<void>;

  override async ready(user?: IAnyoUser): Promise<void> {
    if (user) {
      await this.getData();
    } else {
      this.data = [];
    }
    return Promise.resolve();
  }

  async nzPageIndexChange($event: number) {
    this.currentPageNo = $event;
    this.gridRequest.page = $event;
    await this.getData();
  }

  async nzPageSizeChange($event: number) {
    this.pageSize = $event;
    this.gridRequest.pageSize = $event;
    await this.getData();
  }

  private createInitialGridRequest(): A {
    const initialGridRequest: GridRequest<string, string, string, string> = {
      page: this.pageNo,
      pageSize: this.pageSize,
      export: false,
      // Initialize other properties as needed
    };
    return initialGridRequest as A;
  }
}
