import { GridRequest } from '../../../../app-core/src/models/gridRequest';

export interface PartnerGridRequest
  extends GridRequest<
    PartnerSort,
    PartnerFilter,
    PartnerSearch,
    PartnerDateFilter
  > {}

export enum PartnerSort {
  PASSES = 'PASSES',
}

export enum PartnerSearch {
  PARTNER_ID = 'PARTNER_ID',
  PARTNER = 'PARTNER',
}

export enum PartnerDateFilter {
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum PartnerFilter {
  CITY = 'CITY',
  STATE = 'STATE',
}
