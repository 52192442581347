import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NetworkUtilsService, URL_DICT} from './network-utils.service';
import {UploadUrlRequest, UploadUrlResponse,} from '../models/uploadUrlResponse';
import {parse, ParseResult} from 'papaparse';
import {download, generateCsv, mkConfig} from 'export-to-csv';

export enum AnyoS3Buckets {
  CDN_BUCKET = 'CDN_BUCKET',
}

interface CSVObject {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileUtilsService {
  constructor(
    private http: HttpClient,
    private api: NetworkUtilsService,
  ) {
  }

  public uploadFile(file: File, url: string) {
    return this.http.put(url, file);
  }

  public generateUploadUrl(
    bucket: AnyoS3Buckets,
    filePath: string,
    contentType?: string,
    metaData?: Record<string, string>,
  ) {
    const payload: UploadUrlRequest = {
      bucket: bucket,
      contentType: contentType,
      metaData: metaData,
      path: filePath,
    };
    return this.api.post<UploadUrlResponse>(
      URL_DICT.adminGetUploadUrl,
      payload,
    );
  }

  public exportCSV<T>(data: T[], fileName: string) {
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csv = generateCsv(csvConfig)(
      data as unknown as Record<string, string>[],
    );
    download(csvConfig)(csv);
  }

  public parseCSV<T>(file: File): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target != null && event.target.result != null) {
          const data = event.target?.result;
          parse(data.toString(), {
            header: true,
            delimiter: ',',
            skipEmptyLines: 'greedy',
            transform: (value) =>
              value.trim() === '' ? undefined : value.trim(),
            complete: (results: ParseResult<T>) => {
              const data = results.data;
              return resolve(data);
            },
          });
        }
      };
      reader.onerror = (event) => {
        return reject(event);
      };

      reader.readAsText(file);
    });
  }
}
