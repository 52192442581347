<div class="flex justify-center heading pt-20 text-2xl text-center">
  Welcome to Admin Dashboard
</div>
<div class="login">
  <button
    (click)="authService.adminAuth()"
    type="button"
    class="login-with-google-btn"
  >
    Sign in with Google
  </button>
</div>
