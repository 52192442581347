import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { PostsService } from '../../service/posts.service';
import { ToastService } from '../../../services/toastr.service';
import { CirclePostReportsGridRequest } from '../../model/circlePostReportsGridRequest';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import {
  CirclePostReportsResponseData,
  CirclesPostReportsGridResponse,
} from '../../model/circlePostReportsGridResponse';
import { ModeratorActionsRequest } from '../../model/moderatorActionsRequest';

interface ReportData {
  userName: string;
  circleName: string;
  comments: string;
  problem: string;
  dismissed: boolean;
}

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrl: './report-table.component.scss',
})
export class ReportTableComponent {
  @Input() title = '';
  reportSpinner: boolean = false;
  reportTableTotalCount = 0;
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  reportTableCurrentPageNo = 1;
  postReportsTableQuery: CirclePostReportsGridRequest | undefined;
  @Output() resetFilterEvent = new EventEmitter<void>();
  @Output() pageSizeChangeEvent = new EventEmitter<number>();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() listPostsEvent = new EventEmitter<void>();
  @Output() dismissedReportEvent = new EventEmitter<string>();
  @Input() reportData: CirclesPostReportsGridResponse | undefined;

  nzPageIndexChange(page: number) {
    this.pageChangeEvent.emit(page);
  }

  nzPageSizeChange(pageSize: number) {
    this.pageSizeChangeEvent.emit(pageSize);
  }

  resetFilter() {
    this.resetFilterEvent.emit();
  }

  dismissedReport(reportId: string) {
    this.dismissedReportEvent.emit(reportId);
  }
}
