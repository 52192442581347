import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
})
export class AlertBoxComponent {
  @Input() nameOfUser!: string;
  @Input() alertHeader!: string;
  @Input() alertBody!: string;
  @Input() rejectAlertBody!: string;
  @Input() alertDate!: string;
  @Input() callLink!: string;
  @Input() isInput!: boolean;
  @Input() isContent!: boolean;
  @Input() alertContent!: string;
  @Input() isPatient!: boolean;
  @Input() patientDetails!: any;
  @Input() date!: string;
  @Input() time!: string;
  @Input() isConfirm!: boolean;
  @Input() isRating!: boolean;
  @Input() isReject!: boolean;
  @Input() isCalendar!: boolean;
  @Input() isTimeAndTherapist!: boolean;
  @Input() isTherapist!: boolean;
  @Input() isVideoLink!: boolean;
  @Input() isTherapistName!: boolean;
  @Input() defaultTherapist!: string;
  @Input() therapistDefaultName!: string;
  @Input() objectArray!: any;
  @Input() alertHeaderRating!: string;
  @Input() therapistFullName!: string;
  @Output() closeAlert = new EventEmitter<boolean>();
  @Output() isDisable = new EventEmitter<boolean>();
  @Output() inputValue = new EventEmitter<string>();
  @Output() appointment = new EventEmitter<Object>();
  @Output() okClicked = new EventEmitter<boolean>();
  @Output() feedbackRate = new EventEmitter<object>();
  @Output() rejectReason = new EventEmitter<string>();

  loading = false;

  therapistsId: string = '';
  inputText: string = '';
  inputLink: string = '';
  rejectInputText: string = '';
  inputDate: string = '';
  starValue: string = '';
  inputRate: string = '';
  inputTime: string = '';
  therapistName: string = '';
  appointmentObject: any;
  ratingObject: any;
  filledStar: string = 'assets/icons/coloured-star.svg';
  unfilledStar: string = 'assets/icons/uncoloured-star.svg';
  patient: any;
  content = {
    therapist: ' ',
  };
  uniqueTherapistNames: any[] = [];
  therapistArray: any[] = [];
  stars: number[] = [1, 2, 3, 4, 5];
  selectedRating: number = 0;

  minDate: string = new Date().toISOString().split('T')[0];

  @Input() cancelAppoinment = false
  @Input() userEmail = ''
  @Input() userName = ''

  close() {
    this.closeAlert.emit();
  }
  finishRate() {
    if (this.isRating) {
      this.ratingObject = {
        starValue: this.selectedRating,
        rateFeedback: this.inputRate,
      };
      this.feedbackRate.emit(this.ratingObject);
    }
  }
  disable() {
    if (this.isCalendar) {
      let arr: any = [];
      if (Array.isArray(this.defaultTherapist)) {
        arr.push(this.defaultTherapist[0]);
      } else {
        arr.push(this.defaultTherapist);
      }
      this.appointmentObject = {
        suggestedDate: this.inputDate,
        suggestedTime: this.inputTime,
        videoLink: this.inputLink || null,
        therapistId: arr || [],
      };
      console.log(this.appointmentObject);
      this.appointment.emit(this.appointmentObject);
    } else {
      this.rejectReason.emit(this.rejectInputText);
      this.okClicked.emit();
      this.isDisable.emit();
    }
  }

  submit() {
    this.inputValue.emit(this.inputText);
  }
  getStarImage(index: number): string {
    if (index < this.selectedRating) {
      return this.filledStar;
    } else {
      return this.unfilledStar;
    }
  }

  rate(rating: number) {
    this.selectedRating = rating;
  }

  ngOnInit(): void {
    this.patient = this.patientDetails;
    if (this.date && this.time) {
      this.inputDate = this.date;
      this.inputTime = this.time;
    }
    if (this.callLink) {
      this.inputLink = this.callLink;
    }
    this.therapistArray = this.objectArray;
  }
}
