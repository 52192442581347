import { Component } from '@angular/core';
import { TherapyPlansAdminResponse } from '../../../models/TherapyPlansAdminResponse';
import { AnyoComponent } from 'projects/app-core/src/app/models/AnyoComponent';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { Router } from '@angular/router';
import { MasterdataService } from '../../../services/masterdata.service';

export interface TherapyPlansResponse {
  verticalName: string;
  verticalId: string;
  concerns: {
    concernName: string;
    concernId: string;
    plans: TherapyPlansAdminResponse[];
  }[];
}

@Component({
  selector: 'app-therapy-plans-list',
  templateUrl: './therapy-plans-list.component.html',
  styleUrl: './therapy-plans-list.component.scss',
})
export class TherapyPlansListComponent extends AnyoComponent {
  unLinkPlan(verticalId: string, concernId: string, planId: string) {
    this.pageLoading = true;
    this.therapyPlansService
      .unLinkTherapyPlan(verticalId, concernId, planId)
      .subscribe({
        next: () => {
          this.tostService.showSuccess('Plan unlinked successfully');
          this.pageLoading = false;
          window.location.reload();
        },
        error: (error) => {
          this.tostService.showError(error.message);
          this.pageLoading = false;
        },
      });
  }
  selectedPlansFn($event: string[]) {
    this.selectedPlan = $event;
  }
  selectedConcernsFn($event: string[]) {
    this.selectedConcern = $event;
  }
  verticals: {
    id: string;
    name: string;
  }[] = [];
  concerns: { verticalId: string[]; id: string; name: string }[] = [];
  concernsFiltered: { verticalId: string[]; id: string; name: string }[] = [];
  selectedVertical: string | undefined;
  selectedConcern: string[] = [];
  selectedPlan: string[] = [];

  selectedVerticalFn(event: string) {
    this.selectedVertical = event;
    this.selectedConcern = [];
    this.selectedPlan = [];
    this.concernsFiltered = this.concerns.filter((concern) =>
      concern.verticalId.includes(event),
    );
  }
  navigateToCreatePlanPage() {
    this.router.navigate(['/therapy-plans/create']);
  }
  showLinkingModalFn() {
    this.showLinkingModal = true;
  }
  handleLinkingModalOk() {
    console.log(this.selectedVertical, this.selectedConcern, this.selectedPlan);
    if (!this.selectedVertical) {
      this.tostService.showError('Please select a Vertical');
      return;
    }
    if (!this.selectedConcern.length) {
      this.tostService.showError('Please select atleast one Concern');
      return;
    }
    if (!this.selectedPlan.length) {
      this.tostService.showError('Please select a Plan');
      return;
    }
    this.loading = true;
    this.therapyPlansService
      .linkTherapyPlan(
        this.selectedVertical,
        this.selectedConcern,
        this.selectedPlan,
      )
      .subscribe({
        next: () => {
          this.tostService.showSuccess('Plan linked successfully');
          this.showLinkingModal = false;
          this.loading = false;
          window.location.reload();
        },
        error: (error) => {
          this.tostService.showError(error.message);
          this.loading = false;
        },
      });
  }
  handleLinkingModalCancel() {
    this.showLinkingModal = false;
  }
  loading: boolean = true;
  showLinkingModal: boolean = false;
  override async ready(user?: IAnyoUser | undefined): Promise<void> {
    if (user) {
      this.therapyPlansService.fetchAllPlans().subscribe({
        next: (response) => {
          this.plansMapped = response.plansMapped;
          this.activePlans = response.activePlans;
          this.plansMapped.forEach((vertical, index) => {
            this.isVerticalOpen[index] = false;
            this.isConcernOpen[index] = [];
            vertical.concerns.forEach((concern, subIndex) => {
              this.isConcernOpen[index][subIndex] = false;
            });
          });
        },
        error: (error) => {
          this.tostService.showError(error.message);
        },
      });

      this.masterDataService.masterDataList().subscribe({
        next: (value) => {
          this.concerns = value.therapyConcerns.map((value) => {
            return {
              id: value.m_id,
              name: value.data,
              verticalId: value.verticalId,
            };
          });
          this.verticals = value.therapyVerticals.map((value) => {
            return {
              id: value.m_id,
              name: value.data,
            };
          });
          value.therapistExpertLevels.forEach((value) => {
            this.expertLevelMap[value.m_id] = value.data;
          });
          this.expertLevelArray = Object.keys(this.expertLevelMap);
        },
      });
    }
    this.loading = false;
    return;
  }
  expertLevelArray: string[] = [];
  expertLevelMap: Record<string, string> = {};
  activePlans: TherapyPlansAdminResponse[] = [];
  plansMapped: TherapyPlansResponse[] = [];
  isVerticalOpen: boolean[] = [];
  isConcernOpen: boolean[][] = [];
  constructor(
    auth: AuthService,
    private therapyPlansService: TherapyPlansService,
    private tostService: ToastService,
    private router: Router,
    private masterDataService: MasterdataService,
  ) {
    super(auth);
  }
  toggleVertical(index: number) {
    this.isVerticalOpen[index] = !this.isVerticalOpen[index];
  }

  toggleConcern(verticalIndex: number, concernIndex: number) {
    this.isConcernOpen[verticalIndex][concernIndex] =
      !this.isConcernOpen[verticalIndex][concernIndex];
  }
}
