<div class="card mini">
  <div class="pass-require">
    <p>
      Password Requirements:
      <li>Must be between 8-20 characters</li>
      <li>One lowercase character</li>
      <li>One uppercase character</li>
      <li>One special character & number</li>
      <li>Passwords are case-sensitive</li>
    </p>
  </div>
</div>
