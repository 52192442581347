import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { EventsListGridResponse } from '../../../models/events/eventsAdminGridResponse';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import {
  EventsListTab,
  EventsListsGridRequest,
} from '../../../models/events/eventsGridRequest';
import { EventsService } from '../../../services/events.service';
import { lastValueFrom } from 'rxjs';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  DateFilterEmitData,
  EventsTableComponent,
} from '../events-table/events-table.component';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

@Component({
  selector: 'app-manage-events',
  templateUrl: './manage-events.component.html',
  styleUrl: './manage-events.component.scss',
})
export class ManageEventsComponent {
  totalCount = 0;
  tableData: EventsListGridResponse = {
    data: [],
    filterOptions: {
      category: [],
      paymentTypes: [],
    },
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };
  eventsTableQuery: EventsListsGridRequest | undefined;
  selectedTab: number = 0;
  tabs = [EventsListTab.UP_COMING_ADMIN];
  pageSize = 10;
  eventsSpinner = false;
  globalSearchInput = '';
  @ViewChild('UpcomingTableComponent')
  UpcomingTableComponent?: EventsTableComponent;
  @ViewChild('AllTableComponent')
  AllTableComponent?: EventsTableComponent;

  constructor(
    private eventsService: EventsService,
    private toastrService: ToastService,
    private auth: AuthService,
    public utils: UtilsService,
  ) {}

  async tabChange($event: NzTabChangeEvent) {
    this.selectedTab = $event.index!;
    this.tableData = {
      data: [],
      filterOptions: {
        category: [],
        paymentTypes: [],
      },
      pageNum: 1,
      pageSize: 10,
      totalPages: 0,
      totalRecords: 0,
    };
    this.eventsTableQuery = {
      dateFilters: undefined,
      filters: undefined,
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.UpcomingTableComponent) {
      this.UpcomingTableComponent!.resetValues();
    }
    if (this.AllTableComponent) {
      this.AllTableComponent!.resetValues();
    }
    if ($event.index !== undefined) {
      if ($event.index == 0) {
        this.eventsTableQuery!.tab = EventsListTab.UP_COMING_ADMIN;
      }
      if ($event.index == 1) {
        this.eventsTableQuery!.tab = undefined;
      }
    }
    await this.listEvents();
  }

  async listEvents() {
    try {
      this.eventsSpinner = true;
      const tableData = await lastValueFrom(
        this.eventsService.listEvents(this.eventsTableQuery!),
      );
      tableData.data.forEach((data) => {
        data.paymentTypeColor = this.utils.getRandomColor();
        data.categoryNameColor = data.paymentTypeColor =
          this.utils.getRandomColor();
      });
      this.tableData = tableData;
      this.totalCount = this.tableData.totalRecords;
      if (this.UpcomingTableComponent) {
        this.UpcomingTableComponent.setCategoryFilterData(
          this.tableData.filterOptions,
        );
        this.UpcomingTableComponent.setPaymentTypeFilterData(
          this.tableData.filterOptions,
        );
      }
      if (this.AllTableComponent) {
        this.AllTableComponent.setCategoryFilterData(
          this.tableData.filterOptions,
        );
        this.AllTableComponent.setPaymentTypeFilterData(
          this.tableData.filterOptions,
        );
      }
      this.eventsSpinner = false;
    } catch (e) {
      this.eventsSpinner = false;
      this.toastrService.showError('Something went wrong');
    }
  }

  async categoryFilter($event: string[]) {
    if ($event && $event.length) {
      this.eventsTableQuery = {
        dateFilters: undefined,
        filters: {
          CATEGORY: $event,
          CIRCLE_ID: [],
          PRESENTER_ID: [],
          ACTIVE: [],
          USER_PERSONALISED: [],
          PAYMENT_TYPE: [],
        },
        export: false,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        tab: this.tabs[this.selectedTab],
      };
      await this.listEvents();
    }
  }

  async paymentTypeFilter($event: string[]) {
    if ($event && $event.length) {
      this.eventsTableQuery = {
        dateFilters: undefined,
        filters: {
          CATEGORY: [],
          CIRCLE_ID: [],
          PRESENTER_ID: [],
          ACTIVE: [],
          USER_PERSONALISED: [],
          PAYMENT_TYPE: $event,
        },
        export: false,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        tab: this.tabs[this.selectedTab],
      };
      await this.listEvents();
    }
  }

  async dateFilter($event: DateFilterEmitData) {
    console.log($event);
    if ($event.dates && $event.dates!.length) {
      const startDate = moment($event.dates[0]).startOf('day').toDate();
      const endDate = moment($event.dates[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      if ($event.options.includes('eventDate')) {
        this.eventsTableQuery = {
          export: false,
          sort: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          tab: this.tabs[this.selectedTab],
          dateFilters: {
            EVENT_DATE: {
              from: isToday ? moment().startOf('day').toDate() : startDate,
              to: isToday ? moment().endOf('day').toDate() : endDate,
            },
            REGISTRATION_CUT_OFF: undefined,
            VISIBLE_FROM: undefined,
            CREATED_DATE: undefined,
          },
        };
      } else if ($event.options.includes('registrationCutOffDate')) {
        this.eventsTableQuery = {
          export: false,
          sort: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          tab: this.tabs[this.selectedTab],
          dateFilters: {
            EVENT_DATE: undefined,
            REGISTRATION_CUT_OFF: {
              from: isToday ? moment().startOf('day').toDate() : startDate,
              to: isToday ? moment().endOf('day').toDate() : endDate,
            },
            VISIBLE_FROM: undefined,
            CREATED_DATE: undefined,
          },
        };
      } else if ($event.options.includes('visibleFrom')) {
        this.eventsTableQuery = {
          export: false,
          sort: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          tab: this.tabs[this.selectedTab],
          dateFilters: {
            EVENT_DATE: undefined,
            REGISTRATION_CUT_OFF: undefined,
            VISIBLE_FROM: {
              from: isToday ? moment().startOf('day').toDate() : startDate,
              to: isToday ? moment().endOf('day').toDate() : endDate,
            },
            CREATED_DATE: undefined,
          },
        };
      }
      await this.listEvents();
    }
  }

  async nzPageSizeChange(pageSize: number) {
    this.eventsTableQuery!.pageSize = pageSize;
    await this.listEvents();
  }

  async nzPageIndexChange(page: number) {
    if (this.eventsTableQuery) {
      this.eventsTableQuery!.page = page;
      await this.listEvents();
    }
  }

  async resetFilter() {
    this.globalSearchInput = '';
    this.eventsTableQuery = {
      export: false,
      sort: undefined,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.UpcomingTableComponent) {
      this.UpcomingTableComponent!.resetValues();
    }
    if (this.AllTableComponent) {
      this.AllTableComponent!.resetValues();
    }

    await this.listEvents();
  }

  async searchGlobal() {
    if (this.globalSearchInput.length) {
      this.eventsTableQuery?.globalSearch?.push(this.globalSearchInput.trim());
      await this.listEvents();
    }
  }

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        this.eventsTableQuery = {
          sort: undefined,
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          tab: EventsListTab.UP_COMING_ADMIN,
          to: undefined,
        };
        await this.listEvents();
      }
    });
  }
}
