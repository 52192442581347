<button
  nz-button
  (click)="openSetAvailableSlots()"
  class="btn-color"
  nzType="primary"
>
  Set Available Hours
</button>
<div class="pl-2" *ngIf="isVisible">
  <nz-spin [nzSpinning]="setAvailableSpinner" nzTip="Loading......">
    <div nz-row class="mt-2">
      <h1 class="w-[20%]">Monday :</h1>
      <input
        [(ngModel)]="availability[0].slot1Start"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <input
        [(ngModel)]="availability[0].slot1End"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <img
        *ngIf="!availability[0].ifSlot2"
        src="../../../../assets/icons/plus.svg"
        class="mx-4 cursor-pointer"
        (click)="add('monday')"
      />
      <div *ngIf="availability[0].ifSlot2" class="flex flex-row ml-2">
        <input
          [(ngModel)]="availability[0].slot2Start"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <input
          [(ngModel)]="availability[0].slot2End"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <img
          src="../../../../assets/icons/cross-icon.svg"
          class="mx-4 cursor-pointer"
          (click)="remove('monday')"
        />
      </div>
    </div>
    <div nz-row class="mt-2">
      <h1 class="w-[20%]">Tuesday :</h1>
      <input
        [(ngModel)]="availability[1].slot1Start"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <input
        [(ngModel)]="availability[1].slot1End"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <img
        *ngIf="!availability[1].ifSlot2"
        src="../../../../assets/icons/plus.svg"
        class="mx-4 cursor-pointer"
        (click)="add('tuesday')"
      />
      <div *ngIf="availability[1].ifSlot2" class="flex flex-row ml-2">
        <input
          [(ngModel)]="availability[1].slot2Start"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <input
          [(ngModel)]="availability[1].slot2End"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <img
          src="../../../../assets/icons/cross-icon.svg"
          class="mx-4 cursor-pointer"
          (click)="remove('tuesday')"
        />
      </div>
    </div>
    <div nz-row class="mt-2">
      <h1 class="w-[20%]">Wednesday :</h1>
      <input
        [(ngModel)]="availability[2].slot1Start"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <input
        [(ngModel)]="availability[2].slot1End"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <img
        *ngIf="!availability[2].ifSlot2"
        src="../../../../assets/icons/plus.svg"
        class="mx-4 cursor-pointer"
        (click)="add('wednesday')"
      />
      <div *ngIf="availability[2].ifSlot2" class="flex flex-row ml-2">
        <input
          [(ngModel)]="availability[2].slot2Start"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <input
          [(ngModel)]="availability[2].slot2End"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <img
          src="../../../../assets/icons/cross-icon.svg"
          class="mx-4 cursor-pointer"
          (click)="remove('wednesday')"
        />
      </div>
    </div>
    <div nz-row class="mt-2">
      <h1 class="w-[20%]">Thursday :</h1>
      <input
        [(ngModel)]="availability[3].slot1Start"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <input
        [(ngModel)]="availability[3].slot1End"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <img
        *ngIf="!availability[3].ifSlot2"
        src="../../../../assets/icons/plus.svg"
        class="mx-4 cursor-pointer"
        (click)="add('thursday')"
      />
      <div *ngIf="availability[3].ifSlot2" class="flex flex-row ml-2">
        <input
          [(ngModel)]="availability[3].slot2Start"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <input
          [(ngModel)]="availability[3].slot2End"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <img
          src="../../../../assets/icons/cross-icon.svg"
          class="mx-4 cursor-pointer"
          (click)="remove('thursday')"
        />
      </div>
    </div>
    <div nz-row class="mt-2">
      <h1 class="w-[20%]">Friday :</h1>
      <input
        [(ngModel)]="availability[4].slot1Start"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <input
        [(ngModel)]="availability[4].slot1End"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <img
        *ngIf="!availability[4].ifSlot2"
        src="../../../../assets/icons/plus.svg"
        class="mx-4 cursor-pointer"
        (click)="add('friday')"
      />
      <div *ngIf="availability[4].ifSlot2" class="flex flex-row ml-2">
        <input
          [(ngModel)]="availability[4].slot2Start"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <input
          [(ngModel)]="availability[4].slot2End"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <img
          src="../../../../assets/icons/cross-icon.svg"
          class="mx-4 cursor-pointer"
          (click)="remove('friday')"
        />
      </div>
    </div>
    <div nz-row class="mt-2">
      <h1 class="w-[20%]">Saturday :</h1>
      <input
        [(ngModel)]="availability[5].slot1Start"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <input
        [(ngModel)]="availability[5].slot1End"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <img
        *ngIf="!availability[5].ifSlot2"
        src="../../../../assets/icons/plus.svg"
        class="mx-4 cursor-pointer"
        (click)="add('saturday')"
      />
      <div *ngIf="availability[5].ifSlot2" class="flex flex-row ml-2">
        <input
          [(ngModel)]="availability[5].slot2Start"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <input
          [(ngModel)]="availability[5].slot2End"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <img
          src="../../../../assets/icons/cross-icon.svg"
          class="mx-4 cursor-pointer"
          (click)="remove('saturday')"
        />
      </div>
    </div>
    <div nz-row class="mt-2">
      <h1 class="w-[20%]">Sunday :</h1>
      <input
        [(ngModel)]="availability[6].slot1Start"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <input
        [(ngModel)]="availability[6].slot1End"
        class="w-[120px] mr-1"
        nz-input
        type="time"
      />
      <img
        *ngIf="!availability[6].ifSlot2"
        src="../../../../assets/icons/plus.svg"
        class="mx-4 cursor-pointer"
        (click)="add('sunday')"
      />
      <div *ngIf="availability[6].ifSlot2" class="flex flex-row ml-2">
        <input
          [(ngModel)]="availability[6].slot2Start"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <input
          [(ngModel)]="availability[6].slot2End"
          class="w-[120px] mr-1"
          nz-input
          type="time"
        />
        <img
          src="../../../../assets/icons/cross-icon.svg"
          class="mx-4 cursor-pointer"
          (click)="remove('sunday')"
        />
      </div>
    </div>
    <div nz-row nzJustify="end">
      <button
        nz-button
        (click)="saveAvailability()"
        class="btn-color"
        nzType="primary"
      >
        Save Availability
      </button>
    </div>
  </nz-spin>
</div>
