import { Component } from '@angular/core';
import * as moment from 'moment';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { BytesService } from '../../../services/bytes.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { NewAddBytesRequest } from '../../../models/newCreateBytesRequest';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { Router } from '@angular/router';

interface bytesCategoriesSelectOptions {
  label: string;
  value: string;
}

interface Frame {
  id: string;
  value: string;
  textColor: string;
  textBGColor: string;
  showTimeSeconds: number;
}

@Component({
  selector: 'app-add-bytes',
  templateUrl: './add-bytes.component.html',
  styleUrls: ['./add-bytes.component.scss'],
})
export class AddBytesComponent {
  bytesSpinner!: boolean;
  bytesCategories: bytesCategoriesSelectOptions[] = [];
  selectedBytesCategoryString: string = '';
  titleString: string = '';
  frames: Frame[] = [];
  noOfFrames: number = 0;
  selectedStartDate!: Date;
  selectedStartTime!: Date;
  selectedEndDate!: Date;
  selectedEndTime!: Date;
  masterData!: MasterDataResponse;
  isActive: boolean = true;
  editCache: { [key: string]: { edit: boolean; data: Frame } } = {};

  constructor(
    private toastService: ToastService,
    private auth: AuthService,
    private bytes: BytesService,
    private masterDataService: MasterdataService,
    private router: Router,
  ) {}

  saveBytes() {
    try {
      this.bytesSpinner = true;
      const selectedStartDateTime = moment(this.selectedStartDate).set({
        hours: parseInt(moment(this.selectedStartTime).format('HH')),
        minutes: 0,
        second: 0,
        millisecond: 0,
      });
      const selectedEndDateTime = moment(this.selectedEndDate).set({
        hours: parseInt(moment(this.selectedEndTime).format('HH')),
        minutes: 0,
        second: 0,
        millisecond: 0,
      });
      if (!selectedStartDateTime.isBefore(selectedEndDateTime)) {
        this.toastService.showAnyoErrorToast('Date should be in future');
        this.bytesSpinner = false;
        return;
      }
      const newAddBytesRequest: NewAddBytesRequest = {
        categoryId: this.selectedBytesCategoryString,
        isActive: this.isActive,
        title: this.titleString,
        noOfFrames: this.noOfFrames,
        frames: [],
        startTime: selectedStartDateTime.format('x'),
        endTime: selectedEndDateTime.format('x'),
      };
      const frames: Frame[] = [];
      for (const frame of this.frames) {
        const newFrame: Frame = {
          id: '0',
          value: frame.value,
          textColor: frame.textColor,
          textBGColor: frame.textBGColor,
          showTimeSeconds: frame.showTimeSeconds,
        };
        frames.push(newFrame);
      }
      newAddBytesRequest.frames = frames;
      this.bytes.createBytes(newAddBytesRequest).subscribe({
        next: (value) => {
          this.bytesSpinner = false;
          this.toastService.showSuccess('Byte Successfully Created');
          this.router.navigate(['/bytes']);
        },
        error: (error) => {
          this.bytesSpinner = false;
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
        },
      });
    } catch (e) {
      this.bytesSpinner = false;
      this.toastService.showError(e + '');
    }
  }

  onStartDateChange($event: Date) {
    if (moment($event).isBefore(moment())) {
      this.toastService.showError('Date should be in future');
      return;
    }
    this.selectedStartDate = moment($event)
      .hours(8)
      .minutes(0)
      .second(0)
      .toDate();
    this.selectedStartTime = moment($event)
      .hours(8)
      .minutes(0)
      .second(0)
      .toDate();
    this.selectedEndDate = moment($event)
      .startOf('day')
      .add(1, 'day')
      .add(8, 'hours')
      .toDate();
    this.selectedEndTime = moment($event)
      .startOf('day')
      .add(1, 'day')
      .add(8, 'hours')
      .toDate();
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.masterDataService.masterDataList().subscribe({
          next: (value) => {
            this.bytesCategories = value.bytesCategories.map((data) => {
              return {
                label: data.name,
                value: data._id!,
              };
            });
            this.masterData = value;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      }
    });
  }

  onKeyUp($event: any) {
    const enteredNumber = $event.target.value;
    const data: Frame[] = [];
    for (let i = 0; i < enteredNumber; i++) {
      data.push({
        id: `${i}`,
        value: '',
        textColor: '#000000',
        textBGColor: '#FFFFFF',
        showTimeSeconds: 10,
      });
    }
    this.frames = data;
    this.setEditCache();
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.frames.findIndex((item) => item.id === id);
    this.editCache[id] = {
      data: { ...this.frames[index] },
      edit: false,
    };
  }

  saveEdit(id: string): void {
    const index = this.frames.findIndex((item) => item.id === id);
    Object.assign(this.frames[index], this.editCache[id].data);
    console.log(this.frames);
    this.editCache[id].edit = false;
  }

  setEditCache(): void {
    this.frames.forEach((item) => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item },
      };
    });
  }
}
