<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text headerlistener-text md:break-keep">
        Moderation Events
      </h1>
    </div>
  </div>
</div>
<div class="flex items-center" nz-row>
  <div nz-col nzSpan="12">
    <label class="form-label required">Feed Text</label>
    <textarea
      class="w-[100%] h-60 mr-1"
      [(ngModel)]="postsText"
      nz-input
      rows="4"
      placeholder="Enter text"
      [disabled]="true"
    ></textarea>
  </div>
  <div nz-col class="pl-4" nzSpan="6">
    <button nz-button (click)="openViewMedia()">View Media</button>
  </div>
  <ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Moderation Events For this Post</span>
      </div>
    </div>
  </ng-template>
  <nz-spin [nzSpinning]="postsSpinner" nzTip="Loading...">
    <nz-table
      #basicTable
      [nzData]="tableData"
      [nzTitle]="tableHead"
      [nzScroll]="{ y: '500px' }"
      class="mt-5"
      [nzShowPagination]="true"
      [nzFrontPagination]="true"
      [nzPageSize]="10"
    >
      <thead>
        <tr>
          <th nzWidth="20%">Moderator Name</th>
          <th nzWidth="20%">Moderator Email</th>
          <th nzWidth="20%">Moderated Date</th>
          <th nzWidth="10%">Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of basicTable.data">
          <tr>
            <td>{{ data.name }}</td>
            <td>
              {{ data.email }}
            </td>
            <td>
              {{ convertIsoToFormattedDate(data.date) }}
            </td>
            <td [align]="'left'">
              <nz-tag [nzColor]="data.randomColor">{{
                utils.convertToTitleCase(data.action)
              }}</nz-tag>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </nz-spin>
</div>
<nz-drawer
  [nzSize]="'large'"
  [nzVisible]="visibleViewMedia"
  nzPlacement="bottom"
  [nzTitle]="'View Media'"
  (nzOnClose)="closeViewMedia()"
>
  <ng-container *nzDrawerContent>
    <div class="flex flex-wrap justify-around items-center">
      <div *ngFor="let media of media">
        <nz-card nzHoverable [nzCover]="coverTemplate">
          <nz-card-meta
            [nzTitle]="media.type"
            [nzDescription]="media.deleted ? 'deleted' : 'active'"
          ></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <ng-container *ngIf="media.type === MediaType.IMAGE">
            <img
              style="width: 250px"
              class="h-60 object-cover"
              [src]="media.url"
              alt="Selected Image"
            />
          </ng-container>
          <ng-container *ngIf="media.type === MediaType.VIDEO">
            <video controls class="h-60 object-cover" style="width: 400px">
              <source [src]="media.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</nz-drawer>
