<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Partners</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Partners</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ activeCount }} Active Partners</h1>
    </div>
  </div>

  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/partner/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Add new partner</span>
    </div>
  </div>
</div>
<div class="flex justify-end">
  <div class="w-[30%]">
    <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
      <input
        type="text"
        nz-input
        [(ngModel)]="globalSearchInput"
        placeholder="Search for partner name,member email,partner code"
      />
    </nz-input-group>
  </div>
</div>
<ng-template #suffixButton>
  <button
    nz-button
    nzType="primary"
    nzSize="small"
    nzSearch
    (click)="searchGlobal()"
  >
    Search
  </button>
</ng-template>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Partners</span>
    </div>
    <div nz-col>
      <button nz-button nzSize="small" nzType="primary" (click)="resetFilter()">
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSpinning]="partnerTableSpinner" nzTip="Fetching Partners......">
  <nz-table
    #partnerTable
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzData]="tableData"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="partnerTableTotalCount"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="partnerTableCurrentPageNo"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>
          Partner Id
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="partnerIdSearch"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>
          Partner
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="partnerNameSearch"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th nzCustomFilter>
          Location
          <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="locationMenu">
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
        <th>Invited/total</th>
        <th>No of Therapy Sessions</th>
        <th>Plan Status</th>
        <th nzWidth="25%">Action</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let data of partnerTable.data">
        <td>
          {{ data.partnerId }}
        </td>
        <td>{{ data.partner }}</td>
        <td>{{ data.city }}<br />{{ data.state }}</td>
        <td>{{ data.noOfPassesRedeemed }}/{{ data.noOfPasses }}</td>
        <td>{{ data.noOfSessions }}</td>
        <td [ngClass]="{ 'bg-red-10': data.planStatus.expired }">
          {{ data.planStatus.startDateString }}<br />
          {{ data.planStatus.endDateString }}
        </td>
        <td nzRight>
          <div nz-row>
            <div nz-col class="m-1">
              <span nz-tooltip nzTooltipTitle="Manage Partners">
                <button
                  [routerLink]="['/partner/add/' + data.id]"
                  nz-button
                  nzType="primary"
                  nzShape="circle"
                >
                  <span nz-icon nzType="eye" nzTheme="outline"></span>
                </button>
              </span>
            </div>
            <div nz-col class="m-1">
              <span nz-tooltip nzTooltipTitle="List Members">
                <button
                  [routerLink]="[
                    '/partner/managemember/' +
                      data.partnerId +
                      '/' +
                      data.partner
                  ]"
                  nz-button
                  nzType="primary"
                  nzShape="circle"
                >
                  <span nz-icon nzType="ordered-list" nzTheme="outline"></span>
                </button>
              </span>
            </div>
            <div nz-col class="m-1">
              <span nz-tooltip nzTooltipTitle="Therapy Details">
                <button (click)="open(data)" nz-button nzType="primary">
                  Therapy Details
                </button>
              </span>
            </div>
          </div>
        </td>
      </tr>

      <tr></tr>
    </tbody>
  </nz-table>
</nz-spin>
<nz-dropdown-menu #partnerIdSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="partnerIdColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="partIdColumnSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #partnerNameSearch="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div class="m-1" nz-row>
      <div nz-col>
        <input
          [(ngModel)]="partnerNameColumnSearch"
          nz-input
          placeholder="Search"
          nzSize="small"
        />
      </div>
    </div>
    <div class="m-1" nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="partNameSearchFn()"
        >
          Ok
        </button>
        <button
          class="m-1"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #locationMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Cities</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="cityFilter($event)">
          <div *ngFor="let cities of citiesCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="cities.value"
                [ngModel]="cities.checked"
                >{{ cities.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
      <div nz-col>
        <nz-divider class="height:14em" nzType="vertical"></nz-divider>
      </div>
      <div nz-col>
        <span class="font-bold">States</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="stateFilter($event)">
          <div *ngFor="let state of statesCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="state.value"
                [ngModel]="state.checked"
                >{{ state.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="listPartners()">
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-drawer
  [nzVisible]="visible"
  [nzPlacement]="'bottom'"
  [nzTitle]="'Therapy Session Details for ' + partnerName"
  (nzOnClose)="close()"
>
  <ng-container *nzDrawerContent>
    <div class="flex flex-row justify-between visible md:visible mb-3">
      <div class="flex flex-row justify-between gap-2">
        <app-stats-card
          [count]="sessionStatsData.totalSessions"
          text="Total Sessions"
          image="../../../../assets/icons/members-icon.svg"
        ></app-stats-card>
        <app-stats-card
          [count]="sessionStatsData.confirmedSessions"
          text="Total Confirmed Sessions"
          image="../../../../assets/icons/subscription-icon.svg"
        ></app-stats-card>
        <app-stats-card
          [count]="sessionStatsData.cancelledSessions"
          text="Total Canceled Sessions"
          image="../../../../assets/icons/members-icon.svg"
        ></app-stats-card>
        <app-stats-card
          [count]="sessionStatsData.completedSessions"
          text="Total Completed Sessions"
          image="../../../../assets/icons/members-icon.svg"
        ></app-stats-card>
      </div>
    </div>
    <div class="flex flex-row justify-between visible md:visible mb-3">
      <div class="flex flex-row justify-between gap-2">
        <app-stats-card
          [count]="sessionStatsData.noShowUserSessions"
          text="No Show User Sessions"
          image="../../../../assets/icons/members-icon.svg"
        ></app-stats-card>
        <app-stats-card
          [count]="sessionStatsData.noShowTherapistSessions"
          text="No Show Therapist Sessions"
          image="../../../../assets/icons/subscription-icon.svg"
        ></app-stats-card>
        <app-stats-card
          [count]="sessionStatsData.techIssueSessions"
          text="Tech Issue Sessions"
          image="../../../../assets/icons/members-icon.svg"
        ></app-stats-card>
        <app-stats-card
          [count]="sessionStatsData.feedbackPendingSessions"
          text="Feedback Pending Sessions"
          image="../../../../assets/icons/members-icon.svg"
        ></app-stats-card>
      </div>
    </div>
  </ng-container>
</nz-drawer>
