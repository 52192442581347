<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Bytes</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Bytes</h1>
    </div>
  </div>
  <div class="flex flex-row justify-around space-x-4">
    <div
      [routerLink]="['/bytes/add']"
      class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    >
      <div>
        <img class="add-icon" src="../../../../assets/icons/add-icon.svg"/>
      </div>
      <div>
        <span class="">Add Bytes</span>
      </div>
    </div>
    <div
      [routerLink]="['/bytes/add/bulk']"
      class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    >
      <div>
        <img class="add-icon" src="../../../../assets/icons/add-icon.svg"/>
      </div>
      <div>
        <span class="">Bulk Add Bytes</span>
      </div>
    </div>
  </div>
</div>
<nz-spin [nzSpinning]="bytesTableSpinner">
  <div nz-row nzJustify="end" nzAlign="middle">
    <div nz-col class="p-1">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for bytes title"
        />
      </nz-input-group>
    </div>
    <div nz-row>
      <nz-tabset (nzSelectChange)="tabChange($event)">
        <nz-tab nzTitle="Scheduled Bytes">
          <app-bytes-table
            #bytesScheduledTableComponent
            [inputTableData]="tableData"
            (resetFilterEvent)="resetFilter()"
            (bytesDateSortEvent)="bytesDateSort($event)"
            (bytesCategoryFilterEvent)="bytesCategoryFilter($event)"
            (pageChangeEvent)="nzPageIndexChange($event)"
            (pageSizeChangeEvent)="nzPageSizeChange($event)"
          ></app-bytes-table>
        </nz-tab>
        <nz-tab nzTitle="Delivered Bytes">
          <app-bytes-table
            #bytesDeliveredTableComponent
            [inputTableData]="tableData"
            (resetFilterEvent)="resetFilter()"
            (bytesDateSortEvent)="bytesDateSort($event)"
            (bytesCategoryFilterEvent)="bytesCategoryFilter($event)"
            (pageChangeEvent)="nzPageIndexChange($event)"
            (pageSizeChangeEvent)="nzPageSizeChange($event)"
          ></app-bytes-table>
        </nz-tab>
      </nz-tabset>
    </div>
    <ng-template #suffixButton>
      <button
        nz-button
        nzType="primary"
        nzSize="small"
        nzSearch
        (click)="searchGlobal()"
      >
        Search
      </button>
    </ng-template>
  </div>
</nz-spin>
