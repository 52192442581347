import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface RoutineGridRequest
  extends GridRequest<
    RoutineSort,
    RoutineFilter,
    RoutineSearch,
    RoutineDateFilter
  > {}

export enum RoutineSort {
  ROUTINE_STATUS = 'ROUTINE_STATUS',
}

export enum RoutineFilter {
  ROUTINE_GROUP = 'ROUTINE_GROUP',
  ROUTINE_AVAILABILITY = 'ROUTINE_AVAILABILITY',
}

export enum RoutineSearch {
  ROUTINE_NAME = 'ROUTINE_NAME',
}

export enum RoutineDateFilter {
  CREATED_BY = 'CREATED_BY',
}
