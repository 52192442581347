import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { NewRoutineRequest } from '../models/newRoutineRequest';
import { IContent } from '../models/IContent';
import { UpdateRoutineRequest } from '../models/updateRoutineRequest';
import { IRoutine } from '../models/IRoutine';
import { RoutineGridRequest } from '../models/routineGridRequest';
import { RoutineGridResponse } from '../models/routineGridResponse';
import { EnableDisableRequest } from '../models/enableDisableRequest';

interface ContentsByCategoryRequest {
  category: string;
}

interface RoutineResponse extends IRoutine {
  totalDaysEstimatedTime: number;
  instructors: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class RoutineService {
  constructor(private api: NetworkUtilsService) {}

  public routineList(gridRequest: RoutineGridRequest) {
    return this.api.post<RoutineGridResponse>(
      URL_DICT.adminRoutineGridUrl,
      gridRequest,
    );
  }

  public createRoutine(request: NewRoutineRequest) {
    return this.api.post(URL_DICT.adminRoutineUrl, request);
  }

  public getContentsByCategory(categoryRequest: ContentsByCategoryRequest) {
    return this.api.post<IContent[]>(
      URL_DICT.adminContentsByCategory,
      categoryRequest,
    );
  }

  public getRoutine(routineId: string) {
    return this.api.get<RoutineResponse>(
      URL_DICT.adminRoutineUrl + `/${String(routineId)}`,
    );
  }

  public updateRoutine(
    routineId: string,
    updateRoutineRequest: UpdateRoutineRequest,
  ) {
    return this.api.post(
      URL_DICT.adminRoutineUrl + `/${String(routineId)}`,
      updateRoutineRequest,
    );
  }

  public enableDisableRoutine(
    routineId: string,
    enableDisableRequest: EnableDisableRequest,
  ) {
    return this.api.post(
      URL_DICT.adminRoutineEnableDisableUrl + `${routineId}`,
      enableDisableRequest,
    );
  }
}
