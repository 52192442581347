<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/circles']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 *ngIf="viewMode" class="headeradminui-text pl-2">View Circles</h1>
    <h1 *ngIf="!viewMode" class="headeradminui-text pl-2">Update Circles</h1>
  </div>
</div>
<nz-spin [nzSpinning]="circlesSpinning" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div nz-row nzJustify="end">
        <div nz-col>
          <button nz-button nzType="primary" (click)="editMode()">
            Edit Circles
          </button>
        </div>
      </div>
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="6">
          <label class="form-label required">Circle Name</label>
          <input
            [(ngModel)]="circleName"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Circle Name"
            [disabled]="viewMode"
          />
        </div>
        <div nz-col nzSpan="6">
          <span><b>Upload Circle Pic</b></span>
          <br />
          <img class="pb-2" [src]="circleProfileLink" />
          <input
            (change)="uploadCircleProfilePic($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
            [disabled]="viewMode"
          />
        </div>
        <div nz-col nzSpan="12">
          <label class="form-label required">Circle Description</label>

          <textarea
            [(ngModel)]="circleDescription"
            nz-input
            rows="4"
            [disabled]="viewMode"
          >
Description</textarea
          >
        </div>
      </div>
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="10">
          <label class="form-label required">Share Message</label>
          <textarea
            [(ngModel)]="shareMessage"
            nz-input
            rows="4"
            [disabled]="viewMode"
          ></textarea>
        </div>
        <div nz-col nzSpan="6">
          <span><b>Upload Circle Share Image</b></span>
          <br />
          <img class="pb-2" [src]="circleShareImageLink" />
          <input
            (change)="uploadCirclesSharePic($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
            [disabled]="viewMode"
          />
        </div>
      </div>
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="3">
          <label class="form-label required">isAnonymous</label>
          <nz-switch
            [nzDisabled]="viewMode"
            [(ngModel)]="isAnonymous"
          ></nz-switch>
        </div>
        <div nz-col nzSpan="3">
          <label class="form-label required">isActive</label>
          <nz-switch [nzDisabled]="viewMode" [(ngModel)]="isActive"></nz-switch>
        </div>
        <div nz-col nzSpan="3">
          <label class="form-label required">Pick Light Color</label>

          <input [disabled]="viewMode" [(ngModel)]="circleColor" type="color" />
        </div>
      </div>
      <div></div>
      <nz-select
        [(ngModel)]="selectedUserInterests"
        [nzDisabled]="viewMode"
        nzPlaceHolder="Select User Interests"
        nzMode="multiple"
      >
        <nz-option
          *ngFor="let item of topics"
          [nzLabel]="item.value"
          [nzValue]="item.key"
        ></nz-option>
      </nz-select>
      <!-- <nz-divider nzText="Sub Topics"></nz-divider>
      <div nz-row nzJustify="end" *ngIf="!viewMode">
        <div nz-col>
          <button (click)="addSubTopic()" nz-button nzType="primary">
            Add SubTopic
          </button>
        </div>
      </div> -->
      <!-- <div
        *ngFor="let subtopic of subTopics; index as i"
        class="mt-3"
        nz-row
        nzAlign="middle"
      >
        <div nz-col nzSpan="6">
          <label class="form-label required">{{
            "Sub Topic " + (i + 1)
          }}</label>
          <input
            [(ngModel)]="subtopic.value"
            class="w-[90%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Sub Topic"
            [disabled]="viewMode"
          />
        </div>
        <div nz-col nzSpan="6">
          <span
            nz-icon
            (click)="deleteSubTopic(i)"
            nzType="delete"
            nzTheme="outline"
          ></span>
        </div>
      </div> -->
      <nz-divider nzText="Experts"></nz-divider>
      <div nz-row [nzGutter]="16" nzAlign="middle">
        <div nz-col nzSpan="6">
          <nz-select
            (ngModelChange)="populateExperts($event)"
            [(ngModel)]="selectedExperts"
            class="w-[100%]"
            nzAllowClear
            nzPlaceHolder="Select a Experts"
            nzShowSearch
            nzMode="multiple"
            nzSize="large"
            [nzMaxTagCount]="5"
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let expert of circleExperts"
              [nzLabel]="expert.label"
              [nzValue]="expert.value"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <nz-table
        #expertTable
        nzBordered
        [nzData]="experts"
        nzTableLayout="fixed"
        [nzPageSize]="3"
      >
        <thead>
          <tr>
            <th nzWidth="15%">Name</th>
            <th nzWidth="20%">picture</th>
            <th nzWidth="18%">Description</th>
            <th nzWidth="18%">Qualification</th>
            <th>isActive</th>
            <th>languages</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of expertTable.data; index as i">
            <ng-container
              *ngIf="!editCacheExperts[data.id].edit; else editTemplate"
            >
              <td>{{ data.name }}</td>
              <td>
                <img [src]="data.avatarImage" />
              </td>
              <td>
                {{ data.description }}
              </td>
              <td>
                {{ data.qualifications }}
              </td>
              <td>
                {{ data.isActive ? "active" : "inactive" }}
              </td>
              <td>
                {{ data.languages }}
              </td>
              <td>
                <span nz-tooltip nzTooltipTitle="Edit">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="startEditExpert(data.id)"
                    [disabled]="viewMode"
                  >
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                  </button>
                </span>
              </td>
            </ng-container>
            <ng-template #editTemplate>
              <td>
                {{ editCacheExperts[data.id].data.name }}
              </td>
              <td>
                <input
                  (change)="uploadExpertProfilePic($event, data.id, i)"
                  accept=".jpg,.jpeg,.png"
                  type="file"
                  [disabled]="viewMode"
                />
              </td>
              <td>
                <input
                  type="text"
                  nz-input
                  [(ngModel)]="editCacheExperts[data.id].data.description"
                />
              </td>
              <td>
                <input
                  type="text"
                  nz-input
                  [(ngModel)]="editCacheExperts[data.id].data.qualifications"
                />
              </td>
              <td>
                <nz-switch
                  [(ngModel)]="editCacheExperts[data.id].data.isActive"
                ></nz-switch>
              </td>
              <td>
                {{ editCacheExperts[data.id].data.languages }}
              </td>
              <td>
                <span nz-tooltip nzTooltipTitle="Save">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="saveEditExpert(data.id)"
                  >
                    <span
                      nz-icon
                      nzTheme="outline"
                      [nzType]="'check-circle'"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#52c41a'"
                    ></span>
                  </button>
                </span>
                <span nz-tooltip nzTooltipTitle="Cancel">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="cancelEditExpert(data.id)"
                  >
                    <span nz-icon nzType="left-circle" nzTheme="outline"></span>
                  </button>
                </span>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </nz-table>
      <nz-divider nzText="Moderators"></nz-divider>
      <div nz-row [nzGutter]="16" nzAlign="middle">
        <div nz-col nzSpan="6">
          <nz-select
            (ngModelChange)="populateModerators($event)"
            [(ngModel)]="selectedModerators"
            class="w-[100%]"
            nzAllowClear
            nzPlaceHolder="Select a Moderators"
            nzShowSearch
            nzMode="multiple"
            nzSize="large"
            [nzMaxTagCount]="5"
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let moderator of circleModerators"
              [nzLabel]="moderator.label"
              [nzValue]="moderator.value"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <nz-table
        #moderatorsTable
        nzBordered
        [nzData]="moderators"
        nzTableLayout="fixed"
        [nzPageSize]="3"
      >
        <thead>
          <tr>
            <th nzWidth="15%">Name</th>
            <th nzWidth="20%">Email</th>
            <th nzWidth="18%">Description</th>
            <th nzWidth="18%">Picture</th>
            <th>isActive</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of moderatorsTable.data; index as i">
            <ng-container
              *ngIf="
                !editCacheModerator[data.id].edit;
                else editTemplateModerators
              "
            >
              <td>{{ data.name }}</td>
              <td>
                {{ data.email }}
              </td>
              <td>
                {{ data.about }}
              </td>
              <td>
                <img [src]="data.avatarImage" />
              </td>
              <td>
                {{ data.active ? "active" : "inactive" }}
              </td>
              <td>
                <span nz-tooltip nzTooltipTitle="Edit">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="startEditModerator(data.id)"
                    [disabled]="viewMode"
                  >
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                  </button>
                </span>
              </td>
            </ng-container>
            <ng-template #editTemplateModerators>
              <td>
                <input
                  type="text"
                  nz-input
                  [(ngModel)]="editCacheModerator[data.id].data.name"
                />
              </td>
              <td>
                {{ data.email }}
              </td>
              <td>
                <input
                  type="text"
                  nz-input
                  [(ngModel)]="editCacheModerator[data.id].data.about"
                />
              </td>
              <td>
                <input
                  (change)="uploadModeratorProfilePic($event, data.id, i)"
                  accept=".jpg,.jpeg,.png"
                  type="file"
                />
              </td>
              <td>
                {{ editCacheModerator[data.id].data.active }}
              </td>
              <td>
                <span nz-tooltip nzTooltipTitle="Save">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="saveEditModerator(data.id)"
                  >
                    <span
                      nz-icon
                      nzTheme="outline"
                      [nzType]="'check-circle'"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#52c41a'"
                    ></span>
                  </button>
                </span>
                <span nz-tooltip nzTooltipTitle="Cancel">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="cancelEditModerator(data.id)"
                  >
                    <span nz-icon nzType="left-circle" nzTheme="outline"></span>
                  </button>
                </span>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </nz-table>
      <nz-divider nzText="Therapists"></nz-divider>
      <div nz-row [nzGutter]="16" nzAlign="middle">
        <div nz-col nzSpan="6">
          <nz-select
            (ngModelChange)="populateTherapists($event)"
            [(ngModel)]="selectedTherapists"
            class="w-[100%]"
            nzAllowClear
            nzPlaceHolder="Select a Therapists"
            nzShowSearch
            nzMode="multiple"
            nzSize="large"
            [nzMaxTagCount]="5"
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let therapist of circleTherapists"
              [nzLabel]="therapist.label"
              [nzValue]="therapist.value"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <nz-table
        #therapistsTable
        nzBordered
        [nzData]="therapists"
        nzTableLayout="fixed"
        [nzPageSize]="3"
      >
        <thead>
          <tr>
            <th nzWidth="15%">Name</th>
            <th nzWidth="20%">Email</th>
            <th nzWidth="30%">Description</th>
            <th nzWidth="18%">Picture</th>
            <th>languages</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of therapistsTable.data; index as i">
            <td>{{ data.name }}</td>
            <td>
              {{ data.email }}
            </td>
            <td>
              {{ data.description }}
            </td>
            <td>
              <img [src]="data.profilepic" />
            </td>
            <td>
              {{ data.languages }}
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div class="mt-5" nz-row nzJustify="end" *ngIf="!viewMode">
        <div nz-col>
          <button (click)="updateCircle()" nz-button nzType="primary">
            Update Circle
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
