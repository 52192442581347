<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">{{ tableTitle }}</span>
    </div>
    <div nz-col>
      <button
        class="btn-color"
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="resetFilter()"
      >
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSpinning]="postSpinner" nzTip="Loading...">
  <nz-table
    #basicTable
    [nzData]="postTableData?.data!"
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="postTableData?.totalRecords!"
    [nzPageSize]="postTableData?.pageSize!"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="postTableData?.pageNum!"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th nzWidth="20%">Feed Text</th>
        <th nzWidth="15%">
          Sub Topics
          <nz-filter-trigger
            [nzActive]="true"
            [nzDropdownMenu]="CircleNameAndSubTopicsMenu"
          >
            <span nz-icon nzTheme="outline" nzType="filter-o"></span>
          </nz-filter-trigger>
        </th>
        <th nzWidth="20%">User Details</th>
        <th>
          Tags
          <nz-filter-trigger [nzActive]="true" [nzDropdownMenu]="tagsMenu">
            <span nz-icon nzTheme="outline" nzType="filter-o"></span>
          </nz-filter-trigger>
        </th>
        <th
          (nzSortOrderChange)="sortCombinedDate($event)"
          nzCustomFilter
          nzShowSort="true"
        >
          Date
        </th>

        <th nzWidth="10%">Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of basicTable.data">
        <tr>
          <td>{{ data.text }}</td>
          <td [align]="'left'">
            <div class="mt-2" *ngFor="let data of data.circleTopics">
              <nz-tag [nzColor]="data.randomColor">{{ data.topics }}</nz-tag>
            </div>
          </td>
          <td
            (nzExpandChange)="onExpandChange(data.id, $event)"
            [nzExpand]="expandSet.has(data.id)"
          >
            {{ data.userName }}
          </td>
          <td [align]="'center'">
            <div class="mt-2" *ngFor="let data of data.alltags">
              <nz-tag [nzColor]="data.randomColor">{{ data.tag }}</nz-tag>
            </div>
          </td>
          <td>
            <div class="ml-1 mr-1" colspan="2" nz-col>
              <div nz-row nzJustify="start">
                Post Time:
                {{
                  data.createdAt != undefined
                    ? convertIsoToFormattedDate(data.createdAt)
                    : "N/A"
                }}
              </div>
              <div *ngIf="data.lastReaction" nz-row nzJustify="start">
                lastReaction:
                {{
                  data.lastReaction != undefined
                    ? convertIsoToFormattedDate(data.lastReaction)
                    : "N/A"
                }}
              </div>
              <div *ngIf="data.lastComment" nz-row nzJustify="start">
                lastComment:
                {{
                  data.lastComment != undefined
                    ? convertIsoToFormattedDate(data.lastComment)
                    : "N/A"
                }}
              </div>
            </div>
          </td>
          <td>
            <span nz-tooltip nzTooltipTitle="All Actions">
              <button nz-button nz-dropdown [nzDropdownMenu]="menu">
                All Actions
              </button>
            </span>
          </td>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <span *ngFor="let action of allowedActions">
                <li
                  *ngIf="displayActions(action, data)"
                  nz-menu-item
                  (click)="performAction(action, data)"
                >
                  {{
                    convertToTitleCase(action) == "Moderation Required"
                      ? "Approve Post"
                      : convertToTitleCase(action)
                  }}
                </li>
              </span>
            </ul>
          </nz-dropdown-menu>
        </tr>
        <tr [nzExpand]="expandSet.has(data.id)">
          <div nz-row nzAlign="top" nzJustify="start">
            <div class="ml-1 mr-1 w-[20%]" colspan="2" nz-col></div>
            <div class="ml-1 mr-1 w-[15%]" colspan="2" nz-col></div>
            <div class="ml-1 mr-1 w-[20%]" colspan="2" nz-col>
              <div nz-row nzJustify="start">
                User Email :
                {{ data.userEmail }}
              </div>
            </div>
            <div class="w-[20%]" colspan="1" nz-col></div>
            <div class="w-[20%]" colspan="1" nz-col></div>
          </div>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</nz-spin>
<nz-drawer
  [nzSize]="size"
  [nzVisible]="visible"
  nzPlacement="bottom"
  [nzTitle]="title"
  (nzOnClose)="closeViewReport()"
>
  <ng-container *nzDrawerContent>
    <h1>Feed Text:</h1>
    <p>{{ postText }}</p>
    <div class="flex flex-wrap justify-around items-center">
      <div *ngFor="let media of media">
        <nz-card nzHoverable [nzCover]="coverTemplate">
          <nz-card-meta [nzTitle]="media?.type!"></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <ng-container *ngIf="media?.type === MediaType.IMAGE">
            <img
              style="width: 250px"
              class="h-60 object-cover"
              [src]="media.url"
              alt="Selected Image"
            />
          </ng-container>
          <ng-container *ngIf="media?.type === MediaType.VIDEO">
            <video controls class="h-60 object-cover" style="width: 400px">
              <source [src]="media?.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <nz-spin [nzSpinning]="reportSpinner" nzTip="Loading...">
      <app-report-table
        [title]="'Reports'"
        [reportData]="reportTableData"
        (resetFilterEvent)="resetFilterReports()"
        (pageChangeEvent)="nzPageIndexChangeReportTable($event)"
        (pageSizeChangeEvent)="nzPageSizeChangeReportTable($event)"
        (dismissedReportEvent)="dismissReport($event)"
      ></app-report-table>
    </nz-spin>
  </ng-container>
</nz-drawer>

<nz-drawer
  [nzSize]="size"
  [nzVisible]="visibleViewMedia"
  nzPlacement="bottom"
  [nzTitle]="title"
  (nzOnClose)="closeViewMedia()"
>
  <ng-container *nzDrawerContent>
    <div class="flex flex-wrap justify-around items-center">
      <div *ngFor="let media of postsObj?.allMedia">
        <nz-card nzHoverable [nzCover]="coverTemplate">
          <nz-card-meta
            [nzTitle]="media.type"
            [nzDescription]="media.deleted ? 'deleted' : 'active'"
          ></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <ng-container *ngIf="media.type === MediaType.IMAGE">
            <img
              style="width: 250px"
              class="h-60 object-cover"
              [src]="media.url"
              alt="Selected Image"
            />
          </ng-container>
          <ng-container *ngIf="media.type === MediaType.VIDEO">
            <video controls class="h-60 object-cover" style="width: 400px">
              <source [src]="media.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</nz-drawer>
<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="postCommentTitle"
  (nzOnCancel)="handleCancelBlockPost()"
  (nzOnOk)="handleOkBlockPost()"
>
  <ng-container *nzModalContent>
    <div>
      <input
        class="w-[100%] mr-1"
        [(ngModel)]="blockPostReason"
        nz-input
        nzSize="large"
        [placeholder]="placeholder"
      />
    </div>
    <div class="pt-5" *ngIf="this.postData.visible">
      <nz-select
        [(ngModel)]="selectedProblemString"
        class="w-[100%] mr-3"
        nzAllowClear
        nzPlaceHolder="Select a Problem"
        nzShowSearch
        nzSize="large"
      >
        <nz-option
          *ngFor="let problem of problems"
          [nzLabel]="problem.label"
          [nzValue]="problem.value"
        ></nz-option>
      </nz-select>
    </div>
  </ng-container>
</nz-modal>
<nz-modal
  [(nzVisible)]="isVisibleBlock"
  nzTitle="Block/UnBlock User"
  (nzOnCancel)="handleCancelBlockUser()"
  (nzOnOk)="handleOkBlockUser()"
>
  <ng-container *nzModalContent>
    <div>
      <input
        class="w-[100%] mr-1"
        [(ngModel)]="blockUserReason"
        nz-input
        nzSize="large"
        placeholder="Enter Block/UnBlock User Reason"
      />
    </div>
    <div class="pt-5" *ngIf="!this.postData.userBlocked">
      <nz-select
        [(ngModel)]="selectedProblemString"
        class="w-[100%] mr-1"
        nzAllowClear
        nzPlaceHolder="Select a Problem"
        nzShowSearch
        nzSize="large"
      >
        <nz-option
          *ngFor="let problem of problems"
          [nzLabel]="problem.label"
          [nzValue]="problem.value"
        ></nz-option>
      </nz-select>
    </div>
  </ng-container>
</nz-modal>
<nz-dropdown-menu #CircleNameAndSubTopicsMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">SubTopics</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="subTopicsFilterChangeFn($event)">
          <div *ngFor="let topic of subTopicsCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="topic.value"
                [ngModel]="topic.checked"
                >{{ topic.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="subTopicsOkFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
<nz-dropdown-menu #tagsMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Tags</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="tagsFilterChangeFn($event)">
          <div *ngFor="let topic of tagsCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="topic.value"
                [ngModel]="topic.checked"
                >{{ topic.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="tagsOkFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button
          class="m-1 btn-color"
          nz-button
          nzType="primary"
          (click)="resetFilter()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
