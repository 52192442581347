import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { EmailRequest, OtpRequest } from 'app/models/common-models';
// import { UserService } from '../../services/user.service';
// import { ActivatedRoute, Router } from '@angular/router';
// import { ToastService } from 'app/services/toastr.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent {
  resendOtp: boolean = false;
  email = null;
  firstLogin: boolean = false;
  phoneNumber = null;
  timeLeft: number = 10;
  timer: any;
  otp_1!: string;
  otp_2!: string;
  otp_3!: string;
  otp_4!: string;
  otpForm!: FormGroup;
  url: string = window.location.href;

  //   constructor(private formBuilder: FormBuilder,
  //     private userService: UserService,
  //     private router: Router, private activatedRoute: ActivatedRoute, private toastrService: ToastService) {
  //     this.otpForm = this.formBuilder.group({
  //       otp_1: ['', [Validators.required, Validators.pattern(/^(\d{1})?$/)]],
  //       otp_2: ['', [Validators.required, Validators.pattern(/^(\d{1})?$/)]],
  //       otp_3: ['', [Validators.required, Validators.pattern(/^(\d{1})?$/)]],
  //       otp_4: ['', [Validators.required, Validators.pattern(/^(\d{1})?$/)]],
  //     });
  //     if(router.url.includes('true')){
  // this.firstLogin = true;
  //     }
  //   }
  // ​
  resendOtpFn() {
    //     this.resetTimer();
    //     this.clearOtp();
    //     let req: EmailRequest = {
    //       phoneNumber: null,
    //       email: this.email
    //     };
    //     if (String(this.email).match(/^\d+$/)) {
    //       req = {
    //         email: null,
    //         phoneNumber: this.email
    //       };
    //     }
    //     this.userService.generateOtp(req).subscribe({
    //       next: (value) => {
    //         this.toastrService.showSuccess("OTP sent successfully")
    //       },
    //       error: (err) => {
    //         this.toastrService.showError("Invalid user. Kindly sign-up if you still haven't!")
    //       }
    //     })
  }

  verifyOtp(otpForm: any) {
    //     // const otp: string = `${this.otp_1}${this.otp_2}${this.otp_3}${this.otp_4}`;
    //     if (!otpForm.valid) {
    //       this.toastrService.showError("Please enter a valid OTP")
    //       return;
    //     }
    //     const otp: string = `${otpForm.value.otp_1}${otpForm.value.otp_2}${otpForm.value.otp_3}${otpForm.value.otp_4}`;
    // ​
    //     let req: OtpRequest = {
    //       otp: otp,
    //       phoneNumber: this.phoneNumber || '',
    //       email: this.email || ''
    //     };
    //     this.userService.verifyOtp(req).subscribe({
    //       next: (value) => {
    //         this.router.navigate(['/createpassword/' + this.email||this.phoneNumber+"/"+this.firstLogin]);
    //         this.toastrService.showSuccess("OTP verified successfully")
    //       },
    //       error: (err) => {
    //         if(err.error.message === "Invalid Otp") {
    //           this.toastrService.showError("Invalid OTP. Please enter correct OTP")
    //         }
    //         else {
    //           console.log(err);
    //           this.toastrService.showError("Internal server error. OTP could not be verified. Please try again")
    //         }
    //       }
    //     })
  }
  // ​
  //   ngOnInit(): void {
  //     this.resetTimer();
  //     if(this.activatedRoute.snapshot.params){

  //    let value = this.activatedRoute.snapshot.params['toverify'];
  //    if(value){
  //     if("[0-9]+".match(value)){
  //        this.phoneNumber = value;
  //     }
  //     if(value.includes('@')){
  //       this.email = value;
  //     }else{
  //       this.phoneNumber = value;
  // ​
  //     }
  //    }
  //     }
  //   }
  // ​
  focusNext(val: string, next: string) {
    //     if (val) {
    //       const ele: any = document.getElementById(next);
    //       if (ele) {
    //         ele.focus();
    //       }
    //     }
  }
  // ​
  resetTimer() {
    //     this.resendOtp = false;
    //     this.timer = setInterval(() => {
    //       if (this.timeLeft > 0) {
    //         this.timeLeft--;
    //       } else {
    //         clearInterval(this.timer);
    //         this.resendOtp = true;
    //         this.timeLeft = 10;
    //       }
    //     }, 1000)
  }

  clearOtp() {
    //   this.otp_1 = '';
    //   this.otp_2 = '';
    //   this.otp_3 = '';
    //   this.otp_4 = '';
  }
}
