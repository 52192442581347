import { OnInit } from '@angular/core';
import { IAnyoUser } from '../../auth/models/IAnyoUser';
import { AuthService } from '../../auth/auth.service';

function BaseClassDecorator(): ClassDecorator {
  return () => {}; // No-op decorator
}

@BaseClassDecorator()
export abstract class AnyoComponent implements OnInit {
  pageLoading: boolean = false;
  user: IAnyoUser | undefined;

  protected constructor(protected auth: AuthService) {}

  abstract ready(user?: IAnyoUser | undefined): Promise<void>;

  ngOnInit(): void {
    this.pageLoading = true;
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        this.user = user;
        await this.ready(user);
        this.pageLoading = false;
      }
    });
  }
}
