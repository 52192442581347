<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div
    class="flex flex-wrap w-1/4 justify-start"
    *ngIf="!viewForm && !editMode"
  >
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/therapist/manage']"
    />
    <h1 class="headeradminui-text">{{ "Add Therapist" }}</h1>
  </div>
  <div class="flex flex-wrap w-1/4 justify-start" *ngIf="viewForm || editMode">
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/therapist/manage']"
    />
    <h1 class="headeramdinui-text">
      {{ therapist.firstName }} {{ therapist.lastName }}
    </h1>
  </div>
  <div class="flex gap-2">
    <div *ngIf="(viewForm || editMode) && !isDisabled" class="flex justify-end">
      <button
        type="button"
        class="btn btn-error previous"
        (click)="dialogShow('therapist')"
      >
        Disable
      </button>
    </div>
    <div *ngIf="(viewForm || editMode) && isDisabled" class="flex justify-end">
      <button
        type="button"
        class="btn btn-success"
        (click)="dialogShow('therapist')"
      >
        Enable
      </button>
    </div>
    <div
      *ngIf="viewForm"
      class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
      (click)="editForm()"
    >
      <div>
        <img class="edit-icon" src="../../../../assets/icons/edit-icon.svg" />
      </div>
      <div>
        <span class="">Edit therapist</span>
      </div>
    </div>
    <div
      *ngIf="editMode"
      class="flex flex-row bg-white justify-between btn btn-discard gap-1"
      [routerLink]="['/therapist/manage']"
    >
      Discard
    </div>
  </div>
</div>

<div class="custom-card">
  <div class="card-body">
    <app-progress-bar
      [steppertitle1]="steppertitle1"
      [steppertitle2]="steppertitle2"
      [status]="gotonext"
    ></app-progress-bar>

    <form #addTherapistForm="ngForm" (ngSubmit)="submit(addTherapistForm)">
      <div [hidden]="gotonext">
        <div class="flex flex-col flex-wrap md:gap-4 lg:gap-4 sm:gap-2">
          <div class="title-class">
            <label class="form-label required">Title</label>
            <select
              [ngClass]="
                (isSubmit && therapist.title) || !isSubmit
                  ? 'select select-sm title-select'
                  : 'select select-sm title-select input-adminui-error'
              "
              name="title"
              [(ngModel)]="therapist.title"
              ngModel
              required
              [disabled]="viewForm"
            >
              <option value="" disabled selected>Title</option>
              <option>Mr</option>
              <option>Ms</option>
              <option>Mrs</option>
            </select>
          </div>
          <div class="divider"></div>
          <div
            class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2"
          >
            <div class="flex-auto">
              <label class="form-label required">First Name</label>
              <input
                [ngClass]="
                  (isSubmit && therapist.firstName) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="firstname"
                placeholder="Enter your firstname"
                [(ngModel)]="therapist.firstName"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">Last Name</label>
              <input
                [ngClass]="
                  (isSubmit && therapist.lastName) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="lastname"
                placeholder="Enter your lastname"
                [(ngModel)]="therapist.lastName"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">E-mail ID</label>
              <input
                type="email"
                [ngClass]="
                  (isSubmit && therapist.email) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="email"
                placeholder="Enter your e-mail"
                [(ngModel)]="therapist.email"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
            <div class="flex-auto">
              <label class="form-label required">Mobile Number</label>
              <input
                [ngClass]="
                  (isSubmit && therapist.contactNo) || !isSubmit
                    ? 'input-adminui small-text'
                    : 'input-adminui small-text input-adminui-error'
                "
                name="mobile"
                placeholder="Enter your mobile number"
                [(ngModel)]="therapist.contactNo"
                ngModel
                required
                [disabled]="viewForm"
              />
            </div>
          </div>
          <div
            class="flex flex-row items-center justify-between md:gap-4 lg:gap-4 sm:gap-2"
          >
            <div class="flex-auto w-1/2">
              <label class="form-label required">Languages known</label>
              <select2
                name="languages"
                [value]="therapist.languages"
                [data]="languages"
                multiple="true"
                (update)="updateLanguages($event)"
                [disabled]="viewForm"
              >
              </select2>
            </div>
            <div class="flex-auto w-1/2">
              <label class="form-label required">Qualifications</label>
              <select2
                [ngClass]="
                  (isSubmit && therapist.qualification) || !isSubmit
                    ? ''
                    : '.select2-container--default'
                "
                name="qualification"
                [data]="qualifications"
                [value]="therapist.qualification"
                multiple="true"
                (update)="updateQualification($event)"
                [disabled]="viewForm"
              >
              </select2>
            </div>
            <div class="flex-auto w-1/2">
              <label class="form-label required">Areas of Expertise</label>
              <select2
                [ngClass]="
                  (isSubmit && therapist.yearsOfExperience) || !isSubmit
                    ? ''
                    : '.select2-container--default'
                "
                name="expertise"
                [data]="expertise"
                multiple="true"
                [value]="therapist.areaOfExpertise"
                (update)="updateExpertise($event)"
                [disabled]="viewForm"
              >
              </select2>
            </div>
          </div>
          <div class="divider"></div>
          <div class="grid grid-cols-3 gap-3">
            <div class="flex flex-col w-full">
              <label class="form-label">Select Designation Level :</label>
              <nz-select
                [nzDisabled]="viewForm"
                [(ngModel)]="selectedTherapistLevel"
                nzPlaceHolder="Select Designation Level"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let vertical of therapistExpertLevels"
                  [nzValue]="vertical.data"
                  [nzLabel]="vertical.data"
                ></nz-option>
              </nz-select>
            </div>
            <div class="w-full">
              <label class="form-label">Rate Therapist :</label>
              <nz-rate
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="therapist.rating"
              ></nz-rate>
            </div>
            <div>
              <label class="form-label">Ideal Price :</label>
              <input
                nz-input
                type="number"
                placeholder="Enter Ideal Price"
                [(ngModel)]="idealPrice"
                class="w-full"
                [disabled]="viewForm"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="grid grid-cols-3 gap-3">
            <div class="flex flex-col w-full">
              <label class="form-label">Select User Interest :</label>
              <nz-select
                [nzDisabled]="viewForm"
                [(ngModel)]="selectedUserInterests"
                nzPlaceHolder="Select User Interests"
                nzMode="multiple"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option
                  *ngFor="let item of userInterestTopics"
                  [nzLabel]="item.value"
                  [nzValue]="item.key"
                ></nz-option>
              </nz-select>
            </div>
            <div>
              <label class="form-label">TagLine</label>
              <input
                nz-input
                type="text"
                placeholder="Enter Tag Line"
                [(ngModel)]="therapist.tagLine"
                class="w-full"
                [disabled]="viewForm"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="grid grid-cols-2 gap-3">
            <div
              *ngFor="let item of expertiseLevelArray; index as i"
              class="border border-gray-500 rounded-lg p-5"
            >
              <div class="flex flex-row justify-between gap-3">
                <nz-select
                  [nzDisabled]="viewForm"
                  class="w-[130%]"
                  [ngModel]="item.verticalId"
                  nzPlaceHolder="Select Vertical"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="verticalChange(i, $event)"
                >
                  <nz-option
                    *ngFor="let vertical of verticalsMasterDataFiltered"
                    [nzValue]="vertical.value"
                    [nzLabel]="vertical.label"
                    [nzDisabled]="vertical.disabled"
                  ></nz-option>
                </nz-select>
                <!--Delete button if not last element-->
                <button
                  [disabled]="viewForm"
                  type="button"
                  nz-button
                  nzType="primary"
                  nzShape="circle"
                  *ngIf="i !== expertiseLevelArray.length - 1"
                  (click)="deleteVertical(i)"
                >
                  <span nz-icon nzType="minus" nzTheme="outline"></span>
                </button>

                <button
                  [disabled]="viewForm"
                  *ngIf="
                    i === expertiseLevelArray.length - 1 &&
                    verticalsMasterDataFiltered.length
                  "
                  type="button"
                  nz-button
                  nzType="primary"
                  nzShape="circle"
                  (click)="addVertical()"
                >
                  <span nz-icon nzType="plus" nzTheme="outline"></span>
                </button>
              </div>
              <br />
              <div class="flex flex-col gap-3">
                <div *ngFor="let concern of item.concerns; index as j">
                  <div class="flex flex-row flex-1 gap-3">
                    <nz-select
                      [nzDisabled]="viewForm"
                      [ngModelOptions]="{ standalone: true }"
                      class="w-[100%]"
                      [ngModel]="concern.concernsId"
                      nzPlaceHolder="Select Concern"
                      (ngModelChange)="concernChange(i, j, $event)"
                    >
                      <nz-option
                        *ngFor="let cItem of item.concernsList"
                        [nzLabel]="cItem.label"
                        [nzValue]="cItem.value"
                        [nzDisabled]="cItem.disabled"
                      ></nz-option>
                    </nz-select>
                    <nz-select
                      [nzDisabled]="viewForm"
                      [ngModelOptions]="{ standalone: true }"
                      [ngModel]="concern.expertLevelId"
                      class="w-[100%]"
                      nzPlaceHolder="Select Expertise"
                      (ngModelChange)="expertLevelChange(i, j, $event)"
                    >
                      <nz-option
                        *ngFor="let expItem of expertLevelMasterData"
                        [nzLabel]="expItem.label"
                        [nzValue]="expItem.value"
                      ></nz-option>
                    </nz-select>
                    <button
                      [disabled]="viewForm"
                      type="button"
                      nz-button
                      nzType="primary"
                      nzShape="circle"
                      *ngIf="j !== item.concerns.length - 1"
                      (click)="deleteConcern(i, j)"
                    >
                      <span nz-icon nzType="minus" nzTheme="outline"></span>
                    </button>

                    <button
                      [disabled]="viewForm"
                      *ngIf="
                        j === item.concerns.length - 1 &&
                        item.concernsList.length
                      "
                      (click)="addConcern(i)"
                      type="button"
                      nz-button
                      nzType="primary"
                      nzShape="circle"
                    >
                      <span nz-icon nzType="plus" nzTheme="outline"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex flex-row justify-between items-center md:gap-4 lg:gap-4 sm:gap-2"
          >
            <div
              class="flex flex-col justify-between grow basis-1/2 md:gap-4 lg:gap-4 sm:gap-2"
            >
              <div
                class="flex flex-row justify-between md:gap-4 lg:gap-4 sm:gap-2"
              >
                <div class="grow">
                  <label class="form-label required">Years of Experience</label>
                  <input
                    type="number"
                    max="30"
                    min="0"
                    [ngClass]="
                      (isSubmit && therapist.yearsOfExperience) || !isSubmit
                        ? 'input-adminui small-text'
                        : 'input-adminui small-text input-adminui-error'
                    "
                    name="experience"
                    placeholder="Enter your experience in years"
                    [(ngModel)]="therapist.yearsOfExperience"
                    ngModel
                    required
                    [disabled]="viewForm"
                    (keydown)="inputValidation($event)"
                  />
                </div>
              </div>
              <div>
                <label class="form-label required">About Therapist</label>
                <textarea
                  [ngClass]="
                    (isSubmit && therapist.bio) || !isSubmit
                      ? 'textarea scrollable'
                      : 'textarea scrollable input-adminui-error'
                  "
                  name="about"
                  placeholder="Enter short description here"
                  [(ngModel)]="therapist.bio"
                  ngModel
                  required
                  [readonly]="viewForm"
                  maxlength="400"
                ></textarea>
              </div>
            </div>
            <div class="vertical-separator"></div>
            <div class="flex-row">
              <div
                class="grow basis-1/2 clickable"
                (click)="!viewForm && openFileExplorerPicture()"
              >
                <label class="form-label required"
                  >Upload Profile Picture</label
                >
                <div
                  [ngClass]="
                    (isSubmit && therapist.picture) || !isSubmit
                      ? 'flex p-6 border-dashed rounded-md gap-4 upload-pic'
                      : 'flex p-6 border-dashed rounded-md gap-4 upload-pic-input-adminui-error'
                  "
                  class="flex p-6 border-dashed rounded-md upload-pic"
                >
                  <img
                    *ngIf="!therapist.picture || reUpload"
                    class="m-2 cursor-pointer"
                    src="../../../../assets/icons/upload-pic.svg"
                  />
                  <img
                    *ngIf="therapist.picture"
                    class="md:w-1/12 sm:w-full w-full"
                    [src]="therapist.picture"
                  />
                  <div class="flex flex-col items-center">
                    <p
                      *ngIf="
                        !profilePicture &&
                        (therapist.picture === 'default' || !therapist.picture)
                      "
                    >
                      Browse to select file
                    </p>
                    <p
                      *ngIf="
                        therapist.picture !== 'default' &&
                        therapist.picture &&
                        !profilePicture
                      "
                    >
                      Re-upload file
                    </p>
                    <p *ngIf="profilePicture">File upload successful</p>
                    <p class="text-xs">Upload jpg/png Max 5MB*</p>
                  </div>
                  <input
                    (change)="uploadTherapistImage($event)"
                    accept=".jpg,.jpeg"
                    type="file"
                    #fileInput
                    style="display: none"
                    required
                  />
                </div>
              </div>
              <img
                class="cursor-pointer"
                src="../../../../assets/icons/delete-spoc.svg"
                *ngIf="this.editMode && therapist.picture"
                (click)="dialogShow('thumbnail')"
              />
              <div
                class="grow basis-1/2 clickable"
                (click)="!viewForm && openFileExplorerVideoBio()"
              >
                <label class="form-label required">Upload Video Bio</label>
                <div
                  [ngClass]="
                    (isSubmit && therapist.videoBio) || !isSubmit
                      ? 'flex p-6 border-dashed rounded-md gap-4 upload-pic'
                      : 'flex p-6 border-dashed rounded-md gap-4 upload-pic-input-adminui-error'
                  "
                  class="flex p-6 border-dashed rounded-md upload-pic"
                >
                  <img
                    *ngIf="!therapist.videoBio || reUpload"
                    class="m-2 cursor-pointer"
                    src="../../../../assets/icons/upload-pic.svg"
                  />

                  <div class="flex flex-col items-center">
                    <p *ngIf="!videoBioFile && !therapist.videoBio">
                      Browse to select file
                    </p>
                    <p *ngIf="therapist.videoBio || videoBioFile">
                      Re-upload file
                    </p>
                    <p *ngIf="therapist.videoBio">File upload successful</p>
                    <p class="text-xs">Upload .mp4 Max 10MB*</p>
                  </div>
                  <input
                    (change)="uploadVideoImage($event)"
                    accept=".mp4"
                    type="file"
                    #videoBioFileInput
                    style="display: none"
                    required
                  />
                </div>
              </div>
              <img
                class="cursor-pointer"
                src="../../../../assets/icons/delete-spoc.svg"
                *ngIf="this.editMode && (therapist.videoBio || videoBioFile)"
                (click)="dialogShow('videoBio')"
              />
            </div>

            <!-- <img *ngIf="viewForm || editMode" class="md:w-1/4 sm:w-full w-full"   [src]="therapist.picture | secure | async"> -->
          </div>
        </div>

        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row w-1/4 justify-between">
            <label class="form-label m-2">Flexible With Timings</label>
            <input
              type="checkbox"
              class="switch switch-sm flex gap-3"
              (change)="flexible($event)"
              [(checked)]="therapist.isFlexibleWithTiming"
              [disabled]="viewForm"
            />
          </div>
          <!-- <div>
            <button
              type="button"
              class="btn btn-primary btn-block-adminui reset-button"
              (click)="reset()"
              [disabled]="viewForm"
            >
              Reset All
            </button>
          </div> -->
        </div>

        <!-- <div class="divider"></div> -->

        <div class="flex flex-row w-1/4 mb-4 justify-between">
          <label class="form-label m-2">Therapist Available</label>
          <input
            type="checkbox"
            class="switch switch-sm flex gap-3"
            (change)="available($event)"
            [(checked)]="therapist.isAvailable"
            [disabled]="viewForm"
          />
        </div>

        <label class="form-label mb-4 required"
          >Preferred Mode of Therapy</label
        >
        <div class="flex flex-row w-4/12 justify-between">
          <div class="flex flex-wrap cursor-pointer gap-4">
            <input
              type="checkbox"
              name="mode1"
              value="audio"
              class="checkbox checkbox-sm"
              [(checked)]="audio"
              ngModel
              [disabled]="viewForm"
            />
            <span class="checkbox-span">Audio Call</span>
          </div>
          <div class="flex flex-wrap cursor-pointer gap-4">
            <input
              type="checkbox"
              name="mode2"
              value="video"
              class="checkbox checkbox-sm"
              [(checked)]="video"
              ngModel
              [disabled]="viewForm"
            />
            <span class="checkbox-span">Video Call</span>
          </div>
          <div class="flex flex-wrap cursor-pointer gap-4">
            <input
              type="checkbox"
              name="mode3"
              value="chat"
              class="checkbox checkbox-sm"
              [(checked)]="chat"
              ngModel
              [disabled]="viewForm"
            />
            <span class="checkbox-span">Chat</span>
          </div>
        </div>
      </div>

      <div [hidden]="!gotonext">
        <div class="divider divide"></div>
        <label class="form-label required">Set Available Hours</label>
        <div class="flex flex-row items-center">
          <div class="flex flex-row w-1/5 justify-between">
            <label class="form-label additional-label m-2">Monday</label>
          </div>
          <div class="flex flex-row my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="monday-start"
              placeholder="Start timing"
              [(ngModel)]="availability[0].slot1Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="monday-end"
              placeholder="End timing"
              [(ngModel)]="availability[0].slot1End"
              [disabled]="viewForm"
            />
            <img
              *ngIf="!availability[0].ifSlot2"
              src="../../../../assets/icons/plus.svg"
              class="mx-4 cursor-pointer"
              (click)="add('monday')"
            />
          </div>
          <div *ngIf="availability[0].ifSlot2" class="flex flex-row ml-12 my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="monday2-start"
              placeholder="Start timing"
              [(ngModel)]="availability[0].slot2Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="monday2-end"
              placeholder="End timing"
              [(ngModel)]="availability[0].slot2End"
              [disabled]="viewForm"
            />
            <img
              src="../../../../assets/icons/cross-icon.svg"
              class="mx-4 cursor-pointer"
              (click)="remove('monday')"
            />
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div class="flex flex-row w-1/5 justify-between">
            <label class="form-label additional-label m-2">Tuesday</label>
          </div>
          <div class="flex flex-row my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="tuesday-start"
              placeholder="Start timing"
              [(ngModel)]="availability[1].slot1Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="tuesday-end"
              placeholder="End timing"
              [(ngModel)]="availability[1].slot1End"
              [disabled]="viewForm"
            />
            <img
              *ngIf="!availability[1].ifSlot2"
              src="../../../../assets/icons/plus.svg"
              class="mx-4 cursor-pointer"
              (click)="add('tuesday')"
            />
          </div>
          <div *ngIf="availability[1].ifSlot2" class="flex flex-row ml-12 my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="tuesday2-start"
              placeholder="Start timing"
              [(ngModel)]="availability[1].slot2Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="tuesday2-end"
              placeholder="End timing"
              [(ngModel)]="availability[1].slot2End"
              [disabled]="viewForm"
            />
            <img
              src="../../../../assets/icons/cross-icon.svg"
              class="mx-4 cursor-pointer"
              (click)="remove('tuesday')"
            />
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div class="flex flex-row w-1/5 justify-between">
            <label class="form-label additional-label m-2">Wednesday</label>
          </div>
          <div class="flex flex-row my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="wednesday-start"
              placeholder="Start timing"
              [(ngModel)]="availability[2].slot1Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="wednesday-end"
              placeholder="End timing"
              [(ngModel)]="availability[2].slot1End"
              [disabled]="viewForm"
            />
            <img
              *ngIf="!availability[2].ifSlot2"
              src="../../../../assets/icons/plus.svg"
              class="mx-4 cursor-pointer"
              (click)="add('wednesday')"
            />
          </div>
          <div *ngIf="availability[2].ifSlot2" class="flex flex-row ml-12 my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="wednesday2-start"
              placeholder="Start timing"
              [(ngModel)]="availability[2].slot2Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="wednesday2-end"
              placeholder="End timing"
              [(ngModel)]="availability[2].slot2End"
              [disabled]="viewForm"
            />
            <img
              src="../../../../assets/icons/cross-icon.svg"
              class="mx-4 cursor-pointer"
              (click)="remove('wednesday')"
            />
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div class="flex flex-row w-1/5 justify-between">
            <label class="form-label additional-label m-2">Thursday</label>
          </div>
          <div class="flex flex-row my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="thursday-start"
              placeholder="Start timing"
              [(ngModel)]="availability[3].slot1Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="thursday-end"
              placeholder="End timing"
              [(ngModel)]="availability[3].slot1End"
              [disabled]="viewForm"
            />
            <img
              *ngIf="!availability[3].ifSlot2"
              src="../../../../assets/icons/plus.svg"
              class="mx-4 cursor-pointer"
              (click)="add('thursday')"
            />
          </div>
          <div *ngIf="availability[3].ifSlot2" class="flex flex-row ml-12 my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="thursday2-start"
              placeholder="Start timing"
              [(ngModel)]="availability[3].slot2Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="thursday2-end"
              placeholder="End timing"
              [(ngModel)]="availability[3].slot2End"
              [disabled]="viewForm"
            />
            <img
              src="../../../../assets/icons/cross-icon.svg"
              class="mx-4 cursor-pointer"
              (click)="remove('thursday')"
            />
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div class="flex flex-row w-1/5 justify-between">
            <label class="form-label additional-label m-2">Friday</label>
          </div>
          <div class="flex flex-row my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="friday-start"
              placeholder="Start timing"
              [(ngModel)]="availability[4].slot1Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="friday-end"
              placeholder="End timing"
              [(ngModel)]="availability[4].slot1End"
              [disabled]="viewForm"
            />
            <img
              *ngIf="!availability[4].ifSlot2"
              src="../../../../assets/icons/plus.svg"
              class="mx-4 cursor-pointer"
              (click)="add('friday')"
            />
          </div>
          <div *ngIf="availability[4].ifSlot2" class="flex flex-row ml-12 my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="friday2-start"
              placeholder="Start timing"
              [(ngModel)]="availability[4].slot2Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="friday2-end"
              placeholder="End timing"
              [(ngModel)]="availability[4].slot2End"
              [disabled]="viewForm"
            />
            <img
              src="../../../../assets/icons/cross-icon.svg"
              class="mx-4 cursor-pointer"
              (click)="remove('friday')"
            />
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div class="flex flex-row w-1/5 justify-between">
            <label class="form-label additional-label m-2">Saturday</label>
          </div>
          <div class="flex flex-row my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="saturday-start"
              placeholder="Start timing"
              [(ngModel)]="availability[5].slot1Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="saturday-end"
              placeholder="End timing"
              [(ngModel)]="availability[5].slot1End"
              [disabled]="viewForm"
            />
            <img
              *ngIf="!availability[5].ifSlot2"
              src="../../../../assets/icons/plus.svg"
              class="mx-4 cursor-pointer"
              (click)="add('saturday')"
            />
          </div>
          <div *ngIf="availability[5].ifSlot2" class="flex flex-row ml-12 my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="saturday2-start"
              placeholder="Start timing"
              [(ngModel)]="availability[5].slot2Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="saturday2-end"
              placeholder="End timing"
              [(ngModel)]="availability[5].slot2End"
              [disabled]="viewForm"
            />
            <img
              src="../../../../assets/icons/cross-icon.svg"
              class="mx-4 cursor-pointer"
              (click)="remove('saturday')"
            />
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div class="flex flex-row w-1/5 justify-between">
            <label class="form-label additional-label m-2">Sunday</label>
          </div>
          <div class="flex flex-row my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="sunday-start"
              placeholder="Start timing"
              [(ngModel)]="availability[6].slot1Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="sunday-end"
              placeholder="End timing"
              [(ngModel)]="availability[6].slot1End"
              [disabled]="viewForm"
            />
            <img
              *ngIf="!availability[6].ifSlot2"
              src="../../../../assets/icons/plus.svg"
              class="mx-4 cursor-pointer"
              (click)="add('sunday')"
            />
          </div>
          <div *ngIf="availability[6].ifSlot2" class="flex flex-row ml-12 my-1">
            <input
              type="time"
              class="input-adminui small-text"
              name="sunday2-start"
              placeholder="Start timing"
              [(ngModel)]="availability[6].slot2Start"
              [disabled]="viewForm"
            />
            <label class="form-label additional-label m-2">To</label>
            <input
              type="time"
              class="input-adminui small-text"
              name="sunday2-end"
              placeholder="End timing"
              [(ngModel)]="availability[6].slot2End"
              [disabled]="viewForm"
            />
            <img
              src="../../../../assets/icons/cross-icon.svg"
              class="mx-4 cursor-pointer"
              (click)="remove('sunday')"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <div *ngIf="isAlert && !isDisabled && !isThumbnail">
          <app-alert-box
            [alertHeader]="alertHeaderDisable"
            [alertBody]="alertBodyDisable"
            (closeAlert)="closeAlert()"
            (isDisable)="disableTherapist()"
          ></app-alert-box>
        </div>
        <div *ngIf="isAlert && isDisabled && !isThumbnail">
          <app-alert-box
            [alertHeader]="alertHeaderEnable"
            [alertBody]="alertBodyEnable"
            (closeAlert)="closeAlert()"
            (isDisable)="enableTherapist()"
          ></app-alert-box>
        </div>
        <div *ngIf="isAlert && !isDisabled && isThumbnail">
          <app-alert-box
            [alertHeader]="alertHeaderDeletePicture"
            [alertBody]="alertBodyDeletePicture"
            (closeAlert)="closeAlert()"
            (isDisable)="deletePicture(therapist.picture!)"
          ></app-alert-box>
        </div>
        <div *ngIf="isAlert && !isDisabled && !isThumbnail && isVideoBio">
          <app-alert-box
            [alertHeader]="alertHeaderDeleteVideBio"
            [alertBody]="alertBodyDeleteVideoBio"
            (closeAlert)="closeAlert()"
            (isDisable)="deleteVideoBio()"
          ></app-alert-box>
        </div>
        <!-- <button
          *ngIf="gotonext"
          type="button"
          class="btn btn-primary btn-block-adminui cursor-pointer"
          (click)="previous()"
        >
          Previous
        </button> -->
        <button
          (click)="next(addTherapistForm)"
          *ngIf="!viewForm"
          class="btn btn-primary btn-block-adminui previous cursor-pointer"
        >
          Save
        </button>
        <!-- <button
          type="button"
          *ngIf="!gotonext && !viewForm"
          class="btn btn-primary btn-block-adminui previous cursor-pointer"
          (click)="next(addTherapistForm)"
        >
          Continue
        </button> -->
        <!-- <button
          type="button"
          *ngIf="!gotonext && viewForm"
          class="btn btn-primary btn-block-adminui previous cursor-pointer"
          (click)="next(addTherapistForm)"
        >
          Next
        </button> -->
      </div>
    </form>
  </div>
</div>
