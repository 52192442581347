import { Component } from '@angular/core';
import { TalkMode } from '../../models/TalkGridResponse';
import { AnyoComponent } from '../../../models/AnyoComponent';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { MasterdataService } from 'projects/admin/src/app/services/masterdata.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from '../../../services/toastr.service';
import { CirclesService } from 'projects/admin/src/app/services/circles.service';
import { CircleExperts } from 'projects/admin/src/app/models/circles/CircleExperts';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { GenericMasterData } from 'projects/admin/src/app/models/masterDataResponse';
import { AnyoTalkCreateRequest } from '../../models/CreateTalksRequest';
import { v4 as uuidv4 } from 'uuid';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { lastValueFrom } from 'rxjs';
import { TalksService } from '../../services/talks.service';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-talks',
  templateUrl: './add-talks.component.html',
  styleUrl: './add-talks.component.scss',
})
export class AddTalksComponent extends AnyoComponent {
  talksSpinner = false;
  description = '';
  title = '';
  imageFile!: File;

  constructor(
    private masterData: MasterdataService,
    protected override auth: AuthService,
    protected toast: ToastService,
    private circleService: CirclesService,
    private fileUtils: FileUtilsService,
    private talks: TalksService,
    public utils: UtilsService,
    private router: Router,
  ) {
    super(auth);
  }

  talkMode = Object.values(TalkMode);
  selectedExperts: string[] = [];
  categories: GenericMasterData[] = [];
  experts: CircleExperts[] = [];
  selectedTalkMode: TalkMode = TalkMode.RECURRING;
  selectedCategory = '';
  visible = true;

  frequency: string = '1 1 * * *';
  cron: string = '';
  options = [
    {
      label: 'Every hour',
      value: '0 0-23/1 * * *',
    },
    {
      label: 'Every day at eight',
      value: '0 8 * * *',
    },
    {
      label: 'Every Friday',
      value: '0 0 * * 5',
    },
  ];

  setValue(value: string): void {
    this.frequency = value;
  }

  getValue(value: string): void {
    this.cron = value;
  }

  uploadMedia($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.imageFile = file;
    }
  }

  async saveTalks() {
    this.pageLoading = true;
    const request: AnyoTalkCreateRequest = {
      title: this.title,
      description: this.title,
      mode: this.selectedTalkMode,
      expertIds: this.selectedExperts,
      frequency: this.frequency,
      visible: this.visible,
      image: '',
      categoryId: this.selectedCategory,
      visibleFrom: Number(moment().add(1, 'days').format('x')),
      visibleTill: Number(moment().add(2, 'days').format('x')),
      color: '#FFFFFF',
      talkDate: Number(moment().add(1, 'days').format('x')),
      frequencyText: this.utils.getFrequencyText(this.cron),
    };
    if (this.imageFile) {
      const uuid = uuidv4();
      const uuidWithoutHyphens = uuid.replace(/-/g, '');
      const extension = this.imageFile.name.split('.').pop();
      const filePath = `talks/${uuidWithoutHyphens}.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileUtils.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePath,
          this.imageFile.type,
        ),
      );
      this.toast.showSuccess('Image Uploading');
      await lastValueFrom(
        this.fileUtils.uploadFile(this.imageFile, uploadUrl.url),
      );
      this.toast.showSuccess('Image Uploaded');
      request.image = `https://cdn.anyo.app/${filePath}`;
    }
    this.talks.saveTalks(request).subscribe({
      next: (value) => {
        this.toast.showSuccess('Successfully Talks Saved');
        this.router.navigate(['/talks']);
        this.pageLoading = false;
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
        this.pageLoading = false;
      },
    });
  }

  async ready(user?: IAnyoUser | undefined): Promise<void> {
    if (user) {
      this.pageLoading = true;
      this.circleService.getCircleMasterData().subscribe({
        next: (value) => {
          this.experts = value.circleExperts;
        },
        error: (err) => {
          const errorBody = err.error as IAnyoError;
          this.toast.showAnyoErrorToast(errorBody.description);
          this.pageLoading = false;
        },
      });
      this.masterData.masterDataList().subscribe({
        next: (response) => {
          this.categories = response.talksCategories;
        },
        error: (err) => {
          const errorBody = err.error as IAnyoError;
          this.toast.showAnyoErrorToast(errorBody.description);
          this.pageLoading = false;
        },
      });
    }
  }
}
