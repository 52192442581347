import { Component } from '@angular/core';
import { ContentService } from '../../../services/content.service';
import { AuthService } from '../../../../../../app-core/src/auth/auth.service';
import { IAnyoError } from '../../../../../../app-core/src/models/errorModal';
import { ToastService } from '../../../../../../app-core/src/app/services/toastr.service';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { ContentGridResponseData } from '../../../models/contentGridResponse';
import {
  ContentFilter,
  ContentGridRequest,
} from '../../../models/contentGridRequest';

interface commonSelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  masterData: MasterDataResponse | undefined;
  totalCount: number = 0;
  activeCount: number = 0;
  contentData: ContentGridResponseData[] = [];
  deepCopyCategoryArray: any[] = [];
  globalSearchInput!: string;
  selectedValue: string | null = null;
  categoryDropDown!: commonSelectOptions[];
  genreDropDown!: commonSelectOptions[];
  contentTableQuery: ContentGridRequest | undefined;
  pageSize: number = 6;
  pageSizeOptions = [10, 20];
  contentTableCurrentPageNo = 1;
  contentTableTotalCount = 0;
  contentTableSpinner: boolean = false;
  categorySelectedString!: string;
  genreSelectedString!: string;

  constructor(
    private auth: AuthService,
    private contentService: ContentService,
    private toastrService: ToastService,
  ) {}

  resetFilter() {
    this.contentTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.contentTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.genreSelectedString = '';
    this.categorySelectedString = '';
    this.listContent();
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.contentTableQuery?.globalSearch?.push(this.globalSearchInput.trim());
      this.listContent();
    }
  }

  listContent() {
    this.contentTableSpinner = true;
    this.contentService.listContent(this.contentTableQuery!).subscribe({
      next: (value) => {
        console.log(value);
        this.categoryDropDown = value.filterOptions.category?.map(
          (category) => {
            return {
              label: category.category,
              value: category.id,
            };
          },
        );
        this.genreDropDown = value.filterOptions.genre?.map((genre) => {
          return {
            label: genre,
            value: genre,
          };
        });
        this.contentData = value.data;
        this.contentTableTotalCount = value.totalRecords;
        this.contentTableSpinner = false;
        this.totalCount = value.totalRecords;
      },
      error: (error) => {
        this.contentTableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  nzPageIndexChange(page: number) {
    if (this.contentTableQuery) {
      this.contentTableQuery!.page = page;
      this.listContent();
    }
  }

  nzPageSizeChange(pageSize: number) {
    this.contentTableQuery!.pageSize = pageSize;
    this.listContent();
  }

  categoryFilter(category: any) {
    if (category && category.length) {
      if (!this.contentTableQuery!.filters) {
        this.contentTableQuery!.filters = {
          [ContentFilter.CONTENT_CATEGORY]: [],
          [ContentFilter.CONTENT_GENRE]: [],
          [ContentFilter.CONTENT_RATING]: [],
        };
      }
      this.contentTableQuery!.filters!.CONTENT_CATEGORY = [category];
      this.listContent();
    }
  }
  genreFilter(genre: any) {
    if (genre && genre.length) {
      if (!this.contentTableQuery!.filters) {
        this.contentTableQuery!.filters = {
          [ContentFilter.CONTENT_CATEGORY]: [],
          [ContentFilter.CONTENT_GENRE]: [],
          [ContentFilter.CONTENT_RATING]: [],
        };
      }
      this.contentTableQuery!.filters!.CONTENT_GENRE = [genre];
      this.listContent();
    }
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.contentTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: undefined,
          to: undefined,
        };
        this.listContent();
      }
    });
  }
}
