import { Component } from '@angular/core';
import { TherapyPlansFilter, TherapyPlansGridRequest, TherapyPlansSearch } from '../../../models/therapyPlansGridRequest';
import { TherapyPlansGridResponseData } from '../../../models/therapyPlansGridResp';
import { AnyoTableComponent } from 'projects/app-core/src/app/models/AnyoTableComponent';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-all-therapy-plans-list',
  templateUrl: './all-therapy-plans-list.component.html',
  styleUrl: './all-therapy-plans-list.component.scss'
})
export class AllTherapyPlansListComponent extends AnyoTableComponent<TherapyPlansGridResponseData,TherapyPlansGridRequest> {

  async getData(): Promise<void> {
    this.fetchTherapyPlans()
    return
  }

  constructor(
    protected override auth: AuthService,
    protected toast: ToastService,
    private therapyPlansService: TherapyPlansService,
    public utils: UtilsService
  ) {
    super(auth, toast);
  }

  // grid
  therapyPlans: TherapyPlansGridResponseData[] = []
  totalRecords: number = 0
  paymentTypeCheckBoxOptions: CheckboxOptions[] = []
  page =  1
  activeFromColumnFilterDate: Date[] = []
  planNameColumnSearch = ''
  internalPlanNameColumnSearch = ''


  activeFromFilterDateChange($event: any[]){
    console.log($event)
    if ($event && $event.length == 2) {
      const startDate = moment($event[0]).startOf('day').toDate();
      const endDate = moment($event[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.gridRequest = {
        dateFilters: {
          THERAPY_PLANS_ACTIVE_FROM: {
            from: isToday
              ? moment($event[0]).startOf('day').toDate()
              : startDate,
            to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
          },
        },
        export: false,
        filters: undefined,
        from: undefined,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        searchQuery: undefined,
        to: undefined,
      };

    }
  }

  openTherapyPlansSubscriptionPageByPlanName(planName: string) {
    window.open(
      `therapy-plans/subscriptions?planName=${planName}`,
    );
  }

  fetchTherapyPlans(){
    this.pageLoading = true

    this.therapyPlansService.fetchTherapyPlansGrid(this.gridRequest).subscribe({
      next: (value) => {
        value.data.forEach((data) => {
          data.paymentTypeColor = this.utils.getRandomColor();
        });
        this.therapyPlans = value.data
        this.paymentTypeCheckBoxOptions = []
        this.paymentTypeCheckBoxOptions = value.filterOptions.paymentTypes.map((data) => {
          if(this.gridRequest?.filters?.THERAPY_PLANS_PAYMENT_TYPE?.includes(data)){
            return{
              label: this.utils.convertToTitleCase(data),
              value: data,
              checked: true
            }
          }else{
            return{
              label: this.utils.convertToTitleCase(data),
              value: data,
              checked: false
            }
          }
        })
        this.totalRecords = value.totalRecords
        this.pageLoading = false
      }, error: (err) => {
        this.toastService.showError(err.message);
        this.pageLoading = false;
      }
    })

  }

  searchByPlanName(){
    if(this.planNameColumnSearch){
      this.gridRequest.searchQuery = {
        [TherapyPlansSearch.THERAPY_PLANS_NAME] : '',
        [TherapyPlansSearch.THERAPY_PLANS_INTERNAL_NAME]: ''
      }
      this.gridRequest.searchQuery.THERAPY_PLANS_NAME = this.planNameColumnSearch.trim()
      this.fetchTherapyPlans()
    }
  }

  searchByInternalPlanName(){
    if(this.internalPlanNameColumnSearch){
      this.gridRequest.searchQuery = {
        [TherapyPlansSearch.THERAPY_PLANS_NAME] : '',
        [TherapyPlansSearch.THERAPY_PLANS_INTERNAL_NAME]: ''
      }
      this.gridRequest.searchQuery.THERAPY_PLANS_INTERNAL_NAME = this.internalPlanNameColumnSearch.trim()
      this.fetchTherapyPlans()
    }
  }

  paymentTypeFilterChangeFn($event: any[]){
    this.gridRequest.filters = {
      [TherapyPlansFilter.THERAPY_PLANS_NAME]: [],
      [TherapyPlansFilter.THERAPY_PLANS_PAYMENT_TYPE] : []
    }
    if($event && $event.length){
      this.gridRequest.filters.THERAPY_PLANS_PAYMENT_TYPE = $event
    }
  } 

  override async resetFilter() {
    this.gridRequest = {
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
    };
    this.paymentTypeCheckBoxOptions = []
    this.activeFromColumnFilterDate = []
    this.planNameColumnSearch = ''
    this.internalPlanNameColumnSearch = ''
    this.fetchTherapyPlans();
  }

}
