<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">{{ title }}</span>
    </div>
    <div nz-col>
      <button
        class="btn-color"
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="resetFilter()"
      >
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSpinning]="reportSpinner" nzTip="Loading...">
  <nz-table
    #basicTable
    [nzData]="reportData?.data!"
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="reportData?.totalRecords!"
    [nzPageSize]="reportData?.pageSize!"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="reportData?.pageNum!"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>Comments</th>
        <th>User Name</th>
        <th>Problem</th>
        <th>Dismissed/Not</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data.comments }}</td>
        <td>{{ data.userName }}</td>
        <td>{{ data.problem }}</td>
        <td>{{ data.dismissed ? "yes" : "no" }}</td>
        <td>
          <span nz-tooltip nzTooltipTitle="Dismissed" *ngIf="!data.dismissed">
            <button nz-button (click)="dismissedReport(data.id)">
              Dismissed
            </button>
          </span>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
