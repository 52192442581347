import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { LoginComponent } from '../components/login/login.component';
import { EmailComponent } from '../components/email/email.component';
import { OtpComponent } from '../components/otp/otp.component';
import { CreatePasswordComponent } from '../components/create-password/create-password.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'email',
        component: EmailComponent,
      },
      {
        path: 'email/:firstLogin',
        component: EmailComponent,
      },
      {
        path: 'otp',
        component: OtpComponent,
      },
      {
        path: 'otp/:toverify/:firstLogin',
        component: OtpComponent,
      },
      {
        path: 'createpassword',
        component: CreatePasswordComponent,
      },
      {
        path: 'createpassword/:user/:firstLogin',
        component: CreatePasswordComponent,
      },
      {
        path: 'createpassword/:user',
        component: CreatePasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminAuthRoutingModule {}
