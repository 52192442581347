import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { TherapistService } from 'projects/admin/src/app/services/therapist.service';
import { ToastService } from '../../services/toastr.service';
import { IAnyoError } from '../../models/error/errorResponse';
import { TimeRangesResponse } from 'projects/admin/src/app/models/timeRangesRequestResponse';
import { GroupedDateResponse } from 'projects/admin/src/app/models/markNotAvailableTImings';
import {
  MarkNotAvailableSlotForSelectedDayRequest,
  TimeRange,
} from 'projects/admin/src/app/models/therapistMarkNotAvailableSlotForSelectedDayRequest';
import { TherapistAvailability } from 'projects/admin/src/app/models/IAnyoTherapist';

interface CheckboxOptions {
  label: string;
  value: TimeRangesResponse;
  checked: boolean;
}

export interface NotAvailableSlotsFor {
  date: Date;
  day: string;
  start: Date;
  end: Date;
}

interface timeRanges {
  time: NotAvailableSlotsFor;
  checkBoxValue: CheckboxOptions;
}

interface TimeSlot {
  start: string;
  end: string;
}

@Component({
  selector: 'app-mark-unavailable-slot',
  templateUrl: './mark-unavailable-slot.component.html',
  styleUrl: './mark-unavailable-slot.component.scss',
})
export class MarkUnavailableSlotComponent {
  @Input() therapistId!: string;
  @Input() therapistName!: string;

  visible = false;
  date: Date | null = null;
  checked = false;
  checkBoxTimeRangeOptions: CheckboxOptions[] = [];
  time = new Date();
  timeRanges: timeRanges[] = [];
  unavailableTimeEvent: TimeRangesResponse[] = [];
  allChecked = false;
  markUnavailableSpinner = false;
  notAvailableSlots: GroupedDateResponse[] = [];

  constructor(
    private therapistService: TherapistService,
    private toastrService: ToastService,
  ) {}

  isVisible = false;

  openSetAvailableSlots() {
    this.isVisible = true;
  }

  handleOk() {
    this.isVisible = false;
  }

  handleCancel() {
    this.isVisible = false;
  }

  selectedTimeRanges($event: TimeRangesResponse[]) {
    this.unavailableTimeEvent = $event;
    if (
      this.checkBoxTimeRangeOptions.length !== this.unavailableTimeEvent.length
    ) {
      this.allChecked = false;
    }
  }

  open() {
    this.visible = true;
    this.getTherapist();
  }

  close() {
    this.visible = false;
  }

  convertTimeFormat(time: string) {
    return moment(time, 'HH:mm').format('hh:mm a');
  }

  saveNotAvailableTimings() {
    this.markUnavailableSpinner = true;
    if (this.returnWhenSessionExists) {
      this.toastrService.showInfo('Session exists during these slots');
      this.markUnavailableSpinner = false;
      return;
    }
    const notAvailableSlot: TimeRange[] = [];
    if(this.unavailableTimeEvent && this.unavailableTimeEvent.length){
      this.unavailableTimeEvent.forEach((data) => {
        const selectedDateTime = moment(data.date, 'DD/MM/YYYY').set({
          hours: 0,
          minutes: 0,
          second: 0,
          millisecond: 0,
        });
        if (selectedDateTime.isBefore(selectedDateTime)) {
          this.toastrService.showAnyoErrorToast('Date should be in future');
          this.markUnavailableSpinner = false;
          return;
        }
        const markNotAvailableSlotForSelectedDayRequest: TimeRange = {
          date: moment(data.date, 'DD/MM/YYYY').format('x'),
          start: moment(data.start, 'hh:mm a').format('x'),
          end: moment(data.end, 'hh:mm a').format('x'),
        };
        notAvailableSlot.push(markNotAvailableSlotForSelectedDayRequest);
      });
      const request: MarkNotAvailableSlotForSelectedDayRequest = {
        date: moment(this.date).format('x'),
        timeRanges: notAvailableSlot,
      };
      this.therapistService
        .markNotAvailableSlot(request, this.therapistId)
        .subscribe({
          next: (value) => {
            this.markUnavailableSpinner = false;
            this.toastrService.showSuccess(
              'Not Available Timings Successfully Updated',
            );
            this.onChange(this.date!);
            this.getTherapist();
          },
          error: (error) => {
            this.markUnavailableSpinner = false;
            const errorBody = error.error as IAnyoError;
            this.toastrService.showAnyoErrorToast(errorBody.description);
          },
        });
    }else{
      this.markUnavailableSpinner = false;
      this.toastrService.showError("You didn't Selected any Time Ranges")
    }
    
  }

  editNotAvailable(result: string) {
    this.date = moment(result, 'x').toDate();
    this.onChange(moment(result, 'x').toDate());
  }

  onChange(result: Date) {
    this.allChecked = false;
    this.markUnavailableSpinner = true;
    this.unavailableTimeEvent = [];
    const request = {
      therapistId: this.therapistId,
      date: moment(result).format('x'),
    };
    this.therapistService.getTimeRangesForSelectedDay(request).subscribe({
      next: (therapistTimeSlots) => {
        this.checkBoxTimeRangeOptions = [];
        therapistTimeSlots.forEach((timeSlot: TimeRangesResponse) => {
          if (!timeSlot.notAvailableSlotExists) {
            this.checkBoxTimeRangeOptions.push({
              label: `${timeSlot.start} - ${timeSlot.end}`,
              value: timeSlot,
              checked: false,
            });
          } else {
            this.checkBoxTimeRangeOptions.push({
              label: `${timeSlot.start} - ${timeSlot.end}`,
              value: timeSlot,
              checked: true,
            });
            this.unavailableTimeEvent.push({
              date: timeSlot.date,
              start: timeSlot.start,
              end: timeSlot.end,
              notAvailableSlotExists:timeSlot.notAvailableSlotExists,
              available: timeSlot.available
            })
          }
        });
        if (this.checkBoxTimeRangeOptions.every((data) => data.checked)) {
          this.allChecked = true;
        }

        this.toastrService.showSuccess('Time Ranges For the Day Of Therapist');
        this.markUnavailableSpinner = false;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
        this.markUnavailableSpinner = false;
      },
    });
  }

  convertDateFormat(date: string) {
    return moment(date, 'x').format('YYYY-MM-DD');
  }

  returnWhenSessionExists = false;

  updateAllChecked() {
    if (this.allChecked) {
      this.checkBoxTimeRangeOptions.map((data) => {
        if (!data.value.notAvailableSlotExists && !data.value.available) {
          this.returnWhenSessionExists = true;
        }
      });
      if (this.returnWhenSessionExists) {
        this.toastrService.showError('Session exists during these slots');
        return;
      }
      this.checkBoxTimeRangeOptions = this.checkBoxTimeRangeOptions.map(
        (item) => {
          if (!item.value.notAvailableSlotExists && !item.value.available) {
            return {
              ...item,
              checked: false,
            };
          } else {
            return {
              ...item,
              checked: true,
            };
          }
        },
      );
      this.unavailableTimeEvent = this.checkBoxTimeRangeOptions
        .map((data): TimeRangesResponse | undefined => {
          if (!data.value.notAvailableSlotExists && !data.value.available) {
            return undefined;
          }
          return data.value;
        })
        .filter((data): data is TimeRangesResponse => data !== undefined);
    } else {
      this.returnWhenSessionExists = false;
      this.checkBoxTimeRangeOptions = this.checkBoxTimeRangeOptions.map(
        (item) => {
          return {
            ...item,
            checked: false,
          };
        },
      );
      this.unavailableTimeEvent = [];
    }
  }
  availability: TherapistAvailability[] = [];
  getAvailability($event: TherapistAvailability[]) {
    console.log($event);
    this.availability = $event;
  }

  getTherapist() {
    this.markUnavailableSpinner = true;
    this.therapistService
      .getDatesWithSlotForTherapist(this.therapistId)
      .subscribe({
        next: (value) => {
          this.notAvailableSlots = value;
          this.markUnavailableSpinner = false;
        },
        error: (error) => {
          this.markUnavailableSpinner = false;
          const errorBody = error.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
        },
      });
  }

  disabledDate = (current: Date) => {
    return moment(current).isBefore(moment().startOf('day'));
  };

  validateFutureDate(data: GroupedDateResponse) {
    const date = moment(data.date, 'x').set({
      hour: moment().hour(),
      minute: moment().minute(),
      second: moment().second(),
      millisecond: moment().millisecond(),
    });
    return moment().isSameOrBefore(date);
  }
}
