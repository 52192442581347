import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface MemberGridRequest
  extends GridRequest<
    MemberSort,
    MemberFilter,
    MemberSearch,
    MemberDateFilter
  > {
  tab?: MemberTab;
}

export enum MemberSort {
  LAST_ACTIVE = 'LAST_ACTIVE',
  PASS_REDEEMED_DATE = 'PASS_REDEEMED_DATE',
}

export enum MemberFilter {
  PASS_REDEEMED = 'PASS_REDEEMED',
  BRANCH = 'BRANCH',
  DEPARTMENT = 'DEPARTMENT',
}

export enum MemberSearch {
  EMAIL = 'EMAIL',
  CORPORATE_CODE = 'CORPORATE_CODE',
}

export enum MemberDateFilter {
  PASS_REDEEMED_DATE = 'PASS_REDEEMED_DATE',
}

export enum MemberTab {
  CORPORATE_MEMBER = 'CORPORATE_MEMBER',
  FAMILY_MEMBER = 'FAMILY_MEMBER',
}
