import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface ListenerGridRequest
  extends GridRequest<
    ListenerSort,
    ListenerFilter,
    ListenerSearch,
    ListenerDateFilter
  > {}

export enum ListenerSort {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
}

export enum ListenerSearch {
  LISTENER_EMAIL = 'LISTENER_EMAIL',
  LISTENER_NAME = 'LISTENER_NAME',
}

export enum ListenerDateFilter {
  LAST_ACTIVE_ON = 'LAST_ACTIVE_ON',
}

export enum ListenerFilter {
  LISTENER_STATUS = 'LISTENER_STATUS',
  LISTENER_AVAILABILITY = 'LISTENER_AVAILABILITY',
}
