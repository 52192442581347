import { Component } from '@angular/core';
import * as moment from 'moment';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { lastValueFrom } from 'rxjs';
import { ContentService } from '../../../services/content.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { BytesService } from '../../../services/bytes.service';
import { NewAddBytesCategoryRequest } from '../../../models/newAddBytesCategoryRequest';

@Component({
  selector: 'app-add-bytes-category',
  templateUrl: './add-bytes-category.component.html',
  styleUrls: ['./add-bytes-category.component.scss'],
})
export class AddBytesCategoryComponent {
  bytesCategorySpinning!: boolean;
  backgroundFileList: File[] = [];
  nameString: string = '';
  descriptionString: string = '';
  homeScreenFile!: File;
  uploading = false;
  newId!: string;
  isActive: boolean = true;

  constructor(
    private fileService: FileUtilsService,
    private toastService: ToastService,
    private contentService: ContentService,
    private auth: AuthService,
    private bytes: BytesService,
  ) {}

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.contentService.getNewContentId().subscribe({
          next: (response) => {
            this.newId = response.data;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      }
    });
  }

  uploadHomeScreen($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.homeScreenFile = file;
    }
  }
  uploadBackgroundImages($event: any) {
    this.backgroundFileList = $event.target.files;
  }

  async handleMultipleFileUpload(files: File[]) {
    const uploadedFileUrls: string[] = [];

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.split('.').pop();
        const timestamp = moment().format('x');
        const filePath = `bytes/category/${this.newId}/background_${timestamp}.${extension}`;

        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            file.type,
          ),
        );

        this.toastService.showSuccess(`Uploading background ${i + 1}`);
        await lastValueFrom(this.fileService.uploadFile(file, uploadUrl.url));
        this.toastService.showSuccess(
          `Uploading background ${i + 1} completed`,
        );

        const fileUrl = `https://cdn.anyo.app/${filePath}`;
        uploadedFileUrls.push(fileUrl);
      }

      return uploadedFileUrls;
    } catch (e) {
      this.toastService.showError(e + '');
      throw e;
    }
  }

  async saveBytesCategory() {
    try {
      this.bytesCategorySpinning = true;
      const req: NewAddBytesCategoryRequest = {
        name: this.nameString,
        homescreen: '',
        background: [] as string[],
        description: this.descriptionString,
        isActive: this.isActive,
      };
      if (this.homeScreenFile) {
        const extension = this.homeScreenFile.name.split('.').pop();
        const timestamp = moment().format('x');
        const filePath = `bytes/category/${this.newId}/homescreen_${timestamp}.${extension}`;
        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.homeScreenFile.type,
          ),
        );
        this.toastService.showSuccess(`Uploading Homescreen`);
        await lastValueFrom(
          this.fileService.uploadFile(this.homeScreenFile, uploadUrl.url),
        );
        this.toastService.showSuccess(`Uploading homescreen completed`);
        req.homescreen = `https://cdn.anyo.app/${filePath}`;
      }
      req.background = await this.handleMultipleFileUpload(
        this.backgroundFileList,
      );
      this.bytes.createBytesCategory(req).subscribe({
        next: (response) => {
          this.toastService.showSuccess('Successfully Created');
          this.bytesCategorySpinning = false;
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
          this.bytesCategorySpinning = false;
        },
      });
    } catch (e) {
      this.toastService.showError(e + '');
    }
  }
}
