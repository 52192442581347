import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AddEventComponent} from './add-event/add-event.component';
import {ManageEventsComponent} from './manage-events/manage-events.component';
import {EditEventComponent} from './edit-event/edit-event.component';
import {ManageRegistrationsComponent} from './manage-registrations/manage-registrations.component';

const routes: Routes = [
  {
    path: '',
    component: ManageEventsComponent,
  },
  {
    path: 'add',
    component: AddEventComponent,
  },
  {
    path: 'edit/:id',
    component: EditEventComponent,
  },
  {
    path: 'registrations',
    component: ManageRegistrationsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsRoutingModule {
}
