export interface TherapyPlans {
  id: string;
  canPause: boolean;
  activeFrom?: Date;
  activeUpto?: Date;
  active: boolean;
  planName: string;
  planDescription: string;
  charges?: Record<string, ChargeObject[]>;
  sessionNameMapping?: Record<string, SessionNameMapping>;
  paymentType: TherapyPaymentType;
  noOfSessions: number;
  validityDays: number;
  perSessionTimeInMin: number;
  maxPauseDays: number;
  planImage: string;
  tags?: TherapyPlansTags[];
  internalPlanName: string;
  order?: number;
}
export enum TherapyPlansTags {
  TEST_EVENT = 'TEST_PLAN',
  TEST_REAL_PAYMENT = 'TEST_REAL_PAYMENT',
}
export enum TherapyPaymentType {
  FREE = 'FREE',
  PAID = 'PAID',
  RETAIL_PAID = 'RETAIL_PAID',
  CORPORATE_PAID = 'CORPORATE_PAID',
}
export interface SessionNameMapping {
  name: string;
  description: string;
}
export interface ChargeObject {
  currency: string;
  amount: number;
  currencySymbol: string;
  markupAmount: number;
  savingText: number;
  perSessionAmount: number;
}
