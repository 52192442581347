import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { ListenerService } from '../../../services/listener.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { Status } from '../../../stores/types';
import { IAnyoListener } from '../../../models/IAnyoListener';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { lastValueFrom } from 'rxjs';
import { CircleTopics } from '../../../models/circles/AnyoCircles';
import { CirclesService } from '../../../services/circles.service';
import {
  GENERAL_CIRCLE_TOPIC_TAG,
  TEST_USER_INTERESTS_TAGS,
} from 'projects/admin/src/constants';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent {
  userInterestTagsChangeFn($event: string[]) {
    this.userInterestTags = $event;
  }
  userInterestTags: string[] = [];
  onClick: boolean = true;
  specialization: Select2Data = [];
  language: Select2Data = [];
  avatarNames: any[] = [];
  avatarImages: any[] = [];
  viewForm: boolean = false;
  editMode: boolean = false;
  avtaarCard: boolean = false;
  addListenerForm!: FormGroup;
  isAlert: boolean = false;
  isDisabled: boolean = false;
  thumbnail: string = '';
  isSubmit: boolean = false;
  alertHeaderDisable: string = 'Listener Disable';
  alertBodyDisable: string =
    'Please make sure that you want to disable the listener';
  alertHeaderEnable: string = 'Listener Enable';
  alertBodyEnable: string =
    'Please make sure that you want to enable the listener';

  listener: any = {
    title: '',
    email: '',
    name: '',
    mobileNumber: '',
    languages: [],
    avtaarName: '',
    avtaar: 'dummy-avtaar',
    dob: moment(),
    specialization: [],
    about: '',
  };
  compulsoryFields: any[] = [
    'title',
    'email',
    'name',
    'mobileNumber',
    'languages',
    'avtaarName',
    'avtaar',
    'dob',
    'specialization',
    'about',
  ];
  switchValueExpert = false;
  expertDescription!: string;
  qualification!: string;
  isActive!: boolean;
  viewMode = false;
  avatarImage!: string;
  expertProfilePic!: File;
  newId!: string;

  constructor(
    private circleService: CirclesService,
    private auth: AuthService,
    private router: Router,
    private listenerService: ListenerService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private masterdataService: MasterdataService,
    private toastrService: ToastService,
    private fileService: FileUtilsService,
  ) {
    this.addListenerForm = this.formBuilder.group({});
  }

  changeActive(event: boolean) {
    this.isActive = event;
  }

  displayExpertDetailsContainer() {
    this.viewMode = true;
  }

  uploadExpertProfilePic($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.expertProfilePic = file;
    }
  }

  calculateAge(selectedDate: string) {
    const birthDate = moment(selectedDate);
    const now = moment();
    const ageInYears = now.diff(birthDate, 'years');
    return ageInYears;
  }

  async submit() {
    this.isSubmit = false;

    const validationError = this.validateListenerData();
    if (validationError) {
      this.toastrService.showError(validationError);
      return;
    }

    const age = this.calculateAge(moment(this.listener.dob).format());
    if (age < 18) {
      this.toastrService.showError('Age must be more than 18 years');
      return;
    }

    if (this.editMode) {
      await this.updateListener();
    } else {
      await this.createListener();
    }
  }

  validateListenerData(): string | null {
    for (const field of this.compulsoryFields) {
      if (
        !this.listener[field as keyof IAnyoListener] ||
        (this.listener[field as keyof IAnyoListener] as string[]).length === 0
      ) {
        return 'Please fill all the required fields';
      }
    }
    return null;
  }

  async updateListener() {
    const _id = String(this.activatedRoute.snapshot.params['id']);
    if (this.thumbnail) {
      this.listener.avtaar = this.thumbnail;
    } else {
      this.avatarImages.forEach((avatar) => {
        if (this.listener.avtaar === avatar.label) {
          this.listener.avtaar = avatar.value;
        }
      });
    }
    this.listener.dob = moment(this.listener.dob).format();
    if (this.viewMode) {
      this.listener.expertDetails = {
        isActive: this.isActive,
        about: this.expertDescription,
        avatarImage: this.avatarImage,
        qualifications: this.qualification.split(','),
        listenerId: _id,
        userInterestsTags: this.userInterestTags,
      };
      if (this.expertProfilePic) {
        const extension = this.expertProfilePic.name.split('.').pop();
        const timestamp = moment().format('x');
        const filePath = `circles/${this.newId}/expertprofile_${timestamp}.${extension}`;
        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.expertProfilePic.type,
          ),
        );
        this.toastrService.showSuccess(`Uploading Expert Profile`);
        await lastValueFrom(
          this.fileService.uploadFile(this.expertProfilePic, uploadUrl.url),
        );
        this.toastrService.showSuccess(`Uploading Expert Profile completed`);
        this.listener.expertDetails.avatarImage = `https://cdn.anyo.app/${filePath}`;
      }
    }

    this.onClick = false;
    this.listenerService.updateListener(_id, this.listener).subscribe({
      next: (value) => {
        this.router.navigate(['/listener']);
        this.toastrService.showSuccess('Listener Updated Successfully');
      },
      error: (error) => {
        this.listener.dob = this.listener.dob;
        this.avatarImages.forEach((avatar) => {
          if (this.listener.avtaar === avatar.value) {
            this.listener.avtaar = avatar.label;
          }
        });
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  async createListener() {
    this.listener.dob = moment(this.listener.dob).format();
    const avatar = this.listener.avtaar;
    this.onClick = false;
    if (this.viewMode) {
      this.listener.expertDetails = {
        isActive: this.isActive,
        about: this.expertDescription,
        avatarImage: '',
        qualifications: this.qualification.split(','),
        listenerId: '',
        userInterestsTags: this.userInterestTags,
      };
      console.log(this.listener);
      if (this.expertProfilePic) {
        const extension = this.expertProfilePic.name.split('.').pop();
        const timestamp = moment().format('x');
        const filePath = `circles/${this.newId}/expertprofile_${timestamp}.${extension}`;
        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.expertProfilePic.type,
          ),
        );
        this.toastrService.showSuccess(`Uploading Expert Profile`);
        await lastValueFrom(
          this.fileService.uploadFile(this.expertProfilePic, uploadUrl.url),
        );
        this.toastrService.showSuccess(`Uploading Expert Profile completed`);
        this.listener.expertDetails.avatarImage = `https://cdn.anyo.app/${filePath}`;
      }
    }
    this.listenerService.createListener(this.listener).subscribe({
      next: (value) => {
        this.router.navigate(['/listener']);
        this.toastrService.showSuccess('Listener Created Successfully');
      },
      error: (error) => {
        this.listener.dob = this.listener.dob;
        this.listener.avtaar = avatar;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  updateSpecialization(event: Select2UpdateEvent<any>) {
    this.listener.specialization = event.value;
  }

  updateLanguage(event: Select2UpdateEvent<any>) {
    this.listener.languages = event.value;
  }

  show() {
    this.avtaarCard = !this.avtaarCard;
    const commonCard = document.getElementById('card');
    const choose = document.getElementById('chooseAvatar');
    if (choose && commonCard) {
      if (this.avtaarCard) {
        const elementPosition = choose.getBoundingClientRect();
        commonCard.style.display = 'flex';
        commonCard.style.top = `${elementPosition.bottom}px`;
        commonCard.style.left = `${elementPosition.left}px`;
      } else {
        commonCard.style.display = 'none';
      }
    }
  }

  uploadAvatar(id: string, label: string) {
    const commonCard = document.getElementById('card');
    if (commonCard) commonCard.style.display = 'none';
    this.listener.avtaar = label;
    this.thumbnail = id;
  }

  dialogShow() {
    this.isAlert = !this.isAlert;
  }

  editForm() {
    this.viewForm = false;
    this.editMode = true;
  }

  disableListener() {
    let req = {
      status: Status.Inactive,
    };
    let _id = String(this.activatedRoute.snapshot.params['id']);
    this.listenerService.enableAndDisableListener(_id, req).subscribe({
      next: () => {
        this.router.navigate(['/listener']);
        this.toastrService.showSuccess('Listener Disable Successfully');
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isAlert = false;
    this.isDisabled = true;
  }

  enableListener() {
    let req = {
      status: Status.Active,
    };
    let _id = String(this.activatedRoute.snapshot.params['id']);
    this.listenerService.enableAndDisableListener(_id, req).subscribe({
      next: () => {
        this.router.navigate(['/listener']);
        this.toastrService.showSuccess('Listener Enabled Successfully');
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isAlert = false;
    this.isDisabled = false;
  }

  populate(_id: string) {
    this.listenerService.getListener(_id).subscribe({
      next: (value) => {
        this.viewForm = true;
        this.listener = value.data;
        if (value.data.expertDetails) {
          this.viewMode = true;
        }
        this.userInterestTags =
          value.data.expertDetails?.userInterestsTags?.map(
            (value) => value.key,
          ) || [];
        this.expertDescription = value.data.expertDetails?.about || '';
        this.isActive = value.data.expertDetails?.active || false;
        this.qualification =
          value.data.expertDetails?.qualifications == null
            ? ''
            : value.data.expertDetails?.qualifications.join(',');
        this.avatarImage = value.data.expertDetails?.avatarImage || '';

        this.listener.dob = moment(this.listener.dob).format('YYYY-MM-DD');
        value.masterdata.languages.forEach((data: any) => {
          if (data.status == Status.Active) {
            const obj = {
              value: data.m_id,
              label: data.data,
            };
            this.language.push(obj);
          }
        });

        value.masterdata.specialization.forEach((data: any) => {
          if (data.status == Status.Active) {
            let obj = {
              value: data.m_id,
              label: data.data,
            };
            this.specialization.push(obj);
          }
        });

        value.masterdata.avtaarImages.forEach((data: any) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id,
                label: data.data,
              };
              this.avatarImages.push(obj);
            }
          }
        });

        value.masterdata.avtaarName.forEach((data: any) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id,
                label: data.data,
              };
              this.avatarNames.push(obj);
            }
          }
        });

        this.avatarImages.forEach((avatar) => {
          if (this.listener.avtaar === avatar.value) {
            this.listener.avtaar = avatar.label;
          }
        });
        if (value.data.status == Status.Inactive) {
          this.isDisabled = true;
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  loadMasterData() {
    this.masterdataService.masterDataList().subscribe({
      next: (value) => {
        value.languages.forEach((data: any) => {
          if (data.status == Status.Active) {
            const obj = {
              value: data.m_id,
              label: data.data,
            };
            this.language.push(obj);
          }
        });

        value.listenerSpecialization.forEach((data: any) => {
          if (data.status == Status.Active) {
            const obj = {
              value: data.m_id,
              label: data.data,
            };
            this.specialization.push(obj);
          }
        });

        value.avatarImages.forEach((data: any) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id,
                label: data.data,
              };
              this.avatarImages.push(obj);
            }
          }
        });

        value.avatarNames.forEach((data: any) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id,
                label: data.data,
              };
              this.avatarNames.push(obj);
            }
          }
        });
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        if (this.activatedRoute.snapshot.params) {
          console.log(this.activatedRoute.snapshot.params);
          const value = this.activatedRoute.snapshot.params['id'];
          if (value) {
            this.populate(value);
          } else {
            this.loadMasterData();
          }
        }
      }
      this.userInterestTopics = await this.circleService.getUserInterestsMeta();
      this.userInterestTopics.push(...TEST_USER_INTERESTS_TAGS);
      this.userInterestTopics.push(GENERAL_CIRCLE_TOPIC_TAG);
    });
  }
  userInterestTopics: CircleTopics[] = [];
}
