import { CircleTopics } from 'projects/admin/src/app/models/circles/AnyoCircles';
import { TextProfanityType } from './TextProfanityEnum';
import { MediaModerationTags } from './postsGridResponse';
import { ModeratorEvents } from './ModeratorEvents';
import { BaseDBWithMeta } from './BaseDBWithMeta';

export interface CirclePost extends BaseDBWithMeta<PostMeta> {
  text: string;
  media: MediaMeta[];
  hashTags?: string[];
  circleId: string;
  topics: CircleTopics[];
  tags: (CirclePostTags | TextProfanityType | MediaModerationTags)[];
  reactionIds: string[];
  moderationRequired: boolean;
  moderationEvents: ModeratorEvents[];
  reactions?: Record<string, number>;
  anonymous: boolean;
  locked: boolean;
  visible: boolean;
  parentPostId?: string;
  lastReactionAt?: Date;
  lastCommentAt?: Date;
  commentIds?: string[];
  commenters?: string[];
  commentCount?: number;
  reactionsCount: number;
  starredBy: string[];
  flaggedBy: string[];
}
export enum MediaType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
export interface MediaMeta {
  deletedBy?: string;
  deletedOn?: Date;
  deleted: boolean;
  url: string;
  id: string;
  type: MediaType;
  contentLength: number;
  tags: MediaModerationTags[];
}
export enum CirclePostTags {
  REPLY_POST = 'REPLY_POST',
  ADMIN_POST = 'ADMIN_POST',
}

export interface PostMeta {
  circleName: string;
  partnerId?: string;
  userTags?: UserTags[];
}

export enum UserTags {
  TESTING = 'TESTING',
}
