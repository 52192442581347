import { Component } from '@angular/core';
import { TalkLogsAdminResponse } from '../../models/TalkLogAdminResponse';
import {
  TalkLogDateFilter,
  TalkLogGridFilter,
  TalkLogsGridRequest,
} from '../../models/TalkLogsGridRequest';
import { AuthService } from '../../../../auth/auth.service';
import { ToastService } from '../../../services/toastr.service';
import { AnyoTableComponent } from '../../../models/AnyoTableComponent';
import { TalksService } from '../../services/talks.service';
import { IAnyoError } from '../../../../models/errorModal';
import * as moment from 'moment/moment';
import { UtilsService } from '../../../../service/utils.service';
import Hls from 'hls.js';
import { MarkTalkLogVisibilityRequest } from '../../models/markVisibilityTalkLogRequest';
import { Clipboard } from '@angular/cdk/clipboard';

interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-list-talk-sessions',
  templateUrl: './list-talk-sessions.component.html',
  styleUrl: './list-talk-sessions.component.scss',
})
export class ListTalkSessionsComponent extends AnyoTableComponent<
  TalkLogsAdminResponse,
  TalkLogsGridRequest
> {
  showPlaybackModal: boolean = false;
  talksNames: CheckboxOptions[] = [];
  experts: CheckboxOptions[] = [];
  startColumnFilterDate!: Date[];

  constructor(
    protected override auth: AuthService,
    protected toast: ToastService,
    private talkService: TalksService,
    private utilsService: UtilsService,
    private clipboard: Clipboard,
  ) {
    super(auth, toast);
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.toast.showSuccess('PlaybackUrl: ' + text + ' Copied in ClipBoard');
  }

  formatDate(date: string | Date) {
    return moment(date).format('DD/MM/yyyy');
  }

  formatTime(date: string | Date) {
    return moment(date).format('hh:mm a');
  }

  formatDuration(startDate: string | Date, endDate: string | Date) {
    return this.utilsService.formatDurationFromDate(
      startDate as Date,
      endDate as Date,
    );
  }

  markAsHidden(log: TalkLogsAdminResponse) {
    const req: MarkTalkLogVisibilityRequest = {
      talkLogId: log.id,
      visible: log.visible,
    };
    this.talkService.markTalkLogVisibility(req).subscribe({
      next: () => {
        this.toast.showSuccess('Visibility Updated');
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
        this.pageLoading = false;
      },
    });
  }

  async getData(): Promise<any> {
    this.pageLoading = true;
    this.talkService.getTalkLogs(this.gridRequest).subscribe({
      next: (value) => {
        this.data = value.data.map((value) => {
          value.startTime = moment(value.startTime, 'x').toDate();
          value.endTime = moment(value.endTime, 'x').toDate();
          return value;
        });
        this.experts = [];
        value.filterOptions.hostDetails.map((data) => {
          if (this.gridRequest.filters?.HOST_USER_ID?.includes(data._id)) {
            this.experts.push({
              value: data._id,
              label: data.name,
              checked: true,
            });
          } else {
            this.experts.push({
              value: data._id,
              label: data.name,
              checked: false,
            });
          }
        });
        this.talksNames = [];
        value.filterOptions.talkNames.map((data) => {
          if (this.gridRequest.filters?.TALK_ID?.includes(data._id)) {
            this.talksNames.push({
              value: data._id,
              label: data.name,
              checked: true,
            });
          } else {
            this.talksNames.push({
              value: data._id,
              label: data.name,
              checked: false,
            });
          }
        });
        this.pageSize = value.pageSize;
        this.pageNo = value.pageNum;
        this.totalCount = value.totalRecords;
        this.pageLoading = false;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toast.showAnyoErrorToast(errorBody.description);
        this.pageLoading = false;
      },
    });
    return Promise.resolve();
  }

  formatHostDetails(
    hostDetails: { expertId: string; userId: string; name: string }[],
  ) {
    return hostDetails.map((value) => value.name).join(',');
  }

  handleCancel() {
    this.showPlaybackModal = false;
  }

  handleOk() {
    this.showPlaybackModal = false;
  }

  showModalFn(playbackUrl: string) {
    this.showPlaybackModal = true;

    setTimeout(() => {
      const video = document.getElementById(
        'playbackVideoElem',
      ) as HTMLVideoElement;
      const hls = new Hls();
      hls.loadSource(playbackUrl);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    }, 300);
  }

  hostDetailsFilterChangeFn(host: string[]) {
    if (host && host.length) {
      if (!this.gridRequest!.filters) {
        this.gridRequest.filters = {
          [TalkLogGridFilter.HOST_USER_ID]: [],
        };
      }
      this.gridRequest!.filters!.HOST_USER_ID = host;
    }
  }

  talknameFilterChangeFn(talkName: string[]) {
    if (talkName && talkName.length) {
      if (!this.gridRequest!.filters) {
        this.gridRequest.filters = {
          [TalkLogGridFilter.TALK_ID]: [],
        };
      }
      this.gridRequest!.filters!.TALK_ID = talkName;
    }
  }

  startTimeDateFilter($event: Date[]) {
    if ($event && $event.length == 2) {
      if (!this.gridRequest!.dateFilters) {
        this.gridRequest.dateFilters = {
          [TalkLogDateFilter.START_TIME]: {
            from: moment().toDate(),
            to: moment().toDate(),
          },
        };
      }
      const startDate = moment($event[0]).startOf('day').toDate();
      const endDate = moment($event[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.gridRequest.dateFilters.START_TIME = {
        from: isToday ? moment($event[0]).startOf('day').toDate() : startDate,
        to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
      };
    }
  }
}
