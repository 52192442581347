import { Component } from '@angular/core';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { BytesService } from '../../../services/bytes.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { NewUpdateBytesRequest } from '../../../models/newUpdateBytesRequest';

interface bytesCategoriesSelectOptions {
  label: string;
  value: string;
}

interface Frame {
  id: string;
  uid: string;
  value: string;
  textColor: string;
  textBGColor: string;
  showTimeSeconds: number;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent {
  bytesSpinner!: boolean;
  bytesCategories: bytesCategoriesSelectOptions[] = [];
  selectedBytesCategoryString: string = '';
  titleString: string = '';
  frames: Frame[] = [];
  noOfFrames: number = 0;
  selectedStartDate!: Date;
  selectedStartTime!: Date;
  selectedEndDate!: Date;
  selectedEndTime!: Date;
  masterData!: MasterDataResponse;
  isActive!: boolean;
  editCache: { [key: string]: { edit: boolean; data: Frame } } = {};
  byteId!: string;
  viewMode = true;
  isDuplicate!: boolean;

  constructor(
    private toastService: ToastService,
    private auth: AuthService,
    private bytes: BytesService,
    private masterDataService: MasterdataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.url.subscribe((segments) => {
      this.isDuplicate = segments.some(
        (segment) => segment.path === 'duplicate',
      );
    });
  }

  updateBytes() {
    try {
      this.bytesSpinner = true;
      const selectedStartDateTime = moment(this.selectedStartDate).set({
        hours: parseInt(moment(this.selectedStartTime).format('HH')),
        minutes: 0,
        second: 0,
        millisecond: 0,
      });
      const selectedEndDateTime = moment(this.selectedEndDate).set({
        hours: parseInt(moment(this.selectedEndTime).format('HH')),
        minutes: 0,
        second: 0,
        millisecond: 0,
      });
      if (!selectedStartDateTime.isBefore(selectedEndDateTime)) {
        this.toastService.showAnyoErrorToast('Date should be in future');
        this.bytesSpinner = false;
        return;
      }
      const updateBytesRequest: NewUpdateBytesRequest = {
        categoryId: this.selectedBytesCategoryString,
        isActive: this.isActive,
        title: this.titleString,
        noOfFrames: this.noOfFrames,
        frames: [],
        startTime: selectedStartDateTime.format('x'),
        endTime: selectedEndDateTime.format('x'),
      };
      const frames: Frame[] = [];
      for (const frame of this.frames) {
        const newFrame: Frame = {
          id: '',
          uid: frame.uid,
          value: frame.value,
          textColor: frame.textColor,
          textBGColor: frame.textBGColor,
          showTimeSeconds: frame.showTimeSeconds,
        };
        frames.push(newFrame);
      }
      updateBytesRequest.frames = frames;
      if (this.isDuplicate) {
        this.bytes.createBytes(updateBytesRequest).subscribe({
          next: (value) => {
            this.bytesSpinner = false;
            this.toastService.showSuccess('Byte Successfully Recreated');
            this.router.navigate(['/bytes']);
          },
          error: (error) => {
            this.bytesSpinner = false;
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      } else {
        this.bytes.updateBytes(this.byteId, updateBytesRequest).subscribe({
          next: (value) => {
            this.bytesSpinner = false;
            this.toastService.showSuccess('Byte Successfully Updated');
            this.ngOnInit();
          },
          error: (error) => {
            this.bytesSpinner = false;
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      }
    } catch (e) {
      this.bytesSpinner = false;
      this.toastService.showError(e + '');
    }
  }

  onStartDateChange($event: Date) {
    if (moment($event).isBefore(moment())) {
      this.toastService.showError('Date should be in future');
      return;
    }
    this.selectedStartDate = moment($event)
      .hours(8)
      .minutes(0)
      .second(0)
      .toDate();
    this.selectedStartTime = moment($event)
      .hours(8)
      .minutes(0)
      .second(0)
      .toDate();
    this.selectedEndDate = moment($event)
      .startOf('day')
      .add(1, 'day')
      .add(8, 'hours')
      .toDate();
    this.selectedEndTime = moment($event)
      .startOf('day')
      .add(1, 'day')
      .add(8, 'hours')
      .toDate();
  }

  getByte(id: string) {
    this.bytes.getByte(id).subscribe({
      next: (value) => {
        console.log(value);
        this.titleString = value.title;
        this.selectedBytesCategoryString = value.categoryId;
        this.selectedStartDate = moment(value.startTime).toDate();
        this.selectedStartTime = moment(value.startTime).toDate();
        this.selectedEndDate = moment(value.endTime).toDate();
        this.selectedEndTime = moment(value.endTime).toDate();
        this.frames = value.frames;
        const data: Frame[] = [];
        for (let i = 0; i < value.noOfFrames; i++) {
          const byte = value.frames[i];
          data.push({
            id: `${i}`,
            uid: byte.uid,
            value: byte.value,
            textColor: byte.textColor,
            textBGColor: byte.textBGColor,
            showTimeSeconds: byte.showTimeSeconds,
          });
        }
        this.frames = data;
        this.setEditCache();
        this.noOfFrames = value.noOfFrames;
        this.isActive = value.isActive;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        const id = this.activatedRoute.snapshot.params['id'];
        this.byteId = id;
        this.getByte(id);
        this.masterDataService.masterDataList().subscribe({
          next: (value) => {
            this.bytesCategories = value.bytesCategories.map((data) => {
              return {
                label: data.name,
                value: data._id!,
              };
            });
            this.masterData = value;
          },
          error: (error) => {
            const errorBody = error.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
      }
    });
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.frames.findIndex((item) => item.id === id);
    this.editCache[id] = {
      data: { ...this.frames[index] },
      edit: false,
    };
  }

  saveEdit(id: string): void {
    const index = this.frames.findIndex((item) => item.id === id);
    Object.assign(this.frames[index], this.editCache[id].data);
    this.editCache[id].edit = false;
  }

  setEditCache(): void {
    this.frames.forEach((item) => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item },
      };
    });
  }

  addFrame() {
    const i = this.frames.length + 1;
    const newFrame: Frame = {
      id: `${i}`,
      uid: '',
      value: '',
      textColor: '#000000',
      textBGColor: '#FFFFFF',
      showTimeSeconds: 10,
    };
    this.frames.push(newFrame);
    const data: Frame[] = [];
    for (let i = 0; i < this.frames.length; i++) {
      const byte = this.frames[i];
      data.push({
        id: `${i}`,
        uid: byte.uid,
        value: byte.value,
        textColor: byte.textColor,
        textBGColor: byte.textBGColor,
        showTimeSeconds: byte.showTimeSeconds,
      });
    }
    this.frames = data;
    this.setEditCache();
    this.noOfFrames = this.frames.length;
  }

  deleteFrame(idToDelete: string): void {
    const index = parseInt(idToDelete, 10);
    if (index !== -1) {
      this.frames.splice(index, 1);
      const data: Frame[] = [];
      for (let i = 0; i < this.frames.length; i++) {
        const byte = this.frames[i];
        data.push({
          id: `${i}`,
          uid: byte.uid,
          value: byte.value,
          textColor: byte.textColor,
          textBGColor: byte.textBGColor,
          showTimeSeconds: byte.showTimeSeconds,
        });
      }
      this.frames = data;
      this.setEditCache();

      this.noOfFrames = this.frames.length;
    }
  }
}
