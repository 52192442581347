import { AnyoException } from './anyoException';

export default class AuthorizationException extends AnyoException {
  getSummaryMessage(): string {
    return 'AUTHORIZATION_EXCEPTION';
  }

  override getStatusCode(): number {
    return 401;
  }
}
