import { Component } from '@angular/core';
import { Select2Data } from 'ng-select2-component';
import { ICategory } from 'projects/app-core/src/models/ICategory';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { ContentService } from '../../../services/content.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { IContent, IModule } from '../../../models/IContent';
import { MasterdataService } from '../../../services/masterdata.service';
import { lastValueFrom } from 'rxjs';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';

interface instructorsSelectOptions {
  label: string;
  value: string;
}

interface categorySelectOptions {
  label: string;
  value: string;
}

interface genreSelectOptions {
  label: string;
  value: string;
}

export interface UpdateContentRequest {
  contentThumbnail: string;
  categoryId: string;
  description: string;
  instructor: Instructor[];
  practiceName: string;
  module: IModule[];
  contentBanner: string;
}

interface Instructor {
  _id: string | undefined;
  name: string | undefined;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent {
  modules: IModule[] = [];
  isReUpload: boolean = false;
  totalDelete: boolean = false;
  contentSpinning: boolean = false;
  contentId: string | undefined;
  categories: categorySelectOptions[] = [];
  emotionPurpose: string | undefined = '';
  energyPurpose: string | undefined = '';
  userProblems: string | undefined = '';
  feedback: string | undefined = '';
  practiceName: string = '';
  selectedGenreString: string | undefined = '';
  masterData: MasterDataResponse | undefined;
  selectedCategory: ICategory | undefined;
  selectedCategoryString: undefined | string;
  contentDescription: string | undefined;
  templates: string | undefined;
  genres: genreSelectOptions[] | undefined;
  contentThumbnailFile: File | undefined;
  moduleMediaFiles = new Map<number, File>();
  moduleThumbnailFiles = new Map<number, File>();
  moduleBannerFiles = new Map<number, File>();
  contentThumbnailFileImage: string | undefined;
  moduleThumbnailFileImage: string | undefined;
  moduleContent: string | undefined;
  viewMode: boolean = true;
  private contentBannerFile: File | undefined;
  contentBannerFileImage: string | undefined;
  instructors: instructorsSelectOptions[] = [];
  selectedInstructors: string[] = [];

  constructor(
    private auth: AuthService,
    private contentService: ContentService,
    private activatedRoute: ActivatedRoute,
    private masterDataService: MasterdataService,
    private fileService: FileUtilsService,
    private toastService: ToastService,
  ) {}

  editMode() {
    this.viewMode = false;
  }

  addModule() {
    this.modules.push({
      estimatedTime: '',
      isMandatory: false,
      moduleId: `${this.modules.length + 1}`,
      moduleName: '',
      thumbnail: '',
      type: '',
      uid: '',
      url: '',
      banner: '',
      moduleDescription: '',
    });
  }

  deleteModule(index: number | undefined) {
    if (index != undefined) {
      this.modules.splice(index, 1);
      for (let i = index; i < this.modules.length; i++) {
        this.modules[i].moduleId = (i + 1).toString();
      }
    }
  }

  categoryChangeFn($event: string) {
    if ($event) {
      const category = this.masterData!.categories!.filter(
        (value) => value._id == $event,
      )[0];
      this.selectedCategory = category;
      this.genres = this.masterData!.genre!.filter(
        (value) => value.data == category.genre,
      ).map((value) => {
        const t: genreSelectOptions = {
          label: value.data,
          value: value.m_id,
        };
        return t;
      });
      this.selectedGenreString = this.genres[0].value;
      this.energyPurpose = category!.energyPurpose?.purpose;
      this.emotionPurpose = category!.emotionPurpose?.purpose;
      this.userProblems = category!.userProblem.join(',');
      this.feedback = category.feedback;
      this.templates = category.templates?.join('\n');
    }
  }

  uploadModuleThumbnail($event: any, i: number) {
    const file = $event.target.files[0];
    if (file) {
      this.moduleThumbnailFiles.set(i, file);
    }
  }

  uploadModuleMedia($event: any, i: number) {
    const file = $event.target.files[0];
    if (file) {
      this.moduleMediaFiles.set(i, file);
    }
  }

  uploadContentThumbNail($event: any) {
    const file = $event.target.files[0];

    if (file) {
      this.contentThumbnailFile = file;
    }
  }

  uploadContentBanner($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.contentBannerFile = file;
    }
  }

  uploadModuleBanner($event: any, i: number) {
    const file = $event.target.files[0];
    if (file) {
      this.moduleBannerFiles.set(i, file);
    }
  }

  validateNumber(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
  }

  getContent(id: string) {
    this.contentService.getContent(id).subscribe({
      next: (value: IContent) => {
        this.modules = value.module;
        this.practiceName = value.practiceName;
        const category = value.category;
        this.selectedCategoryString = category?._id;
        this.selectedGenreString = category?.genre;
        this.energyPurpose = category?.energyPurpose?.purpose;
        this.emotionPurpose = category?.emotionPurpose?.purpose;
        this.feedback = category?.feedback;
        category?.userProblem.map((data: string) => (this.userProblems = data));
        this.contentDescription = value.about;
        this.contentThumbnailFileImage = value.thumbnail;
        this.contentBannerFileImage = value.contentBanner;
        this.selectedInstructors = value.instructor.map((obj) => obj._id);
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  loadMasterData() {
    this.masterDataService.masterDataList().subscribe({
      next: (value) => {
        this.categories = value.categories.map((cat) => {
          return {
            label: cat.category,
            value: cat._id,
          };
        });
        this.genres = value.genre.map((genre) => {
          return {
            label: genre.data,
            value: genre.data,
          };
        });
        this.instructors = value.instructors.map((int) => {
          return {
            label: int.name,
            value: int._id,
          };
        });
        this.masterData = value;
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  async updateContent() {
    try {
      this.contentSpinning = true;
      const instructors = this.selectedInstructors.map((id) => {
        const instructor = this.masterData?.instructors.find(
          (instructor) => instructor._id === id,
        );
        return { _id: instructor?._id, name: instructor?.name };
      });
      const updateContentRequest: UpdateContentRequest = {
        categoryId: this.selectedCategoryString!,
        description: this.contentDescription!,
        instructor: instructors,
        practiceName: this.practiceName,
        module: [],
        contentThumbnail: this.contentThumbnailFileImage!,
        contentBanner: this.contentBannerFileImage!,
      };

      if (this.contentThumbnailFile) {
        updateContentRequest.contentThumbnail = await this.handleFileUpload(
          this.contentThumbnailFile,
          `contentthumnail`,
          'Content Thumbnail',
        );
      }

      if (this.contentBannerFile) {
        updateContentRequest.contentBanner = await this.handleFileUpload(
          this.contentBannerFile,
          `contentbanner`,
          'Content Banner',
        );
      }

      const updatedModules = await Promise.all(
        this.modules.map(async (module, index) => {
          return await this.updateModuleFiles(module, index);
        }),
      );

      updateContentRequest.module = updatedModules;

      await lastValueFrom(
        this.contentService.updateContent(
          this.contentId!,
          updateContentRequest,
        ),
      ).then(() => {
        this.toastService.showSuccess('Content updated successfully');
        this.contentSpinning = false;
        this.moduleBannerFiles.clear();
        this.moduleThumbnailFiles.clear();
        this.moduleMediaFiles.clear();
        this.ngOnInit();
      });
    } catch (e) {
      this.toastService.showError(e + '');
      this.contentSpinning = false;
    }
  }

  async updateModuleFiles(module: IModule, index: number): Promise<IModule> {
    const thumbnailFile = this.moduleThumbnailFiles.get(index);
    const mediaFile = this.moduleMediaFiles.get(index);
    const bannerFile = this.moduleBannerFiles.get(index);

    const updatedModule: IModule = {
      estimatedTime: module.estimatedTime,
      isMandatory: module.isMandatory,
      moduleId: module.moduleId,
      moduleName: module.moduleName,
      thumbnail: module.thumbnail,
      type: module.type,
      uid: module.uid || uuidv4(),
      url: module.url,
      banner: module.banner,
      moduleDescription: module.moduleDescription,
    };

    if (thumbnailFile) {
      const link = await this.handleFileUpload(
        thumbnailFile,
        `module_thumbnail_${index + 1}`,
        `thumbnail ${index + 1}`,
      );
      updatedModule.thumbnail = link;
    }

    if (mediaFile) {
      const link = await this.handleFileUpload(
        mediaFile,
        `module_media_${index + 1}`,
        `media ${index + 1}`,
      );
      updatedModule.url = link;
    }

    if (bannerFile) {
      const link = await this.handleFileUpload(
        bannerFile,
        `module_banner_${index + 1}`,
        `banner ${index + 1}`,
      );
      updatedModule.banner = link;
    }

    return updatedModule;
  }

  async handleFileUpload(
    file: File,
    filePathString: string,
    toastrString: string,
  ) {
    try {
      const extension = file.name.split('.').pop();
      const timestamp = moment().format('x');
      const filePath = `content/${this.contentId}/${filePathString}_${timestamp}.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePath,
          file.type,
        ),
      );
      this.toastService.showSuccess(`Uploading ${toastrString}`);
      await lastValueFrom(this.fileService.uploadFile(file, uploadUrl.url));
      this.toastService.showSuccess(
        `Uploading module ${toastrString} completed`,
      );
      return `https://cdn.anyo.app/${filePath}`;
    } catch (e) {
      this.toastService.showError(e + '');
      throw e;
    }
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.loadMasterData();
        if (this.activatedRoute.snapshot.params) {
          const id = this.activatedRoute.snapshot.params['id'];
          this.contentId = id;
          this.getContent(id);
        }
      }
    });
  }
}
