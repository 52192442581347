import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { MemberService } from '../../../services/member.service';
import { ISubscriptionCount } from '../../../stores/types';
import { AuthService } from '../../../../../../app-core/src/auth/auth.service';
import {
  MemberGridRequest,
  MemberTab,
} from '../../../models/memberGridRequest';
import { MemberGridResponse } from '../../../models/memberTable';
import * as moment from 'moment';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { FileUtilsService } from '../../../../../../app-core/src/service/fileService';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { SubscriptionService } from '../../../services/subscription.service';
import { ISubscription } from '../../../models/ISubscription';

import { MemberTableComponent } from '../member-table/member-table.component';

interface BulkUploadCSVTemplate {
  error?: string;
  email: string;
  departmentName?: string;
  branch?: string;
  sendMail: boolean;
}

export interface BulkUploadMemberRequest {
  email: string;
  departmentName?: string;
  branch?: string;
  sendMail: boolean;
  error?: string;
}

@Component({
  selector: 'app-manage-member',
  templateUrl: './manage-member.component.html',
  styleUrls: ['./manage-member.component.scss'],
})
export class ManageMemberComponent {
  totalCount: number = 0;
  pageSize: number = 10;
  partnerId!: string;
  subscriptionCount: ISubscriptionCount = { total: 0 };
  memberTableQuery: MemberGridRequest | undefined;

  selectedMembersArray!: string[];
  isAlert: boolean = false;
  memberId!: string;
  partnerName!: string;
  alertHeaderDisable: string = 'Member Delete';
  alertBodyDisable: string =
    'Please make sure that you want to delete the member';

  memberTableSpinner = false;
  memberTableTotalCount: number = 0;
  availablePasses: number = 0;
  invitedPasses: number = 0;
  availedPasses: number = 0;
  totalFamily: number = 0;
  totalCorporate: number = 0;
  totalInvitedFamily: number = 0;
  totalFamilyMemberPassRedeemed: number = 0;
  availableFamilyPasses: number = 0;
  bulkUploadLoading: boolean = false;
  showBulkUploadDrawer: boolean = false;
  subscription!: ISubscription;
  tableData: MemberGridResponse = {
    data: [],
    filterOptions: {
      branch: [],
      department: [],
    },
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };

  dialogShow() {
    this.isAlert = !this.isAlert;
  }

  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private memberService: MemberService,
    private fileService: FileUtilsService,
    private subscriptionService: SubscriptionService,
  ) {}

  @ViewChild('coporateMemberTable') coporateMemberTable!: MemberTableComponent;
  @ViewChild('familyMemberTable') familyMemberTable!: MemberTableComponent;

  async reload() {
    await this.listMembers();
  }

  deleteMemberAlert(id: string) {
    this.isAlert = true;
    this.memberId = id;
  }

  check() {
    // if (this.subscriptionCount.total == 0) {
    //   this.toastrService.showError(
    //     'Please provide subscription details to add members',
    //   );
    // } else {
    //   if (
    //     isDateInRange(
    //       String(this.subscriptionDetails.startDate),
    //       String(this.subscriptionDetails.endDate),
    //     ) == false
    //   ) {
    //     this.toastrService.showError(
    //       'You must have an active subscription to add members',
    //     );
    //   } else {
    //
    //   }
    // }
    this.router.navigate([
      '/partner/addmember/' + this.partnerId + '/' + this.partnerName,
    ]);
  }

  selectedMembers(event: any) {
    this.selectedMembersArray = event;
  }

  deleteMember() {
    this.memberService.deleteMember(this.memberId).subscribe({
      next: (value) => {
        this.isAlert = false;
        this.listMembers();
        this.toastService.showSuccess('Member deleted successfully');
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  deleteMembers() {
    console.log(this.selectedMembersArray);
    let updatedArray: any[] = [];
    this.selectedMembersArray.forEach((member) => {
      const isIdPresent = this.tableData.data.some((obj) => obj.id === member);
      if (isIdPresent) {
        updatedArray.push(member);
      }
    });
    this.selectedMembersArray = updatedArray;
    let req = {
      data: this.selectedMembersArray,
    };
    this.memberService.bulkDeleteMember(req).subscribe({
      next: (value) => {
        console.log(value);
        this.toastService.showSuccess('Members deleted successfully');
        this.selectedMembersArray.forEach((member) => {
          const newArray = this.tableData.data.filter(
            (obj) => obj.id !== member,
          );
          this.tableData.data = newArray;
        });
        this.totalCount = this.tableData.data.length;
        this.subscriptionCount.available =
          Number(this.subscriptionCount.total) - this.totalCount;
        this.subscriptionCount.active = this.totalCount;
        this.selectedMembersArray = [];
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  getCount() {
    this.memberService.memberCount(this.partnerId).subscribe({
      next: (value) => {
        this.totalCount = value.totalPasses;
        this.invitedPasses = value.totalInvited;
        this.availedPasses = value.totalRedeemed;
        this.availablePasses = value.totalCorporate - value.totalInvited;
        this.subscriptionCount.available =
          value.totalPasses - value.totalInvited;
        this.totalFamily = value.totalFamily;
        this.totalFamilyMemberPassRedeemed =
          value.totalFamilyMemberPassRedeemed;
        this.totalInvitedFamily = value.totalInvitedFamily;
        this.availableFamilyPasses =
          value.totalFamily - value.totalInvitedFamily;
        this.totalCorporate = value.totalCorporate;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
  resetFilter() {
    this.memberTableQuery = {
      export: false,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.familyMemberTable) {
      this.familyMemberTable.resetValues();
    }
    if (this.coporateMemberTable) {
      this.coporateMemberTable.resetValues();
    }
    this.listMembers();
  }

  getSubcriptionForPartner() {
    this.subscriptionService.getSubscription(this.partnerId).subscribe({
      next: (value) => {
        this.subscription = value;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params) {
      let value = this.activatedRoute.snapshot.params['id'];
      this.partnerName = this.activatedRoute.snapshot.params['partner'];
      if (value) {
        this.partnerId = value;
      }
    }
    this.memberTableSpinner = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.memberTableQuery = {
          export: false,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          tab: MemberTab.CORPORATE_MEMBER,
        };
        this.getCount();
        this.listMembers();
        this.getSubcriptionForPartner();
      }
    });
  }

  nzPageIndexChange(page: number) {
    if (this.memberTableQuery) {
      this.memberTableQuery!.page = page;
      this.listMembers();
    }
  }

  emailColumnSearchFn($event: string) {
    if ($event.length) {
      if (!this.memberTableQuery!.searchQuery) {
        this.memberTableQuery!.searchQuery = {
          CORPORATE_CODE: '',
          EMAIL: '',
        };
      }
      this.memberTableQuery!.searchQuery.EMAIL = $event;
      this.listMembers();
    }
  }

  corporateCodeSearchFn($event: string) {
    if ($event.length) {
      if (!this.memberTableQuery!.searchQuery) {
        this.memberTableQuery!.searchQuery = {
          CORPORATE_CODE: '',
          EMAIL: '',
        };
      }
      this.memberTableQuery!.searchQuery.CORPORATE_CODE = $event;
      this.listMembers();
    }
  }

  codeRedeemColumnFilterFn($event: string) {
    if ($event) {
      if (!this.memberTableQuery!.filters) {
        this.memberTableQuery!.filters = {
          BRANCH: [],
          DEPARTMENT: [],
          PASS_REDEEMED: [],
        };
      }
      this.memberTableQuery!.filters.PASS_REDEEMED = [
        $event === 'Yes' ? 'true' : 'false',
      ];
      this.listMembers();
    }
  }

  listMembers() {
    this.memberTableSpinner = true;
    this.memberService
      .memberList(this.memberTableQuery!, this.partnerId)
      .subscribe({
        next: (value) => {
          this.memberTableSpinner = false;
          value.data.forEach((member) => {
            member.codeRedeemedOnString = moment(
              member.codeRedeemedOn,
              'X',
            ).format('DD/MM/YYYY');
          });
          this.tableData = value;
          this.memberTableTotalCount = value.totalRecords;
        },
        error: (error) => {
          this.memberTableSpinner = false;
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
        },
      });
  }

  nzPageSizeChange(pageSize: number) {
    this.memberTableQuery!.pageSize = pageSize;
    this.listMembers();
  }

  showUploadDrawerFn() {
    this.showBulkUploadDrawer = true;
  }

  closeBulkUploadDrawerFn() {
    this.showBulkUploadDrawer = false;
  }

  exportMemberTable() {
    this.memberTableQuery!.export = true;
    this.memberService
      .memberList(this.memberTableQuery!, this.partnerId)
      .subscribe({
        next: (response) => {
          try {
            const csvData = response.data as unknown as Record<
              string,
              string | null | undefined
            >[];
            const csvConfig = mkConfig({ useKeysAsHeaders: true });
            const csv = generateCsv(csvConfig)(csvData);
            download(csvConfig)(csv);
          } catch (e) {
            console.log(e);
            this.toastService.showError('Export Error');
          }
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
        },
      });
  }

  downloadTemplate() {
    const sampleData: BulkUploadCSVTemplate[] = [];
    sampleData.push({
      email: 'prgwar@gmail.com',
      departmentName: 'IT',
      branch: 'Chennai',
      sendMail: false,
    });
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename: `bulk_upload_members_${moment().format('DD-MM-yyyy_HH-mm-ss')}`,
    });
    const csv = generateCsv(csvConfig)(
      sampleData as unknown as Record<string, string>[],
    );
    download(csvConfig)(csv);
  }

  async csvFileSelected($event: any) {
    if (!$event.target.files[0]) {
      this.toastService.showError('Please select a CSV file');
      return;
    }
    this.bulkUploadLoading = true;
    const file = $event.target.files[0];
    const parsed = await this.fileService.parseCSV<BulkUploadCSVTemplate>(file);
    const maxLimitReachedRows = [];

    const corporateSubscriptions = this.subscription.corporateSubscriptions;
    const parsedRows = parsed.length;

    if (this.availablePasses === 0) {
      this.toastService.showError(
        'Maximum limit reached,please update the csv and uploaded,No member Got Uploaded',
      );
      this.bulkUploadLoading = false;
      return;
    }

    if (parsedRows > corporateSubscriptions) {
      for (let i = 0; i < parsedRows; i++) {
        const row = parsed[i];
        const errorCSVRow: BulkUploadCSVTemplate = {
          email: row.email,
          branch: row.branch,
          departmentName: row.departmentName,
          sendMail: row.sendMail,
          error: 'Max Passes limit Reached',
        };
        maxLimitReachedRows.push(errorCSVRow);
      }
    }

    if (maxLimitReachedRows.length) {
      this.fileService.exportCSV(maxLimitReachedRows, 'error_members');
      this.toastService.showError(
        'Maximum limit reached,please update the csv and uploaded,No member Got Uploaded',
      );
      this.bulkUploadLoading = false;
      return;
    }

    for (let i = 0; i < parsed.length; i++) {
      const errors = [];
      const row: BulkUploadCSVTemplate = parsed[i];
      if (!row.email) {
        errors.push('Please enter a valid email');
      }
      parsed[i].error = errors.join(',');
    }
    if (parsed.some((row) => row.error)) {
      this.toastService.showError('Invalid CSV file');
      this.fileService.exportCSV(parsed, file.name);
      this.bulkUploadLoading = false;
      return;
    }
    const payload: BulkUploadMemberRequest[] = [];
    for (let i = 0; i < parsed.length; i++) {
      const row: BulkUploadCSVTemplate = parsed[i];
      const data: BulkUploadMemberRequest = {
        branch: row.branch?.trim(),
        departmentName: row.departmentName?.trim(),
        email: row.email.trim(),
        sendMail: false,
      };
      payload.push(data);
    }
    this.memberService.bulkUploadRequest(payload, this.partnerId).subscribe({
      next: (value) => {
        this.toastService.showSuccess('Bulk upload request sent successfully');
        this.bulkUploadLoading = false;
        this.showBulkUploadDrawer = false;
        this.getCount();
      },
      error: (error) => {
        this.bulkUploadLoading = false;
        const errorBody = error.error as BulkUploadMemberRequest[];
        const errorCSVData: BulkUploadCSVTemplate[] = [];
        for (let i = 0; i < errorBody.length; i++) {
          const row = errorBody[i];
          const errorCSVRow: BulkUploadCSVTemplate = {
            email: row.email,
            branch: row.branch,
            departmentName: row.departmentName,
            sendMail: row.sendMail,
            error: row.error,
          };
          errorCSVData.push(errorCSVRow);
        }
        this.toastService.showError('Error while bulk uploading members');
        this.fileService.exportCSV(errorCSVData, file.name);
        this.getCount();
      },
    });
  }

  tabs = [MemberTab.CORPORATE_MEMBER, MemberTab.FAMILY_MEMBER];
  selectedTab: number = 0;

  tabChange($event: NzTabChangeEvent) {
    this.selectedTab = $event.index!;
    console.log($event);
    this.memberTableQuery = {
      export: false,
      sort: undefined,
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if ($event.index == 0) {
      this.memberTableQuery!.tab = MemberTab.CORPORATE_MEMBER;
    }
    if ($event.index == 1) {
      this.memberTableQuery!.tab = MemberTab.FAMILY_MEMBER;
    }
    this.listMembers();
  }
}
