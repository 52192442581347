import { Component } from '@angular/core';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { BytesService } from '../../../services/bytes.service';
import { BytesCategoryGridRequest } from '../../../models/bytesCategoriesGridRequest';
import { BytesCategoryGridResponseData } from '../../../models/bytesCategoriesGridResponse';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-manage-bytes-category',
  templateUrl: './manage-bytes-category.component.html',
  styleUrls: ['./manage-bytes-category.component.scss'],
})
export class ManageBytesCategoryComponent {
  totalCount: number = 0;
  bytesCategoryTableQuery: BytesCategoryGridRequest | undefined;
  bytesCategorySpinner: boolean = false;
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  bytesCategoryTableCurrentPageNo = 1;
  bytesCategoryTableTotalCount = 0;
  globalSearchInput = '';
  bytesCategoryData!: BytesCategoryGridResponseData[];

  constructor(
    private auth: AuthService,
    private bytes: BytesService,
    private toastrService: ToastService,
  ) {}

  resetFilter() {
    this.bytesCategoryTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.bytesCategoryTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.listBytesCatgories();
  }

  listBytesCatgories() {
    this.bytesCategorySpinner = true;
    this.bytes.listBytesCategories(this.bytesCategoryTableQuery!).subscribe({
      next: (value) => {
        console.log(value);
        this.bytesCategoryData = value.data;
        this.bytesCategoryTableTotalCount = value.totalRecords;
        this.bytesCategorySpinner = false;
        this.totalCount = value.totalRecords;
      },
      error: (error) => {
        this.bytesCategorySpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.bytesCategoryTableQuery?.globalSearch?.push(
        this.globalSearchInput.trim(),
      );
      this.listBytesCatgories();
    }
  }

  nzPageIndexChange(page: number) {
    if (this.bytesCategoryTableQuery) {
      this.bytesCategoryTableQuery!.page = page;
      this.listBytesCatgories();
    }
  }

  nzPageSizeChange(pageSize: number) {
    this.bytesCategoryTableQuery!.pageSize = pageSize;
    this.listBytesCatgories();
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.bytesCategoryTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: undefined,
          to: undefined,
        };
        this.listBytesCatgories();
      }
    });
  }

  enableDisableBytesCategory(flag: boolean) {
    console.log(flag);
  }
}
