import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PostsRoutingModule} from './posts-routing.module';
import {ManagePostsComponent} from './pages/manage-posts/manage-posts.component';
import {PostTableComponent} from './components/post-table/post-table.component';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {FormsModule} from '@angular/forms';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {ReportTableComponent} from './components/report-table/report-table.component';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {FilterOutline} from '@ant-design/icons-angular/icons';
import {AddPostComponent} from './pages/add-post/add-post.component';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {ModerationEventsTableComponent} from './components/moderation-events-table/moderation-events-table.component';
import {NzTypographyComponent} from "ng-zorro-antd/typography";

@NgModule({
  declarations: [
    ManagePostsComponent,
    PostTableComponent,
    ReportTableComponent,
    AddPostComponent,
    ModerationEventsTableComponent,
  ],
  imports: [
    CommonModule,
    PostsRoutingModule,
    NzInputModule,
    NzTableModule,
    NzSpinModule,
    NzButtonModule,
    NzGridModule,
    FormsModule,
    NzToolTipModule,
    NzIconModule.forRoot([FilterOutline]),
    NzTabsModule,
    NzDropDownModule,
    NzDrawerModule,
    NzModalModule,
    NzCardModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSelectModule,
    NzSelectModule,
    NzSwitchModule,
    NzDatePickerModule,
    NzTimePickerModule,
    ClipboardModule,
    NzTagModule,
    NzTypographyComponent,
  ],
})
export class PostsModule {}
