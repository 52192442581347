<div class="progress-bar">
  <ol class="steps">
    <li
      [ngClass]="
        status
          ? 'step overflow-hidden'
          : 'step step-primary step-active overflow-hidden'
      "
    >
      <!-- <div class="flex flex-col">
                <div class="step-circle">
                    <div *ngIf="!status">
                        <img src="../../../../assets/icons/incomplete.svg">
                    </div>
                    <div *ngIf="status">
                        <img src="../../../../assets/icons/finished.svg">
                    </div>
                </div>
                <div>
                    <h3>Profile Information</h3>
                </div>
            </div> -->
      <div class="step-circle">
        <div *ngIf="!status">
          <img src="../../../../assets/icons/incomplete.svg" />
        </div>
        <div *ngIf="status">
          <img src="../../../../assets/icons/finished.svg" />
        </div>
      </div>
      <h3>{{ steppertitle1 }}</h3>
    </li>
    <li
      [ngClass]="
        status
          ? 'step step-primary step-active overflow-hidden'
          : 'step overflow-hidden'
      "
    >
      <div class="step-circle">
        <div *ngIf="!status">
          <img src="../../../../assets/icons/untouched.svg" />
        </div>
        <div *ngIf="status">
          <img src="../../../../assets/icons/incomplete.svg" />
        </div>
      </div>
      <h3>{{ steppertitle2 }}</h3>
    </li>
  </ol>
</div>
