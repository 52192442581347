<nz-spin [nzSpinning]="pageLoading">
<div nz-row>
  <div nz-col nzSpan="24">
    <nz-card
      nzTitle="Go Live {{talkName}} {{this.broadcastSessionId}} ({{this.broadcastSession?.participantDetails?.length||0}} Participants)">
      <div *ngIf="!layoutConfigDisabled && !watchOnly">
        <h5 nz-typography>Layout Mode</h5>
        <br>
        <nz-radio-group (ngModelChange)="layoutSelectFn()" [(ngModel)]="selectedLayout">
          <label [nzDisabled]="layoutConfigDisabled" nz-radio nzValue="portrait">Portrait</label>
          <label [nzDisabled]="layoutConfigDisabled" nz-radio nzValue="landscape">Landscape</label>
        </nz-radio-group>
        <br>
        <br>
        <span>Audio Only</span>
        <br>
        <nz-switch [(ngModel)]="audioOnly">Audio Only</nz-switch>
        <br>
        <span>Allow Users to Comment</span>
        <br>
        <nz-switch [(ngModel)]="enableComments"></nz-switch>
        <nz-divider></nz-divider>
      </div>
      <div *ngIf="broadcastSession">

        <nz-divider nzOrientation="left" nzText="Particioants"></nz-divider>
        <div>
          <nz-table #participantsTable
                    [nzData]="broadcastSession!.participantDetails||[]"
                    [nzScroll]="{ y: '300px' }">
            <thead>
            <tr>
              <th>
                Name
              </th>
              <th> Partner Id</th>
              <th>Tags & Interests</th>
              <th>
                Join Time
              </th>

            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let participant of participantsTable.data">
              <td>
                {{ participant.name }}<br>
                {{ participant.email }}
              </td>
              <td>
                {{ participant.partnerId }}<br>
                <nz-tag [nzColor]="'magenta'">{{ participant.corporateUser ? "Corporate User" : "Retail User" }}
                </nz-tag>
              </td>
              <td>
                <nz-tag *ngFor="let tag of participant?.userTags||[]" [nzColor]="'magenta'">{{ tag }}</nz-tag>
                <nz-tag *ngFor="let tag of participant?.interests||[]" [nzColor]="'cyan'">{{ tag }}</nz-tag>
              </td>
              <td>
                {{ formatDate(participant.joinTime.toDate()) }}
              </td>
            </tr>
            </tbody>
          </nz-table>
        </div>

        <nz-divider></nz-divider>

      </div>
      <div *ngIf="!watchOnly" nz-row nzGutter="16">
        <div nz-col nzSpan="6">
          <div *ngIf="selectedLayout==='portrait'">
            <div class="min-h-[30em] max-w-[16.875em]">
              <nz-card *ngIf="!showVideComponent" class="min-h-[30em] max-w-[16.875em]">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
                <div style="margin-top: 16px;">
                  <p>{{ audioOnly ? "Audio Only Live" : "Live video will appear here" }}</p>
                </div>
              </nz-card>
              <br>
              <span *ngIf="!audioOnly">Select Camera Input</span>
              <br>
              <nz-select (ngModelChange)="cameraInputChangeFn()" *ngIf="!audioOnly" [(ngModel)]="selectedCameraInput"
                         class="min-w-[100%]">
                <nz-option *ngFor="let cInput of cameraInputs" nzLabel="{{cInput.label}}"
                           nzValue="{{cInput.deviceId}}"></nz-option>
              </nz-select>
              Select Audio Input
              <br>
              <nz-select [(ngModel)]="selectedAudioInput" class="min-w-[100%]">
                <nz-option *ngFor="let cInput of audioInputs" nzLabel="{{cInput.label}}"
                           nzValue="{{cInput.deviceId}}"></nz-option>
              </nz-select>
              <nz-card [ngStyle]="{'display': showVideComponent ? 'block' : 'none'}" style=" border-radius: 15px">
                <div nz-row>
                  <div nz-col>
                    <video #localVideoPortrait autoplay style=" border-radius: 10px"></video>
                  </div>
                </div>
              </nz-card>
              <div [nzGutter]="16" class="mt-5 ml-5" nz-row>
                <div nz-col>
                  <div (click)="toggleCamera()" *ngIf="!cameraToggle && broadcastLive &&!audioOnly && permissions.video"
                       class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-green-500">
                    <img height="15" ngSrc="../../../../assets/icons/camera-filled.svg" width="15"/>
                  </div>
                  <div (click)="toggleCamera()" *ngIf="cameraToggle && broadcastLive &&!audioOnly && permissions.video"
                       class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-red-500">
                    <!-- SVG for Camera Icon -->
                    <img height="15" ngSrc="../../../../assets/icons/camera-filled.svg" width="15"/>
                  </div>
                  <div (click)="toggleMic()" *ngIf="!micToggle  && broadcastLive"
                       class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-green-500">
                    <!-- SVG for Camera Icon -->
                    <img height="15" ngSrc="../../../../assets/icons/mic.svg" width="15"/>
                  </div>
                  <div (click)="toggleMic()" *ngIf="micToggle  && broadcastLive"
                       class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-red-500">
                    <!-- SVG for Camera Icon -->
                    <img height="15" ngSrc="../../../../assets/icons/mic.svg" width="15"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedLayout==='landscape'" class="min-h-[16.875em] max-w-[30em]">
            <nz-card *ngIf="!showVideComponent" class="min-h-[16.875em] max-w-[30em]">
              <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
              <div style="margin-top: 16px;">
                <p>{{ audioOnly ? "Audio Only Live" : "Live video will appear here" }}</p>
              </div>
            </nz-card>
            <span *ngIf="!audioOnly && cameraToggle">Select Camera Input</span>
            <br>
            <nz-select (ngModelChange)="cameraInputChangeFn()" *ngIf="!audioOnly && cameraToggle"
                       [(ngModel)]="selectedCameraInput" class="min-w-[100%]">
              <nz-option *ngFor="let cInput of cameraInputs" nzLabel="{{cInput.label}}"
                         nzValue="{{cInput.deviceId}}"></nz-option>
            </nz-select>
            <span *ngIf="micToggle">Select Audio Input</span>
            <br>
            <nz-select (ngModelChange)="audioInputChangeFn()" *ngIf="micToggle" [(ngModel)]="selectedAudioInput"
                       class="min-w-[100%]">
              <nz-option *ngFor="let cInput of audioInputs" nzLabel="{{cInput.label}}"
                         nzValue="{{cInput.deviceId}}"></nz-option>
            </nz-select>
            <br>
            <nz-card [ngStyle]="{'display': showVideComponent ? 'block' : 'none'}" style=" border-radius: 15px">
              <video #localVideoLandscape autoplay style=" border-radius: 10px"></video>
            </nz-card>
            <div nz-row nzGutter="16">
              <div nz-col>
                <div (click)="toggleCamera()" *ngIf="!cameraToggle && broadcastLive &&!audioOnly && permissions.video"
                     class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-green-500">
                  <img height="15" ngSrc="../../../../assets/icons/camera-filled.svg" width="15"/>
                </div>
                <div (click)="toggleCamera()" *ngIf="cameraToggle && broadcastLive &&!audioOnly && permissions.video"
                     class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-red-500">
                  <!-- SVG for Camera Icon -->
                  <img height="15" ngSrc="../../../../assets/icons/camera-filled.svg" width="15"/>
                </div>
                <div (click)="toggleMic()" *ngIf="!micToggle  && broadcastLive"
                     class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-green-500">
                  <!-- SVG for Camera Icon -->
                  <img height="15" ngSrc="../../../../assets/icons/mic.svg" width="15"/>
                </div>
                <div (click)="toggleMic()" *ngIf="micToggle  && broadcastLive"
                     class="m-1 p-3 inline-flex items-center justify-center rounded-lg text-white cursor-pointer bg-red-500">
                  <!-- SVG for Camera Icon -->
                  <img height="15" ngSrc="../../../../assets/icons/mic.svg" width="15"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="18">
          <nz-card nzTitle="Chat">
            <div
              class="max-h-[400px] min-h-[400px] flex flex-col-reverse overflow-y-auto p-3 bg-gray-100 rounded-t-lg">
              <div *ngFor="let message of messages">
                <!-- Message from other user -->
                <div *ngIf="!message.host" class="flex justify-start mb-4">
                  <div class="bg-white rounded-lg px-4 py-2 shadow min-w-[300px]">
                    <div class="flex justify-between items-center">
                      <h5 class="text-sm font-semibold">{{ message.name }}</h5>

                      <span class="text-xs text-gray-500">{{ formatMessageTime((message.timeStamp)) }}</span>
                    </div>
                    <p class="text-sm">{{ message.message }}</p>
                  </div>
                </div>
                <!-- Message from current user -->
                <div *ngIf="message.host" class="flex justify-end mb-4">
                  <div class="bg-blue-500 rounded-lg px-4 py-2 shadow min-w-[300px]">
                    <div class="flex justify-between items-center">
                      <h5 class="text-sm font-semibold text-white">{{ message.name }}</h5>
                      <span class="text-xs text-gray-200">{{ formatMessageTime((message.timeStamp)) }}</span>
                    </div>
                    <p class="text-sm text-white">{{ message.message }}</p>
                  </div>
                </div>
              </div>


              <!-- Repeat for more messages -->
            </div>
            <nz-spin [nzSpinning]="sendingMessage">
              <!-- Input container -->
              <div class="mt-auto bg-gray-200 rounded-b-lg">
                <div class="flex items-center p-3">
                  <input
                    (keyup)="onEnterPress($event)"
                    [(ngModel)]="message"
                    class="flex-grow rounded-md border px-4 py-2 text-sm focus:outline-none"
                    placeholder="Type a message..."
                    type="text"/>
                  <button (click)="addMessage()"
                          class="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md text-sm focus:outline-none">
                    Send
                  </button>
                </div>
              </div>
            </nz-spin>
          </nz-card>
        </div>
      </div>
      <div *ngIf="watchOnly" nz-row nzGutter="16">
        <div *ngIf="broadcastSession?.layout==='portrait'" class="min-h-[30em] max-w-[16.875em]" nz-col nzSpan="6">
          <video id="hlsPlayer"></video>
        </div>
        <div *ngIf="broadcastSession?.layout==='landscape'" class="min-h-[16.875em] max-w-[30em]" nz-col nzSpan="6">
          <video id="hlsPlayer"></video>
        </div>
        <div nz-col nzSpan="18">
          <nz-card nzTitle="Chat">
            <div
              class="max-h-[400px] min-h-[400px] flex flex-col-reverse overflow-y-auto p-3 bg-gray-100 rounded-t-lg">
              <div *ngFor="let message of messages">
                <!-- Message from other user -->
                <div *ngIf="!message.host" class="flex justify-start mb-4">
                  <div class="bg-white rounded-lg px-4 py-2 shadow min-w-[300px]">
                    <div class="flex justify-between items-center">
                      <h5 class="text-sm font-semibold">{{ message.name }}</h5>

                      <span class="text-xs text-gray-500">{{ formatMessageTime((message.timeStamp)) }}</span>
                    </div>
                    <p class="text-sm">{{ message.message }}</p>
                  </div>
                </div>
                <!-- Message from current user -->
                <div *ngIf="message.host" class="flex justify-end mb-4">
                  <div class="bg-blue-500 rounded-lg px-4 py-2 shadow min-w-[300px]">
                    <div class="flex justify-between items-center">
                      <h5 class="text-sm font-semibold text-white">{{ message.name }}</h5>
                      <span class="text-xs text-gray-200">{{ formatMessageTime((message.timeStamp)) }}</span>
                    </div>
                    <p class="text-sm text-white">{{ message.message }}</p>
                  </div>
                </div>
              </div>


            </div>


            <nz-spin [nzSpinning]="sendingMessage">
              <!-- Input container -->
              <div class="mt-auto bg-gray-200 rounded-b-lg">
                <div class="flex items-center p-3">
                  <input (keyup)="onEnterPress($event)"
                         [(ngModel)]="message"
                         class="flex-grow rounded-md border px-4 py-2 text-sm focus:outline-none"
                         placeholder="Type a message..."
                         type="text"/>
                  <button (click)="addMessage()"
                          class="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md text-sm focus:outline-none">
                    Send
                  </button>
                </div>
              </div>
            </nz-spin>
          </nz-card>
        </div>
      </div>
      <nz-divider nzOrientation="left" nzText="Title & Description">
      </nz-divider>
      <div nz-row>
        <div nz-col nzSpan="8">
          <label class="form-label required">Title</label>
          <input
            [(ngModel)]="todayTopic"
            [disabled]="watchOnly || broadcastLive"
            nz-input
            placeholder="Notification Title"
            type="text"
          />
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">
          <label class="form-label required">Description</label>
          <textarea
            [(ngModel)]="todayTalkDescription"
            [disabled]="watchOnly || broadcastLive"
            nz-input
            placeholder="Notification Body"
            rows="4"
            type="text"
          ></textarea>
        </div>
      </div>
      <nz-divider nzOrientation="left" nzText="Notification"></nz-divider>
      <div nz-row>
        <div nz-col nzSpan="8">
          <label class="form-label required">Notification Title</label>
          <input [(ngModel)]="notificationTitle" [disabled]="watchOnly || broadcastLive" nz-input placeholder="Notification Title"
                 type="text">
        </div>
      </div>
      <br/>
      <div nz-row>
        <div nz-col nzSpan="16">
          <label class="form-label required">Notification Body</label>
          <textarea [(ngModel)]="notificationBody" [disabled]="watchOnly || broadcastLive" nz-input placeholder="Notification Body" rows="4"
                    type="text"></textarea>
        </div>

      </div>

      <div *ngIf="!watchOnly">
        <nz-divider></nz-divider>
        <nz-spin [nzSpinning]="isGoingLive">
          <div nz-row nzGutter="16">
            <div *ngIf="!broadcastLive" nz-col nzSpan="24">
              <button (click)="toggleLive()" [ngStyle]="{'width': '100%', 'display': 'block'}" nz-button nzSize="large"
                      nzType="primary">Go Live
              </button>
            </div>
            <div *ngIf="broadcastLive" nz-col nzSpan="24">
              <button (click)="toggleLive()" [ngStyle]="{'width': '100%', 'display': 'block'}" nz-button nzDanger
                      nzSize="large"
                      nzType="primary">Stop Live
              </button>
            </div>
          </div>
        </nz-spin>
      </div>
      <div *ngIf="watchOnly" class="mt-2">
        <button [ngStyle]="{'width': '100%', 'display': 'block'}" disabled nz-button nzDanger nzSize="large"
                nzType="primary">Watch only Mode
        </button>
      </div>

    </nz-card>

  </div>
</div>
</nz-spin>
<canvas
  #canvasLandscape
  height="480"
  style="display: none"
  width="640"
></canvas>
<canvas #canvasPortrait height="640" style="display: none" width="480"></canvas>
