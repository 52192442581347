<div
  class="flex flex-row flex-nowrap md:gap-3 items-center justify-between mt-2"
>
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/routine']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 *ngIf="viewMode" class="headeradminui-text pl-3">View Routine</h1>
    <h1 *ngIf="!viewMode" class="headeradminui-text pl-3">Update Routine</h1>
  </div>
</div>
<nz-spin [nzSpinning]="routineSpinning" nzTip="Loading...">
  <div class="custom-card mt-3">
    <div class="card-body">
      <div nz-row nzJustify="end">
        <div nz-col>
          <button nz-button nzType="primary" (click)="editMode()">
            Edit Routine
          </button>
        </div>
      </div>
      <div nzGutter="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="8">
          <label class="form-label required">Choose Group</label>
          <nz-select
            [(ngModel)]="selectedGroupString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Group"
            nzShowSearch
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let group of groups"
              [nzLabel]="group.label"
              [nzValue]="group.value"
            ></nz-option>
          </nz-select>
        </div>

        <div nz-col nzSpan="8">
          <label class="form-label">Choose Timing</label>
          <nz-select
            [(ngModel)]="selectedTimingString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Timing"
            nzShowSearch
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let timing of timings"
              [nzLabel]="timing.label"
              [nzValue]="timing.value"
            ></nz-option>
          </nz-select>
        </div>

        <div nz-col nzSpan="8">
          <label class="form-label required">Routine Name</label>
          <input
            [(ngModel)]="routineName"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Routine Name"
            [disabled]="viewMode"
          />
        </div>
      </div>
      <div nzGutter="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="12">
          <label class="form-label">Choose Difficulty Level</label>
          <nz-select
            [(ngModel)]="selectedDifficultyString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Difficulty"
            nzShowSearch
            [nzDisabled]="viewMode"
          >
            <nz-option
              *ngFor="let difficulty of difficultys"
              [nzLabel]="difficulty.label"
              [nzValue]="difficulty.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="12">
          <label class="form-label required">Description</label>
          <textarea
            [(ngModel)]="routineDescription"
            nz-input
            rows="3"
            placeholder="Description"
            [disabled]="viewMode"
          >
Description</textarea
          >
        </div>
      </div>
      <div nzGutter="16" nz-row>
        <div nz-col nzSpan="12">
          <label class="form-label required">Instructors for All days</label>
          <input
            [(ngModel)]="instructors"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Instructors All Days"
            [disabled]="true"
          />
        </div>
        <div nz-col nzSpan="12">
          <label class="form-label required"
            >Total EstimatedTime for All Days(In Mins)</label
          >
          <input
            [(ngModel)]="totalDaysEstimatedTime"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            placeholder="Total Estimated Time for All Days"
            [disabled]="true"
          />
        </div>
      </div>
      <div nz-col nzSpan="12">
        <img class="pt-2 pb-2" [src]="routineBannerImage" />
        <span><b>Upload Routine Banner</b></span>
        <br />
        <input
          (change)="uploadRoutineBanner($event)"
          accept=".jpg,.jpeg,.png"
          type="file"
        />
      </div>
      <nz-divider nzText="Days"></nz-divider>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button
            *ngIf="!viewMode"
            (click)="addRoutine()"
            nz-button
            nzType="primary"
          >
            Add Day
          </button>
        </div>
      </div>
      <div class="routine-container">
        <div *ngFor="let day of days; index as i" class="mt-3" nz-row>
          <nz-card
            [nzExtra]="deleteRoutineTemplate"
            [nzTitle]="'Day ' + (i + 1)"
            style="width: 100%"
          >
            <div class="mt-3" nzGutter="16" nz-row nzAlign="middle">
              <div nz-col nzSpan="6">
                <label class="form-label required">Day number</label>
                <input
                  [(ngModel)]="day.dayNumber"
                  class="w-[100%] mr-1"
                  nz-input
                  nzSize="large"
                  type="number"
                  placeholder="Day Number"
                  [disabled]="viewMode"
                />
              </div>
              <div nz-col nzSpan="6">
                <img class="pl-3 pr-3" [src]="day.contentThumbnail" />
              </div>
              <div nz-col nzSpan="6">
                <img class="pl-3 pr-3" [src]="day.contentBanner" />
              </div>
              <div nz-col nzSpan="6" nzJustify="end">
                <button
                  *ngIf="viewMode"
                  nz-button
                  nzType="primary"
                  [routerLink]="['/content/view/' + day.contentId]"
                >
                  Go to this Day's Content
                </button>
              </div>
            </div>
            <div
              class="mt-3"
              nzGutter="16"
              nz-row
              nzAlign="middle"
              nzJustify="space-evenly"
            >
              <div nz-col nzSpan="8">
                <label class="form-label required">Genre</label>
                <nz-select
                  (ngModelChange)="genreChangeFn($event, i)"
                  [(ngModel)]="day.selectedGenreString"
                  class="w-[100%] mr-1"
                  nzAllowClear
                  nzPlaceHolder="Select a Genre"
                  nzShowSearch
                  [nzDisabled]="viewMode"
                >
                  <nz-option
                    *ngFor="let genre of day.genres"
                    [nzLabel]="genre.label"
                    [nzValue]="genre.value"
                  ></nz-option>
                </nz-select>
              </div>
              <div nz-col nzSpan="8">
                <label class="form-label required">Category</label>
                <nz-select
                  (ngModelChange)="categoriesChangeFn($event, i)"
                  [(ngModel)]="day.selectedCategoryString"
                  class="w-[100%] mr-1"
                  nzAllowClear
                  nzPlaceHolder="Select a Category"
                  nzShowSearch
                  [nzDisabled]="viewMode"
                >
                  <nz-option
                    *ngFor="let category of day.categories"
                    [nzLabel]="category.label"
                    [nzValue]="category.value"
                  ></nz-option>
                </nz-select>
              </div>
              <div nz-col nzSpan="8">
                <label class="form-label required">Practice Name</label>
                <nz-select
                  (ngModelChange)="contentChangeFn($event, i)"
                  [(ngModel)]="day.contentId"
                  class="w-[100%] mr-1"
                  nzAllowClear
                  nzPlaceHolder="Select a practiceName"
                  nzShowSearch
                  [nzDisabled]="viewMode"
                >
                  <nz-option
                    *ngFor="let content of day.contents"
                    [nzLabel]="content.label"
                    [nzValue]="content.value"
                  ></nz-option>
                </nz-select>
              </div>
            </div>
            <div
              class="mt-3"
              nzGutter="16"
              nz-row
              nzAlign="middle"
              nzJustify="space-evenly"
            >
              <div nz-col nzSpan="8">
                <label class="form-label required"
                  >Estimated Time (in Mins)</label
                >
                <input
                  [(ngModel)]="day.estimatedTotalTime"
                  class="w-[100%] mr-1"
                  nz-input
                  nzSize="large"
                  type="number"
                  placeholder="Estimated Time"
                  [disabled]="true"
                />
              </div>
              <div nz-col nzSpan="8">
                <label class="form-label">Choose difficulty level</label>
                <nz-select
                  [(ngModel)]="day.difficulty"
                  class="w-[100%] mr-1"
                  nzAllowClear
                  nzPlaceHolder="Select a difficulty"
                  nzShowSearch
                  [nzDisabled]="viewMode"
                >
                  <nz-option
                    *ngFor="let difficulty of difficultys"
                    [nzLabel]="difficulty.label"
                    [nzValue]="difficulty.value"
                  ></nz-option>
                </nz-select>
              </div>
              <div nz-col nzSpan="8">
                <label class="form-label required">Instructor</label>
                <input
                  [(ngModel)]="day.instructors"
                  class="w-[100%] mr-1"
                  nz-input
                  nzSize="large"
                  type="text"
                  placeholder="Instructor"
                  [disabled]="true"
                />
              </div>
            </div>
            <div
              class="mt-3"
              nzGutter="16"
              nz-row
              nzAlign="middle"
              nzJustify="space-evenly"
            >
              <div nz-col nzSpan="12">
                <label class="form-label required">Description</label>
                <textarea
                  [(ngModel)]="day.about"
                  nz-input
                  rows="2"
                  placeholder="Description"
                  [disabled]="true"
                >
Description</textarea
                >
              </div>
              <div nz-col nzSpan="12">
                <div class="flex flex-col gap-2 justify-end items-end">
                  <label class="form-label required">Upload required</label>
                  <div class="flex gap-2">
                    <input
                      type="radio"
                      [value]="true"
                      class="radio radio-sm"
                      name="yes{{ i }}"
                      [(ngModel)]="day.uploadRequired"
                    /><span class="form-label">Yes</span>
                    <input
                      type="radio"
                      [value]="false"
                      class="radio radio-sm"
                      name="no{{ i }}"
                      [(ngModel)]="day.uploadRequired"
                    />
                    <span class="form-label">No</span>
                  </div>
                </div>
              </div>
            </div>
          </nz-card>
          <ng-template #deleteRoutineTemplate>
            <span
              (click)="deleteRoutine(i)"
              nz-icon
              nzTheme="outline"
              nzType="delete"
              class="cursor-pointer"
            ></span>
          </ng-template>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col class="pr-4">
          <button
            nz-button
            nzType="default"
            *ngIf="!viewMode"
            [routerLink]="['/routine']"
          >
            Cancel
          </button>
        </div>
        <div nz-col>
          <button
            (click)="updateRoutine()"
            *ngIf="!viewMode"
            nz-button
            nzType="primary"
          >
            Update Routine
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
