import { Injectable } from '@angular/core';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { AddRolesAndPermissionRequest } from '../models/addRolesAndPermissionRequest';
import { AddNewAdminUserRequest } from '../models/addNewAdminUserRequest';

interface User extends IAnyoUser {
  firebaseDetails: {
    roles: string[];
    additionalPermissions: string[];
  };
}

interface RolesAndPermission {
  roles: string[];
  permission: string[];
}

export interface EnableDisableUserInAllCollRequest {
  consoleUserEnableDisableFlag: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RolesPermissionService {
  constructor(private api: NetworkUtilsService) {}

  public listData() {
    return this.api.get<User[]>(URL_DICT.adminRolesAndPermissionTableUrl);
  }

  public rolesAndPermission() {
    return this.api.get<RolesAndPermission>(
      URL_DICT.adminRolesAndPermissionsUrl,
    );
  }

  public addRolesAndPermission(request: AddRolesAndPermissionRequest) {
    return this.api.post(URL_DICT.adminAddRolesAndPermissionsUrl, request);
  }

  public createAdmin(request: AddNewAdminUserRequest) {
    return this.api.post(URL_DICT.adminUserCreateUrl, request);
  }

  public enableDisableCommonFlagForUser(
    request: EnableDisableUserInAllCollRequest,
    userId: string,
  ) {
    return this.api.post(
      URL_DICT.adminEnableDisableUserInAllCollectionUrl + `/${userId}`,
      request,
    );
  }
}
