<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div
    class="flex flex-wrap w-1/4 justify-start"
    *ngIf="!viewForm && !editMode"
  >
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/listener']"
    />
    <h1 class="headeradminui-text">{{ "Add Listener" }}</h1>
  </div>
  <div class="flex flex-wrap w-1/4 justify-start" *ngIf="viewForm || editMode">
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/listener']"
    />
    <h1 class="headeradminui-text">{{ listener.name }}</h1>
  </div>
</div>

<div class="custom-card">
  <div class="card-body">
    <form #addListenerForm="ngForm" (ngSubmit)="submit()">
      <div class="flex flex-col flex-wrap md:gap-4 lg:gap-4 sm:gap-2">
        <div class="flex justify-between items-center">
          <div class="title-class w-1/12">
            <label class="form-label required">{{ "Title" }}</label>
            <select
              [ngClass]="
                (isSubmit && listener.title) || !isSubmit
                  ? 'select select-sm title-select'
                  : 'select select-sm title-select input-adminui-error'
              "
              name="title"
              [(ngModel)]="listener.title"
              ngModel
              required
              [disabled]="viewForm"
            >
              <option value="" disabled selected>Title</option>
              <option>Mr</option>
              <option>Ms</option>
              <option>Mrs</option>
            </select>
          </div>
          <div
            *ngIf="viewForm || editMode"
            class="flex flex-row justify-end gap-2"
          >
            <div
              *ngIf="(viewForm || editMode) && !isDisabled"
              class="flex justify-end"
            >
              <button
                type="button"
                class="btn btn-error rounded-md"
                (click)="dialogShow()"
              >
                Disable
              </button>
            </div>
            <div
              *ngIf="(viewForm || editMode) && isDisabled"
              class="flex justify-end"
            >
              <button
                type="button"
                class="btn btn-success"
                (click)="dialogShow()"
              >
                <p *ngIf="onClick">Enable</p>
                <app-loader></app-loader>
              </button>
            </div>
            <div *ngIf="isAlert && !isDisabled">
              <app-alert-box
                [alertHeader]="alertHeaderDisable"
                [alertBody]="alertBodyDisable"
                (closeAlert)="dialogShow()"
                (isDisable)="disableListener()"
              ></app-alert-box>
            </div>
            <div *ngIf="isAlert && isDisabled">
              <app-alert-box
                [alertHeader]="alertHeaderEnable"
                [alertBody]="alertBodyEnable"
                (closeAlert)="dialogShow()"
                (isDisable)="enableListener()"
              ></app-alert-box>
            </div>
            <div
              *ngIf="viewForm"
              class="flex items-center justify-end flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
            >
              <div
                class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
                (click)="editForm()"
              >
                <div>
                  <img
                    class="add-icon"
                    src="../../../../assets/icons/add-icon.svg"
                  />
                </div>
                <div>
                  <span class="">Edit Content</span>
                </div>
              </div>
            </div>
            <div
              *ngIf="editMode"
              class="flex flex-row bg-white justify-between btn btn-discard gap-1"
              [routerLink]="['/listener']"
            >
              Discard
            </div>
          </div>
        </div>

        <div class="divider"></div>

        <div
          class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2"
        >
          <div class="flex-auto">
            <label class="form-label required">{{ "Email ID" }}</label>
            <input
              [ngClass]="
                (isSubmit && listener.email) || !isSubmit
                  ? 'input-adminui small-text'
                  : 'input-adminui small-text input-adminui-error'
              "
              name="email"
              placeholder="Enter your emailID"
              ngModel
              required
              [(ngModel)]="listener.email"
              [disabled]="viewForm"
            />
          </div>
          <div class="flex-auto">
            <label class="form-label required">{{ "Full Name" }}</label>
            <input
              [ngClass]="
                (isSubmit && listener.name) || !isSubmit
                  ? 'input-adminui small-text'
                  : 'input-adminui small-text input-adminui-error'
              "
              name="name"
              placeholder="Enter your fullname"
              ngModel
              required
              [(ngModel)]="listener.name"
              [disabled]="viewForm"
            />
          </div>
          <div class="flex-auto">
            <label class="form-label required">{{ "Mobile Number" }}</label>
            <input
              [ngClass]="
                (isSubmit && listener.mobileNumber) || !isSubmit
                  ? 'input-adminui small-text'
                  : 'input-adminui small-text input-adminui-error'
              "
              name="mobile"
              placeholder="Enter your mobile number"
              ngModel
              required
              [(ngModel)]="listener.mobileNumber"
              [disabled]="viewForm"
            />
          </div>
        </div>

        <div
          class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2"
        >
          <div class="flex-auto w-1/12">
            <label class="form-label required">{{ "Languages Known" }}</label>
            <select2
              name="languages"
              [value]="listener.languages"
              [data]="language"
              multiple="true"
              (update)="updateLanguage($event)"
              [disabled]="viewForm"
            >
            </select2>
          </div>
          <div class="flex-auto">
            <label class="form-label required">{{ "Avatar Name" }}</label>
            <select
              [ngClass]="
                (isSubmit && listener.avtaarName) || !isSubmit
                  ? 'select select-md title-select fullwidth'
                  : 'select select-md title-select fullwidth input-adminui-error'
              "
              name="avtaarname"
              [(ngModel)]="listener.avtaarName"
              ngModel
              required
              [disabled]="viewForm"
            >
              <option value="" disabled selected>Avtaar Name</option>
              <option *ngFor="let option of avatarNames" [value]="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="flex-auto">
            <div
              id="chooseAvatar"
              [ngClass]="
                (isSubmit && listener.avtaar) || !isSubmit
                  ? 'flex p-2 items-center border-solid rounded-md upload-pic cursor-pointer'
                  : 'flex p-2 items-center border-solid rounded-md upload-pic cursor-pointer input-adminui-error'
              "
              (click)="!viewForm && show()"
            >
              <img
                class="m-2 cursor-pointer"
                src="../../../../assets/icons/{{ listener.avtaar }}.png"
              />
              <div class="flex flex-col items-center">
                <p class="text-sm">Choose your avtaar</p>
                <div class="flex gap-1"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-avatar" id="card" style="display: none">
          <img
            *ngFor="let avatar of avatarImages"
            class="m-2 cursor-pointer"
            src="../../../../assets/icons/{{ avatar.label }}.png"
            (click)="uploadAvatar(avatar.value, avatar.label)"
          />
        </div>
        <div class="divider"></div>

        <label class="form-label">{{ "Other Details" }}</label>

        <div
          class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2"
        >
          <div class="flex-auto">
            <label class="form-label required">{{ "Date of Birth" }}</label>
            <input
              [ngClass]="
                (isSubmit && listener.dob) || !isSubmit
                  ? 'input-adminui small-text'
                  : 'input-adminui small-text input-adminui-error'
              "
              type="date"
              name="dob"
              [(ngModel)]="listener.dob"
              ngModel
              required
              [disabled]="viewForm"
            />
          </div>
          <div class="flex-auto w-1/2">
            <label class="form-label required">{{
              "Areas of Specialization"
            }}</label>
            <select2
              name="specialization"
              [value]="listener.specialization"
              [data]="specialization"
              multiple="true"
              (update)="updateSpecialization($event)"
              [disabled]="viewForm"
            >
            </select2>
          </div>
        </div>

        <div
          class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2"
        >
          <div class="flex-auto">
            <label class="form-label required">{{ "About" }}</label>
            <textarea
              [ngClass]="
                (isSubmit && listener.about) || !isSubmit
                  ? 'textarea'
                  : 'textarea input-adminui-error'
              "
              name="about"
              placeholder="Enter short description here"
              [(ngModel)]="listener.about"
              ngModel
              required
              [disabled]="viewForm"
              maxlength="400"
            ></textarea>
          </div>
          <div *ngIf="!viewForm && !viewMode">
            <label class="form-label required">Add Expert Details</label>
            <div nz-col>
              <button
                nz-button
                nzType="primary"
                (click)="displayExpertDetailsContainer()"
              >
                Add Expert Details
              </button>
            </div>
          </div>
        </div>
        <nz-divider nzText="Circle Expert Details"></nz-divider>
        <div nzGutter="16" nz-row *ngIf="viewMode">
          <div nz-col nzSpan="6">
            <label class="form-label required">{{ "Qualifications" }}</label>
            <input
              [(ngModel)]="qualification"
              nz-input
              rows="3"
              placeholder="enter qualifications separated by comma"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="viewForm"
            />
          </div>
          <div nz-col nzSpan="12">
            <label class="form-label required">Description</label>
            <textarea
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="expertDescription"
              nz-input
              rows="3"
              placeholder="Description"
              [disabled]="viewForm"
            ></textarea>
          </div>
        </div>
        <div class="min-w-full" *ngIf="viewMode">
          <nz-select
            class="min-w-full"
            nzMode="multiple"
            nzPlaceHolder="User Interest Tags"
            [(ngModel)]="userInterestTags"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="userInterestTagsChangeFn($event)"
          >
            <nz-option
              *ngFor="let item of userInterestTopics"
              [nzValue]="item.key"
              [nzLabel]="item.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-row *ngIf="viewMode">
          <div nz-col nzSpan="6">
            <span><b>Upload Expert Profile Pic</b></span>
            <img class="pr-4" [src]="avatarImage" />
            <br />
            <input
              (change)="uploadExpertProfilePic($event)"
              accept=".jpg,.jpeg,.png"
              type="file"
            />
          </div>
          <div nz-col nzSpan="6">
            <label class="form-label required">Expert Is Active</label>
            <nz-switch
              (ngModelChange)="changeActive($event)"
              [(ngModel)]="isActive"
              [ngModelOptions]="{ standalone: true }"
              [nzDisabled]="viewForm"
            ></nz-switch>
          </div>
        </div>

        <div class="flex justify-between mt-8">
          <div class="flex justify-end w-full">
            <button
              type="button"
              class="btn btn-primary btn-block-adminui enable-button"
              [routerLink]="['/listener']"
            >
              {{ "Cancel" }}
            </button>
            <button
              *ngIf="!viewForm && !editMode"
              class="btn btn-primary btn-bigger"
            >
              <p *ngIf="onClick">{{ "Save Listener" }}</p>
              <app-loader></app-loader>
            </button>
            <button *ngIf="editMode" class="btn btn-primary btn-bigger">
              <p *ngIf="onClick">{{ "Edit Listener" }}</p>
              <app-loader></app-loader>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
