import { IAnyoError } from './errorResponse';

export abstract class AnyoException extends Error {
  errorBody: string;

  constructor(errorBody: string) {
    super();
    this.errorBody = errorBody;
    this.message = errorBody;
  }
  abstract getSummaryMessage(): string;
  getStatusCode() {
    return 500;
  }

  toErrorResponse(): IAnyoError {
    return {
      statusCode: this.getStatusCode(),
      message: this.getSummaryMessage(),
      description: this.errorBody,
    };
  }
}
