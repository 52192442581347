import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { AddNewCirclesRequest } from '../models/circles/newAddAnyoCircles';
import { NewCircleModeratorRequest } from '../models/circles/newModeratorRequest';
import { CircleMasterDataResponse } from '../models/circles/circleMasterDataResponse';
import { CircleGridRequest } from '../models/circles/circleGridRequest';
import { CirclesGridResponse } from '../models/circles/circleGridResponse';
import { AnyoCirclesDetails } from '../models/circles/anyoCirclesDetails';
import { UpdateCircleModeratorRequest } from '../models/circles/updateModeratorRequest';
import { UpdateCircleExpertRequest } from '../models/circles/updateExpertRequest';
import { ModeratorGridRequest } from '../models/circles/moderatorGridRequest';
import { ModeratorGridResponse } from '../models/circles/moderatorGridResponse';
import { UpdateCirclesRequest } from '../models/circles/updateCircleRequest';
import { CircleModerators } from '../models/circles/CircleModerators';
import { lastValueFrom } from 'rxjs';
import {
  UserInteractionTemplateResponse,
  UserPsychologySurveyResponse,
} from '../models/UserInteractionTemplateResponse';
import { CircleTopics } from '../models/circles/AnyoCircles';
import { MasterdataService } from './masterdata.service';

@Injectable({
  providedIn: 'root',
})
export class CirclesService {
  constructor(
    private api: NetworkUtilsService,
    private masterData: MasterdataService,
  ) {}

  public createCircleModerator(request: NewCircleModeratorRequest) {
    return this.api.post(URL_DICT.adminCircles + 'moderators', request);
  }

  public getCircleMasterData() {
    return this.api.get<CircleMasterDataResponse>(
      URL_DICT.adminCircles + 'master-data',
    );
  }

  public createCircle(request: AddNewCirclesRequest) {
    return this.api.post(URL_DICT.adminCircles + 'create', request);
  }

  public updateCircle(circeId: string, request: UpdateCirclesRequest) {
    return this.api.post(URL_DICT.adminCircles + `update/${circeId}`, request);
  }

  public circlesGrid(request: CircleGridRequest) {
    return this.api.post<CirclesGridResponse>(
      URL_DICT.adminCircles + 'grid',
      request,
    );
  }

  public moderatorsGrid(request: ModeratorGridRequest) {
    return this.api.post<ModeratorGridResponse>(
      URL_DICT.adminCircles + 'moderators/grid',
      request,
    );
  }

  public getCircle(id: string) {
    return this.api.get<AnyoCirclesDetails>(URL_DICT.adminCircles + `${id}`);
  }

  public updateModerator(request: UpdateCircleModeratorRequest, id: string) {
    return this.api.post(
      URL_DICT.adminCircles + `moderators/update/${id}`,
      request,
    );
  }

  public updateExpert(request: UpdateCircleExpertRequest, id: string) {
    return this.api.post(
      URL_DICT.adminCircles + `experts/update/${id}`,
      request,
    );
  }

  public getModerator(id: string) {
    return this.api.get<CircleModerators>(
      URL_DICT.adminCircles + `moderators/${id}`,
    );
  }
  public async getUserInterestsMeta() {
    const masterDataResp = await lastValueFrom(
      this.masterData.masterDataList(),
    );
    const userInterestsArray: CircleTopics[] = [];
    for (let i = 0; i < masterDataResp.userInterestTags.length; i++) {
      const interest = masterDataResp.userInterestTags[i];
      userInterestsArray.push({
        key: interest.key,
        value: interest.value,
        imageUrl: interest.imageUrl,
      });
    }
    return userInterestsArray;
  }
}
