import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import IVSBroadcastClient, {
  AmazonIVSBroadcastClient,
  StreamConfig,
} from 'amazon-ivs-web-broadcast';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 } from 'uuid';
import * as moment from 'moment';
import Hls from 'hls.js';
import { Timestamp } from 'firebase/compat/firestore/dist/firestore';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AnyoComponent } from '../../../models/AnyoComponent';
import { AnyoTalk } from 'projects/app-core/src/app/talks/models/AnyoTalk';
import { TalkLogFirestore } from 'projects/app-core/src/app/talks/models/TalkLogFirestore';
import { SessionMessage } from 'projects/app-core/src/app/talks/models/SessionMessage';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { TalksService } from 'projects/app-core/src/app/talks/services/talks.service';
import { ToastService } from '../../../services/toastr.service';
import { NetworkUtilsService } from 'projects/app-core/src/service/network-utils.service';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { IAnyoError } from '../../../models/error/errorResponse';

export interface PlaybackDetails {
  playbackUrl: string;
}
@Component({
  selector: 'app-low-latency-streaming-live-component',
  templateUrl: './low-latency-streaming-live-component.component.html',
  styleUrl: './low-latency-streaming-live-component.component.scss',
})
export class LowLatencyStreamingLiveComponentComponent extends AnyoComponent {
  @ViewChild('localVideoLandscape')
  localVideoElementLandscape!: ElementRef<HTMLVideoElement>;
  @ViewChild('localVideoPortrait')
  localVideoElementPortrait!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasLandscape') canvasLandscape!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasPortrait') canvasPortrait!: ElementRef<HTMLCanvasElement>;
  MESSAGE_TIME_FORMAT = 'hh:mm a';
  localVideoElement: ElementRef<HTMLVideoElement> | undefined;
  talkName: string | undefined;
  broadcastClient: AmazonIVSBroadcastClient | undefined;
  enableControls: boolean = false;
  talkId: string | undefined;
  talk: AnyoTalk | undefined;
  broadcastUrl: string | undefined;
  layoutConfig: StreamConfig | undefined;
  localCameraStream: MediaStream | undefined;
  localCameraTrack: MediaStreamTrack | undefined;
  localMicTrack: MediaStreamTrack | undefined;
  localMicStream: MediaStream | undefined;
  cameraName: string | undefined;
  micName: string | undefined;
  streamKey: string | undefined;
  broadcastLive: boolean = false;
  audioOnly: boolean = false;
  enableComments: boolean = true;
  permissions: {
    audio: boolean;
    video: boolean;
  } = {
    audio: false,
    video: false,
  };
  cameraToggle: boolean = false;
  micToggle: boolean = false;
  selectedLayout: string = 'portrait';
  layoutConfigDisabled: boolean = false;
  showVideComponent: boolean = false;
  isGoingLive: boolean = false;
  broadcastSessionId: string | undefined;
  broadcastSession: TalkLogFirestore | undefined;
  messages: SessionMessage[] = [];
  messageListener: boolean = false;
  watchOnly: boolean = false;
  cameraInputs: MediaDeviceInfo[] = [];
  audioInputs: MediaDeviceInfo[] = [];
  todayTopic: string | undefined;

  constructor(
    private modal: NzModalService,
    private router: Router,
    private elRef: ElementRef,
    protected override auth: AuthService,
    private talksService: TalksService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private api: NetworkUtilsService,
  ) {
    super(auth);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    // Your logic to decide whether to show the alert or not
    // For example, check if the user has unsaved changes
    const shouldShowAlert = this.broadcastLive; // This condition should be replaced with your actual logic
    if (shouldShowAlert) {
      $event.returnValue = 'You are live! Do you want to stop live?'; // Legacy way for cross-browser support
    }
  }
  message: string | undefined;
  sendingMessage: boolean = false;
  selectedCameraInput: string | undefined;
  selectedAudioInput: string | undefined;
  notificationTitle: string | undefined;
  notificationBody: string | undefined;
  todayTalkDescription: string | undefined;

  override async ready(user?: IAnyoUser): Promise<void> {
    this.enableControls = false;
    this.isGoingLive = true;
    await this.handlePermissions();
    if (!this.permissions.audio) {
      this.toastService.showAnyoErrorToast(
        'At least Mic Permissions are required',
      );
      return;
    }
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        switch (device.kind) {
          case 'audioinput':
            this.audioInputs.push(device);
            break;
          case 'videoinput':
            this.cameraInputs.push(device);
            break;
        }
      }
      this.selectedAudioInput = this.audioInputs[0].deviceId;
      this.selectedCameraInput = this.cameraInputs[0].deviceId;
    });
    this.isGoingLive = false;
    this.pageLoading = true;
    this.enableControls = false;
    if (user) {
      try {
        this.user = user;
        const id = this.activatedRoute.snapshot.params['id'];
        this.activatedRoute.queryParams.subscribe((value) => {
          if (value['watchOnly'] && value['watchOnly'] == 'true') {
            this.watchOnly = true;
          }
        });
        if (!id) {
          this.toastService.showAnyoErrorToast('Invalid talk id passed');
          return;
        }
        this.talkId = id;
        this.talksService.getTalkDetailsById(id).subscribe({
          next: (value) => {
            this.talk = value;
            if (this.talk && this.talk.notificationDetails) {
              this.notificationBody =
                this.talk.notificationDetails.body ||
                this.talk.notificationDetails.defaultBody;
              this.notificationTitle =
                this.talk.notificationDetails.title ||
                this.talk.notificationDetails.defaultTitle;
            }
          },
          error: (error) => {
            const errorBody = error.errorBody as unknown as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
          },
        });
        const res = await lastValueFrom(
          this.talksService.getBroadcastDetails(id),
        );
        this.broadcastUrl = res.singleHost.ingestEndpoint;
        this.streamKey = res.singleHost.streamKey;
        this.talkName = res.title;
      } catch (e) {
        this.pageLoading = false;
        this.enableControls = false;
        this.toastService.showAnyoErrorToast(
          'Unable to fetch broadcast details',
        );
        return;
      }
      this.talksService
        .getLiveSessionListener(this.talkId!)
        .subscribe(async (snapShot) => {
          for (let i = 0; i < snapShot.length; i++) {
            const event = snapShot[i].type;
            switch (event) {
              case 'added':
                this.broadcastSessionId = snapShot[i].payload.doc.id;
                this.broadcastSession = snapShot[
                  i
                ].payload.doc.data() as unknown as TalkLogFirestore;
                this.broadcastSession.participantDetails = [];
                if (!this.watchOnly) {
                  await this.talksService.updateHostDetailToSession(
                    user.userId!,
                    this.broadcastSessionId,
                    this.cameraToggle,
                    this.micToggle,
                    this.audioOnly,
                    this.selectedLayout,
                    true,
                    this.enableComments,
                    this.todayTopic!,
                    this.todayTalkDescription!,
                  );
                  this.toastService.showSuccess('You are live');
                }
                this.messages = [];
                this.broadcastLive = true;
                this.isGoingLive = false;

                if (this.watchOnly) {
                  this.talksService
                    .addParticipant(this.broadcastSession.talkId)
                    .subscribe({
                      next: (value) => {
                        const resp = value as unknown as PlaybackDetails;
                        if (resp && resp.playbackUrl) {
                          const video = document.getElementById(
                            'hlsPlayer',
                          ) as HTMLVideoElement;
                          const hls = new Hls();
                          hls.loadSource(resp.playbackUrl);
                          hls.attachMedia(video);
                          hls.on(Hls.Events.MANIFEST_PARSED, function () {
                            video.play();
                          });
                        }
                      },
                      error: (error) => {
                        const errorBody = error.error as IAnyoError;
                        this.toastService.showAnyoErrorToast(
                          errorBody.description,
                        );
                      },
                    });
                }
                break;
              case 'modified':
                this.broadcastSession = snapShot[
                  i
                ].payload.doc.data() as unknown as TalkLogFirestore;
                if (!this.messageListener) {
                  this.attachMessageListener();
                }
                break;
              case 'removed':
                if (
                  this.broadcastSession &&
                  !this.broadcastSession.startStreaming &&
                  this.watchOnly
                ) {
                  this.modal.confirm({
                    nzTitle: 'Session over',
                    nzContent:
                      'Session is over do you want to redirect to home page.',
                    nzOnOk: () => this.router.navigate(['/talks/']),
                  });
                }
                break;
            }
          }
        });
      this.layoutSelectFn();
      this.pageLoading = false;
    }
  }

  async handlePermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      for (const track of stream.getTracks()) {
        track.stop();
      }
      this.permissions = { video: true, audio: true };
    } catch (e) {
      this.toastService.showAnyoErrorToast(e);
    }
  }

  async toggleLive() {
    this.broadcastLive ? await this.stopLive() : await this.startLive();
  }

  stopCamera() {
    if (this.localCameraStream) {
      this.localCameraStream?.getTracks().forEach((track) => track.stop());
      this.showVideComponent = false;
    }
  }

  stopMic() {
    if (this.localMicStream) {
      this.localMicStream?.getTracks().forEach((track) => track.stop());
    }
  }

  async removeCameraFromBroadcast() {
    try {
      const stream = this.broadcastClient?.getVideoInputDevice(
        this.cameraName!,
      )?.source;
      stream!.getVideoTracks().forEach((track) => {
        track.stop();
      });
    } catch (e) {
      this.toastService.showAnyoErrorToast(
        'Error while stopping camera stream but local camera will be stopped',
      );
    } finally {
      this.stopCamera();
      this.cameraToggle = false;
    }
  }
  async toggleCamera() {
    if (this.cameraToggle) {
      await this.removeCameraFromBroadcast();
      await this.addDummyStream();
    } else {
      await this.addCameraToBroadcast();
    }
    await this.talksService.updateCameraState(
      this.cameraToggle,
      this.broadcastSessionId!,
      this.user!.userId!,
    );
    this.isGoingLive = false;
  }

  async removeMicFromBroadcast() {
    try {
      //remove camera stream
      const stream = this.broadcastClient?.getVideoInputDevice(
        this.cameraName!,
      )?.source;
      stream!.getAudioTracks().forEach((track) => {
        track.stop();
      });
    } catch (e) {
      this.toastService.showAnyoErrorToast(
        'Error while stopping mic  but local mic will be stopped',
      );
    } finally {
      this.stopMic();
      this.micToggle = false;
    }
  }

  async toggleMic() {
    this.isGoingLive = true;
    if (this.micToggle) {
      await this.removeMicFromBroadcast();
    } else {
      await this.addMicToBroadCast();
    }
    await this.talksService.updateMicState(
      this.micToggle,
      this.broadcastSessionId!,
      this.user!.userId!,
    );
    this.isGoingLive = false;
  }

  async stopLive() {
    try {
      this.isGoingLive = true;
      await this.broadcastClient?.stopBroadcast();
      this.broadcastLive = false;
      this.toastService.showSuccess('Live stopped');
      this.layoutConfigDisabled = false;
      this.broadcastSessionId = undefined;
      this.broadcastSession = undefined;
    } catch (e) {
      this.toastService.showAnyoErrorToast('Error while stopping live' + e);
    } finally {
      this.stopCamera();
      this.stopMic();
      this.isGoingLive = false;
      window.open('', localStorage.getItem('liveWindowId') || '')?.close();
    }
  }

  async addCameraToBroadcast() {
    if (!this.permissions.video) {
      this.toastService.showAnyoErrorToast('Please enable camera permission');
      return;
    }
    this.localCameraStream = await this.getCamera();
    this.localVideoElement!.nativeElement.srcObject = new MediaStream();
    this.localCameraTrack = this.localCameraStream!.getVideoTracks()[0];
    this.localVideoElement!.nativeElement.srcObject.addTrack(
      this.localCameraTrack,
    );
    this.cameraName = 'camera' + v4();
    await this.broadcastClient?.addVideoInputDevice(
      this.localCameraStream!,
      this.cameraName,
      { index: 0 },
    );
    this.layoutConfigDisabled = true;
    this.cameraToggle = true;
    this.showVideComponent = true;
  }

  async addMicToBroadCast() {
    await this.handlePermissions();
    if (!this.permissions.video) {
      this.toastService.showAnyoErrorToast('Please enable mic permission');
      return;
    }
    this.localMicStream = await this.getMic();
    this.localMicTrack = this.localMicStream!.getAudioTracks()[0];
    this.micName = 'mic' + v4();
    this.broadcastClient?.addAudioInputDevice(
      this.localMicStream!,
      this.micName,
    );
    this.micToggle = true;
  }

  async addDummyStream() {
    const canvas = this.canvasPortrait.nativeElement;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = async () => {
      ctx!.drawImage(image, 0, 0, canvas.width, canvas.height);
      const stream = canvas.captureStream();
      this.localVideoElement!.nativeElement.srcObject = stream;
      this.showVideComponent = true;
      this.cameraName = 'camera' + v4();
      await this.broadcastClient?.addVideoInputDevice(stream, this.cameraName, {
        index: 0,
      });
    };
    image.src =
      this.selectedLayout === 'portrait'
        ? 'https://cdn.anyo.app/ivs/portrait.jpg'
        : 'https://cdn.anyo.app/ivs/landscape.jpg';
  }
  async startLive() {
    try {
      if (!this.todayTalkDescription && !this.todayTalkDescription) {
        this.toastService.showAnyoErrorToast(
          "Please fill today's topic and description",
        );
        return;
      }
      this.layoutSelectFn();
      if (!this.streamKey || !this.broadcastUrl) {
        this.toastService.showAnyoErrorToast(
          'Error while fetching broadcast details,please contact admin',
        );
        return;
      }
      await this.handlePermissions();
      if (!this.permissions.audio) {
        this.toastService.showAnyoErrorToast(
          'Please enable at-least audio permission to go live',
        );
        return;
      }
      this.broadcastClient = IVSBroadcastClient.create({
        streamConfig: this.layoutConfig,
      });
      if (this.permissions.video && !this.audioOnly) {
        await this.addCameraToBroadcast();
      }
      if (this.notificationBody && this.notificationTitle) {
        try {
          const resp = await lastValueFrom(
            this.talksService.updateTalkNotificationDetails(
              this.talkId!,
              (this.notificationTitle || '').trim(),
              (this.notificationBody || '').trim(),
            ),
          );
        } catch (e) {
          console.log(e);
          const error = e as unknown as Error;
          const errorBody = error as unknown as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
        }
      }
      await this.addMicToBroadCast();
      this.isGoingLive = true;
      await this.broadcastClient?.startBroadcast(
        this.streamKey,
        this.broadcastUrl,
      );
    } catch (e) {
      console.log(e);
      this.isGoingLive = false;
      this.toastService.showAnyoErrorToast('Error while going live' + e);
    }
  }

  layoutSelectFn() {
    this.layoutConfig =
      this.selectedLayout === 'portrait'
        ? IVSBroadcastClient.BASIC_FULL_HD_PORTRAIT
        : IVSBroadcastClient.BASIC_FULL_HD_LANDSCAPE;
    this.localVideoElement =
      this.selectedLayout === 'portrait'
        ? this.localVideoElementPortrait
        : this.localVideoElementLandscape;
  }

  formatDate(date: Date) {
    return moment(date).format('hh:mm a');
  }

  attachMessageListener() {
    if (!this.broadcastSessionId) {
      return;
    }

    this.talksService
      .getLiveSessionMessageListener(this.broadcastSessionId!)
      .subscribe((docs) => {
        this.messages = [];
        this.messageListener = true;

        const messages: SessionMessage[] = [];
        for (let i = 0; i < docs.length; i++) {
          const doc = docs[i];
          const data = doc.payload.doc.data() as SessionMessage;
          switch (doc.type) {
            case 'added':
              const message: SessionMessage = {
                host: data.host,
                message: data.message,
                name: data.name,
                timeStamp: (data.timeStamp as Timestamp).toDate(),
                userId: data.userId,
              };
              messages.push(message);
              break;
          }
        }
        this.messages = messages.sort(
          (a, b) =>
            (b.timeStamp as Date).getTime() - (a.timeStamp as Date).getTime(),
        );
      });
  }

  formatMessageTime(date: Date | Timestamp) {
    return moment(date).format(this.MESSAGE_TIME_FORMAT);
  }

  async addMessage() {
    if (!this.message! || !this.message!.trim()) {
      this.toastService.showAnyoErrorToast('Please enter a message');
      return;
    }
    if (!this.broadcastLive || !this.broadcastSessionId) {
      this.toastService.showError('Session is not live, cannot send message');
      return;
    }
    try {
      this.sendingMessage = true;
      const message: SessionMessage = {
        host: true,
        email: this.user!.email!,
        message: this.message!.trim()!,
        name: this.user!.name!,
        timeStamp: new Date(),
        userId: this.user!.userId!,
      };
      await this.talksService.addMessage(this.broadcastSessionId!, message);
      this.message = '';
    } catch (e) {
      console.log(e);
      this.toastService.showAnyoErrorToast('Error while sending message' + e);
    } finally {
      this.sendingMessage = false;
    }
  }

  async onEnterPress($event: KeyboardEvent) {
    if ($event.key === 'Enter' || $event.keyCode === 13) {
      if (!this.message! || !this.message!.trim()) {
        $event.preventDefault();
        return;
      }
      await this.addMessage();
    }
  }

  getCamera() {
    // Use Max Width and Height
    return navigator.mediaDevices.getUserMedia({
      video: {
        width: {
          ideal: this.layoutConfig!.maxResolution.width,
        },
        height: {
          ideal: this.layoutConfig!.maxResolution.height,
        },
        deviceId: { exact: this.selectedCameraInput! },
      },
      audio: false,
    });
  }

  getMic() {
    return navigator.mediaDevices.getUserMedia({
      video: false,
      audio: {
        deviceId: { exact: this.selectedAudioInput! },
      },
    });
  }

  async audioInputChangeFn() {
    await this.removeMicFromBroadcast();
    await this.addCameraToBroadcast();
  }

  async cameraInputChangeFn() {
    await this.removeCameraFromBroadcast();
    await this.addCameraToBroadcast();
  }
}
