<div class="flex flex-col flex-wrap w-3/5 ml-36 mt-24" *ngIf="!showCreatePass">
  <p class="text-xl font-semibold color heading">OTP Verification</p>
  <label class="label-color mb-2">OTP</label>
  <div class="flex justify-start gap-8">
    <input
      name="otp_1"
      id="otp1"
      (keyup)="focusNext('otp_1', 'otp2')"
      maxlength="1"
      [(ngModel)]="otp[0]"
      class="input-otp w-20 text-center"
      autofocus
    />
    <input
      name="otp_2"
      id="otp2"
      (keyup)="focusNext('otp_2', 'otp3')"
      maxlength="1"
      [(ngModel)]="otp[1]"
      class="input-otp w-20 text-center"
      autofocus
    />
    <input
      name="otp_3"
      id="otp3"
      (keyup)="focusNext('otp_3', 'otp4')"
      maxlength="1"
      [(ngModel)]="otp[2]"
      class="input-otp w-20 text-center"
      autofocus
    />
    <input
      name="otp_4"
      id="otp4"
      (keyup)="focusNext('otp_4', 'verifybtn')"
      maxlength="1"
      [(ngModel)]="otp[3]"
      class="input-otp w-20 text-center"
      autofocus
    />
  </div>
  <div *ngIf="!resendOtp" class="flex justify-center">
    <a class="a cursor-pointer">Haven't received the OTP?</a>
    <span class="timer">00:{{ timeLeft }}</span>
  </div>
  <div *ngIf="resendPin" class="flex justify-center">
    <a (click)="resendOtpFn()" class="a cursor-pointer">Resend OTP</a>
  </div>
  <button
    (click)="verifyOtp()"
    id="verifybtn"
    class="btn btn-primary btn-block-fp"
    [disabled]="!(otp.length == 4)"
  >
    Verify OTP
  </button>
</div>
<div class="flex flex-col flex-wrap w-3/5 ml-36 mt-24" *ngIf="showCreatePass">
  <p class="text-xl font-semibold color heading">Set Password</p>
  <label class="label-color mb-2">Create Password </label>
  <app-partner-input-with-icon
    [placeholder]="placeholder"
    [border]="border"
    [src]="eyeClosedIcon"
    [src_2]="eyeOpenIcon"
    (input)="onKey($event)"
  ></app-partner-input-with-icon>
  <div class="flex justify-end items-end">
    <img
      class="cursor-pointer mt-1"
      src="../../../assets/icons/info.svg"
      (click)="show()"
    />
  </div>
  <div class="ml-28" *ngIf="isShow">
    <app-password-requirements></app-password-requirements>
  </div>
  <label class="label-color mb-2">Confirm Password </label>
  <app-partner-input-with-icon
    [placeholder]="placeholder"
    [border]="border"
    [src]="eyeClosedIcon"
    [src_2]="eyeOpenIcon"
    (input)="validate($event)"
  ></app-partner-input-with-icon>
  <button
    (click)="updatePassword()"
    [disabled]="!password"
    class="btn btn-primary btn-block-fp"
  >
    Set Password
  </button>
</div>
