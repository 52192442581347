import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-input-with-icon-adminui',
  templateUrl: './input-with-icon-adminui.component.html',
  styleUrls: ['./input-with-icon-adminui.component.scss'],
})
export class InputWithIconAdminUiComponent {
  @ViewChild('box') box!: ElementRef;
  @ViewChild('pass') pass!: ElementRef;
  @Input() placeholder!: string;
  @Input() isDisabled!: boolean;
  @Output() input = new EventEmitter<string>();
  @Output() inputString = new EventEmitter<string>();
  @Input() border!: string;
  @Input() isFilled: boolean = true;
  @Input() src!: string;
  @Input() value!: string;
  @Input() src_2!: string;
  inputField: string = '';

  show: boolean = false;

  onKey(event: any) {
    this.input.emit(event);
    this.inputField = event.target.value;
    this.box.nativeElement.classList = 'inputwithicon ' + this.border;
  }

  toggle(event: any) {
    this.inputString.emit(this.inputField);
    // if(this.pass.nativeElement.type == 'password'){
    //   this.pass.nativeElement.type = 'input';
    //   this.show = true;
    // }
    // else{
    //   this.pass.nativeElement.type = 'password'
    //   this.show = false;
    // }
  }
}
