import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CustomHttpUrlEncodingCodec} from 'projects/app-core/src/app/utils/encoder';
import {MemberGridRequest} from '../models/memberGridRequest';
import {MemberGridResponse} from '../models/memberTable';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../app-core/src/service/network-utils.service';
import {MemberGridStats} from '../models/memberGridStatsResponse';
import {IMember} from '../models/IMember';
import {BulkUploadMemberRequest} from '../pages/partner/manage-member/manage-member.component';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  protected basePath = environment.serviceUrl;
  protected exportBasePath = environment.contentServiceUrl;
  constructor(
    protected httpClient: HttpClient,
    protected api: NetworkUtilsService,
  ) {}

  public memberCount(partnerId: string) {
    return this.api.post<MemberGridStats>(URL_DICT.memberGridStats + partnerId);
  }

  public memberList(
    gridRequest: MemberGridRequest,
    partnerId: string,
  ): Observable<MemberGridResponse> {
    return this.api.post<MemberGridResponse>(
      URL_DICT.memberGrid + partnerId,
      gridRequest,
    );
  }

  public createMember(createMemberRequest: IMember) {
    return this.api.post<IMember>(
      URL_DICT.member + 'create',
      createMemberRequest,
    );
  }

  public getMember(memberId: string) {
    return this.api.get<IMember>(URL_DICT.member + `${String(memberId)}`);
  }

  public updateMember(memberId: string, updateMemberRequest: IMember) {
    return this.api.post(
      URL_DICT.member + `update/${String(memberId)}`,
      updateMemberRequest,
    );
  }

  public deleteMember(memberId: String) {
    return this.api.post(URL_DICT.member + `delete/${String(memberId)}`);
  }

  public bulkDeleteMember(body: Object): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    return this.httpClient.request<Object>(
      'delete',
      `${this.basePath}/members/`,
      {
        headers: {
          Authorization: 'Bearer 64e5d35828a4251948fbe3e1',
        },
        body: body,
        params: queryParameters,
      },
    );
  }

  public bulkSendMemberPass(ids: string[]) {
    return this.api.post<any>(URL_DICT.adminBulkSendCorporateCode, {
      memberPassIds: ids,
    });
  }

  public bulkUploadRequest(
    payload: BulkUploadMemberRequest[],
    partnerId: string,
  ) {
    return this.api.post(URL_DICT.adminBulkUploadMembers + partnerId, payload);
  }
}
