<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/bytes']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text pl-2">Add Bytes</h1>
  </div>
</div>
<nz-spin [nzSpinning]="bytesSpinner" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div [nzGutter]="16" nz-row nzAlign="middle">
        <div nz-col nzSpan="6">
          <label class="form-label required">Bytes Category</label>
          <nz-select
            [(ngModel)]="selectedBytesCategoryString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Category"
            nzShowSearch
          >
            <nz-option
              *ngFor="let category of bytesCategories"
              [nzLabel]="category.label"
              [nzValue]="category.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Title</label>
          <input
            class="w-[100%] mr-1"
            [(ngModel)]="titleString"
            nz-input
            nzSize="large"
            placeholder="Enter Description"
          />
        </div>
        <div nz-col nzSpan="4">
          <label class="form-label required">No of Frames</label>
          <input
            [(ngModel)]="noOfFrames"
            (keyup)="onKeyUp($event)"
            class="w-[100%] mr-1"
            nz-input
            nzSize="large"
            type="number"
            placeholder="No of Frames"
          />
        </div>
        <div nz-col nzSpan="4">
          <label class="form-label required">isActive</label>
          <nz-switch [(ngModel)]="isActive"></nz-switch>
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nzAlign="middle" nz-row>
        <div class="mt-5" nz-col nzSpan="6">
          <label class="form-label required">Start Date and Time</label>
          <nz-date-picker
            class="w-[100%]"
            (ngModelChange)="onStartDateChange($event)"
            [(ngModel)]="selectedStartDate"
            nzSize="large"
          ></nz-date-picker>
          <nz-time-picker
            class="w-[100%]"
            [(ngModel)]="selectedStartTime"
            nzFormat="HH:mm"
            nzPlaceHolder="Select Start time"
            nzSize="large"
          ></nz-time-picker>
        </div>
        <div class="mt-5" nz-col nzSpan="6">
          <label class="form-label required">End Date and Time</label>
          <nz-date-picker
            class="w-[100%]"
            [(ngModel)]="selectedEndDate"
            nzSize="large"
          ></nz-date-picker>
          <nz-time-picker
            class="w-[100%]"
            [(ngModel)]="selectedEndTime"
            nzFormat="HH:mm"
            nzPlaceHolder="Select End time"
            nzSize="large"
          ></nz-time-picker>
        </div>
      </div>
      <nz-divider nzText="Frames"></nz-divider>
      <nz-table
        #frameTable
        nzBordered
        [nzData]="frames"
        nzTableLayout="fixed"
        [nzPageSize]="5"
      >
        <thead>
          <tr>
            <th nzWidth="30%">Text</th>
            <th nzWidth="15%">Text Color</th>
            <th nzWidth="18%">Text Background Color</th>
            <th nzWidth="18%">Show Time Seconds</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of frameTable.data">
            <ng-container *ngIf="!editCache[data.id].edit; else editTemplate">
              <td>{{ data.value }}</td>
              <td>
                {{ data.textColor }}
              </td>
              <td>
                {{ data.textBGColor }}
              </td>
              <td>
                {{ data.showTimeSeconds }}
              </td>
              <td>
                <span nz-tooltip nzTooltipTitle="Edit">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="startEdit(data.id)"
                  >
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                  </button>
                </span>
              </td>
            </ng-container>
            <ng-template #editTemplate>
              <td>
                <input
                  type="text"
                  nz-input
                  [(ngModel)]="editCache[data.id].data.value"
                />
              </td>
              <td>
                <input
                  type="color"
                  nz-input
                  [(ngModel)]="editCache[data.id].data.textColor"
                />
              </td>
              <td>
                <input
                  type="color"
                  nz-input
                  [(ngModel)]="editCache[data.id].data.textBGColor"
                />
              </td>
              <td>
                <input
                  type="number"
                  nz-input
                  [(ngModel)]="editCache[data.id].data.showTimeSeconds"
                />
              </td>
              <td>
                <span nz-tooltip nzTooltipTitle="Save">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="saveEdit(data.id)"
                  >
                    <span
                      nz-icon
                      nzTheme="outline"
                      [nzType]="'check-circle'"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#52c41a'"
                    ></span>
                  </button>
                </span>
                <span nz-tooltip nzTooltipTitle="Cancel">
                  <button
                    nz-button
                    class="save"
                    nzShape="circle"
                    (click)="cancelEdit(data.id)"
                  >
                    <span nz-icon nzType="left-circle" nzTheme="outline"></span>
                  </button>
                </span>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </nz-table>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button (click)="saveBytes()" nz-button nzType="primary">
            Save Bytes
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
