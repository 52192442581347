import {Component} from '@angular/core';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import {AuthService} from '../../../../../app-core/src/auth/auth.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css'],
})
export class AdminHomeComponent {
  constructor(private auth: AuthService) {
  }

  ngOnInit() {
    const sdk = new ChartsEmbedSDK({
      getUserToken: async () => {
        const token = this.auth.getToken();
        if (token) {
          return token as unknown as string;
        }
        return '';
      },
      baseUrl: 'https://charts.mongodb.com/charts-anyo-product-midov',
    });
    const dashboard = sdk.createDashboard({
      dashboardId: 'f0188eef-02d4-4dc0-883b-181aff127b68',
    });
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        dashboard
          .render(document.getElementById('adminDashboard')!)
          .catch((e) => {
            console.log(e);
          });
      }
    });
  }
}
