import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import {
  CirclePostsAdminResponse,
  CirclesPostsGridResponse,
  PostsGridFilterData,
} from '../../model/postsGridResponse';
import { CirclePostsGridRequest, PostTab } from '../../model/postsGridRequest';
import { PostsService } from '../../service/posts.service';
import { ToastService } from '../../../services/toastr.service';
import * as moment from 'moment';
import { CirclePostReportsGridRequest } from '../../model/circlePostReportsGridRequest';
import {
  CirclePostReportsResponseData,
  CirclesPostReportsGridResponse,
} from '../../model/circlePostReportsGridResponse';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { MediaType } from '../../model/createPostRequestAdmin';
import { ModeratorActionsRequest } from '../../model/moderatorActionsRequest';
import { MasterdataService } from 'projects/admin/src/app/services/masterdata.service';
import { ModeratorActions } from '../../moderatorAction';
import { lastValueFrom } from 'rxjs';

interface CheckboxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-post-table',
  templateUrl: './post-table.component.html',
  styleUrl: './post-table.component.scss',
})
export class PostTableComponent {
  @Input() postTableData: CirclesPostsGridResponse | undefined;
  @Input() tableTitle: string = '';
  @Input() allowedActions: ModeratorActions[] = [];
  postsTableQuery: CirclePostsGridRequest | undefined;
  postCommentTitle = '';
  tableData: CirclesPostsGridResponse = {
    data: [],
    filterOptions: {
      circleTopics: [],
      tags: [],
    },
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };

  postSpinner: boolean = false;
  postTableTotalCount: number = 0;
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  postTableCurrentPageNo = 1;
  globalSearchInput = '';
  placement: NzDrawerPlacement = 'left';
  @Output() resetFilterEvent = new EventEmitter<void>();
  @Output() pageSizeChangeEvent = new EventEmitter<number>();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() listPostsEvent = new EventEmitter<void>();
  @Output() postsFilterEvent = new EventEmitter<string[]>();
  @Output() subTopicsFilterEvent = new EventEmitter<string[]>();
  @Output() tagsFilterEvent = new EventEmitter<string[]>();
  @Output() titleSettingEvent = new EventEmitter<string>();
  @Output() lastCommentDateSortEvent = new EventEmitter<string | null>();
  @Output() lastReactionDateSortEvent = new EventEmitter<string | null>();
  postsObj: CirclePostsAdminResponse | undefined;
  protected readonly MediaType = MediaType;
  @Input() selectedTab: PostTab = PostTab.MODERATION_REQUIRED;
  subTopicsCheckBoxOptions: CheckboxOptions[] = [];
  tagsCheckBoxOptions: CheckboxOptions[] = [];
  selectedSubTopicsString: string[] = [];
  selectedtagsString: string[] = [];
  problems: { label: string; value: string }[] = [];
  randomTagColors: string[] = [];

  async performAction(
    action: ModeratorActions,
    data: CirclePostsAdminResponse,
  ) {
    switch (action) {
      case ModeratorActions.VIEW_REPORT:
        await this.showReport(data);
        break;
      case ModeratorActions.VIEW_MEDIA:
        this.showViewMedia(data);
        break;
      case ModeratorActions.VIEW_COMMENTS:
        this.showViewComments(data);
        break;
      case ModeratorActions.VIEW_USER_PAST_POST:
        this.showUserPastPosts(data);
        break;
      case ModeratorActions.BLOCK_POST:
        this.postCommentTitle = 'Block Post';
        this.placeholder = 'Enter Block Post Reason';
        this.showModalBlockPost(data);
        break;
      case ModeratorActions.UNBLOCK_POST:
        this.postCommentTitle = 'UnBlock Post';
        this.placeholder = 'Enter UnBlock Post Reason';
        this.showModalBlockPost(data);
        break;
      case ModeratorActions.STAR_POST:
        this.postData = data;
        this.handleOkStarredUnStarredPost();
        break;
      case ModeratorActions.UNSTAR_POST:
        this.postData = data;
        this.handleOkStarredUnStarredPost();
        break;
      case ModeratorActions.FLAG_POST:
        this.postData = data;
        this.handleOkFlagUnFlagPost();
        break;
      case ModeratorActions.UNFLAG_POST:
        this.postData = data;
        this.handleOkFlagUnFlagPost();
        break;
      case ModeratorActions.BLOCK_USER:
        this.showModalBlockUser(data);
        break;
      case ModeratorActions.UNBLOCK_USER:
        this.showModalBlockUser(data);
        break;
      case ModeratorActions.BLOCK_COMMENTS:
        this.postCommentTitle = 'Post Comment';
        this.placeholder = 'Enter Block Comment Reason';
        this.showModalBlockPost(data);
        break;
      case ModeratorActions.UNBLOCK_COMMENTS:
        this.postCommentTitle = 'UnBlock Comment';
        this.placeholder = 'Enter UnBlock Comment Reason';
        this.showModalBlockPost(data);
        break;
      case ModeratorActions.COPY_POST_ID:
        this.copyToClipboard(data.id);
        break;
      case ModeratorActions.MODERATION_EVENTS:
        this.navigateModerationTable(data.id);
        break;
      case ModeratorActions.MODERATION_REQUIRED:
        this.postData = data;
        this.handleOkModerationRequiredPost();
        break;
      default:
        break;
    }
  }

  displayActions(action: ModeratorActions, data: CirclePostsAdminResponse) {
    switch (action) {
      case ModeratorActions.VIEW_MEDIA:
        return true;
      case ModeratorActions.VIEW_REPORT:
        return true;
      case ModeratorActions.VIEW_COMMENTS:
        return true;
      case ModeratorActions.VIEW_USER_PAST_POST:
        return true;
      case ModeratorActions.MODERATION_EVENTS:
        return true;
      case ModeratorActions.MODERATION_REQUIRED:
        return data.moderationRequired ? true : false;
      case ModeratorActions.BLOCK_POST:
        return data.visible ? true : false;
      case ModeratorActions.UNBLOCK_POST:
        return data.visible ? false : true;
      case ModeratorActions.STAR_POST:
        return data.starred ? false : true;
      case ModeratorActions.UNSTAR_POST:
        return data.starred ? true : false;
      case ModeratorActions.FLAG_POST:
        return data.flagged ? false : true;
      case ModeratorActions.UNFLAG_POST:
        return data.flagged ? true : false;
      case ModeratorActions.BLOCK_USER:
        return data.userBlocked ? false : true;
      case ModeratorActions.UNBLOCK_USER:
        return data.userBlocked ? true : false;
      case ModeratorActions.BLOCK_COMMENTS:
        return data.visible ? true : false;
      case ModeratorActions.UNBLOCK_COMMENTS:
        return data.visible ? false : true;
      case ModeratorActions.COPY_POST_ID:
        return true;
      default:
        return false;
    }
  }

  navigateModerationTable(postId: string) {
    const routePath = 'posts/' + postId;

    window.open(
      this.router.serializeUrl(this.router.createUrlTree([routePath])),
      '_blank',
    );
  }

  convertToTitleCase(inputString: string) {
    const words = inputString.split('_');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    );
    const result = capitalizedWords.join(' ');

    return result;
  }

  generateValueFromKey(key: string) {
    const words = key.split('_');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );
    const value = capitalizedWords.join(' ');
    return value;
  }

  expandSet = new Set<string>();
  onExpandChange(id: string, checked: boolean) {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  constructor(
    private router: Router,
    private posts: PostsService,
    private toastrService: ToastService,
    private clipboard: Clipboard,
    private masterDataService: MasterdataService,
  ) {}

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.toastrService.showSuccess('Post Id: ' + text + ' Copied in ClipBoard');
  }

  sortlastCommentDate(sort: string | null) {
    this.lastCommentDateSortEvent.emit(sort);
  }

  sortlastReactionDate(sort: string | null) {
    this.lastReactionDateSortEvent.emit(sort);
  }

  sortCombinedDate(sort: string | null) {}

  setSubTopicsFilterData(filterOptions?: PostsGridFilterData) {
    this.subTopicsCheckBoxOptions = [];
    filterOptions?.circleTopics.map((data) => {
      this.subTopicsCheckBoxOptions.push({
        checked: false,
        label: data.value,
        value: data.key,
      });
    });
  }

  setTagsFilterData(filterOptions?: PostsGridFilterData) {
    this.tagsCheckBoxOptions = [];
    filterOptions?.tags.map((data) => {
      this.tagsCheckBoxOptions.push({
        checked: false,
        label: this.generateValueFromKey(data),
        value: data,
      });
    });
  }

  subTopicsFilterChangeFn($event: string[]) {
    this.selectedSubTopicsString = $event;
  }

  tagsFilterChangeFn($event: string[]) {
    this.selectedtagsString = $event;
  }

  subTopicsOkFilterFn() {
    this.subTopicsFilterEvent.emit(this.selectedSubTopicsString);
  }

  tagsOkFilterFn() {
    this.tagsFilterEvent.emit(this.selectedtagsString);
  }

  nzPageIndexChange(page: number) {
    this.pageChangeEvent.emit(page);
  }

  nzPageSizeChange(pageSize: number) {
    this.pageSizeChangeEvent.emit(pageSize);
  }

  resetFilter() {
    this.resetFilterEvent.emit();
  }

  listPosts() {
    this.listPostsEvent.emit();
  }

  visible = false;
  visibleViewMedia = false;

  size: 'large' | 'default' = 'default';
  blockPostReason = '';
  blockUserReason = '';
  title!: string;
  selectedId: string = '';
  postData!: CirclePostsAdminResponse;
  isVisible = false;
  selectedProblemString = '';
  placeholder = '';

  showModalBlockPost(data: CirclePostsAdminResponse): void {
    this.isVisible = true;
    this.postData = data;
    this.selectedProblemString = '';
    this.blockPostReason = '';
  }

  handleOkBlockPost(): void {
    this.isVisible = false;
    this.postSpinner = true;
    const req: ModeratorActionsRequest = {
      userId: this.postData.userId,
      circleId: this.postData.circleId,
      postId: this.postData.id,
      key: this.postData.visible
        ? ModeratorActions.BLOCK_POST
        : ModeratorActions.UNBLOCK_POST,
      reason: this.blockPostReason,
      problemId: this.selectedProblemString,
    };
    this.posts.moderatorActions(req).subscribe({
      next: (value) => {
        this.postSpinner = false;
        if (this.postData.visible) {
          this.toastrService.showSuccess('Post Blocked');
        } else {
          this.toastrService.showSuccess('Post UnBlocked');
        }
        this.listPosts();
      },
      error: (err) => {
        this.postSpinner = false;
        const errorBody = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  handleOkStarredUnStarredPost() {
    this.isVisible = false;
    this.postSpinner = true;
    const req: ModeratorActionsRequest = {
      postId: this.postData.id,
      key: !this.postData.starred
        ? ModeratorActions.STAR_POST
        : ModeratorActions.UNSTAR_POST,
    };
    this.posts.moderatorActions(req).subscribe({
      next: (value) => {
        this.postSpinner = false;
        if (this.postData.starred) {
          this.toastrService.showSuccess('Post UnStarred');
        } else {
          this.toastrService.showSuccess('Post Starred');
        }
        this.listPosts();
      },
      error: (err) => {
        this.postSpinner = false;
        const errorBody = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  handleOkFlagUnFlagPost() {
    this.isVisible = false;
    this.postSpinner = true;
    const req: ModeratorActionsRequest = {
      postId: this.postData.id,
      key: !this.postData.flagged
        ? ModeratorActions.FLAG_POST
        : ModeratorActions.UNFLAG_POST,
    };
    this.posts.moderatorActions(req).subscribe({
      next: (value) => {
        this.postSpinner = false;
        if (this.postData.flagged) {
          this.toastrService.showSuccess('Post UnFlagged');
        } else {
          this.toastrService.showSuccess('Post Flagged');
        }
        this.listPosts();
      },
      error: (err) => {
        this.postSpinner = false;
        const errorBody = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  handleCancelBlockPost(): void {
    this.isVisible = false;
  }

  closeViewMedia(): void {
    this.visibleViewMedia = false;
  }

  showViewMedia(data: CirclePostsAdminResponse) {
    this.postsObj = data;
    this.title = 'View Media';
    this.visibleViewMedia = true;
  }

  visibleViewComments = false;

  closeViewComments() {
    this.visibleViewComments = false;
  }

  showViewComments(data: CirclePostsAdminResponse) {
    this.title = 'View Comments';
    this.postCommentTitle = 'Comment';
    this.visibleViewComments = true;
    const routePath =
      '/posts/' + data.id + '/circle/' + data.circleId + '/comments/';

    window.open(
      this.router.serializeUrl(this.router.createUrlTree([routePath])),
      '_blank',
    );
  }

  visibleViewPastPosts = false;

  showUserPastPosts(data: CirclePostsAdminResponse) {
    this.title = 'View Past Post';
    this.visibleViewPastPosts = true;
    const routePath = 'posts/user/' + data.userId;
    window.open(
      this.router.serializeUrl(this.router.createUrlTree([routePath])),
      '_blank',
    );
  }

  closeUserPastPosts() {
    this.visibleViewPastPosts = false;
  }
  isVisibleBlock = false;

  showModalBlockUser(data: CirclePostsAdminResponse) {
    this.isVisibleBlock = true;
    this.postData = data;
    this.blockUserReason = '';
    this.selectedProblemString = '';
  }

  handleOkBlockUser(): void {
    this.isVisibleBlock = false;
    const req: ModeratorActionsRequest = {
      userId: this.postData.userId,
      circleId: this.postData.circleId,
      postId: this.postData.id,
      key: this.postData.userBlocked
        ? ModeratorActions.UNBLOCK_USER
        : ModeratorActions.BLOCK_USER,
      reason: this.blockUserReason,
      problemId: this.selectedProblemString,
    };
    this.posts.moderatorActions(req).subscribe({
      next: (value) => {
        this.postSpinner = false;
        if (this.postData.userBlocked) {
          this.toastrService.showSuccess('This Post User UnBlocked');
        } else {
          this.toastrService.showSuccess('This Post User Blocked');
        }
        this.listPosts();
      },
      error: (err) => {
        this.postSpinner = false;
        const errorBody = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  handleCancelBlockUser(): void {
    this.isVisibleBlock = false;
  }

  resetValues() {
    this.pageSize = 10;
    this.subTopicsCheckBoxOptions = [];
    this.tagsCheckBoxOptions = [];
  }

  convertIsoToFormattedDate(isoDate: Date): string {
    return moment(isoDate).format('DD/MM/YYYY hh:mm A');
  }

  //-----------------------------------------------------------------------
  reportSpinner: boolean = false;
  reportTableTotalCount = 0;
  reportTableCurrentPageNo = 1;
  postReportsTableQuery: CirclePostReportsGridRequest | undefined;
  reportData: CirclePostReportsResponseData[] = [];
  postObj: CirclePostsAdminResponse | undefined;
  postText = '';
  media:
    | {
        type: MediaType;
        url: string;
      }[]
    | undefined;
  reportTableData: CirclesPostReportsGridResponse = {
    data: [],
    filterOptions: {},
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };

  async showReport(data: CirclePostsAdminResponse | undefined) {
    this.postObj = data;
    this.size = 'large';
    this.title = 'View Reports';
    this.visible = true;
    if (data) {
      this.postText = data.text;
      if (data.media.length > 0 && data.media) {
        this.media = data.allMedia;
      }
      this.postReportsTableQuery = {
        dateFilters: undefined,
        export: false,
        filters: {
          POST_ID: [data.id],
          CIRCLE_ID: [data.circleId],
          DISMISSED_OR_NOT: [],
        },
        from: undefined,
        globalSearch: [],
        searchQuery: undefined,
        sort: undefined,
        to: undefined,
        page: this.reportTableCurrentPageNo,
        pageSize: this.pageSize,
      };

      await this.listReports();
    }
  }

  closeViewReport(): void {
    this.visible = false;
  }

  async resetFilterReports() {
    this.postReportsTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: {
        POST_ID: [this.postObj?.id!],
        CIRCLE_ID: [this.postObj?.circleId!],
        DISMISSED_OR_NOT: [],
      },
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.reportTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    await this.listReports();
  }

  async listReports() {
    try {
      this.reportSpinner = true;
      this.reportTableData = await lastValueFrom(
        this.posts.postReportGrid(this.postReportsTableQuery!),
      );
      this.reportSpinner = false;
    } catch (e) {
      this.reportSpinner = false;
      throw e;
    }
  }

  async nzPageIndexChangeReportTable(page: number) {
    if (this.postReportsTableQuery) {
      this.postReportsTableQuery!.page = page;
      await this.listReports();
    }
  }

  async nzPageSizeChangeReportTable(pageSize: number) {
    this.postReportsTableQuery!.pageSize = pageSize;
    await this.listReports();
  }

  dismissReport($event: string) {
    this.reportSpinner = true;
    const req: ModeratorActionsRequest = {
      postId: this.postObj?.id,
      reportId: $event,
      key: ModeratorActions.DISMISSED_REPORT,
    };
    this.posts.moderatorActions(req).subscribe({
      next: async (value) => {
        this.reportSpinner = false;
        this.toastrService.showSuccess('Report Dismissed');
        await this.listReports();
      },
      error: (value) => {
        this.reportSpinner = false;
        const errorBody = value.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit() {
    this.postSpinner = true;
    this.masterDataService.masterDataList().subscribe({
      next: (value) => {
        this.problems = value.circleReportProblems.map((data) => {
          return {
            label: data.data,
            value: data.m_id,
          };
        });
        this.postSpinner = false;
      },
      error: (error) => {
        this.postSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  handleOkModerationRequiredPost() {
    this.postSpinner = true;
    const req: ModeratorActionsRequest = {
      postId: this.postData.id,
      key: ModeratorActions.MODERATION_REQUIRED,
    };
    this.posts.moderatorActions(req).subscribe({
      next: (value) => {
        this.postSpinner = false;
        this.toastrService.showSuccess('Post Moderation flag Updated');
        this.listPosts();
      },
      error: (err) => {
        this.postSpinner = false;
        const errorBody = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
}
