import { Component } from '@angular/core';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  CreatePostRequestAdmin,
  MediaType,
} from '../../model/createPostRequestAdmin';
import { lastValueFrom } from 'rxjs';
import { ToastService } from '../../../services/toastr.service';
import { PostsService } from '../../service/posts.service';
import {
  UploadFileRequest,
  UploadFileUrlResponse,
} from '../../model/postMediaUpload';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUtilsService } from 'projects/app-core/src/service/fileService';
import { MediaMeta } from '../../model/CirclePost';
import { AnyoCircles } from 'projects/admin/src/app/models/circles/AnyoCircles';
import { v4 as uuidv4 } from 'uuid';

interface SelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrl: './add-post.component.scss',
})
export class AddPostComponent {
  selectedCircleString = '';
  selectedSubTopicsArray = [];
  mediaFiles: File[] = [];
  feedText!: string;
  postSpinning: boolean = false;
  descriptionString = '';
  circles: SelectOptions[] = [];
  subTopics: SelectOptions[] = [];
  emotionPurpose = '';
  energyPurpose = '';
  masterData: AnyoCircles[] | undefined;
  mediaUrls: UploadFileUrlResponse[] = [];
  images: { type: string; url: string; file: File }[] = [];
  postCommentTitle = 'Posts';
  parentPostId!: string;
  displayMediaUpload = true;
  whenDisplayManyCircles = false;

  uploadMedia($event: any) {
    const files = ($event.target as HTMLInputElement).files;

    if (files) {
      const newFiles = Array.from(files).map((file: File) => {
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        return new File([file], uuidWithoutHyphens, { type: file.type });
      });
      this.mediaFiles = this.mediaFiles.concat(newFiles);
      this.readMedia(newFiles);
    }
  }

  protected readonly MediaType = MediaType;

  deleteImage(index: number) {
    this.images.splice(index, 1);
    this.mediaFiles.splice(index, 1);
  }

  notificationTitle: string | undefined;

  async saveComments() {
    this.postSpinning = true;
    const newPost: CreatePostRequestAdmin = {
      circleId: this.selectedCircleString,
      text: this.feedText,
      topics: this.selectedSubTopicsArray,
      parentPostId: this.parentPostId,
    };
    this.posts.addPost(newPost).subscribe({
      next: (value) => {
        this.toastService.showSuccess('Successfully Commented');
        this.postSpinning = false;
        const routePath =
          '/posts/' +
          this.parentPostId +
          '/circle/' +
          this.selectedCircleString +
          '/comments/';
        this.router.navigate([routePath]);
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.postSpinning = false;
      },
    });
  }

  readMedia(files: File[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const mediaType = this.getContentType(file.type);
        this.images.push({ type: mediaType, url: e.target.result, file });
      };

      reader.readAsDataURL(file);
    }
    this.postSpinning = false;
  }
  populateSubtopics($event: string) {
    const circle = this.masterData?.find((data) => data._id === $event);
    if (circle) {
      this.subTopics = circle?.circleTopics!.map((data) => {
        return {
          label: data.value,
          value: data.key,
        };
      });
    }
  }

  uploadFileRequest: UploadFileRequest[] = [];

  getContentType(contentType: string) {
    switch (contentType) {
      case 'image/jpeg':
        return MediaType.IMAGE;
      case 'image/png':
        return MediaType.IMAGE;
      case 'image/jpg':
        return MediaType.IMAGE;
      case 'video/mp4':
        return MediaType.VIDEO;
      default:
        throw 'invalid type';
    }
  }

  constructor(
    private auth: AuthService,
    private posts: PostsService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private file: FileUtilsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.postSpinning = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.posts.getCirclesForUser().subscribe({
          next: (value) => {
            this.circles = value.map((data) => {
              return {
                label: data.name,
                value: data._id!,
              };
            });
            this.masterData = value;
            this.activatedRoute.params.subscribe((params) => {
              if (params['postId']) {
                this.postCommentTitle = 'Comments';
                this.selectedCircleString = params['circleId'];
                this.parentPostId = params['postId'];
                this.populateSubtopics(this.selectedCircleString);
                this.displayMediaUpload = false;
                this.viewPost = true;
                this.whenDisplayManyCircles = true;
                this.getPost(this.parentPostId);
              }
            });
            this.postSpinning = false;
          },
          error: (err) => {
            const errorBody = err.error as IAnyoError;
            this.toastService.showAnyoErrorToast(errorBody.description);
            this.postSpinning = false;
          },
        });
      }
    });
  }

  async handleMultipleFileUpload(files: File[]) {
    const uploadedFileUrls: UploadFileUrlResponse[] = [];
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const uploadRequest: UploadFileRequest = {
          fileName: file.name,
          contentLength: file.size,
          contentType: this.getContentType(file.type),
        };
        this.uploadFileRequest.push(uploadRequest);
      }
      this.toastService.showSuccess(`Generating upload urls`);
      const urlPromises = this.uploadFileRequest.map(
        async (uploadRequest) =>
          await lastValueFrom(
            this.posts.generateUploadMediaUrl([uploadRequest]),
          ),
      );

      const urlArrays = await Promise.all(urlPromises);

      const flattenedArray = urlArrays.flat();
      this.toastService.showSuccess(`Generated upload urls`);
      for (let i = 0; i < flattenedArray.length; i++) {
        const file = flattenedArray[i];
        this.toastService.showSuccess(`uploading ${i + 1} media`);
        const mediaFile = this.mediaFiles.find(
          (data) => data.name == file.fileName,
        );

        if (mediaFile) {
          await lastValueFrom(this.file.uploadFile(mediaFile, file.url));
          this.toastService.showSuccess(`uploaded ${i + 1} media`);
        } else {
          console.error(`Media file not found for ${file.fileName}`);
        }
      }

      return uploadedFileUrls;
    } catch (e) {
      this.postSpinning = false;
      this.toastService.showError(e + '');
      throw e;
    }
  }
  postsText: string = '';
  media: MediaMeta[] | undefined;
  viewPost = false;
  visibleViewMedia = false;
  notificationBody: string | undefined;

  async savePost() {
    if (
      (this.notificationBody && !this.notificationTitle) ||
      (this.notificationTitle && !this.notificationBody)
    ) {
      this.toastService.showError(
        'Both notification title and body are required',
      );
    }
    this.postSpinning = true;
    const newPost: CreatePostRequestAdmin = {
      circleId: this.selectedCircleString,
      text: this.feedText,
      topics: this.selectedSubTopicsArray,
      mediaFiles: [],
      notificationBody: this.notificationBody,
      notificationTitle: this.notificationTitle,
    };
    if (this.mediaFiles && this.mediaFiles.length) {
      await this.handleMultipleFileUpload(this.mediaFiles);
      newPost.mediaFiles = this.mediaFiles.map((data) => {
        return {
          fileName: data.name,
          contentLength: data.size,
          contentType: this.getContentType(data.type),
        };
      });
    }
    this.posts.addPost(newPost).subscribe({
      next: (value) => {
        this.toastService.showSuccess('Successfully Posted');
        this.postSpinning = false;
        this.router.navigate(['/posts']);
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.postSpinning = false;
      },
    });
  }
  closeViewMedia() {
    this.visibleViewMedia = false;
  }

  openViewMedia() {
    this.visibleViewMedia = true;
  }

  getPost(id: string) {
    this.posts.getPost(id).subscribe({
      next: (value) => {
        this.postsText = value.text;
        if (value.media.length > 0) {
          this.media = value.media!;
        }
        this.toastService.showSuccess('Post View');
        this.postSpinning = false;
      },
      error: (value) => {
        this.postSpinning = false;
      },
    });
  }
}
