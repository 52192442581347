import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { EmailComponent } from './email/email.component';
import { OtpComponent } from './otp/otp.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { AppRoutingModule } from '../app-routing.module';
import { HttpLoaderFactory } from '../app.module';
import { CommonComponentModule } from 'projects/app-core/src/app/components/commoncomponent.module';
import { Router, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '../pages/main-layout/main-layout.component';
import { DatepickerModule } from 'ng2-datepicker';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    EmailComponent,
    OtpComponent,
    CreatePasswordComponent,
    MainLayoutComponent,
  ],
  imports: [
    RouterModule,
    CommonComponentModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class ComponentModule {}
