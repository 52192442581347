<nz-table
  #basicTable
  [nzData]="listOfData"
  #sessionTable
  [nzTitle]="tableHead"
  [nzScroll]="{ y: '500px' }"
  class="mt-5"
  [nzData]="inputTableData!.data"
  nzFrontPagination="false"
  [nzTotal]="inputTableData!.totalRecords"
  [nzPageSize]="inputTableData!.pageSize"
  [nzBordered]="true"
  [nzShowSizeChanger]="true"
  [nzPageSizeOptions]="pageSizeOptions"
  [nzPageIndex]="inputTableData!.pageNum"
  nzTableLayout="fixed"
  (nzPageIndexChange)="nzPageIndexChange($event)"
  (nzPageSizeChange)="nzPageSizeChange($event)"
>
  <thead>
    <tr>
      <th>Title</th>
      <th nzCustomFilter>
        Category<nz-filter-trigger
          [nzActive]="true"
          [nzDropdownMenu]="bytesCategoryMenu"
        >
          <span nz-icon nzType="filter"></span>
        </nz-filter-trigger>
      </th>
      <th>No of Frames</th>
      <th
        (nzSortOrderChange)="sortBytesDates($event)"
        nzCustomFilter
        nzShowSort="true"
      >
        Date
      </th>
      <th>Views</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>{{ data.title }}</td>
      <td>{{ data.catgeory }}</td>
      <td>{{ data.noOfFrames }}</td>
      <td>{{ convertDateFormat(data.date) }}</td>
      <td>{{ data.views }}</td>
      <td nzRight>
        <span
          *ngIf="checkScheduledStatus(data)"
          nz-tooltip
          nzTooltipTitle="View Bytes"
        >
          <button
            nz-button
            class="save"
            nzShape="circle"
            [routerLink]="['/bytes/view/' + data.id]"
          >
            <span nz-icon nzType="eye" nzTheme="outline"></span>
          </button>
        </span>
        <span
          *ngIf="checkDeliveredStatus(data)"
          nz-tooltip
          nzTooltipTitle="Duplication Bytes"
        >
          <button
            nz-button
            class="save"
            nzShape="circle"
            [routerLink]="['/bytes/duplicate/' + data.id]"
          >
            <span nz-icon nzType="copy" nzTheme="outline"></span>
          </button>
        </span>
      </td>
    </tr>
  </tbody>
</nz-table>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Bytes</span>
    </div>
    <div nz-col class="m-1">
      <button nz-button nzSize="small" nzType="primary" (click)="resetFilter()">
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-dropdown-menu #bytesCategoryMenu="nzDropdownMenu">
  <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
    <div nz-row>
      <div nz-col>
        <span class="font-bold">Bytes Categories</span>
        <nz-divider></nz-divider>
        <nz-checkbox-wrapper (nzOnChange)="bytesCategoryFilterChangeFn($event)">
          <div *ngFor="let status of bytesCategoryCheckBoxOptions" nz-row>
            <div nz-col>
              <label
                nz-checkbox
                [nzValue]="status.value"
                [ngModel]="status.checked"
                >{{ status.label }}</label
              >
            </div>
          </div>
        </nz-checkbox-wrapper>
      </div>
    </div>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          class="m-1"
          nz-button
          nzType="primary"
          (click)="bytesCategoryFilterFn()"
        >
          Ok
        </button>
      </div>

      <div nz-col>
        <button class="m-1" nz-button nzType="primary" (click)="resetFilter()">
          Reset
        </button>
      </div>
    </div>
  </div>
</nz-dropdown-menu>
