import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circled-input-listener',
  templateUrl: './circled-input-listener.component.html',
  styleUrls: ['./circled-input-listener.component.scss'],
})
export class CircledInputListenerComponent {
  @Input() array!: any;
  finalArray: any[] = [];

  ngOnInit() {
    this.finalArray = this.array;
    console.log(this.finalArray);
  }
}
