import { Component } from '@angular/core';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { lastValueFrom } from 'rxjs';
import { CirclesService } from '../../../services/circles.service';
import { UpdateCircleModeratorRequest } from '../../../models/circles/updateModeratorRequest';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

interface Qualifications {
  value: string;
}

@Component({
  selector: 'app-view-moderator',
  templateUrl: './view-moderator.component.html',
  styleUrl: './view-moderator.component.scss',
})
export class ViewModeratorComponent {
  viewMode = true;
  moderatorSpinner = false;
  moderatorName = '';
  moderatorEmail = '';
  contactNo = '';
  moderatorDescription = '';
  qualifications: Qualifications[] = [];
  avatarImage!: File;
  isActive!: boolean;
  moderatorId!: string;
  moderatorImage!: string;

  editMode() {
    this.viewMode = false;
  }

  uploadModeratorProfilePic($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.avatarImage = file;
      this.readMedia(this.avatarImage);
    }
  }

  readMedia(file: File) {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.moderatorImage = e.target.result;
    };

    reader.readAsDataURL(file);
  }

  addQualification() {
    this.qualifications.push({ value: '' });
  }

  deleteQualification(index: number) {
    if (index != undefined) {
      this.qualifications.splice(index, 1);
    }
  }

  constructor(
    private fileService: FileUtilsService,
    private toastService: ToastService,
    private circles: CirclesService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  async saveModerator() {
    this.moderatorSpinner = true;
    const request: UpdateCircleModeratorRequest = {
      name: this.moderatorName,
      about: this.moderatorDescription,
      contactNo: this.contactNo,
      active: this.isActive,
      qualifications: this.qualifications.map((data) => data.value),
      avatarImage: this.moderatorImage,
    };
    if (this.avatarImage) {
      const uuid = uuidv4();
      const uuidWithoutHyphens = uuid.replace(/-/g, '');
      const extension = this.avatarImage.name.split('.').pop();
      const filePath = `circles/${uuidWithoutHyphens}/moderatorprofile.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePath,
          this.avatarImage.type,
        ),
      );
      this.toastService.showSuccess('Uploading Profile Pic');
      await lastValueFrom(
        this.fileService.uploadFile(this.avatarImage, uploadUrl.url),
      );
      request.avatarImage = `https://cdn.anyo.app/${filePath}`;
      this.toastService.showSuccess('Uploading Profile Pic completed');
    }
    this.circles.updateModerator(request, this.moderatorId).subscribe({
      next: (value) => {
        this.toastService.showSuccess('Moderator Updated Sucessfully');
        this.moderatorSpinner = false;
        this.router.navigate(['circles/moderators']);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.moderatorSpinner = false;
      },
    });
  }

  populateModerator(id: string) {
    this.moderatorSpinner = true;
    this.circles.getModerator(id).subscribe({
      next: (value) => {
        this.moderatorEmail = value.email;
        this.moderatorName = value.name;
        this.moderatorDescription = value.about;
        this.isActive = value.active;
        if (value.qualifications) {
          this.qualifications = value.qualifications.map((data) => {
            return {
              value: data,
            };
          });
        }

        this.contactNo = value.contactNo;
        this.moderatorImage = value.avatarImage;
        this.moderatorSpinner = false;
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.moderatorSpinner = false;
      },
    });
  }

  ngOnInit(): void {
    this.moderatorSpinner = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        if (this.activatedRoute.snapshot.params) {
          const id = this.activatedRoute.snapshot.params['id'];
          this.moderatorId = id;
          this.populateModerator(id);
        }
      }
    });
  }
}
