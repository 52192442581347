<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/talks']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text headerlistener-text pl-2">Add Talks</h1>
  </div>
</div>
<nz-spin [nzSpinning]="pageLoading" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div [nzGutter]="16" nz-row nzAlign="bottom">
        <div nz-col nzSpan="12">
          <label class="form-label required">Title</label>
          <input [(ngModel)]="title" nz-input placeholder="Title" type="text" />
        </div>
        <div nz-col nzSpan="12">
          <a
            href="https://cdn.anyo.app/app-assets/Cron%20Text%20Expression%20Frequency%20Notes.pdf"
            download="frequency.pdf"
            target="_blank"
          >
            <button nz-button class="btn-color">
              How to Use Frequency Docs
            </button>
          </a>
        </div>
        <!-- <div nz-col nzSpan="12">
          <label class="form-label required">Description</label>
          <textarea
            class="w-[100%] h-60 mr-1"
            [(ngModel)]="description"
            nz-input
            rows="4"
            placeholder="Enter Description"
          ></textarea>
        </div> -->
      </div>

      <div>
        <label class="form-label required">Frequency</label>
        <nz-cron-expression
          [nzExtra]="shortcuts"
          [(ngModel)]="frequency"
          (ngModelChange)="getValue($event)"
        ></nz-cron-expression>
        <ng-template #shortcuts>
          <button nz-button nz-dropdown [nzDropdownMenu]="menu">
            Shortcuts
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              @for (item of options; track item.value) {
              <li
                nz-menu-item
                [value]="item.value"
                (click)="setValue(item.value)"
              >
                {{ item.label }}
              </li>
              }
            </ul>
          </nz-dropdown-menu>
        </ng-template>
        <p>
          Cron Text :
          {{ cron + " " + "-" + " " + this.utils.getFrequencyText(cron) }}
        </p>
      </div>
      <div [nzGutter]="16" nz-row>
        <div nz-col nzSpan="6">
          <label class="form-label required">Talk Mode</label>
          <nz-select
            [(ngModel)]="selectedTalkMode"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Talk Mode"
            nzShowSearch
            nzSize="large"
          >
            <nz-option
              *ngFor="let mode of talkMode"
              [nzLabel]="mode"
              [nzValue]="mode"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Category</label>
          <nz-select
            [(ngModel)]="selectedCategory"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Category"
            nzShowSearch
            nzSize="large"
          >
            <nz-option
              *ngFor="let category of categories"
              [nzLabel]="category.data"
              [nzValue]="category.m_id"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Experts</label>
          <nz-select
            [(ngModel)]="selectedExperts"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Experts"
            nzShowSearch
            nzSize="large"
            nzMode="multiple"
            [nzMaxTagCount]="5"
          >
            <nz-option
              *ngFor="let expert of experts"
              [nzLabel]="expert.name"
              [nzValue]="expert._id"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Visible</label>
          <nz-switch [(ngModel)]="visible"></nz-switch>
        </div>
      </div>
      <div [nzGutter]="16" nz-row>
        <div class="mt-5" nz-col nzSpan="6">
          <span><b class="form-label required">Image Upload</b></span>
          <input
            (change)="uploadMedia($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
          />
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col>
          <button
            (click)="saveTalks()"
            nz-button
            nzType="primary"
            class="btn-color"
          >
            Save Talks
          </button>
        </div>
      </div>
    </div>
  </div></nz-spin
>
