<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/bytes/category']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text pl-2">Add Bytes Category</h1>
  </div>
</div>
<nz-spin [nzSpinning]="bytesCategorySpinning" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="6">
          <label class="form-label required">Name</label>
          <input
            class="w-[100%] mr-1"
            [(ngModel)]="nameString"
            nz-input
            nzSize="large"
            placeholder="Enter Name"
          />
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Descripition</label>
          <input
            class="w-[100%] mr-1"
            [(ngModel)]="descriptionString"
            nz-input
            nzSize="large"
            placeholder="Enter Description"
          />
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">isActive</label>
          <nz-switch [(ngModel)]="isActive"></nz-switch>
        </div>
      </div>
      <div [nzGutter]="16" class="mt-5" nz-row nzJustify="space-evenly">
        <div class="mt-5" nz-col nzSpan="6">
          <span><b class="form-label required">Upload Home Screen</b></span>
          <input
            (change)="uploadHomeScreen($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
          />
        </div>
        <div class="mt-5" nz-col nzSpan="6">
          <span
            ><b class="form-label required">Upload Background Images</b></span
          >
          <input
            multiple
            (change)="uploadBackgroundImages($event)"
            accept=".jpg,.jpeg,.png"
            type="file"
          />
          <div *ngFor="let file of backgroundFileList">
            {{ file.name }}
          </div>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col>
          <button (click)="saveBytesCategory()" nz-button nzType="primary">
            Save Bytes Category
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
