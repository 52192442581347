import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LiveComponent } from './pages/live/live.component';
import { FormsModule } from '@angular/forms';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzCardComponent } from 'ng-zorro-antd/card';
import { NgForOf, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { LowLatencyStreamingLiveComponentComponent } from './pages/low-latency-streaming-live-component/low-latency-streaming-live-component.component';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzRadioComponent, NzRadioGroupComponent } from 'ng-zorro-antd/radio';
import { NzTypographyComponent } from 'ng-zorro-antd/typography';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzSkeletonComponent } from 'ng-zorro-antd/skeleton';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import {
  NzCellFixedDirective,
  NzFilterTriggerComponent,
  NzTableCellDirective,
  NzTableComponent,
  NzTbodyComponent,
  NzThAddOnComponent,
  NzTheadComponent,
  NzThMeasureDirective,
  NzTrDirective,
} from 'ng-zorro-antd/table';
import { NzTagComponent } from 'ng-zorro-antd/tag';
import { ListTalksComponent } from './pages/list-talks/list-talks.component';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
import { NzInputDirective, NzInputModule } from 'ng-zorro-antd/input';
import { ListTalkSessionsComponent } from './pages/list-talk-sessions/list-talk-sessions.component';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { AddTalksComponent } from './pages/add-talks/add-talks.component';
import { EditTalksComponent } from './pages/edit-talks/edit-talks.component';
import { NzCronExpressionModule } from 'ng-zorro-antd/cron-expression';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

const routes: Routes = [
  {
    path: '',
    component: ListTalksComponent,
  },
  {
    path: 'talk-logs/grid',
    component: ListTalkSessionsComponent,
  },
  {
    path: 'live',
    component: LiveComponent,
  },

  {
    path: 'single/live/:id',
    component: LowLatencyStreamingLiveComponentComponent,
  },
  {
    path: 'add',
    component: AddTalksComponent,
  },
  {
    path: 'edit/:id',
    component: EditTalksComponent,
  },
];

@NgModule({
  declarations: [
    LiveComponent,
    LowLatencyStreamingLiveComponentComponent,
    ListTalksComponent,
    ListTalkSessionsComponent,
    AddTalksComponent,
    EditTalksComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    NzInputModule,
    NzButtonComponent,
    NzCardComponent,
    NgForOf,
    NzRowDirective,
    NzColDirective,
    NzRadioGroupComponent,
    NzRadioComponent,
    NzTypographyComponent,
    NzDividerComponent,
    NgIf,
    NgStyle,
    NzSkeletonComponent,
    NzSpinComponent,
    NzIconDirective,
    NzTableComponent,
    NzTagComponent,
    NzCellFixedDirective,
    NzFilterTriggerComponent,
    NzTableCellDirective,
    NzTbodyComponent,
    NzThAddOnComponent,
    NzThMeasureDirective,
    NzTheadComponent,
    NzTooltipDirective,
    NzTrDirective,
    NgOptimizedImage,
    NzSelectComponent,
    NzOptionComponent,
    NzSwitchComponent,
    NzInputDirective,
    NzButtonComponent,
    NzColDirective,
    NzRowDirective,
    NzModalComponent,
    NzModalContentDirective,
    NzCronExpressionModule,
    NzDropDownModule,
    NzCheckboxModule,
    NzDatePickerModule,
  ],
  exports: [RouterModule],
})
export class TalksModule {}
