import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { ResetPasswordRequest } from 'app/models/common-models';
// import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { ToastService } from 'app/services/toastr.service';
@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent {
  password!: string;
  buttonValue: string = 'Reset Password';
  phone = null;
  email = null;
  confirmpassword!: string;
  view1: boolean = false;
  view2: boolean = false;
  placeholder = 'Enter your password';
  border!: string;
  eyeClosedIcon = '../../../assets/icons/icon-eye.svg';
  eyeOpenIcon = '../../../assets/icons/icon-eye-open.svg';
  isShow: boolean = false;
  resetPasswordForm!: FormGroup;
  isSame: boolean = false;
  url: string = window.location.href;

  constructor(
    private formBuilder: FormBuilder,
    // private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute, //  private toastrService: ToastService
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    });
    if (router.url.includes('true')) {
      this.buttonValue = 'Create Password';
    }
  }

  onKey(event: any) {
    this.password = event.target.value;

    if (this.password.length < 7) {
      this.border = 'error';
    } else {
      this.border = 'valid';
    }
  }

  validate(event: any) {
    this.confirmpassword = event.target.value;
    if (this.password == this.confirmpassword && this.password != '') {
      this.border = 'valid';
      this.isSame = true;
    } else {
      this.border = 'error';
      this.isSame = false;
    }
  }

  show() {
    this.isShow = !this.isShow;
  }

  resetPassword(resetPasswordForm: any) {
    //   const email_id = this.url.split('/createpassword/')[1];
    //   let req: ResetPasswordRequest = {
    //     password: resetPasswordForm.value.password,
    //     phoneNumber: this.phone || '',
    //     email: email_id || ''
    //   };
    //   if ("[0-9]+".match(email_id)) {
    //     req.phoneNumber = email_id;
    //   }
    //   if (email_id.includes('@')) {
    //     req.email = email_id;
    //   } else {
    //     req.phoneNumber = email_id;
    //     req.email = null;
    // }
    //   console.log(req);
    //   this.userService.resetPassword(req).subscribe({
    //     next: (value) => {
    //       this.toastrService.showSuccess("Password changed successfully");
    //       this.router.navigate(['/login']);
    //     },
    //     error: (err) => {
    //       this.toastrService.showError("Error changing password. Please try again later");
    //     }
    //   })
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params) {
      let value = this.activatedRoute.snapshot.params['toverify'];
      if (value) {
        if ('[0-9]+'.match(value)) {
          this.phone = value;
        } else {
          this.email = value;
        }
      }
    }
  }
}
