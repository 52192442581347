<div class="max-w-1xl mx-auto bg-white p-8 rounded-lg shadow-lg">
  <div class="flex flex-wrap pb-4 justify-start">
    <img
      [routerLink]="['/therapy-plans/all']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text pl-2">Create Plan</h1>
  </div>
  <nz-spin [nzSpinning]="pageLoading">
    <form [formGroup]="therapyPlanForm" nz-form>
      <div class="grid grid-cols-3 gap-4">
        <!-- Plan Name -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="planName"
            >Plan Name</label
          >

          <nz-input-group nzSize="large">
            <input
              nz-input
              id="planName"
              formControlName="planName"
              placeholder="Enter Plan Name"
              class="w-full"
              [ngClass]="{
                'border-red-500': therapyPlanForm.get('planName')?.invalid
              }"
            />
          </nz-input-group>
          <div *ngIf="therapyPlanForm.get('planName')?.hasError('required')">
            Plan name is required.
          </div>
        </div>
        <!-- Internal Plan Name -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="planName"
            >Internal Plan Name</label
          >

          <nz-input-group nzSize="large">
            <input
              nz-input
              id="planName"
              formControlName="internalPlanName"
              placeholder="Enter Internal Plan Name"
              class="w-full"
              [ngClass]="{
                'border-red-500': therapyPlanForm.get('planName')?.invalid
              }"
            />
          </nz-input-group>
          <div *ngIf="therapyPlanForm.get('planName')?.hasError('required')">
            Internal Plan name is required.
          </div>
        </div>

        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="planDescription"
            >Plan Description</label
          >
          <nz-input-group nzSize="large">
            <textarea
              nz-input
              formControlName="planDescription"
              id="planDescription"
              placeholder="Enter Plan Description"
              class="w-full h-32 resize-none"
              [ngClass]="{
                'border-red-500':
                  therapyPlanForm.get('planDescription')?.invalid
              }"
            ></textarea>
          </nz-input-group>
          <div
            *ngIf="therapyPlanForm.get('planDescription')?.hasError('required')"
          >
            Plan description is required.
          </div>
        </div>

        <!-- Active From -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="activeFrom"
            >Active From</label
          >
          <nz-date-picker
            formControlName="activeFrom"
            id="activeFrom"
            class="w-full"
            [ngClass]="{
              'border-red-500': therapyPlanForm.get('activeFrom')?.invalid
            }"
          ></nz-date-picker>
          <div *ngIf="therapyPlanForm.get('activeFrom')?.hasError('required')">
            Active from is required.
          </div>
        </div>

        <!-- Active Upto -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="activeUpto"
            >Active Upto (Optional)</label
          >
          <nz-date-picker
            formControlName="activeUpto"
            id="activeUpto"
            class="w-full"
          ></nz-date-picker>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="mb-4 flex items-center">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="active"
              >Active</label
            >
            <nz-switch formControlName="active" id="active"></nz-switch>
            <div *ngIf="therapyPlanForm.get('active')?.hasError('required')">
              Active status is required.
            </div>
          </div>
        </div>

        <!-- Plan Image -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="planImage"
            >Plan Image</label
          >
          <input
            (change)="uploadImages($event)"
            multiple
            accept=".jpg,.jpeg,.png"
            type="file"
            id="planImage"
            class="w-full border border-gray-300 rounded-md p-2"
          />
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <!-- No. of Sessions -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="noOfSessions"
            >No. of Sessions</label
          >
          <nz-input-number
            [nzMin]="1"
            id="noOfSessions"
            formControlName="noOfSessions"
            (ngModelChange)="onNoOfSessionsChange($event)"
            class="w-full"
            [ngClass]="{
              'border-red-500': therapyPlanForm.get('noOfSessions')?.invalid
            }"
          ></nz-input-number>
          <div
            *ngIf="therapyPlanForm.get('noOfSessions')?.hasError('required')"
          >
            Number of sessions is required.
          </div>
        </div>

        <!-- Per Session Time (Minutes) -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="perSessionTimeInMin"
            >Per Session Time (Minutes)</label
          >
          <nz-input-number
            [nzMin]="15"
            [nzMax]="240"
            id="perSessionTimeInMin"
            formControlName="perSessionTimeInMin"
            class="w-full"
            [ngClass]="{
              'border-red-500': therapyPlanForm.get('perSessionTimeInMin')
                ?.invalid
            }"
          ></nz-input-number>
          <div
            *ngIf="
              therapyPlanForm.get('perSessionTimeInMin')?.hasError('required')
            "
          >
            Per session time is required.
          </div>
        </div>

        <!-- Validity Days -->
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="validityDays"
            >Validity Days</label
          >
          <nz-input-number
            [nzMin]="1"
            [nzMax]="365"
            id="validityDays"
            formControlName="validityDays"
            class="w-full"
            [ngClass]="{
              'border-red-500': therapyPlanForm.get('validityDays')?.invalid
            }"
          ></nz-input-number>
          <div
            *ngIf="therapyPlanForm.get('validityDays')?.hasError('required')"
          >
            Validity days is required.
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <!-- Max Pause Days -->
        <div class="mb-4 flex items-center">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="canPause"
            >Can Pause</label
          >
          <nz-switch formControlName="canPause" id="canPause"></nz-switch>
          <div *ngIf="therapyPlanForm.get('canPause')?.hasError('required')">
            Can Pause status is required.
          </div>
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="maxPauseDays"
            >Max Pause Days</label
          >
          <nz-input-group nzSize="large">
            <input
              nz-input
              id="maxPauseDays"
              formControlName="maxPauseDays"
              placeholder="Max Pause Days"
              class="w-full"
              [ngClass]="{
                'border-red-500': therapyPlanForm.get('maxPauseDays')?.invalid
              }"
            />
          </nz-input-group>
          <div
            *ngIf="therapyPlanForm.get('maxPauseDays')?.hasError('required')"
          >
            Max Pause Days
          </div>
        </div>
      </div>
       <div class="grid grid-cols-2 gap-4">
          <div class="mb-4 flex items-center">
        <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="order"
            >Plan Order</label>
          <nz-input-group nzSize="large">
            <input
              nz-input
              id="order"
              formControlName="order"
              placeholder="Plan Order"
              class="w-full"
              [ngClass]="{
                'border-red-500': therapyPlanForm.get('order')?.invalid
              }"
            />
          </nz-input-group>
          <div
            *ngIf="therapyPlanForm.get('order')?.hasError('required')"
          >
           Plan Order required
          </div>
          </div>
          
       </div>
    </form>
    <!-- Divider -->
    <nz-divider nzDashed nzText="Session Name Mapping"></nz-divider>

    <!-- Session Name Mapping -->
    <div
      *ngFor="let session of [].constructor(noOfSessions); let i = index"
      class="grid grid-cols-2 gap-4 mb-4"
    >
      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="sessionName{{ i + 1 }}"
          >Session {{ i + 1 }} Name</label
        >
        <nz-input-group nzSize="large">
          <input
            nz-input
            id="sessionName{{ i + 1 }}"
            placeholder="Enter Session {{ i + 1 }} Name"
            class="w-full"
            [(ngModel)]="sessionNameMapping[i].name"
            name="sessionName{{ i + 1 }}"
          />
        </nz-input-group>
        <div
          *ngIf="
            therapyPlanForm
              .get('sessionNameMapping.' + (i + 1) + '.name')
              ?.hasError('required')
          "
        >
          Session {{ i + 1 }} name is required.
        </div>
      </div>

      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="sessionDescription{{ i + 1 }}"
          >Session {{ i + 1 }} Description</label
        >
        <nz-input-group nzSize="large">
          <textarea
            nz-input
            id="sessionDescription{{ i + 1 }}"
            placeholder="Enter Session {{ i + 1 }} Description"
            class="w-full h-32 resize-none"
            [(ngModel)]="sessionNameMapping[i].description"
            name="sessionDescription{{ i + 1 }}"
          ></textarea>
        </nz-input-group>
        <div
          *ngIf="
            therapyPlanForm
              .get('sessionNameMapping.' + (i + 1) + '.description')
              ?.hasError('required')
          "
        >
          Session {{ i + 1 }} description is required.
        </div>
      </div>
    </div>

    <!-- Divider -->
    <nz-divider nzDashed nzText="Charge Details"></nz-divider>
    <div class="grid grid-cols-3 gap-4">
      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="paymentType"
          >Payment Type</label
        >
        <nz-select
          id="paymentType"
          class="w-full"
          [(ngModel)]="paymentType"
          name="paymentType"
        >
          <nz-option nzValue="FREE" nzLabel="Free"></nz-option>
          <nz-option nzValue="PAID" nzLabel="Paid"></nz-option>
          <nz-option nzValue="RETAIL_PAID" nzLabel="Retail Paid"></nz-option>
          <nz-option
            nzValue="CORPORATE_PAID"
            nzLabel="Corporate Paid"
          ></nz-option>
        </nz-select>
        <div *ngIf="therapyPlanForm.get('paymentType')?.hasError('required')">
          Payment type is required.
        </div>
      </div>
    </div>
    <!-- Plan Charges -->
    <div class="mb-4">
      <div
        *ngFor="let level of expertLevels; let levelIndex = index"
        class="mb-6"
      >
        <h4 class="text-lg font-semibold mb-2">{{ level }}</h4>
        <div
          *ngFor="let charge of charges[level]; let chargeIndex = index"
          class="grid grid-cols-3 gap-4 mb-4"
        >
          <!-- Amount -->
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="{{ level }}Amount{{ chargeIndex }}"
              >Amount</label
            >
            <nz-input-group nzSize="large">
              <input
                nz-input
                id="{{ level }}Amount{{ chargeIndex }}"
                type="number"
                placeholder="Enter Amount"
                class="w-full"
                [(ngModel)]="charges[level][chargeIndex].amount"
                name="{{ level }}Amount{{ chargeIndex }}"
              />
            </nz-input-group>
            <div
              *ngIf="
                therapyPlanForm
                  .get('charges.' + level + '.' + chargeIndex + '.amount')
                  ?.hasError('required')
              "
            >
              Amount is required.
            </div>
          </div>
          <!-- Currency -->
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="{{ level }}Currency{{ chargeIndex }}"
              >Currency</label
            >

            <nz-input-group nzSize="large">
              <input
                nz-input
                id="{{ level }}Currency{{ chargeIndex }}"
                placeholder="Enter Currency"
                class="w-full"
                disabled="true"
                [(ngModel)]="charges[level][chargeIndex].currency"
                name="{{ level }}Currency{{ chargeIndex }}"
              />
            </nz-input-group>
          </div>
          <!-- Currency Symbol -->
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="{{ level }}CurrencySymbol{{ chargeIndex }}"
              >Currency Symbol</label
            >
            <nz-input-group nzSize="large">
              <input
                disabled="true"
                nz-input
                id="{{ level }}CurrencySymbol{{ chargeIndex }}"
                placeholder="Enter Currency Symbol"
                class="w-full"
                [(ngModel)]="charges[level][chargeIndex].currencySymbol"
                name="{{ level }}CurrencySymbol{{ chargeIndex }}"
              />
            </nz-input-group>
          </div>
          <!-- Price Per Session -->
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="{{ level }}Price{{ chargeIndex }}"
              >Price Per Session</label
            >
            <nz-input-group nzSize="large">
              <input
                nz-input
                id="{{ level }}Price{{ chargeIndex }}"
                type="number"
                placeholder="Enter Price Per Session"
                class="w-full"
                [(ngModel)]="charges[level][chargeIndex].perSessionAmount"
                name="{{ level }}Price{{ chargeIndex }}"
              />
            </nz-input-group>
            <div
              *ngIf="
                therapyPlanForm
                  .get('charges.' + level + '.' + chargeIndex + '.amount')
                  ?.hasError('required')
              "
            >
              Price Per Session is required.
            </div>
          </div>
          <!-- MarkUp Price -->
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="{{ level }}MarkUpPrice{{ chargeIndex }}"
              >MarkUp Price</label
            >

            <nz-input-group nzSize="large">
              <input
                nz-input
                id="{{ level }}MarkUpPrice{{ chargeIndex }}"
                placeholder="Enter markup amount"
                class="w-full"
                [(ngModel)]="charges[level][chargeIndex].markupAmount"
                name="{{ level }}MarkUpPrice{{ chargeIndex }}"
              />
            </nz-input-group>
          </div>
          <!-- Savings -->
          <div>
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="{{ level }}Savings{{ chargeIndex }}"
              >Savings</label
            >
            <nz-input-group nzSize="large">
              <input
                nz-input
                id="{{ level }}Savings{{ chargeIndex }}"
                placeholder="Enter Currency Symbol"
                class="w-full"
                [(ngModel)]="charges[level][chargeIndex].savingText"
                name="{{ level }}Savings{{ chargeIndex }}"
              />
            </nz-input-group>
          </div>
        </div>
      </div>
    </div>
    <!-- Submit Button -->
    <div class="flex items-center justify-between mt-4">
      <button
        (click)="createPlan()"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create Plan
      </button>
    </div>
  </nz-spin>
</div>
