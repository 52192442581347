import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomHttpUrlEncodingCodec } from 'projects/app-core/src/app/utils/encoder';
import { ISubscription } from '../models/ISubscription';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  protected basePath = environment.serviceUrl;
  constructor(private api: NetworkUtilsService) {}

  public createSubscription(createSubscription: ISubscription) {
    return this.api.post(URL_DICT.subscription + 'create', createSubscription);
  }

  public getSubscription(partnerId: string) {
    return this.api.get<ISubscription>(
      URL_DICT.subscription + `${String(partnerId)}`,
    );
  }

  public updateSubscription(
    subscriptionId: string,
    updateSubscriptionRequest: ISubscription,
  ) {
    return this.api.post(
      URL_DICT.subscription + `${String(subscriptionId)}`,
      updateSubscriptionRequest,
    );
  }
}
