import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface ContentGridRequest
  extends GridRequest<
    ContentSort,
    ContentFilter,
    ContentSearch,
    ContentDateFilter
  > {}

export enum ContentSort {
  CONTENT_STATUS = 'STATUS',
}
export enum ContentSearch {
  CONTENT_NAME = 'CONTENT_PRACTICE_NAME',
}

export enum ContentDateFilter {
  CONTENT_CATEGORY = 'CONTENT_CATEGORY',
}

export enum ContentFilter {
  CONTENT_RATING = 'CONTENT_RATING',
  CONTENT_CATEGORY = 'CONTENT_CATEGORY',
  CONTENT_GENRE = 'CONTENT_GENRE',
}
