import { Component, Input } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { Roles } from '../../../auth/models/roles';
import { ToastService } from '../../services/toastr.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(
    private routerService: Router,
    public authService: AuthService,
    public toastService: ToastService,
  ) {}
  username: string = '';
  password: string = '';
  @Input() forgotpasswordpath!: string;
  @Input() registerpath!: string;
  @Input() loginType!: string;
  placeholder = 'Enter your password';
  border!: string;
  eyeClosedIcon = '../../../assets/icons/icon-eye.svg';
  eyeOpenIcon = '../../../assets/icons/icon-eye-open.svg';
  loading: boolean = false;

  onKey(event: any) {
    this.password = event.target.value;
  }

  async login() {
    //TODO add loader
    try {
      this.loading = true;
      await this.authService.signInWithEmailAndPassword(
        this.username,
        this.password,
        Roles[this.loginType as keyof typeof Roles],
      );
      await this.routerService.navigate(['/']);
      this.loading = true;
    } catch (e: unknown) {
      this.loading = false;
      this.toastService.showAnyoErrorToast(e);
    }
  }
}
