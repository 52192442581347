import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MemberService } from '../../../services/member.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  MemberGridResponse,
  MemberGridResponseData,
} from '../../../models/memberTable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

import { PartnerGridRequest } from '../../../models/partnerGridRequest';
import { lastValueFrom } from 'rxjs';
import { PartnerService } from '../../../services/partner.service';
import { Router } from '@angular/router';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { Roles } from 'projects/app-core/src/auth/models/roles';

@Component({
  selector: 'app-member-table',
  templateUrl: './member-table.component.html',
  styleUrl: './member-table.component.scss',
})
export class MemberTableComponent {
  pageSize: number = 10;
  memberTableCurrentPageNo: number = 1;
  setOfCheckedId: Set<string> = new Set<string>();
  listOfCurrentPageData: readonly MemberGridResponseData[] = [];
  checked: boolean = false;
  @Input() selectedTab!: number;
  @Input() tableData!: MemberGridResponse;
  memberSizeOptions = [10, 20, 50, 100];
  @Input() partnerName = '';
  emailColumnSearch: string = '';
  corporateCodeColumnSearch: string = '';
  codeRedeemColumnValue: string | undefined;
  partnerList: {
    label: string;
    value: string;
  }[] = [];
  @Output() reloadTable = new EventEmitter<void>();
  selectedPartner: string | undefined;
  transferLoading: boolean = false;

  @Output() exportMemberTableEvent = new EventEmitter<void>();
  @Output() resetFilterEvent = new EventEmitter<void>();
  @Output() pageSizeChangeEvent = new EventEmitter<number>();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() deleteMemberEvent = new EventEmitter<string>();
  @Output() corporateSearchEvent = new EventEmitter<string>();
  @Output() emailSearchEvent = new EventEmitter<string>();
  @Output() codeReedemFilterEvent = new EventEmitter<string>();
  protected showTransferMemberModal: boolean = false;
  private selectedMemberId: string | undefined;

  constructor(
    private memberService: MemberService,
    private toastService: ToastService,
    private modal: NzModalService,
    private partnerService: PartnerService,
    private router: Router,
    private auth: AuthService,
  ) {}

  navigateToUserProfile(code: string) {
    window.open(`${'/user-profile/'}?code=${code}`);
  }

  resetValues() {
    this.emailColumnSearch = '';
    this.codeRedeemColumnValue = '';
  }

  exportMemberTable() {
    this.exportMemberTableEvent.emit();
  }
  resetFilter() {
    this.resetFilterEvent.emit();
  }
  nzPageIndexChange($event: number) {
    this.pageChangeEvent.emit($event);
  }
  nzPageSizeChange($event: number) {
    this.pageSizeChangeEvent.emit($event);
  }
  deleteMemberAlert($event: string) {
    this.deleteMemberEvent.emit($event);
  }
  corporateCodeSearchFn() {
    this.corporateSearchEvent.emit(this.corporateCodeColumnSearch.trim());
  }
  codeRedeemColumnFilterFn() {
    this.codeReedemFilterEvent.emit(this.codeRedeemColumnValue);
  }
  emailColumnSearchFn() {
    this.emailSearchEvent.emit(this.emailColumnSearch.trim());
  }

  bulkSendMail(): void {
    if (this.setOfCheckedId.size == 0) {
      this.toastService.showError(
        'Please select atleast one member to send mail',
      );
      return;
    }
    this.modal.confirm({
      nzTitle: 'Send Mail',
      nzContent: `Do you want to send mail to ${this.setOfCheckedId.size} members?`,
      nzOnOk: async () => {
        return new Promise((resolve, reject) => {
          this.memberService
            .bulkSendMemberPass(Array.from(this.setOfCheckedId))
            .subscribe({
              next: () => {
                this.toastService.showSuccess('Mails sent successfully');
                resolve();
              },
              error: (err) => {
                const errorBody = err.error as IAnyoError;
                this.toastService.showAnyoErrorToast(errorBody.description);
                reject();
              },
            });
        });
      },
      nzOnCancel: async () => {},
      nzOkText: 'Send',
      nzCancelText: 'Cancel',
      nzClosable: false,
    });
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: string, checked: boolean) {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean) {
    this.listOfCurrentPageData.forEach(({ id }) =>
      this.updateCheckedSet(id, checked),
    );
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange(
    listOfCurrentPageData: readonly MemberGridResponseData[],
  ) {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.checked = this.listOfCurrentPageData.every(({ id }) =>
      this.setOfCheckedId.has(id),
    );
  }
  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(({ id }) =>
      this.setOfCheckedId.has(id),
    );
  }

  clearSelection(): void {
    this.setOfCheckedId.clear();
  }

  async showTransferPartnerFn(id: string) {
    this.selectedMemberId = id;
    this.showTransferMemberModal = true;
    const gridReq: PartnerGridRequest = {
      dateFilters: undefined,
      export: true,
      filters: undefined,
      page: 1,
      pageSize: 100,
      searchQuery: undefined,
      sort: undefined,
    };
    const girdRes = await lastValueFrom(
      this.partnerService.partnerList(gridReq),
    );
    const partners = girdRes.data;
    this.partnerList = partners
      .map((value) => {
        return {
          label: `${value.partner} (${value.partnerId})`,
          value: value.partnerId,
        };
      })
      .filter((value) => value.value != 'PR-9008');
  }

  handleCancelTransferModal() {
    this.selectedMemberId = undefined;
    this.showTransferMemberModal = false;
  }

  handleOkTransferModal() {
    if (!this.selectedPartner) {
      this.toastService.showError('Please select a partner');
      return;
    }
    if (!this.selectedMemberId) {
      this.toastService.showError('Please select a Member');
      return;
    }
    this.transferLoading = true;
    this.partnerService
      .transferMember(this.selectedPartner, this.selectedMemberId!)
      .subscribe({
        next: async (value) => {
          this.toastService.showSuccess('Member transferred successfully');
          this.showTransferMemberModal = false;
          this.reloadTable.emit();
          this.transferLoading = false;
        },
        error: (error) => {
          this.transferLoading = false;
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
        },
      });
  }

  async manageMember(id: string, partnerName: string) {
    await this.router.navigate([
      '/partner/addmember/' + id + '/' + partnerName,
    ]);
  }

  viewUserProfileRole = false;

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        if (user.customClaims?.roles.includes(Roles.ViewUserProfile)) {
          this.viewUserProfileRole = true;
        }
      }
    });
  }
}
