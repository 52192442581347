import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { ICountResponse } from '../models/IcountResponse';
import { EnableDisableRequest } from '../models/enableDisableRequest';
import { GetListenerResponse, IAnyoListener } from '../models/IAnyoListener';
import { ListenerGridResponse } from '../models/listenerGridResponse';
import { ListenerGridRequest } from '../models/listenerGridRequest';
import { NewCircleExpertRequest } from '../models/circles/newCIrcleExpertRequest';
import { UpdateCircleExpertRequest } from '../models/circles/updateExpertRequest';

interface listenerStatsResponse {
  totalChats: number;
  totalUsers: number;
  totalListeners: number;
}

export interface CreateListenerRequest extends IAnyoListener {
  expertDetails?: NewCircleExpertRequest;
}

export interface UpdateListenerRequest extends IAnyoListener {
  expertDetails?: UpdateCircleExpertRequest;
}

@Injectable({
  providedIn: 'root',
})
export class ListenerService {
  protected basePath = environment.serviceUrl;
  constructor(private api: NetworkUtilsService) {}

  public listenerCount() {
    return this.api.get<ICountResponse[]>(URL_DICT.listener + 'count');
  }

  public listenerList(listenerGridRequest: ListenerGridRequest) {
    return this.api.post<ListenerGridResponse>(
      URL_DICT.listener + 'grid/list',
      listenerGridRequest,
    );
  }

  public createListener(createListenerRequest: CreateListenerRequest) {
    return this.api.post(URL_DICT.listener, createListenerRequest);
  }

  public getListener(listenerId: string) {
    return this.api.get<GetListenerResponse>(
      URL_DICT.listener + `${String(listenerId)}`,
    );
  }

  public updateListener(
    listenerId: string,
    updateListenerRequest: UpdateListenerRequest,
  ) {
    return this.api.post(
      URL_DICT.listener + `${String(listenerId)}`,
      updateListenerRequest,
    );
  }

  public enableAndDisableListener(
    listenerId: string,
    enableAndDisableRequest: EnableDisableRequest,
  ) {
    console.log(listenerId, enableAndDisableRequest);
    return this.api.post(
      URL_DICT.listener + `availability/${listenerId}`,
      enableAndDisableRequest,
    );
  }

  public getListenerStats() {
    return this.api.get<listenerStatsResponse[]>(
      URL_DICT.listener + 'grid/stats',
    );
  }
}
