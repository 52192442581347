import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../service/network-utils.service';

import { SessionGridResponse } from '../models/sessionGridResponse';
import {
  CancelSessionRequest,
  ConfirmSessionRequest,
  UpdateSessionRequest,
} from '../models/SessionRequest';
import { SessionFeedbackFormSubmitRequest } from '../models/sessionFeedbackFormSubmitRequest';
import Acknowledgement from '../../../models/acknowledgement';
import { IAnyoTherapist } from '../../../../../admin/src/app/models/IAnyoTherapist';
import {
  AdminTherapistBookingRequest,
  SearchUserResponse,
  SlotAvailability,
} from 'projects/app-core/src/app/session/components/session-table/session-table.component';
import { SessionGridRequest } from '../models/sessionGridRequest';
import {
  SessionGridStatsData,
  SessionGridStatsResp,
} from '../models/sessionStatsData';
import { AppointmentStatus } from '../models/TherapistAppointment';

export interface SessionAddRemarkRequest {
  sessionId: string;
  remarks: string;
}

@Injectable({
  providedIn: 'root',
})
export class SessionsService {
  constructor(
    protected httpClient: HttpClient,
    protected api: NetworkUtilsService,
  ) {}

  public addRemarkForPastSession(request: SessionAddRemarkRequest) {
    return this.api.post(URL_DICT.sessionExpertAddRemark, request);
  }

  public fetchSessions(gridRequest: SessionGridRequest) {
    return this.api.post<SessionGridResponse>(
      URL_DICT.adminSessionGrid,
      gridRequest,
    );
  }
  public confirmAppointment(
    sessionId: string,
    confirmSessionRequest: ConfirmSessionRequest,
  ) {
    return this.api.post<Acknowledgement>(
      URL_DICT.adminSessionConfirmUrl + `/${String(sessionId)}`,
      confirmSessionRequest,
    );
  }
  public cancelAppointment(
    sessionId: string,
    cancelSessionRequest: CancelSessionRequest,
  ) {
    return this.api.post<Acknowledgement>(
      URL_DICT.adminSessionCancelUrl + `/${String(sessionId)}`,
      cancelSessionRequest,
    );
  }

  public updateAppointment(
    sessionId: string,
    updateSessionRequest: UpdateSessionRequest,
  ) {
    return this.api.post(
      URL_DICT.adminSessionUpdateUrl + `/${String(sessionId)}`,
      updateSessionRequest,
    );
  }

  public updateSessionFeedbackFom(request: SessionFeedbackFormSubmitRequest) {
    return this.api.post<Acknowledgement>(
      URL_DICT.adminSessionFeedBack,
      request,
    );
  }

  public getActiveTherapists() {
    return this.api.get<IAnyoTherapist[]>(URL_DICT.getActiveTherapists);
  }

  public adminSessionBooking(request: AdminTherapistBookingRequest) {
    return this.api.post<Acknowledgement>(
      URL_DICT.adminSessionBooking,
      request,
    );
  }

  public adminSearchUsers(searchText: String) {
    return this.api.get<SearchUserResponse[]>(
      `${URL_DICT.adminSearchUsers}?user=${searchText}`,
    );
  }

  public getAvailableSlotsTherapist(selectedTherapist: string, label: string) {
    return this.api.get<Record<string, SlotAvailability>>(
      URL_DICT.getAvailableSlots + `${selectedTherapist}?email=${label}`,
    );
  }

  public fetchSessionsForTherapist(gridRequest: SessionGridRequest) {
    return this.api.post<SessionGridResponse>(
      URL_DICT.supportPortal + 'sessions/grid',
      gridRequest,
    );
  }

  public sessionGridStats(request: SessionGridRequest) {
    return this.api.post<SessionGridStatsResp>(
      URL_DICT.sessionStatsUrl,
      request,
    );
  }

  public mapSessionGridStatsResp(value: SessionGridStatsResp) {
    const response: SessionGridStatsData = {
      totalSessions: value.totalSessions,
      confirmedSessions:
        value.allOtherSessions.find(
          (data) => data._id === AppointmentStatus.CONFIRMED,
        )?.count || 0,
      cancelledSessions:
        value.allOtherSessions.find(
          (data) => data._id === AppointmentStatus.CANCELLED,
        )?.count || 0,
      completedSessions:
        value.allOtherSessions.find(
          (data) => data._id === AppointmentStatus.COMPLETED,
        )?.count || 0,
      noShowUserSessions:
        value.allOtherSessions.find(
          (data) => data._id === AppointmentStatus.NO_SHOW_USER,
        )?.count || 0,
      noShowTherapistSessions:
        value.allOtherSessions.find(
          (data) => data._id === AppointmentStatus.NO_SHOW_THERAPIST,
        )?.count || 0,
      techIssueSessions:
        value.allOtherSessions.find(
          (data) => data._id === AppointmentStatus.TECH_ISSUE,
        )?.count || 0,
      feedbackPendingSessions:
        value.allOtherSessions.find(
          (data) => data._id === AppointmentStatus.FEEDBACK_PENDING,
        )?.count || 0,
    };
    return response;
  }
}
