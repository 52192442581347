import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { CreatePostRequestAdmin } from '../model/createPostRequestAdmin';
import {
  UploadFileRequest,
  UploadFileUrlResponse,
} from '../model/postMediaUpload';
import { CirclePostsGridRequest } from '../model/postsGridRequest';
import { CirclesPostsGridResponse } from '../model/postsGridResponse';
import { CirclePostReportsGridRequest } from '../model/circlePostReportsGridRequest';
import { CirclesPostReportsGridResponse } from '../model/circlePostReportsGridResponse';
import { ModeratorActionsRequest } from '../model/moderatorActionsRequest';
import { CirclePost } from '../model/CirclePost';
import { AnyoCircles } from 'projects/admin/src/app/models/circles/AnyoCircles';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  constructor(private api: NetworkUtilsService) {}

  public addPost(request: CreatePostRequestAdmin) {
    return this.api.post(URL_DICT.adminPostsUrl, request);
  }

  public generateUploadMediaUrl(request: UploadFileRequest[]) {
    return this.api.post<UploadFileUrlResponse[]>(
      URL_DICT.postsMediaUploadUrl,
      request,
    );
  }

  public postsGrid(request: CirclePostsGridRequest) {
    return this.api.post<CirclesPostsGridResponse>(
      URL_DICT.adminCirclePostsGrid,
      request,
    );
  }

  public postReportGrid(request: CirclePostReportsGridRequest) {
    return this.api.post<CirclesPostReportsGridResponse>(
      URL_DICT.adminCirclePostReportsGrid,
      request,
    );
  }

  public moderatorActions(request: ModeratorActionsRequest) {
    return this.api.post(URL_DICT.adminCirclePostModeratorActions, request);
  }

  public getPost(id: string) {
    return this.api.get<CirclePost>(URL_DICT.adminCirclePostGet + `${id}`);
  }

  public getCirclesForUser() {
    return this.api.get<AnyoCircles[]>(URL_DICT.adminCirclesGetUrl);
  }
}
