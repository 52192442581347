import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { UserJourneyResp } from '../models/user-profile/UserProfileSearchResponse';

export interface UserJourneyRequest {
  emailOrCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private api: NetworkUtilsService) {}

  public searchUserProfile(req: UserJourneyRequest) {
    return this.api.post<UserJourneyResp>(URL_DICT.userProfileSearchUrl, req);
  }
}
