import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CirclesRoutingModule } from './circles-routing.module';
import { AddCircleComponent } from './add-circle/add-circle.component';
import { ManageCircleComponent } from './manage-circle/manage-circle.component';
import { ViewCircleComponent } from './view-circle/view-circle.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { AddModeratorComponent } from './add-moderator/add-moderator.component';
import { ManageModeratorComponent } from './manage-moderator/manage-moderator.component';
import { ViewModeratorComponent } from './view-moderator/view-moderator.component';

@NgModule({
  declarations: [
    AddCircleComponent,
    ManageCircleComponent,
    ViewCircleComponent,
    ManageModeratorComponent,
    AddModeratorComponent,
    ViewModeratorComponent,
  ],
  imports: [
    CommonModule,
    CirclesRoutingModule,
    NzSpinModule,
    NzGridModule,
    FormsModule,
    NzInputModule,
    NzDividerModule,
    NzCardModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    NzSwitchModule,
    NzTableModule,
  ],
})
export class CirclesModule {}
