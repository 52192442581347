<div class="flex flex-row justify-between visible md:visible mb-3">
  <div class="flex flex-row justify-between gap-2">
    <app-stats-card
      [count]="sessionStatsData.totalSessions"
      text="Total Sessions"
      image="../../../../assets/icons/members-icon.svg"
    ></app-stats-card>
    <app-stats-card
      [count]="sessionStatsData.confirmedSessions"
      text="Total Confirmed Sessions"
      image="../../../../assets/icons/subscription-icon.svg"
    ></app-stats-card>
    <app-stats-card
      [count]="sessionStatsData.cancelledSessions"
      text="Total Canceled Sessions"
      image="../../../../assets/icons/members-icon.svg"
    ></app-stats-card>
    <app-stats-card
      [count]="sessionStatsData.completedSessions"
      text="Total Completed Sessions"
      image="../../../../assets/icons/members-icon.svg"
    ></app-stats-card>
  </div>
</div>
<div class="flex flex-row justify-between visible md:visible mb-3">
  <div class="flex flex-row justify-between gap-2">
    <app-stats-card
      [count]="sessionStatsData.noShowUserSessions"
      text="No Show User Sessions"
      image="../../../../assets/icons/members-icon.svg"
    ></app-stats-card>
    <app-stats-card
      [count]="sessionStatsData.noShowTherapistSessions"
      text="No Show Therapist Sessions"
      image="../../../../assets/icons/subscription-icon.svg"
    ></app-stats-card>
    <app-stats-card
      [count]="sessionStatsData.techIssueSessions"
      text="Tech Issue Sessions"
      image="../../../../assets/icons/members-icon.svg"
    ></app-stats-card>
    <app-stats-card
      [count]="sessionStatsData.feedbackPendingSessions"
      text="Feedback Pending Sessions"
      image="../../../../assets/icons/members-icon.svg"
    ></app-stats-card>
  </div>
</div>
<nz-spin [nzSpinning]="tableSpinner">
  <div nz-row nzJustify="end" nzAlign="middle">
    <app-mark-unavailable-slot
      *ngIf="isAdminPortal"
      [therapistId]="therapistId"
      [therapistName]="therapistName"
    ></app-mark-unavailable-slot>
    <div nz-col class="p-1">
      <nz-range-picker
        [nzDisabledDate]="disabledDate"
        [nzFormat]="dateFormat"
        [(ngModel)]="dateRange"
        (ngModelChange)="dateChange($event)"
      ></nz-range-picker>
    </div>
    <div nz-col class="p-1">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input
          type="text"
          nz-input
          [(ngModel)]="globalSearchInput"
          placeholder="Search for email,phone number"
        />
      </nz-input-group>
    </div>
  </div>
  <div nz-row>
    <nz-tabset (nzSelectChange)="tabChange($event)">
      <nz-tab nzTitle="Upcoming Sessions">
        <app-session-table
          #sessionTableComponent
          [inputTableData]="tableData"
          (sessionStatusFilterEvent)="sessionStatusFilter($event)"
          (therapistFilterEvent)="therapistFilterFn($event)"
          (sessionDateSortEvent)="sessionDateSort($event)"
          (sessionDateFilterEvent)="sessionDateFilter($event)"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (cancelAppointmentEvent)="cancelAppointmentFn($event)"
          (viewPatientDetailsEvent)="showPatientDetailsFn($event)"
          (confirmAppointmentEvent)="confirmAppointmentFn($event)"
          (updateAppointmentEvent)="updateAppointment($event)"
          (reloadTableEvent)="reloadTableFn()"
          (feedbackSubmitEvent)="submitFeedBack($event)"
          [showNewBookingBtn]="showNewBookingBtn"
          [therapistWithId]="therapistSelectData"
          [showUpdateButtonPortal]="true"
          (hideTestBookingEvent)="hideTestBookingFn($event)"
          (partnerIdFilterEvent)="filterPartnerIdFn($event)"
          (exportDataEvent)="exportDataFn()"
          (partnerNameFilterEvent)="filterPartnerNameFn($event)"
          [tableQuery]="sessionTableQuery"
        ></app-session-table>
      </nz-tab>
      <nz-tab nzTitle="All Sessions">
        <app-session-table
          #allSessionTableComponent
          [inputTableData]="tableData"
          (exportDataEvent)="exportDataFn()"
          (partnerIdFilterEvent)="filterPartnerIdFn($event)"
          (sessionStatusFilterEvent)="sessionStatusFilter($event)"
          (therapistFilterEvent)="therapistFilterFn($event)"
          (sessionDateSortEvent)="sessionDateSort($event)"
          (sessionDateFilterEvent)="sessionDateFilter($event)"
          (cancelAppointmentEvent)="cancelAppointmentFn($event)"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (confirmAppointmentEvent)="confirmAppointmentFn($event)"
          (viewPatientDetailsEvent)="showPatientDetailsFn($event)"
          (reloadTableEvent)="reloadTableFn()"
          (resetFilterEvent)="resetFilter()"
          (feedbackSubmitEvent)="submitFeedBack($event)"
          (updateAppointmentEvent)="updateAppointment($event)"
          [therapistWithId]="therapistSelectData"
          [showNewBookingBtn]="showNewBookingBtn"
          [showUpdateButtonPortal]="showUpdateButton"
          (hideTestBookingEvent)="hideTestBookingFn($event)"
          (partnerNameFilterEvent)="filterPartnerNameFn($event)"
          [tableQuery]="sessionTableQuery"
        ></app-session-table>
      </nz-tab>
    </nz-tabset>
  </div>
  <ng-template #suffixButton>
    <button
      class="btn-color pr-1"
      nz-button
      nzType="primary"
      nzSize="small"
      nzSearch
      (click)="searchGlobal()"
    >
      Search
    </button>
  </ng-template>
</nz-spin>

<app-alert-box
  *ngIf="patientAlert"
  [alertHeader]="patientHeader"
  [isPatient]="true"
  [patientDetails]="patient"
  (closeAlert)="close()"
></app-alert-box>
<div *ngIf="confirmAlert">
  <app-alert-box
    #confirmAppointmentAlertBox
    [alertHeader]="confirmHeader"
    [date]="upcomingDate"
    [time]="upcomingTime"
    [callLink]="callLink"
    [isCalendar]="true"
    [isVideoLink]="true"
    [isTherapistName]="true"
    (closeAlert)="close()"
    [alertDate]="alertSuccess"
    (appointment)="confirmAppointment($event)"
    [objectArray]="therapistDetailsWithId"
    [defaultTherapist]="fullNameTherapist"
  ></app-alert-box>
</div>
<div *ngIf="cancelAlert">
  <app-alert-box
    #cancelAppointmentAlertBox
    [isInput]="true"
    [alertHeader]="cancelHeader"
    (closeAlert)="close()"
    [alertBody]="cancelBody"
    (inputValue)="cancelAppointment($event)"
    [cancelAppoinment]="true"
    [userEmail]="userEmail"
    [userName]="userName"
  ></app-alert-box>
</div>
