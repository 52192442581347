import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progressbar-with-timer-listener',
  templateUrl: './progressbar-with-timer-listener.component.html',
  styleUrls: ['./progressbar-with-timer-listener.component.scss'],
})
export class ProgressbarWithTimerListenerComponent implements OnInit {
  // @Input() duration!:number; // Duration in seconds
  // timerText: string=" ";
  // progressOffset:any=0;
  // color:string="#5A55D2";
  // changeTime:boolean=false;
  // ngOnInit() {
  //   this.startTimer();
  // }

  // startTimer() {
  //   const initialDuration = this.duration;
  //   const interval = 1000; // Interval in milliseconds
  //   const increment = 360 / initialDuration;

  //   const timerInterval = setInterval(() => {
  //     if (this.duration <= 0) {
  //       clearInterval(timerInterval);
  //       return;
  //     }

  //     this.timerText = this.formatTime(this.duration);
  //     this.progressOffset = (initialDuration - this.duration) * increment;
  //     this.changeTime=this.duration < 120;
  //     this.duration--;
  //     // console.log(this.progressOffset);
  //   }, interval);

  // }

  // formatTime(seconds: number): string {
  //   // const minutes = Math.floor(seconds / 60);
  //   // const remainingSeconds = seconds % 60;
  //   // const formattedMinutes = String(minutes).padStart(2, '0');
  //   // const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  //   // return `00:${formattedMinutes}:${formattedSeconds}`;
  //   const hours = Math.floor(seconds / 3600);
  // const minutes = Math.floor((seconds % 3600) / 60);
  // const remainingSeconds = seconds % 60;

  // const formattedHours = String(hours).padStart(2, '0');
  // const formattedMinutes = String(minutes).padStart(2, '0');
  // const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  // return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // }
  @Input() duration!: number; // Duration in seconds
  @Input() warningTime!: number;
  timerText: string = '00:00:00';
  progressOffset: any = 0;
  color: string = '#5A55D2';
  changeTime: boolean = false;

  ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    const interval = 1000; // Interval in milliseconds
    let elapsedDuration = 0;

    const timerInterval = setInterval(() => {
      elapsedDuration++;
      this.timerText = this.formatTime(elapsedDuration);
      this.progressOffset = (elapsedDuration / this.duration) * 360;
      this.color = elapsedDuration > this.warningTime ? '#EB5757' : '#5A55D2';
      this.progressOffset = this.progressOffset % 360;
      this.changeTime = elapsedDuration < 120;

      if (elapsedDuration >= this.duration) {
        clearInterval(timerInterval);
      }
    }, interval);
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
}
