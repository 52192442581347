import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListenerRoutingModule } from './listener-routing.module';
import { ManageComponent } from './manage/manage.component';
import { AddComponent } from './add/add.component';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Select2Module } from 'ng-select2-component';
import { RouterModule } from '@angular/router';
import { CommonComponentModule } from 'projects/app-core/src/app/components/commoncomponent.module';
import { HttpLoaderFactory } from '../../app.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  declarations: [ManageComponent, AddComponent],
  imports: [
    NzSelectModule,
    NzButtonModule,
    NzSpinModule,
    NzDropDownModule,
    NzCheckboxModule,
    NzGridModule,
    NzDividerModule,
    NzToolTipModule,
    NzRadioModule,
    NzInputModule,
    NzIconModule,
    NzTableModule,
    NzSwitchModule,
    CommonComponentModule,
    RouterModule,
    CommonModule,
    ListenerRoutingModule,
    FormsModule,
    Select2Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    InfiniteScrollModule,
  ],
})
export class ListenerModule {}
