<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div
    class="flex flex-wrap w-1/4 justify-start"
    *ngIf="!viewForm && !editMode"
  >
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/partner/managemember', member.partnerId, partnerName]"
    />
    <h1 class="headeradminui-text">Add Member</h1>
  </div>
  <div class="flex flex-wrap w-1/4 justify-start" *ngIf="viewForm">
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/partner/managemember', member.partnerId, partnerName]"
    />
    <h1 class="headeradminui-text">View Member</h1>
  </div>
  <div class="flex flex-wrap w-1/4 justify-start" *ngIf="editMode">
    <img
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
      [routerLink]="['/partner/managemember', member.partnerId, partnerName]"
    />
    <h1 class="headeradminui-text">Edit Member</h1>
  </div>
</div>

<div class="custom-card">
  <div class="card-body">
    <form #addMemberForm="ngForm" (ngSubmit)="submit(addMemberForm)">
      <div class="flex flex-col flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2">
        <div
          *ngIf="viewForm || editMode"
          class="flex flex-row justify-end gap-2"
        >
          <div *ngIf="showDisable && !isDisabled" class="flex justify-end">
            <button
              type="button"
              class="btn btn-error rounded-md"
              (click)="dialogShow()"
            >
              Delete
            </button>
          </div>
          <div *ngIf="showDisable && isDisabled" class="flex justify-end">
            <button
              type="button"
              class="btn btn-success"
              (click)="dialogShow()"
            >
              Enable
            </button>
          </div>
          <div *ngIf="isAlert">
            <app-alert-box
              [alertHeader]="alertHeaderDisable"
              [alertBody]="alertBodyDisable"
              (closeAlert)="dialogShow()"
              (isDisable)="deleteMember()"
            ></app-alert-box>
          </div>
          <div
            *ngIf="viewForm"
            class="flex items-center justify-end flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div
              class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
              (click)="editForm()"
            >
              <div>
                <img
                  class="add-icon"
                  src="../../../../assets/icons/add-icon.svg"
                />
              </div>
              <div>
                <span class="">Edit Member</span>
              </div>
            </div>
          </div>
          <div
            *ngIf="editMode"
            class="flex flex-row bg-white justify-between btn btn-discard gap-1"
            [routerLink]="['/partner']"
          >
            Discard
          </div>
        </div>
        <div *ngIf="!viewForm && !editMode">
          <div
            class="flex items-center justify-end flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
          >
            <div
              class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
              [routerLink]="[
                '/partner/addmember/bulk',
                member.partnerId,
                partnerName
              ]"
            >
              <div>
                <img
                  class="add-icon"
                  src="../../../../assets/icons/add-icon.svg"
                />
              </div>
              <div>
                <span class="">Add Bulk Members</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex items-center justify-between flex-wrap md:gap-4 lg:gap-4 sm:gap-2 gap-2"
        >
          <div class="flex-auto">
            <label class="form-label required">Email ID</label>
            <input
              class="input-adminui small-text"
              name="email"
              placeholder="Enter e-mail"
              [(ngModel)]="member.email"
              ngModel
              required
              [disabled]="viewForm"
            />
          </div>
          <div class="flex-auto">
            <label class="form-label">Department Name</label>
            <input
              class="input-adminui small-text"
              name="deptname"
              placeholder="Enter Dept Name"
              [(ngModel)]="member.department"
              ngModel
              [disabled]="viewForm"
            />
          </div>
          <div class="flex-auto">
            <label class="form-label">Branch</label>
            <input
              class="input-adminui small-text"
              name="branch"
              placeholder="Enter Branch"
              [(ngModel)]="member.branch"
              ngModel
              [disabled]="viewForm"
            />
          </div>
          <div *ngIf="viewForm || editMode" class="flex-auto">
            <label class="form-label">Corporate Code</label>
            <input
              class="input-adminui small-text"
              name="corporatecode"
              [(ngModel)]="member.corporateCode"
              ngModel
              disabled
            />
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-8">
        <div class="flex justify-end w-full">
          <button
            type="button"
            class="btn btn-primary btn-block-adminui enable-button"
            [routerLink]="[
              '/partner/managemember',
              member.partnerId,
              partnerName
            ]"
          >
            Cancel
          </button>
          <button
            *ngIf="!viewForm && !editMode"
            class="btn btn-primary btn-bigger"
            [disabled]="!addMemberForm.valid"
          >
            Create Member
          </button>
          <button
            *ngIf="editMode"
            class="btn btn-primary btn-bigger"
            [disabled]="!addMemberForm.valid"
          >
            Edit Member
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
