import { CircleTopics } from './app/models/circles/AnyoCircles';

export const CONFIG = {
  VIDEO_URL: '`http://localhost:3000/contents/${id}`',
};
export const TEST_USER_INTERESTS_TAGS: CircleTopics[] = [
  {
    key: '573fa07536f74382a82c7d7452d632cc',
    value: 'Testing Tag 1',
  },
  {
    key: '8d19427b89c84faf8d38eb24297de687',
    value: 'Testing Tag 2',
  },
];
export const GENERAL_CIRCLE_TOPIC_TAG: CircleTopics = {
  key: 'd8d70d6daa5e428b9c7bd32fc3ef8464',
  value: 'General',
};
