import {Component} from '@angular/core';
import {AuthService} from '../../../../../../app-core/src/auth/auth.service';
import {Permissions} from '../../../../../../app-core/src/auth/models/roles';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {download, generateCsv, mkConfig} from 'export-to-csv';
import {ToastService} from '../../../../../../app-core/src/app/services/toastr.service';
import {FileUtilsService} from '../../../../../../app-core/src/service/fileService';
import {IAnyoError} from '../../../../../../app-core/src/models/errorModal';
import {MasterdataService} from '../../../services/masterdata.service';
import {NewAddBytesRequest} from '../../../models/newCreateBytesRequest';
import {Frame} from '../../../models/anyoByte';
import {BytesService} from '../../../services/bytes.service';

interface BytesBulkUploadCSVTemplate {
  category: string;
  title: string;
  noOfFrames: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  frame1?: string;
  frame2?: string;
  frame3?: string;
  frame4?: string;
  frame5?: string;
  frame6?: string;
  frame7?: string;
  frame8?: string;
  frame9?: string;
  frame10?: string;
  error?: string;
}

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
})
export class BulkUploadComponent {
  CSV_DATE_FORMAT = 'DD/MM/yyyy';
  CSV_TIME_FORMAT = 'HH:mm';
  pageLoading: boolean = false;
  private bytesCategories: { label: string; value: string }[] = [];

  constructor(
    private auth: AuthService,
    private routerService: Router,
    private toastService: ToastService,
    private fileService: FileUtilsService,
    private masterDataService: MasterdataService,
    private bytesService: BytesService,
  ) {
  }

  async csvFileSelected($event: any) {
    if (!$event.target.files[0]) {
      this.toastService.showError('Please select a CSV file');
      return;
    }
    this.pageLoading = true;
    const file = $event.target.files[0];
    const parsed =
      await this.fileService.parseCSV<BytesBulkUploadCSVTemplate>(file);
    const categories = this.bytesCategories.map((value) => value.label);
    for (let i = 0; i < parsed.length; i++) {
      const row: BytesBulkUploadCSVTemplate = parsed[i];
      const startDate = moment(
        row.startDate + ' ' + row.startTime,
        this.CSV_DATE_FORMAT + ' ' + this.CSV_TIME_FORMAT,
      );
      const endDate = moment(
        row.endDate + ' ' + row.endTime,
        this.CSV_DATE_FORMAT + ' ' + this.CSV_TIME_FORMAT,
      );
      const errors = [];
      if (!startDate.isAfter(moment())) {
        errors.push('Start date must be in the future');
      }
      if (!endDate.isAfter(startDate)) {
        errors.push('End date must be after start date');
      }
      if (!row.title) {
        errors.push('Title is required');
      }
      if (!categories.includes(row.category)) {
        errors.push('Invalid category');
      }
      if (row.noOfFrames < 1 || row.noOfFrames > 10) {
        errors.push('Number of frames must be between 1 and 10');
      }
      for (let j = 1; j <= row.noOfFrames; j++) {
        if (!(row as any)[`frame${j}`]) {
          errors.push(`Frame ${j} is required`);
        }
      }
      parsed[i].error = errors.join(', ');
    }
    if (parsed.some((row) => row.error)) {
      this.toastService.showError('Invalid CSV file');
      this.fileService.exportCSV(parsed, file.name);
      this.pageLoading = false;
      return;
    }
    const newAddBytesRequests: NewAddBytesRequest[] = [];
    for (let i = 0; i < parsed.length; i++) {
      const row: BytesBulkUploadCSVTemplate = parsed[i];
      const startDate = moment(
        row.startDate + ' ' + row.startTime,
        this.CSV_DATE_FORMAT + ' ' + this.CSV_TIME_FORMAT,
      );
      const endDate = moment(
        row.endDate + ' ' + row.endTime,
        this.CSV_DATE_FORMAT + ' ' + this.CSV_TIME_FORMAT,
      );
      const category = this.bytesCategories.find(
        (value) => value.label === row.category,
      );
      const newAddBytesRequest: NewAddBytesRequest = {
        categoryId: category!.value,
        isActive: true,
        title: row.title,
        noOfFrames: row.noOfFrames,
        frames: [],
        startTime: startDate.format('x'),
        endTime: endDate.format('x'),
      };
      for (let j = 1; j <= row.noOfFrames; j++) {
        const frame: Frame = {
          value: (row as any)[`frame${j}`],
          textColor: '#000000',
          textBGColor: '#ffffff',
          showTimeSeconds: 10,
        };
        newAddBytesRequest.frames.push(frame);
      }
      newAddBytesRequests.push(newAddBytesRequest);
    }
    this.bytesService.bulkUploadBytes(newAddBytesRequests).subscribe({
      next: (value) => {
        this.pageLoading = false;
        this.toastService.showSuccess('Bytes uploaded successfully');
        this.routerService.navigate(['/bytes']);
      },
      error: (error) => {
        this.pageLoading = false;
        const errorBody = error.error as NewAddBytesRequest[];
        const errorCSVData: BytesBulkUploadCSVTemplate[] = [];
        for (let i = 0; i < errorBody.length; i++) {
          const newAddBytesRequest: NewAddBytesRequest = errorBody[i];
          const startDate = moment(newAddBytesRequest.startTime, 'x');
          const endDate = moment(newAddBytesRequest.endTime, 'x');
          const errorCSVRow: BytesBulkUploadCSVTemplate = {
            category: this.bytesCategories.find(
              (value) => value.value === newAddBytesRequest.categoryId,
            )!.label!,
            title: newAddBytesRequest.title,
            noOfFrames: newAddBytesRequest.noOfFrames,
            startDate: startDate.format(this.CSV_DATE_FORMAT),
            startTime: startDate.format(this.CSV_TIME_FORMAT),
            endDate: endDate.format(this.CSV_DATE_FORMAT),
            endTime: endDate.format(this.CSV_TIME_FORMAT),
          };
          newAddBytesRequest.frames.forEach((frame, index) => {
            (errorCSVRow as any)[`frame${index + 1}`] = frame.value;
          });
          errorCSVRow.error = newAddBytesRequest.error;
          errorCSVData.push(errorCSVRow);
        }
        this.toastService.showError('Error while bulk uploading bytes');
        this.fileService.exportCSV(errorCSVData, file.name);
      },
    });
  }

  downloadTemplate() {
    const sampleData: BytesBulkUploadCSVTemplate[] = [];
    sampleData.push({
      category: 'Relationships',
      title: 'What is your Parenting style ?',
      startDate: moment().add(1, 'day').format(this.CSV_DATE_FORMAT),
      startTime: '20:00',
      endDate: moment().add(2, 'day').format(this.CSV_DATE_FORMAT),
      endTime: '20:00',
      noOfFrames: 2,
      frame1:
        'Authoritative : You offer a balance of warmth, support, and reasonable rules',
      frame2:
        'Authoritarian : You impose strict rules and discipline with limited emotional support',
      frame3: '',
      frame4: '',
      frame5: '',
      frame6: '',
      frame7: '',
      frame8: '',
      frame9: '',
      frame10: '',
    });
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename: `bulk_upload_bytes_${moment().format('DD-MM-yyyy_HH-mm-ss')}`,
    });
    const csv = generateCsv(csvConfig)(
      sampleData as unknown as Record<string, string>[],
    );
    download(csvConfig)(csv);
  }

  ngOnInit() {
    this.pageLoading = true;
    this.auth.currentAuthStatus.subscribe(async (user) => {
      this.masterDataService.masterDataList().subscribe({
        next: (value) => {
          this.bytesCategories = value.bytesCategories.map((data) => {
            return {
              label: data.name,
              value: data._id!,
            };
          });
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
        },
      });
      if (user) {
        this.pageLoading = false;
        if (
          !user.customClaims ||
          !user.customClaims.additionalPermissions.length ||
          !user.customClaims!.additionalPermissions.includes(
            Permissions.BytesManager,
          )
        ) {
          await this.auth.signOut();
          await this.routerService.navigate(['/login']);
        }
      }
    });
  }
}
