import { Component, ViewChild } from '@angular/core';
import {
  BytesGridRequest,
  BytesGridTab,
} from '../../../models/bytesGridRequest';
import { BytesService } from '../../../services/bytes.service';
import { lastValueFrom } from 'rxjs';
import { BytesGridResponse } from '../../../models/bytesGridResponse';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { BytesTableComponent } from '../../../../../../app-core/src/app/components/bytes-table/bytes-table.component';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';

@Component({
  selector: 'app-manage-bytes',
  templateUrl: './manage-bytes.component.html',
  styleUrls: ['./manage-bytes.component.scss'],
})
export class ManageBytesComponent {
  totalCount: number = 0;
  bytesTableSpinner!: boolean;
  bytesTableQuery: BytesGridRequest | undefined;
  globalSearchInput = '';
  tableData: BytesGridResponse = {
    data: [],
    filterOptions: {
      categories: [],
    },
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };
  private pageSize: number = 10;
  tabs = [BytesGridTab.BYTES_SCHEDULED, BytesGridTab.BYTES_DELIVERED];
  selectedTab: number = 0;

  constructor(
    private bytes: BytesService,
    private toastrService: ToastService,
    private auth: AuthService,
  ) {}

  @ViewChild('bytesScheduledTableComponent')
  bytesScheduledTableComponent?: BytesTableComponent;
  @ViewChild('bytesDeliveredTableComponent')
  bytesDeliveredTableComponent?: BytesTableComponent;

  async listBytes() {
    try {
      this.bytesTableSpinner = true;
      this.tableData = await lastValueFrom(
        this.bytes.listBytes(this.bytesTableQuery!),
      );
      this.totalCount = this.tableData.totalRecords;
      this.bytesTableSpinner = false;
      if (this.bytesScheduledTableComponent) {
        this.bytesScheduledTableComponent.setBytesCategoryFilterData(
          this.tableData.filterOptions,
        );
      }
      if (this.bytesDeliveredTableComponent) {
        this.bytesDeliveredTableComponent.setBytesCategoryFilterData(
          this.tableData.filterOptions,
        );
      }
    } catch (e) {
      this.bytesTableSpinner = false;
      this.toastrService.showError('Something went wrong');
    }
  }

  async searchGlobal() {
    this.bytesTableQuery!.globalSearch = [this.globalSearchInput];
    await this.listBytes();
  }

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        this.bytesTableQuery = {
          sort: {
            BYTES_START_DATE: AnyoSortDirection.ASC,
          },
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          tab: BytesGridTab.BYTES_SCHEDULED,
          to: undefined,
        };
        await this.listBytes();
      }
    });
  }

  async resetFilter() {
    this.globalSearchInput = '';
    this.bytesTableQuery = {
      export: false,
      sort: {
        BYTES_START_DATE: AnyoSortDirection.ASC,
      },
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.bytesDeliveredTableComponent) {
      this.bytesDeliveredTableComponent!.resetValues();
    }
    if (this.bytesScheduledTableComponent) {
      this.bytesScheduledTableComponent!.resetValues();
    }

    await this.listBytes();
  }

  async tabChange($event: NzTabChangeEvent) {
    this.selectedTab = $event.index!;
    console.log($event);
    this.tableData = {
      data: [],
      filterOptions: {
        categories: [],
      },
      pageNum: 1,
      pageSize: 10,
      totalPages: 0,
      totalRecords: 0,
    };

    this.globalSearchInput = '';
    this.bytesTableQuery = {
      export: false,
      sort: {
        BYTES_START_DATE: AnyoSortDirection.ASC,
      },
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.bytesDeliveredTableComponent) {
      this.bytesDeliveredTableComponent!.resetValues();
    }
    if (this.bytesScheduledTableComponent) {
      this.bytesScheduledTableComponent!.resetValues();
    }
    if ($event.index == 0) {
      this.bytesTableQuery!.tab = BytesGridTab.BYTES_SCHEDULED;
    }
    if ($event.index == 1) {
      this.bytesTableQuery!.tab = BytesGridTab.BYTES_DELIVERED;
    }
    await this.listBytes();
  }

  async bytesDateSort($event: string | null) {
    if ($event) {
      this.bytesTableQuery!.sort!.BYTES_START_DATE =
        $event === 'ascend' ? AnyoSortDirection.ASC : AnyoSortDirection.DESC;
    } else {
      this.bytesTableQuery!.sort!.BYTES_START_DATE = AnyoSortDirection.ASC;
    }
    await this.listBytes();
  }

  async bytesCategoryFilter($event: string[]) {
    if (!this.bytesTableQuery!.filters) {
      this.bytesTableQuery!.filters = {
        BYTES_CATEGORY: [],
        BYTES_STATUS: [],
        BYTES_VIEWS: [],
      };
    }
    if ($event && $event.length) {
      this.bytesTableQuery!.filters.BYTES_CATEGORY = $event;
      await this.listBytes();
    }
  }

  async nzPageSizeChange(pageSize: number) {
    this.bytesTableQuery!.pageSize = pageSize;
    await this.listBytes();
  }

  async nzPageIndexChange(page: number) {
    if (this.bytesTableQuery) {
      this.bytesTableQuery!.page = page;
      await this.listBytes();
    }
  }
}
