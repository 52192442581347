import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
// import { AnyoTranslateService } from '../../services/anyo-translate.service';
// import { isoToDDMMYYHHMM } from '../../utils/utils';
// import { CookieService } from 'ngx-cookie-service';
// import { Router } from '@angular/router';
// import { UserStateService } from 'src/app/State/User/userstate.service';
// import { CacheService } from '../../services/cache.service';
// import { environment } from 'src/environments/environment';
// import { END } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {
  name: string = 'error';
  @Input() user: any;
  @Input() domainName: string = '';
  // constructor(private translate: AnyoTranslateService, private cookieService: CookieService, private cacheService:CacheService) {
  // }

  constructor(private auth: AuthService) {}

  // lastSeenAt: string = isoToDDMMYYHHMM(new Date().toISOString());
  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe(
      (value) => (this.name = value?.name || 'user'),
    );
  }

  async logout() {
    await this.auth.signOut();
  }
}
