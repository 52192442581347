import { Injectable } from '@angular/core';
import Acknowledgement from 'projects/app-core/src/models/acknowledgement';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { CreateTherapyPlanRequest } from '../pages/therapyPlans/create-therapy-plan/create-therapy-plan.component';
import { TherapyPlansResponse } from '../pages/therapyPlans/therapy-plans-list/therapy-plans-list.component';
import { TherapyPlansAdminResponse } from '../models/TherapyPlansAdminResponse';
import { TherapyPlansGridRequest } from '../models/therapyPlansGridRequest';
import { TherapyPlansGridResponse } from '../models/therapyPlansGridResp';
import { TherapyPlans } from '../models/TherapyPlans';
import { TherapyPlanSubscriptionsGridRequest } from '../models/therapyPlanSubscribtionGridRequest';
import { TherapyPlansSubscriptionGridResponse } from '../models/therapyPlansSubcriptionGridResponse';

export interface TherapyPlanUpdateRequest extends CreateTherapyPlanRequest {
  active: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TherapyPlansService {
  constructor(private api: NetworkUtilsService) {}

  public addTherapyPlan(request: CreateTherapyPlanRequest) {
    return this.api.post<Acknowledgement>(
      URL_DICT.createTherapyPlanUrl,
      request,
    );
  }
  public linkTherapyPlan(
    verticalId: string,
    concernIds: string[],
    planIds: string[],
  ) {
    return this.api.post<Acknowledgement>(URL_DICT.linkTherapyPlanUrl, {
      verticalId: verticalId,
      concernIds: concernIds,
      planIds: planIds,
    });
  }
  public unLinkTherapyPlan(
    verticalId: string,
    concernId: string,
    planId: string,
  ) {
    return this.api.post<Acknowledgement>(URL_DICT.unLinkTherapyPlanUrl, {
      verticalId: verticalId,
      concernId: concernId,
      planId: planId,
    });
  }
  public fetchAllPlans() {
    return this.api.get<{
      plansMapped: TherapyPlansResponse[];
      activePlans: TherapyPlansAdminResponse[];
    }>(URL_DICT.fetchTherapyPlans);
  }

  public fetchTherapyPlansGrid(gridRequest: TherapyPlansGridRequest) {
    return this.api.post<TherapyPlansGridResponse>(URL_DICT.adminTherapyPlansGrid,gridRequest)
  }

  public getPlan(id: string){
    return this.api.get<TherapyPlans>(URL_DICT.adminTherapyPlanGetUrl + id)
  }

  public updatePlan(id: string,data: TherapyPlanUpdateRequest){
    return this.api.post(URL_DICT.adminTherapyPlanUpdateUrl + id,data)
  }

  public fetchTherapyPlansSubscription(gridRequest: TherapyPlanSubscriptionsGridRequest){
    return this.api.post<TherapyPlansSubscriptionGridResponse>(URL_DICT.adminTherapyPlansSubcriptionGrid,gridRequest)
  }
}
