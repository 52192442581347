import { Component } from '@angular/core';
import { IListenerCount } from '../../../stores/types';
import { ListenerService } from '../../../services/listener.service';
import { ICountResponse } from '../../../models/IcountResponse';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import {
  ListenerFilter,
  ListenerGridRequest,
  ListenerSearch,
  ListenerSort,
} from '../../../models/listenerGridRequest';
import { ListenerTable } from '../../../models/listenerGridResponse';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import * as moment from 'moment';

interface CheckBoxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  totalCount: number = 0;
  activeCount: number = 0;
  listenerCount: IListenerCount = {
    totalChats: 0,
    totalMins: 0,
    totalUsers: 0,
    totalListeners: this.totalCount,
  };
  tableData: ListenerTable[] = [];
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  listenerTableCurrentPageNo = 1;
  listenerTableTotalCount = 0;
  listenerTableQuery: ListenerGridRequest | undefined;
  listenerTableSpinner: boolean = false;
  globalSearchInput = '';
  listenerNameColumnSearch = '';
  listenerEmailColumnSearch = '';
  statusCheckBoxOptions: CheckBoxOptions[] = [];
  availabilityCheckBoxOptions: CheckBoxOptions[] = [];

  constructor(
    private auth: AuthService,
    private listenerService: ListenerService,
    private toastrService: ToastService,
  ) {}

  resetFilter() {
    this.listenerTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: {
        [ListenerSort.STATUS]: AnyoSortDirection.ASC,
        [ListenerSort.CREATED_AT]: AnyoSortDirection.ASC,
      },
      to: undefined,
      page: this.listenerTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.listListeners();
  }

  timeAgo(timestamp: string) {
    return moment(timestamp, 'x').fromNow();
  }

  getCount() {
    this.listenerService.listenerCount().subscribe({
      next: (value) => {
        value.forEach((v: ICountResponse) => {
          this.totalCount += v.count;
          if (v._id === 'active') {
            this.activeCount += v.count;
          }
        });
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  listenerNameSearchFn() {
    this.listenerNameColumnSearch = this.listenerNameColumnSearch.trim();
    if (this.listenerNameColumnSearch.length) {
      if (!this.listenerTableQuery!.searchQuery) {
        this.listenerTableQuery!.searchQuery = {
          [ListenerSearch.LISTENER_NAME]: '',
          [ListenerSearch.LISTENER_EMAIL]: '',
        };
      }
      this.listenerTableQuery!.searchQuery!.LISTENER_NAME =
        this.listenerNameColumnSearch;
      this.listListeners();
    }
  }
  listenerEmailSearchFn() {
    this.listenerNameColumnSearch = this.listenerNameColumnSearch.trim();
    if (this.listenerNameColumnSearch.length) {
      if (!this.listenerTableQuery!.searchQuery) {
        this.listenerTableQuery!.searchQuery = {
          [ListenerSearch.LISTENER_NAME]: '',
          [ListenerSearch.LISTENER_EMAIL]: '',
        };
      }
      this.listenerTableQuery!.searchQuery!.LISTENER_EMAIL =
        this.listenerNameColumnSearch;
      this.listListeners();
    }
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.listenerTableQuery?.globalSearch?.push(
        this.globalSearchInput.trim(),
      );
      this.listListeners();
    }
  }

  listListeners() {
    this.listenerTableSpinner = true;
    this.listenerService.listenerList(this.listenerTableQuery!).subscribe({
      next: (value) => {
        this.statusCheckBoxOptions = value.filterOptions.status.map((stat) => {
          return {
            label: stat,
            value: stat,
            checked: false,
          };
        });
        this.availabilityCheckBoxOptions = value.filterOptions.availability.map(
          (stat) => {
            if (stat == true) {
              return {
                label: 'Online',
                value: 'true',
                checked: false,
              };
            } else {
              return {
                label: 'Offline',
                value: 'false',
                checked: false,
              };
            }
          },
        );
        this.listenerTableTotalCount = value.totalRecords;
        this.tableData = value.data;
        this.listenerTableSpinner = false;
      },
      error: (error) => {
        this.listenerTableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
        this.toastrService.showError('Something went wrong');
      },
    });
  }

  nzPageIndexChange(page: number) {
    if (this.listenerTableQuery) {
      this.listenerTableQuery!.page = page;
      this.listListeners();
    }
  }

  nzPageSizeChange(pageSize: number) {
    this.listenerTableQuery!.pageSize = pageSize;
    this.listListeners();
  }

  statusFilter(status: any[]) {
    if (status && status.length) {
      if (!this.listenerTableQuery!.filters) {
        this.listenerTableQuery!.filters = {
          [ListenerFilter.LISTENER_STATUS]: [],
          [ListenerFilter.LISTENER_AVAILABILITY]: [],
        };
      }
      this.listenerTableQuery!.filters!.LISTENER_STATUS = status;
    }
  }

  availabilityFilter(availability: any[]) {
    if (availability && availability.length) {
      if (!this.listenerTableQuery!.filters) {
        this.listenerTableQuery!.filters = {
          [ListenerFilter.LISTENER_STATUS]: [],
          [ListenerFilter.LISTENER_AVAILABILITY]: [],
        };
      }
      this.listenerTableQuery!.filters!.LISTENER_AVAILABILITY = availability;
    }
  }

  getStatsCount() {
    this.listenerService.getListenerStats().subscribe({
      next: (value) => {
        value.forEach((data) => {
          this.listenerCount.totalChats += data.totalChats;
          this.listenerCount.totalListeners += data.totalListeners;
          this.listenerCount.totalUsers += data.totalUsers;
        });
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
        this.toastrService.showError('Something went wrong');
      },
    });
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.listenerTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: {
            [ListenerSort.STATUS]: AnyoSortDirection.ASC,
            [ListenerSort.CREATED_AT]: AnyoSortDirection.DESC,
          },
          to: undefined,
        };
        this.listListeners();
        this.getCount();
        this.getStatsCount();
      }
    });
  }
}
