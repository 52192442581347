import { Component } from '@angular/core';
import { ModeratorGridRequest } from '../../../models/circles/moderatorGridRequest';
import { ModeratorGridResponseData } from '../../../models/circles/moderatorGridResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { CirclesService } from '../../../services/circles.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-manage-moderator',
  templateUrl: './manage-moderator.component.html',
  styleUrl: './manage-moderator.component.scss',
})
export class ManageModeratorComponent {
  totalCount: number = 0;
  moderatorTableQuery: ModeratorGridRequest | undefined;
  moderatorSpinner: boolean = false;
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  moderatorTableCurrentPageNo = 1;
  moderatorTableTotalCount = 0;
  globalSearchInput = '';
  moderatorData!: ModeratorGridResponseData[];

  constructor(
    private auth: AuthService,
    private circles: CirclesService,
    private toastrService: ToastService,
  ) {}

  resetFilter() {
    this.moderatorTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.moderatorTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.listModerators();
  }

  listModerators() {
    this.circles.moderatorsGrid(this.moderatorTableQuery!).subscribe({
      next: (value) => {
        this.moderatorData = value.data;
        this.moderatorTableTotalCount = value.totalRecords;
        this.moderatorSpinner = false;
        this.totalCount = value.totalRecords;
      },
      error: (error) => {
        this.moderatorSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.moderatorTableQuery?.globalSearch?.push(
        this.globalSearchInput.trim(),
      );
      this.listModerators();
    }
  }

  nzPageIndexChange(page: number) {
    if (this.moderatorTableQuery) {
      this.moderatorTableQuery!.page = page;
      this.listModerators();
    }
  }

  nzPageSizeChange(pageSize: number) {
    this.moderatorTableQuery!.pageSize = pageSize;
    this.listModerators();
  }

  ngOnInit(): void {
    this.moderatorSpinner = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.moderatorTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: undefined,
          to: undefined,
        };
        this.listModerators();
      }
    });
  }
}
