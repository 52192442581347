import { Component } from '@angular/core';
import { CircleGridRequest } from '../../../models/circles/circleGridRequest';
import { CircleGridResponseData } from '../../../models/circles/circleGridResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { CirclesService } from '../../../services/circles.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

@Component({
  selector: 'app-manage-circle',
  templateUrl: './manage-circle.component.html',
  styleUrl: './manage-circle.component.scss',
})
export class ManageCircleComponent {
  totalCount: number = 0;
  circlesTableQuery: CircleGridRequest | undefined;
  circlesSpinner: boolean = false;
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  circlesTableCurrentPageNo = 1;
  circlesTableTotalCount = 0;
  globalSearchInput = '';
  circlesData!: CircleGridResponseData[];

  constructor(
    private auth: AuthService,
    private circles: CirclesService,
    private toastrService: ToastService,
  ) {}

  resetFilter() {
    this.circlesTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.circlesTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.listCircles();
  }

  listCircles() {
    this.circlesSpinner = true;
    this.circles.circlesGrid(this.circlesTableQuery!).subscribe({
      next: (value) => {
        this.circlesData = value.data;
        this.circlesTableTotalCount = value.totalRecords;
        this.circlesSpinner = false;
        this.totalCount = value.totalRecords;
      },
      error: (error) => {
        this.circlesSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.circlesTableQuery?.globalSearch?.push(this.globalSearchInput.trim());
      this.listCircles();
    }
  }

  nzPageIndexChange(page: number) {
    if (this.circlesTableQuery) {
      this.circlesTableQuery!.page = page;
      this.listCircles();
    }
  }

  nzPageSizeChange(pageSize: number) {
    this.circlesTableQuery!.pageSize = pageSize;
    this.listCircles();
  }

  ngOnInit(): void {
    this.circlesSpinner = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.circlesTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: undefined,
          to: undefined,
        };
        this.listCircles();
      }
    });
  }

  enableDisableTherapist() {}
}
