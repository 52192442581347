<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text headerlistener-text md:break-keep">
        Manage {{ postCommentTitle }}
      </h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text sub-headerlistener-text">
        {{ totalCount }} {{ postCommentTitle }}
      </h1>
    </div>
  </div>
  <div
    *ngIf="displayAddPost"
    class="flex flex-row justify-between btn custom-btn-primary-adminui custom-btn-primary-listener gap-1"
    (click)="navigatePages()"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">{{ "add " + postCommentTitle }}</span>
    </div>
  </div>
</div>
<div class="flex items-center" nz-row>
  <div nz-col nzSpan="12" *ngIf="viewPost">
    <label class="form-label required">Feed Text</label>
    <textarea
      class="w-[100%] h-60 mr-1"
      [(ngModel)]="postsText"
      nz-input
      rows="4"
      placeholder="Enter text"
      [disabled]="true"
    ></textarea>
  </div>
  <div nz-col class="pl-4" nzSpan="6" *ngIf="viewPost">
    <button nz-button (click)="openViewMedia()">View Media</button>
  </div>
  <nz-spin [nzSpinning]="postsSpinner">
    <div nz-row nzJustify="end" nzAlign="middle">
      <div nz-col nzSpan="6" *ngIf="viewCircleDropdown">
        <nz-select
          (ngModelChange)="listCirclePosts($event)"
          [(ngModel)]="selectedCirclesString"
          class="w-[100%] mr-1"
          nzAllowClear
          nzPlaceHolder="Select a circle"
          nzShowSearch
          nzSize="large"
          [nzDisabled]="whenDisplayManyCircles"
        >
          <nz-option
            *ngFor="let circle of circles"
            [nzLabel]="circle.label"
            [nzValue]="circle.value"
          ></nz-option>
        </nz-select>
      </div>
      <div nz-col class="p-1">
        <nz-range-picker
          [nzDisabledDate]="disabledDate"
          [nzFormat]="dateFormat"
          [(ngModel)]="dateRange"
          (ngModelChange)="dateChange($event)"
          nzSize="large"
        ></nz-range-picker>
      </div>

      <div nz-col class="p-1">
        <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
          <input
            type="text"
            nz-input
            [(ngModel)]="globalSearchInput"
            placeholder="Search for posts text,userName,userEmail"
          />
        </nz-input-group>
      </div>
      <div nz-row>
        <nz-tabset (nzSelectChange)="tabChange($event)">
          <nz-tab [nzTitle]="'All ' + postCommentTitle">
            <app-post-table
              #allTableComponent
              [postTableData]="tableData"
              [tableTitle]="'All ' + postCommentTitle"
              [allowedActions]="allowedActions"
              (pageSizeChangeEvent)="nzPageSizeChange($event)"
              (pageChangeEvent)="nzPageIndexChange($event)"
              (resetFilterEvent)="resetFilter()"
              (subTopicsFilterEvent)="subTopicsFilter($event)"
              (lastCommentDateSortEvent)="lastCommentDateSort($event)"
              (lastReactionDateSortEvent)="lastReactionDateSort($event)"
              (listPostsEvent)="listPosts()"
              (tagsFilterEvent)="tagsFilter($event)"
            ></app-post-table>
          </nz-tab>
          <nz-tab [nzTitle]="'Moderation Required ' + postCommentTitle">
            <app-post-table
              #moderatorRequiredTableComponent
              [postTableData]="tableData"
              [tableTitle]="'Moderation Required ' + postCommentTitle"
              [allowedActions]="allowedActions"
              (pageSizeChangeEvent)="nzPageIndexChange($event)"
              (pageChangeEvent)="nzPageSizeChange($event)"
              (resetFilterEvent)="resetFilter()"
              (subTopicsFilterEvent)="subTopicsFilter($event)"
              (lastCommentDateSortEvent)="lastCommentDateSort($event)"
              (lastReactionDateSortEvent)="lastReactionDateSort($event)"
              (listPostsEvent)="listPosts()"
              (tagsFilterEvent)="tagsFilter($event)"
            ></app-post-table>
          </nz-tab>
          <nz-tab [nzTitle]="'Starred ' + postCommentTitle">
            <app-post-table
              #starredTable
              [postTableData]="tableData"
              [tableTitle]="'Starred' + postCommentTitle"
              [allowedActions]="allowedActions"
              (pageSizeChangeEvent)="nzPageSizeChange($event)"
              (pageChangeEvent)="nzPageIndexChange($event)"
              (resetFilterEvent)="resetFilter()"
              (subTopicsFilterEvent)="subTopicsFilter($event)"
              (lastCommentDateSortEvent)="lastCommentDateSort($event)"
              (lastReactionDateSortEvent)="lastReactionDateSort($event)"
              (listPostsEvent)="listPosts()"
              (tagsFilterEvent)="tagsFilter($event)"
            ></app-post-table>
          </nz-tab>
          <nz-tab [nzTitle]="'Flagged ' + postCommentTitle">
            <app-post-table
              #flaggedTable
              [postTableData]="tableData"
              [tableTitle]="'Flagged ' + postCommentTitle"
              [allowedActions]="allowedActions"
              (pageSizeChangeEvent)="nzPageSizeChange($event)"
              (pageChangeEvent)="nzPageIndexChange($event)"
              (resetFilterEvent)="resetFilter()"
              (subTopicsFilterEvent)="subTopicsFilter($event)"
              (lastCommentDateSortEvent)="lastCommentDateSort($event)"
              (lastReactionDateSortEvent)="lastReactionDateSort($event)"
              (listPostsEvent)="listPosts()"
              (tagsFilterEvent)="tagsFilter($event)"
            ></app-post-table>
          </nz-tab>
          <nz-tab [nzTitle]="'Blocked ' + postCommentTitle">
            <app-post-table
              #blockedTable
              [postTableData]="tableData"
              [tableTitle]="'Blocked ' + postCommentTitle"
              [allowedActions]="allowedActions"
              (pageSizeChangeEvent)="nzPageSizeChange($event)"
              (pageChangeEvent)="nzPageIndexChange($event)"
              (resetFilterEvent)="resetFilter()"
              (subTopicsFilterEvent)="subTopicsFilter($event)"
              (lastCommentDateSortEvent)="lastCommentDateSort($event)"
              (lastReactionDateSortEvent)="lastReactionDateSort($event)"
              (listPostsEvent)="listPosts()"
              (tagsFilterEvent)="tagsFilter($event)"
            ></app-post-table>
          </nz-tab>
        </nz-tabset>
      </div>
      <ng-template #suffixButton>
        <button
          nz-button
          class="btn-color"
          nzType="primary"
          nzSize="small"
          nzSearch
          (click)="searchGlobal()"
        >
          Search
        </button>
      </ng-template>
    </div>
  </nz-spin>
</div>
<nz-drawer
  [nzSize]="'large'"
  [nzVisible]="visibleViewMedia"
  nzPlacement="bottom"
  [nzTitle]="'View Media'"
  (nzOnClose)="closeViewMedia()"
>
  <ng-container *nzDrawerContent>
    <div class="flex flex-wrap justify-around items-center">
      <div *ngFor="let media of media">
        <nz-card nzHoverable [nzCover]="coverTemplate">
          <nz-card-meta
            [nzTitle]="media.type"
            [nzDescription]="media.deleted ? 'deleted' : 'active'"
          ></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <ng-container *ngIf="media.type === MediaType.IMAGE">
            <img
              style="width: 250px"
              class="h-60 object-cover"
              [src]="media.url"
              alt="Selected Image"
            />
          </ng-container>
          <ng-container *ngIf="media.type === MediaType.VIDEO">
            <video controls class="h-60 object-cover" style="width: 400px">
              <source [src]="media.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</nz-drawer>
