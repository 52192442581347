import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { MemberService } from '../../../services/member.service';
import { Status } from '../../../stores/types';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { AuthService } from 'projects/app-core/src/auth/auth.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
})
export class AddMemberComponent {
  addMemberForm!: FormGroup;
  viewForm: boolean = false;
  showDisable: boolean = false;
  isDisabled: boolean = false;
  editMode: boolean = false;
  isAlert: boolean = false;
  partnerName!: string;
  alertHeaderDisable: string = 'Member Delete';
  alertBodyDisable: string =
    'Please make sure that you want to delete the member';
  alertHeaderEnable: string = 'Member Enable';
  alertBodyEnable: string =
    'Please make sure that you want to enable the member';

  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastrService: ToastService,
    private memberService: MemberService,
  ) {
    this.addMemberForm = this.formBuilder.group({
      membername: ['', Validators.required],
      membercode: ['', Validators.required],
      email: ['', Validators.required],
    });
  }

  member: any = {
    partnerId: '',
    name: '',
    email: '',
    department: '',
    branch: '',
  };

  dialogShow() {
    this.isAlert = !this.isAlert;
  }

  editForm() {
    this.viewForm = false;
    this.editMode = true;
  }

  deleteMember() {
    let _id = String(this.activatedRoute.snapshot.params['id']);
    this.memberService.deleteMember(_id).subscribe({
      next: (value) => {
        console.log(value);
        this.toastrService.showSuccess('Member deleted successfully');
        this.router.navigate([
          '/partner/managemember/' +
            this.member.partnerId +
            '/' +
            this.partnerName,
        ]);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
    this.isAlert = false;
  }

  submit(form: any) {
    console.log(form.value);
    console.log(this.member);
    let req = this.member;
    this.member.email = this.member.email.trim();
    if (this.editMode) {
      let _id = String(this.activatedRoute.snapshot.params['id']);
      this.memberService.updateMember(_id, req).subscribe({
        next: (value) => {
          console.log(value);
          this.router.navigate([
            '/partner/managemember/' +
              this.member.partnerId +
              '/' +
              this.partnerName,
          ]);
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
        },
      });
    } else {
      this.memberService.createMember(req).subscribe({
        next: (value) => {
          console.log(value);
          this.router.navigate([
            '/partner/managemember/' +
              this.member.partnerId +
              '/' +
              this.partnerName,
          ]);
        },
        error: (error) => {
          const errorBody = error.error as IAnyoError;
          this.toastrService.showAnyoErrorToast(errorBody.description);
        },
      });
    }
  }

  populate(_id: string) {
    this.memberService.getMember(_id).subscribe({
      next: (value) => {
        console.log(value);
        this.viewForm = true;
        this.showDisable = true;
        this.member = value;
        if (value.status == Status.Inactive) {
          this.isDisabled = true;
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        if (this.activatedRoute.snapshot.params) {
          let value = this.activatedRoute.snapshot.params['id'];
          this.partnerName = this.activatedRoute.snapshot.params['partner'];
          var pattern: RegExp = /(A-Z)(a-z){2}/;
          if (value.indexOf('PR-') > -1) {
            this.member.partnerId = value;
          } else {
            this.populate(value);
          }
        }
      }
    });
  }
}
