import { Component } from '@angular/core';
import { EventsRegistrationsAdminResponse } from '../../../models/events/eventRegistrationGridResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { EventsService } from '../../../services/events.service';
import {
  EventRegistrationFilter,
  EventRegistrationGridRequest,
  EventRegistrationSearch,
} from '../../../models/events/eventRegistrationGridRequest';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import * as moment from 'moment';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { lastValueFrom } from 'rxjs';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

interface CheckBoxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-manage-registrations',
  templateUrl: './manage-registrations.component.html',
  styleUrl: './manage-registrations.component.scss',
})
export class ManageRegistrationsComponent {
  totalCount: number = 0;
  tableGridQuery: EventRegistrationGridRequest | undefined;
  eventsLoading!: boolean;
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  eventRegistrationTableCurrentPageNo = 1;
  eventRegistrationTableTotalCount = 0;
  globalSearchInput = '';
  eventRegistrationData!: EventsRegistrationsAdminResponse[];
  emailColumnSearch = '';
  isEventsMenu: boolean = false;
  ispaymentTypeMenu: boolean = false;
  paymentTypeCheckBoxOptions: CheckBoxOptions[] = [];
  dateFormat = 'dd/MM/yyyy';
  eventsRegistrationColumnFilterDate: Date[] | undefined;
  eventNameFilterCheckBoxOptions: CheckBoxOptions[] = [];
  showTestEvents = false;

  constructor(
    private auth: AuthService,
    private events: EventsService,
    private toastrService: ToastService,
    public utils: UtilsService,
  ) {}

  onInputChange() {}

  async testEventChangeFn(flag: boolean) {
    if (flag) {
      this.tableGridQuery!.filters!.HIDE_TEST_REGISTRATION = ['true'];
    } else {
      this.tableGridQuery!.filters!.HIDE_TEST_REGISTRATION = ['false'];
    }
    await this.listEventRegistration();
  }

  async userEmailSearchFn() {
    this.emailColumnSearch = this.emailColumnSearch.trim();
    if (this.emailColumnSearch.length) {
      if (!this.tableGridQuery!.searchQuery) {
        this.tableGridQuery!.searchQuery = {
          [EventRegistrationSearch.USER_EMAIL]: '',
        };
      }
      this.tableGridQuery!.searchQuery!.USER_EMAIL = this.emailColumnSearch;
      await this.listEventRegistration();
    }
  }

  async dateFilterFn() {
    if (
      this.eventsRegistrationColumnFilterDate &&
      this.eventsRegistrationColumnFilterDate.length
    ) {
      const startDate = moment(this.eventsRegistrationColumnFilterDate[0])
        .startOf('day')
        .toDate();
      const endDate = moment(this.eventsRegistrationColumnFilterDate[1])
        .endOf('day')
        .toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.tableGridQuery = {
        export: false,
        sort: undefined,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        dateFilters: {
          REGISTRATION_DATE: {
            from: isToday ? moment().startOf('day').toDate() : startDate,
            to: isToday ? moment().endOf('day').toDate() : endDate,
          },
        },
      };

      await this.listEventRegistration();
    }
  }

  async listEventRegistration() {
    try {
      this.eventsLoading = true;
      const tableData = await lastValueFrom(
        this.events.listEventRegistration(this.tableGridQuery!),
      );
      tableData.data.forEach((data) => {
        data.paymentTypeColor = this.utils.getRandomColor();
      });
      this.eventRegistrationData = tableData.data;
      this.totalCount = tableData.totalRecords;
      this.eventRegistrationTableTotalCount = tableData.totalRecords;
      this.totalCount = tableData.totalRecords;
      this.paymentTypeCheckBoxOptions = [];
      tableData.filterOptions!.eventPaymentTypes.map((data) => {
        this.paymentTypeCheckBoxOptions.push({
          checked: false,
          label: this.utils.convertToTitleCase(data),
          value: data,
        });
      });
      this.eventNameFilterCheckBoxOptions = [];
      tableData.filterOptions!.eventNames.map((data) => {
        this.eventNameFilterCheckBoxOptions.push({
          checked: false,
          label: data,
          value: data,
        });
      });
      this.eventsLoading = false;
      this.eventsLoading = false;
    } catch (e) {
      this.eventsLoading = false;
      this.toastrService.showError('Something went wrong');
    }
  }

  eventNameFilter(eventName: string[]) {
    if (eventName && eventName.length) {
      if (!this.tableGridQuery!.filters) {
        this.tableGridQuery!.filters = {
          [EventRegistrationFilter.HIDE_TEST_REGISTRATION]: [],
          [EventRegistrationFilter.PAYMENT_TYPE]: [],
          [EventRegistrationFilter.EVENT_NAME]: [],
        };
      }
      this.tableGridQuery!.filters!.EVENT_NAME = eventName;
    }
  }

  paymentTypeFilter(paymentType: any[]) {
    if (paymentType && paymentType.length) {
      if (!this.tableGridQuery!.filters) {
        this.tableGridQuery!.filters = {
          [EventRegistrationFilter.HIDE_TEST_REGISTRATION]: [],
          [EventRegistrationFilter.PAYMENT_TYPE]: [],
          [EventRegistrationFilter.EVENT_NAME]: [],
        };
      }
      this.tableGridQuery!.filters!.PAYMENT_TYPE = paymentType;
    }
  }

  async resetFilter() {
    this.tableGridQuery = {
      dateFilters: undefined,
      export: false,
      filters: {
        [EventRegistrationFilter.HIDE_TEST_REGISTRATION]: [
          this.showTestEvents ? 'true' : 'false',
        ],
        [EventRegistrationFilter.PAYMENT_TYPE]: [],
        [EventRegistrationFilter.EVENT_NAME]: [],
      },
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: undefined,
      to: undefined,
      page: this.eventRegistrationTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.emailColumnSearch = '';
    await this.listEventRegistration();
  }

  async searchGlobal() {
    if (this.globalSearchInput.length) {
      this.tableGridQuery?.globalSearch?.push(this.globalSearchInput.trim());
      await this.listEventRegistration();
    }
  }

  async nzPageIndexChange(page: number) {
    if (this.tableGridQuery) {
      this.tableGridQuery!.page = page;
      await this.listEventRegistration();
    }
  }

  async nzPageSizeChange(pageSize: number) {
    this.tableGridQuery!.pageSize = pageSize;
    await this.listEventRegistration();
  }

  async sortRegisteredDates($event: string | null) {
    if ($event) {
      this.tableGridQuery = {
        dateFilters: undefined,
        export: false,
        filters: {
          [EventRegistrationFilter.HIDE_TEST_REGISTRATION]: [
            this.showTestEvents ? 'true' : 'false',
          ],
          [EventRegistrationFilter.PAYMENT_TYPE]: [],
          [EventRegistrationFilter.EVENT_NAME]: [],
        },
        from: undefined,
        globalSearch: [],
        page: this.eventRegistrationTableCurrentPageNo,
        pageSize: this.pageSize,
        searchQuery: undefined,
        sort: {
          REGISTRATION_DATE:
            $event === 'ascend'
              ? AnyoSortDirection.ASC
              : AnyoSortDirection.DESC,
        },
        to: undefined,
      };
    }
    await this.listEventRegistration();
  }

  ngOnInit(): void {
    this.eventsLoading = true;
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        this.tableGridQuery = {
          dateFilters: undefined,
          export: false,
          filters: {
            [EventRegistrationFilter.HIDE_TEST_REGISTRATION]: ['false'],
            [EventRegistrationFilter.PAYMENT_TYPE]: [],
            [EventRegistrationFilter.EVENT_NAME]: [],
          },
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: undefined,
          to: undefined,
        };
        await this.listEventRegistration();
      }
    });
  }

  exportRegistrationTable() {
    this.eventsLoading = true;
    this.tableGridQuery!.export = true;
    this.events.listEventRegistration(this.tableGridQuery!).subscribe({
      next: (response) => {
        try {
          const tableData = response.data.map((data) => {
            return {
              id: data.id,
              registerOn: this.utils.convertEpochToFormattedDate(
                data.registerOn,
              ),
              eventId: data.eventId,
              eventName: data.eventName,
              eventCategory: data.eventCategory,
              userId: data.userId,
              userName: data.userName,
              userEmail: data.userEmail,
              amount: data.amount,
              paymentType: data.paymentType,
            };
          });
          const csvData = tableData as unknown as Record<
            string,
            string | null | undefined
          >[];
          const csvConfig = mkConfig({ useKeysAsHeaders: true });
          const csv = generateCsv(csvConfig)(csvData);
          download(csvConfig)(csv);
          this.eventsLoading = false;
        } catch (e) {
          this.toastrService.showError('Export Error');
          this.eventsLoading = false;
        }
      },
      error: (error) => {
        this.eventsLoading = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
}
