<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">
        Manage Roles And Permission
      </h1>
    </div>
  </div>
  <div class="flex flex-row justify-around space-x-4">
    <div
      *ngIf="displayAddAdmin"
      [routerLink]="['/roles-permission/add']"
      class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    >
      <div>
        <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
      </div>
      <div>
        <span class="">Add Admin</span>
      </div>
    </div>
  </div>
</div>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Roles And Permission</span>
    </div>
    <!-- <div nz-col class="m-1">
      <button nz-button nzSize="small" nzType="primary" (click)="resetFilter()">
        Clear filter
      </button>
    </div> -->
  </div>
</ng-template>
<nz-spin [nzSpinning]="userSpinner" [title]="'Loading....'">
  <nz-table
    #basicTable
    [nzPageSize]="10"
    [nzShowPagination]="true"
    [nzFrontPagination]="true"
    [nzData]="listOfData"
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th nzWidth="20%">Roles</th>
        <th nzWidth="20%">Permission</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data.name }}</td>
        <td>{{ data.email }}</td>
        <td>
          <div *ngIf="data.firebaseDetails != null">
            <div class="mt-2" *ngFor="let data of data.firebaseDetails.roles">
              <nz-tag [nzColor]="data.color">{{ data.value }}</nz-tag>
            </div>
          </div>
        </td>
        <td>
          <div *ngIf="data.firebaseDetails != null">
            <div
              class="mt-2"
              *ngFor="let data of data.firebaseDetails.additionalPermissions"
            >
              <nz-tag [nzColor]="data.color">{{ data.value }}</nz-tag>
            </div>
          </div>
        </td>

        <td>
          <div class="flex items-center justify-evenly">
            <button nz-button (click)="showModal(data)">
              <span>Update Roles And Permission</span>
            </button>
            <nz-switch
              (ngModelChange)="enableDisableForUserInAllColl(data)"
              [(ngModel)]="data.active"
            ></nz-switch>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Update Roles And Permission"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzOkLoading]="isOkLoading"
>
  <ng-container *nzModalContent>
    <div>
      <label>Roles</label>
      <nz-select
        nzSize="large"
        class="w-[100%]"
        [nzMaxTagCount]="3"
        nzMode="multiple"
        nzPlaceHolder="Please select Roles"
        [(ngModel)]="listOfSelectedRoles"
      >
        <nz-option
          *ngFor="let item of listOfRoles"
          [nzLabel]="item.label"
          [nzValue]="item.value"
        ></nz-option>
      </nz-select>
    </div>
    <div>
      <label>Permission</label>
      <nz-select
        nzSize="large"
        class="w-[100%]"
        [nzMaxTagCount]="3"
        nzMode="multiple"
        nzPlaceHolder="Please select Permission"
        [(ngModel)]="listOfSelectedPermission"
      >
        <nz-option
          *ngFor="let item of listOfPermission"
          [nzLabel]="item.label"
          [nzValue]="item.value"
        ></nz-option>
      </nz-select>
    </div>
  </ng-container>
</nz-modal>
