import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ToastService } from '../../services/toastr.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  firstLogin!: boolean;
  otp_1!: string;
  otp_2!: string;
  otp_3!: string;
  otp_4!: string;
  timeLeft: number = 10;
  timer: any;
  resendOtp: boolean = false;
  resendPin: boolean = false;

  password1: string = '';
  password2: string = '';
  password: string = '';
  isShow: boolean = false;
  isSame: boolean = false;
  placeholder = 'Enter your password';
  border!: string;
  eyeClosedIcon = '../../../assets/icons/icon-eye.svg';
  eyeOpenIcon = '../../../assets/icons/icon-eye-open.svg';
  showCreatePass: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastService,
  ) {}

  otp: string[] = [];
  email: string = '';
  finalOtp: string = '';

  validate(event: any) {
    this.password2 = event.target.value;
    if (this.password1 == this.password2 && this.password1 != '') {
      this.border = 'valid';
      this.isSame = true;
      this.password = this.password2;
    } else {
      this.border = 'error';
      this.isSame = false;
      this.password = '';
    }
  }

  onKey(event: any) {
    this.password1 = event.target.value;

    if (this.password1.length < 7) {
      this.border = 'error';
    } else {
      this.border = 'valid';
    }
  }

  async verifyOtp() {
    try {
      this.finalOtp = this.otp.join('');
      this.finalOtp = this.finalOtp.trim();
      let req = {
        otp: this.finalOtp,
        phoneNumber: null,
        email: this.email,
      };
      await this.authService.verifyOtp(req);
      this.toastrService.showSuccess('OTP Verified');
      this.showCreatePass = true;
    } catch (e: unknown) {
      this.toastrService.showError('Invalid OTP');
    }
  }

  async updatePassword() {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const email: string | null = queryParams.get('email');
      const email_id = decodeURIComponent(email || '') as string;
      let passReq = {
        email: email_id,
        otp: this.finalOtp,
        newPassword: this.password,
      };
      await this.authService.resetPassword(passReq);
      this.toastrService.showSuccess('Password saved successfully');
      await this.router.navigate(['/login']);
    } catch (e: unknown) {
      this.toastrService.showError('Invalid Otp or Wrong Password');
    }
  }

  show() {
    this.isShow = !this.isShow;
  }

  async resendOtpFn() {
    try {
      this.resetTimer();
      this.resendPin = false;
      this.otp = [];
      let req = {
        email: this.email,
      };
      await this.authService.generateOtp(req);
      await this.toastrService.showSuccess('OTP verified successfully');
    } catch (e: unknown) {
      this.toastrService.showAnyoErrorToast(e);
    }
  }

  resetTimer() {
    this.resendOtp = false;
    this.timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.timer);
        this.resendOtp = true;
        this.resendPin = true;
        this.timeLeft = 10;
      }
    }, 1000);
  }

  focusNext(val: string, next: string) {
    if (val) {
      const ele: any = document.getElementById(next);
      if (ele) {
        ele.focus();
      }
    }
  }

  ngOnInit(): void {
    this.resetTimer();
    if (this.activatedRoute.snapshot.params) {
      let value = this.activatedRoute.snapshot.params['toverify'];
      if (value) {
        this.email = value;
      }
    }
  }
}
