<div
  class="min-h-screen"
  style="background: url(../../../assets/images/background.svg)"
>
  <div class="absolute top-[20%] left-[20%] bg-white p-4 rounded-md shadow-md">
    <div class="flex">
      <img
        src="../../../assets/images/left-image.svg"
        alt="image"
        class="w-1/2 mr-4"
      />
      <div class="divider divider-vertical"></div>
      <div class="mt-4">
        <img
          src="../../../assets/images/logo.svg"
          alt="image"
          class="w-1/2 mr-4"
        />
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
