import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ManageBytesComponent} from './manage-bytes/manage-bytes.component';
import {ManageBytesCategoryComponent} from './manage-bytes-category/manage-bytes-category.component';
import {AddBytesComponent} from './add-bytes/add-bytes.component';
import {AddBytesCategoryComponent} from './add-bytes-category/add-bytes-category.component';
import {BulkUploadComponent} from './bulk-upload/bulk-upload.component';
import {ViewComponent} from './view/view.component';

const routes: Routes = [
  {
    path: '',
    component: ManageBytesComponent,
  },
  {
    path: 'category',
    component: ManageBytesCategoryComponent,
  },
  {
    path: 'add',
    component: AddBytesComponent,
  },
  {
    path: 'add/bulk',
    component: BulkUploadComponent,
  },
  {
    path: 'category/add',
    component: AddBytesCategoryComponent,
  },
  {
    path: 'view/:id',
    component: ViewComponent,
  },
  {
    path: 'duplicate/:id',
    component: ViewComponent,
  },
  {
    path: 'category/add/:id',
    component: AddBytesCategoryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BytesRoutingModule {}
