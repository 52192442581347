<nz-card class="min-w-full min-h-[50%]" nzTitle="Bulk Upload Bytes">
  <div nz-row nzJustify="end">
    <div nz-col>
      <button (click)="downloadTemplate()" nz-button nzType="primary">
        <span nz-icon nzType="download"></span>
        Download Template
      </button>
    </div>
  </div>
  <nz-spin [nzSpinning]="pageLoading">
    <div class="mt-10" nz-row nzAlign="middle" nzJustify="space-evenly">
      <div nz-col nzSpan="24">
        <div class="flex items-center justify-center w-full">
          <label
            class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-10 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            for="dropzone-file"
          >
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                fill="none"
                viewBox="0 0 20 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span class="font-semibold">Click to upload</span>
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-400">CSV</p>
            </div>
            <input
              (change)="csvFileSelected($event)"
              accept=".csv"
              class="hidden"
              id="dropzone-file"
              type="file"
            />
          </label>
        </div>
      </div>
    </div>
  </nz-spin>
</nz-card>
