import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface BytesGridRequest
  extends GridRequest<BytesSort, BytesFilter, BytesSearch, BytesDateFilter> {
  tab: BytesGridTab;
}

export enum BytesGridTab {
  BYTES_DELIVERED = 'BYTES_DELIVERED',
  BYTES_SCHEDULED = 'BYTES_SCHEDULED',
}

export enum BytesSort {
  BYTES_START_DATE = 'BYTES_START_DATE',
}
export enum BytesSearch {
  BYTES_NAME = 'BYTES_NAME',
}

export enum BytesDateFilter {
  BYTES_START_DATE = 'BYTES_START_DATE',
}

export enum BytesFilter {
  BYTES_STATUS = 'BYTES_STATUS',
  BYTES_CATEGORY = 'BYTES_CATEGORY',
  BYTES_VIEWS = 'BYTES_VIEWS',
}
