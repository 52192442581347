<div class="alert-card">
  <div class="alert-card-body">
    <div>
      <div class="flex justify-between">
        <h2 class="alert-card-header">{{ alertHeader }}</h2>
        <div *ngIf="!isRating">
          <img
            class="cursor-pointer"
            src="assets/icons/close-icon.svg"
            (click)="close()"
          />
        </div>
      </div>
      <div *ngIf="isRating" class="flex justify-center">
        <h2 class="card-header-rating">
          {{ alertHeaderRating }} {{ nameOfUser }} ?
        </h2>
      </div>
    </div>

    <div class="divider"></div>

    <div *ngIf="isPatient" class="flex flex-col items-start">
      <label class="form-label"
        >Patient Name:
        <span class="form-label">{{ patientDetails.name }}</span></label
      >
      <label class="form-label"
        >Gender:
        <span class="form-label">{{ patientDetails.gender }}</span></label
      >
      <label class="form-label"
        >Past Therapy Experience:
        <span class="form-label">{{
          patientDetails.pastTherapyExperience
        }}</span></label
      >
      <label class="form-label"
        >Last Session Details:
        <span class="form-label">{{ patientDetails.lastSession }}</span></label
      >
      <label class="form-label"
        >Past Medical Issues:
        <span class="form-label">{{
          patientDetails.pastMedCondition
        }}</span></label
      >
      <label class="form-label"
        >Medication:
        <span class="form-label">{{ patientDetails.isMedication }}</span></label
      >
      <label class="form-label"
        >Current Symptoms:
        <span class="form-label">{{
          patientDetails.currentSymptoms
        }}</span></label
      >
      <!-- <label class="form-label">Medication undertaking: <span class="form-label">{{patientDetails.medication}}</span></label> -->
    </div>

    <div *ngIf="cancelAppoinment" class="flex flex-col gap-2" >
      <p class="flex text-content2">User Name</p>
      <input
        class="input-listener input-adminui small-text"
        name="data"
        [(ngModel)]="userName"
        placeholder="Please enter here"
        [disabled]="true"
      />
    </div>
    <div *ngIf="cancelAppoinment" class="flex flex-col gap-2" >
      <p class="flex text-content2">User Email</p>
      <input
        class="input-listener input-adminui small-text"
        name="data"
        [(ngModel)]="userEmail"
        placeholder="Please enter here"
        [disabled]="true"
      />
    </div>

    <div *ngIf="isInput" class="flex flex-col items-start">
      <p class="flex text-content2">{{ alertBody }}</p>
      <input
        class="input-listener input-adminui small-text"
        name="data"
        [(ngModel)]="inputText"
        placeholder="Please enter here"
      />
    </div>
    <div *ngIf="isReject" class="flex flex-col items-start">
      <p class="flex text-content2">{{ rejectAlertBody }}</p>
      <input
        class="input-listener input-adminui small-text"
        name="data"
        [(ngModel)]="rejectInputText"
        placeholder="Please enter here"
      />
    </div>
    <div *ngIf="isContent == true">
      <div class="flex text-content2 items-center">
        <p>{{ alertContent }}</p>
      </div>
    </div>
    <div *ngIf="isCalendar == true" class="flex flex-col justify-between gap-1">
      <div class="flex flex-row justify-between mb-1 mt-1">
        <div>
          <label class="feedback-yes">{{ alertDate }}</label>
          <div>
            <input
              type="date"
              placeholder="DD/MM/YYYY"
              class="input-adminui input small-text width"
              [min]="minDate"
              [(ngModel)]="inputDate"
              name="calendar"
              [disabled]="isTherapist"
            />
          </div>
        </div>
        <div>
          <label class="feedback-yes">Time</label>
          <div>
            <input
              type="Time"
              step="900"
              class="input-adminui input small-text w-200"
              [(ngModel)]="inputTime"
              name="calendar"
              [disabled]="isTherapist"
            />
          </div>
        </div>
        <!-- <div class="flex flex-col w-1/2">
                    <div>
                        <label class="feedback-yes">Time</label>
                         <div>
                             <input type="Time" step="900" class="input small-text w-200" [(ngModel)]="inputTime" name="calendar" [disabled]="isTherapist">
                         </div>
                     </div>
                     <div>

                     </div>
                </div> -->
      </div>
      <div *ngIf="isCalendar == true" class="divider divider-horizontal"></div>
      <div *ngIf="!isVideoLink && isTherapistName == true">
        <label>Therapist</label>
        <select
          class="select select-sm title-select small-text"
          name="therapist"
          [(ngModel)]="defaultTherapist"
        >
          <option *ngFor="let option of therapistArray" [value]="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
      <!-- <div *ngIf="isCalendar == true" class="divider divider-horizontal"></div> -->
      <div *ngIf="isVideoLink == true">
        <div class="flex flex-col md:gap-1">
          <!-- <label>Video Call Link</label>
          <div class="flex flex-row w-full">
            <div>
              <input
                type="text"
                placeholder="Please Enter here"
                [(ngModel)]="inputLink"
                class="input-adminui input small-text width"
              />
            </div>
            <img src="assets/icons/meeting-icon.svg" class="btn link-button" />
          </div> -->
          <div *ngIf="isTherapistName == true">
            <label>Therapist</label>
            <select
              class="select select-sm title-select small-text"
              name="therapist"
              [(ngModel)]="defaultTherapist"
            >
              <option *ngFor="let option of therapistArray" [value]="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isRating">
      <div class="star-rating">
        <span *ngFor="let star of stars; let i = index" (click)="rate(i + 1)">
          <img [src]="getStarImage(i)" alt="Star" />
        </span>
      </div>
      <div class="text-box">
        <textarea
          placeholder="Please Enter here"
          [(ngModel)]="inputRate"
        ></textarea>
      </div>
      <div class="flex justify-center pt-6">
        <button
          type="button"
          class="btn btn-primary btn-block-common"
          (click)="finishRate()"
        >
          Rate
        </button>
      </div>
    </div>
    <p *ngIf="!isInput" class="flex text-center text-content2">
      {{ alertBody }}
    </p>
    <div
      *ngIf="!isInput && !isCalendar && !isPatient && !isRating"
      class="flex flex-row items-center gap-3 w-3/4 justify-end"
    >
      <button class="btn btn-primary cancel" (click)="close()">Cancel</button>
      <button
        type="button"
        class="btn btn-primary btn-block-common ok"
        (click)="disable()"
      >
        OK
      </button>
    </div>
    <div
      *ngIf="isInput && isTherapist"
      class="flex flex-row items-center gap-3 justify-end mt-4"
    >
      <button class="btn btn-primary cancel" (click)="close()">Cancel</button>
      <button
        type="button"
        class="btn btn-primary btn-block-common submit"
        (click)="submit()"
        [disabled]="inputText.length == 0"
      >
        Request
      </button>
    </div>
    <div
      *ngIf="isInput && !isTherapist"
      class="flex flex-row items-center gap-3 justify-end mt-4"
    >
      <button class="btn btn-primary cancel" (click)="close()">Cancel</button>
      <button
        type="button"
        class="btn btn-primary btn-block-common submit"
        (click)="submit()"
        [disabled]="inputText.length == 0"
      >
        <span *ngIf="!loading">Confirm</span>
        <div *ngIf="loading" class="progressbar"></div>
      </button>
    </div>
    <div
      *ngIf="!isInput && isCalendar && !isTherapist && !isRating"
      class="flex flex-row items-center gap-3 w-3/4 justify-end"
    >
      <button class="btn btn-primary cancel" (click)="close()">Cancel</button>
      <button
        *ngIf="isCalendar"
        type="button"
        class="btn btn-primary btn-block-common ok"
        (click)="disable()"
        [disabled]="!inputTime || !inputDate"
      >
        <span *ngIf="!loading">Confirm</span>
        <div *ngIf="loading" class="progressbar"></div>
      </button>
    </div>
    <div
      *ngIf="!isInput && isCalendar && isTherapist"
      class="flex flex-row items-center gap-3 w-3/4 justify-end"
    >
      <button class="btn btn-primary cancel" (click)="close()">Cancel</button>
      <button
        *ngIf="isCalendar"
        type="button"
        class="btn btn-primary btn-block ok"
        (click)="disable()"
        [disabled]="!inputLink && !defaultTherapist"
      >
        <span *ngIf="!loading">Confirm</span>
        <div *ngIf="loading" class="progressbar"></div>
      </button>
    </div>
  </div>
</div>
