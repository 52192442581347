export function isoToDDMMYYHHMM(date: string) {
  //dd-mm-yy
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  const day = prependToString(dateObj.getDate().toString(), '0', 2);
  const month = prependToString((dateObj.getMonth() + 1).toString(), '0', 2);
  const year = dateObj.getFullYear();
  const hour = prependToString(dateObj.getHours().toString(), '0', 2);
  const minute = prependToString(dateObj.getMinutes().toString(), '0', 2);
  return day + '-' + month + '-' + year + ' ' + hour + ':' + minute;
}

export function isoToDDMMYYYYslash(date: string) {
  //    dd/mm/yyyy
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  const day = prependToString(dateObj.getDate().toString(), '0', 2);
  const month = prependToString((dateObj.getMonth() + 1).toString(), '0', 2);
  const year = dateObj.getFullYear();

  return day + '/' + month + '/' + year;
}

export function isoToDDMMMYYYY(date: string) {
  //20 March 2023 |
  if (!date) {
    return '';
  }
  const months: string[] = [
    'January',
    'Febraury',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dateObj = new Date(date);
  const day = prependToString(dateObj.getDate().toString(), '0', 2);
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  const hour = prependToString(dateObj.getHours().toString(), '0', 2);
  const minute = prependToString(dateObj.getMinutes().toString(), '0', 2);
  return day + ' ' + month + ' ' + year + ' ' + '|' + ' ';
}

export function isoToDDMMYYYY(date: string) {
  //dd/mm/yy
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  const day = prependToString(dateObj.getDate().toString(), '0', 2);
  const month = prependToString((dateObj.getMonth() + 1).toString(), '0', 2);
  const year = dateObj.getFullYear();
  return day + ' / ' + month + ' / ' + year + ' ';
}

export function isoToYYYYMMDD(date: string) {
  //yyyy-mm-dd
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  const day = prependToString(dateObj.getDate().toString(), '0', 2);
  const month = prependToString((dateObj.getMonth() + 1).toString(), '0', 2);
  const year = dateObj.getFullYear();
  return year + '-' + month + '-' + day;
}

export function isoToMMMDDYYYY(date: string) {
  //MMM DD,YYYY
  if (!date) {
    return '';
  }
  const months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const dateObj = new Date(date);
  const day = prependToString(dateObj.getDate().toString(), '0', 2);
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  return month + ' ' + day + ' , ' + year + ' ';
}

export function prependToString(
  str: string,
  prependText: string,
  minLength: number,
) {
  let prependedString = str;
  while (prependedString.length < minLength) {
    prependedString = prependText + prependedString;
  }
  return prependedString;
}

export function dateToddMMYYYY(dateString: Date) {
  const date: Date = new Date(dateString);
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1;
  const year: number = date.getFullYear();
  const formattedDate: string = `${day < 10 ? '0' + day : day}/${
    month < 10 ? '0' + month : month
  }/${year}`;
  return formattedDate;
}

export function isoToTime(dateString: string) {
  //hh:mm
  const date: Date = new Date(dateString);
  const hours: string = date.getHours().toString().padStart(2, '0');
  const minutes: string = date.getMinutes().toString().padStart(2, '0');
  const formattedDate: string = `${hours}:${minutes}`;
  return formattedDate;
}

// export function isoToTimeAlert(dateString: string) { //hh:mm
//     const date: Date = new Date(dateString);
//     const timeString = dateString.substring(11, 16);
//     const [hours, minutes] = timeString.split(':');
//     const formattedDate: string = `${hours}:${minutes}`;
//     return formattedDate;
// }

export function isoToTimeFormat(dateString: string) {
  //hh:mm AM/PM
  let formatHour: string;
  const date: Date = new Date(dateString);
  const hours: number = date.getHours();

  const minutes: string = date.getMinutes().toString().padStart(2, '0');
  if (hours > 12) {
    formatHour = hours - 12 + ':' + minutes + ' ' + 'PM';
  } else if (hours == 12) {
    formatHour = '12' + ':' + minutes + ' ' + 'PM';
  } else if (hours == 0) {
    formatHour = '12' + ':' + minutes + ' ' + 'AM';
  } else {
    formatHour = hours + ':' + minutes + ' ' + 'AM';
  }
  const formattedDate: string = `${formatHour}`;
  return formattedDate;
}

// export function isoToTimeFormat(dateString: string) { //hh:mm AM/PM
//     let formatHour:string;
//     const date: Date = new Date(dateString);
//     const timeString = dateString.substring(11, 16);
//     const [hours, minutes] = timeString.split(':');
//     const ampm = Number(hours) >= 12 ? 'PM' : 'AM';
//     const formattedHours = Number(hours) % 12 || 12;
//     const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
//     return formattedTime;
// }

export function isoToYMD(isoString: string): string {
  //yyyy-mm-dd
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function dateToISODate(dateString: string): string {
  //dd-mm-yy to iso
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(Date.UTC(year, month - 1, day));

  const isoDate = date.toISOString();
  return isoDate;
}

export function dateTimeToISODateTime(
  dateString: string,
  timeString: string,
): string {
  //dd-mm-yy and hh:mm to iso
  const [year, month, day] = dateString.split('-').map(Number);
  const [hours, minutes] = timeString.split(':').map(Number);

  const date = new Date(year, month - 1, day, hours, minutes);
  const isoDateTime = date.toISOString();

  return isoDateTime;
}

export function isDateInRange(
  startDateISO: string,
  endDateISO: string,
): boolean | string {
  const currentDate = new Date();
  const startDate = new Date(startDateISO);
  const endDate = new Date(endDateISO);
  if (currentDate < startDate) {
    return 'early';
  } else {
    return currentDate >= startDate && currentDate <= endDate;
  }
}
