import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthRoutingModule } from './admin-auth/admin-auth-routing.module';
import { PageNotFoundComponentComponent } from 'projects/app-core/src/app/components/page-not-found-component/page-not-found-component.component';
import { MainLayoutComponent } from './pages/main-layout/main-layout.component';
import { AdminHomeComponent } from './pages/admin-home/admin-home.component';
import { TherapistRoutingModule } from './pages/therapist/therapist-routing.module';
import { PartnerRoutingModule } from './pages/partner/partner-routing.module';
import { ContentRoutingModule } from './pages/content/content-routing.module';
import { ListenerRoutingModule } from './pages/listener/listener-routing.module';
import { RoutineRoutingModule } from './pages/routine/routine-routing.module';
import {
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { BytesRoutingModule } from './pages/bytes/bytes-routing.module';
import { CirclesRoutingModule } from './pages/circles/circles-routing.module';
import { PostsRoutingModule } from 'projects/app-core/src/app/posts/posts-routing.module';
import { RolesPermissionRoutingModule } from './pages/roles-permission/roles-permission-routing.module';
import { EventsRoutingModule } from './pages/events/events-routing.module';
import { TalksModule } from 'projects/app-core/src/app/talks/talks.module';
import { TherapyPlansRoutingModule } from './pages/therapyPlans/therpay-plans/therapy-plans-routing.module';
import { UserProfileRoutingModule } from './pages/user-profile/user-profile-routing.module';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedIn = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => AdminAuthRoutingModule,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedIn,
    },
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    children: [
      {
        path: '',
        component: AdminHomeComponent,
      },
      {
        path: 'therapist',
        loadChildren: () => TherapistRoutingModule,
      },
      {
        path: 'partner',
        loadChildren: () => PartnerRoutingModule,
      },
      {
        path: 'content',
        loadChildren: () => ContentRoutingModule,
      },
      {
        path: 'listener',
        loadChildren: () => ListenerRoutingModule,
      },
      {
        path: 'routine',
        loadChildren: () => RoutineRoutingModule,
      },
      {
        path: 'bytes',
        loadChildren: () => BytesRoutingModule,
      },
      {
        path: 'circles',
        loadChildren: () => CirclesRoutingModule,
      },
      {
        path: 'posts',
        loadChildren: () => PostsRoutingModule,
      },
      {
        path: 'events',
        loadChildren: () => EventsRoutingModule,
      },
      {
        path: 'talks',
        loadChildren: () => TalksModule,
      },
      {
        path: 'roles-permission',
        loadChildren: () => RolesPermissionRoutingModule,
      },
      {
        path: 'user-profile',
        loadChildren: () => UserProfileRoutingModule,
      },
      {
        path: 'therapy-plans',
        loadChildren: () => TherapyPlansRoutingModule,
      },
    ],
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponentComponent },
  // { path: 'video', component: VideostreamComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
