import { Injectable } from '@angular/core';
import { AnyoEvents } from '../models/events/AnyoEvents';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../app-core/src/service/network-utils.service';
import { EventsListsGridRequest } from '../models/events/eventsGridRequest';
import { EventsListGridResponse } from '../models/events/eventsAdminGridResponse';
import { EventsRegistrationsGridResponse } from '../models/events/eventRegistrationGridResponse';
import { EventRegistrationGridRequest } from '../models/events/eventRegistrationGridRequest';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private api: NetworkUtilsService) {}

  saveEvents(anyoEvent: AnyoEvents) {
    return this.api.post(URL_DICT.addEvents, anyoEvent);
  }

  listEvents(gridRequest: EventsListsGridRequest) {
    return this.api.post<EventsListGridResponse>(
      URL_DICT.eventsGridUrl,
      gridRequest,
    );
  }

  listEventRegistration(gridRequest: EventRegistrationGridRequest) {
    return this.api.post<EventsRegistrationsGridResponse>(
      URL_DICT.eventsRegistrationGridUrl,
      gridRequest,
    );
  }

  getEvents(id: string) {
    return this.api.get<AnyoEvents>(URL_DICT.eventsGetUrl + `${id}`);
  }

  updateEvents(updateEvents: AnyoEvents, eventId: string) {
    return this.api.post(URL_DICT.eventsUpdateUrl + `${eventId}`, updateEvents);
  }
}
