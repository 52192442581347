export interface TherapistAppointment {
  _id?: string;
  therapistId: string[];
  therapistName: string;
  therapistEmail: string;
  partnerId?: string;
  userName: string;
  userEmail: string;
  userPhone?: string;
  callLink?: string;
  userId: string;
  patientId: string;
  date: Date;
  startTime?: Date;
  endTime?: Date;
  status: AppointmentStatus;
  createdAt?: Date;
  createdBy: string;
}

export interface AppointmentCancelRequest {
  requestTime: Date;
  requestedBy: string;
  reason?: string;
}

export enum AppointmentStatus {
  ACCEPTED = 'accepted',
  COMPLETED = 'Completed',
  CANCELLATION_REQUESTED = 'Cancellation Requested',
  CANCELLED = 'Cancelled',
  NEW_REQUEST = 'New Request',
  THERAPIST_RESCHEDULE_REQUESTED = 'Therapist Reschedule Requested',
  CONFIRMED = 'Confirmed',
  MISSED_APPOINTMENT = 'Missed Appointment',
  NO_SHOW_THERAPIST = 'No Show Therapist',
  NO_SHOW_USER = 'No Show User',
  TECH_ISSUE = 'Tech Issue',
  FEEDBACK_PENDING = 'Feedback Pending',
}

export enum SessionTags {
  SESSION_TIME_UPDATED = 'SESSION_TIME_UPDATED',
  SESSION_THERAPIST_UPDATED = 'SESSION_THERAPIST_UPDATED',
  AUTO_CONFIRMED = 'AUTO_CONFIRMED',
  USER_CANCELATION_REQUESTED = 'USER_CANCELATION_REQUESTED',
  THERAPIST_CANCELATION_REQUESTED = 'THERAPIST_CANCELATION_REQUESTED',
}
