import { Moment } from 'moment';
import { CircleExperts } from './circles/CircleExperts';
import { NewCircleExpertRequest } from './circles/newCIrcleExpertRequest';

export interface IAnyoListener {
  _id?: string;
  u_id?: string;
  title: string;
  email: string;
  name: string;
  mobileNumber: string;
  avtaarName: string;
  languages: string[];
  avtaar: string;
  dob: Date | string | Moment;
  specialization: string[];
  about: string;
  status?: string;
  rating?: number;
  totalChats?: number;
  totalMinutes?: number;
  totalUsers?: number;
  createdAt?: Date;
  createdBy?: string;
  lastModifiedAt?: Date;
  lastModifiedBy?: string;
  isAvailable?: boolean;
}

export interface GetListenerResponse {
  masterdata: MasterData;
  data: ListenerDetails;
}

interface ListenerDetails extends IAnyoListener {
  expertDetails?: CircleExperts;
}

export interface MasterData {
  specialization: Array<Data>;
  languages: Array<Data>;
  avtaarImages: Array<AvatarNames>;
  avtaarName: Array<AvatarNames>;
}

interface Data {
  status: string;
  data: string;
  m_id?: string;
}

interface AvatarNames {
  data: string;
  gender?: string;
  isAvailable?: string;
  status?: string;
  m_id?: string;
}
