import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomHttpUrlEncodingCodec } from 'projects/app-core/src/app/utils/encoder';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import { MasterDataResponse } from '../models/masterDataResponse';

@Injectable({
  providedIn: 'root',
})
export class MasterdataService {
  protected basePath = environment.serviceUrl;
  constructor(
    protected httpClient: HttpClient,
    private api: NetworkUtilsService,
  ) {}

  public masterDataList() {
    return this.api.get<MasterDataResponse>(URL_DICT.getMasterData);
  }
}
