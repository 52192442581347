import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileRoutingModule } from './user-profile-routing.module';
import { SearchUserComponent } from './search-user/search-user.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { FormsModule } from '@angular/forms';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PostsModule } from 'projects/app-core/src/app/posts/posts.module';

@NgModule({
  declarations: [SearchUserComponent],
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    NzInputModule,
    NzCardModule,
    NzGridModule,
    NzSpinModule,
    FormsModule,
    NzTagModule,
    NzCollapseModule,
    NzTableModule,
    NzDividerModule,
    NzPaginationModule,
    NzDrawerModule,
    NzButtonModule,
    PostsModule,
  ],
})
export class UserProfileModule {}
