import { Injectable } from '@angular/core';
import * as moment from 'moment';
import cronstrue from 'cronstrue';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  tagColors: string[] = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
  ];
  constructor() {}

  convertEpochToFormattedDate(isoDate: string) {
    return moment(isoDate, 'x').format('DD/MM/YYYY hh:mm A');
  }

  convertToTitleCase(inputString: string) {
    const words = inputString.split('_');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    );
    const result = capitalizedWords.join(' ');

    return result;
  }

  getRandomColor(): string {
    const randomIndex = Math.floor(Math.random() * this.tagColors.length);
    return this.tagColors[randomIndex];
  }

  validateEmail(email?: string) {
    if (!email) {
      return false;
    }
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!pattern.test(email)) {
      return false;
    }
    return true;
  }

  formatDurationFromDate(startDate: Date, endDate: Date): string {
    // Calculate total seconds between the times
    let delta = Math.abs(endDate.getTime() - startDate.getTime()) / 1000;
    // Calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // Calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    // Calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    // What's left is seconds
    const seconds = delta % 60; // in theory the modulus is not required
    // Build the string
    let durationString = '';
    if (days > 0) {
      durationString += `${days} day${days > 1 ? 's' : ''} `;
    }
    if (hours > 0) {
      durationString += `${hours} hr${hours > 1 ? 's' : ''} `;
    }
    if (minutes > 0) {
      durationString += `${minutes} min${minutes > 1 ? 's' : ''} `;
    }
    if (seconds > 0) {
      durationString += `${Math.floor(seconds)} sec${seconds > 1 ? 's' : ''}`;
    }
    return durationString.trim();
  }

  getFrequencyText(cron: string) {
    return cronstrue.toString(cron, {
      verbose: true,
      use24HourTimeFormat: true,
    });
  }
}
