import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BytesRoutingModule} from './bytes-routing.module';
import {AddBytesCategoryComponent} from './add-bytes-category/add-bytes-category.component';
import {AddBytesComponent} from './add-bytes/add-bytes.component';
import {ManageBytesCategoryComponent} from './manage-bytes-category/manage-bytes-category.component';
import {ManageBytesComponent} from './manage-bytes/manage-bytes.component';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzRateModule} from 'ng-zorro-antd/rate';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {FormsModule} from '@angular/forms';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {ViewComponent} from './view/view.component';
import {CommonComponentModule} from 'projects/app-core/src/app/components/commoncomponent.module';
import {BulkUploadComponent} from './bulk-upload/bulk-upload.component';

@NgModule({
  declarations: [
    AddBytesCategoryComponent,
    AddBytesComponent,
    ManageBytesCategoryComponent,
    ManageBytesComponent,
    ViewComponent,
    BulkUploadComponent,
  ],
  imports: [
    CommonModule,
    BytesRoutingModule,
    NzUploadModule,
    NzIconModule,
    NzSelectModule,
    NzInputModule,
    NzDividerModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzSpinModule,
    NzPaginationModule,
    NzRateModule,
    NzSwitchModule,
    NzGridModule,
    NzTableModule,
    FormsModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzTabsModule,
    CommonComponentModule,
    NzToolTipModule,
    NzDropDownModule,
  ],
})
export class BytesModule {}
