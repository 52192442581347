import { Component } from '@angular/core';
import { NewCircleModeratorRequest } from '../../../models/circles/newModeratorRequest';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { lastValueFrom } from 'rxjs';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { CirclesService } from '../../../services/circles.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { Router } from '@angular/router';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { ContentService } from '../../../services/content.service';
import { v4 as uuidv4 } from 'uuid';

interface Qualifications {
  value: string;
}

@Component({
  selector: 'app-add-moderator',
  templateUrl: './add-moderator.component.html',
  styleUrl: './add-moderator.component.scss',
})
export class AddModeratorComponent {
  moderatorSpinner = false;
  moderatorName = '';
  moderatorEmail = '';
  contactNo = '';
  moderatorDescription = '';
  qualifications: Qualifications[] = [];
  avatarImage!: File;
  isActive!: boolean;

  uploadModeratorProfilePic($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.avatarImage = file;
    }
  }

  addQualification() {
    this.qualifications.push({ value: '' });
  }

  deleteQualification(index: number) {
    if (index != undefined) {
      this.qualifications.splice(index, 1);
    }
  }

  constructor(
    private fileService: FileUtilsService,
    private toastService: ToastService,
    private circles: CirclesService,
    private router: Router,
  ) {}

  async saveModerator() {
    this.moderatorSpinner = true;
    const request: NewCircleModeratorRequest = {
      name: this.moderatorName,
      about: this.moderatorDescription,
      email: this.moderatorEmail,
      contactNo: this.contactNo,
      active: this.isActive,
      qualifications: this.qualifications.map((data) => data.value),
      avatarImage: '',
    };
    if (this.avatarImage) {
      const uuid = uuidv4();
      const uuidWithoutHyphens = uuid.replace(/-/g, '');
      const extension = this.avatarImage.name.split('.').pop();
      const filePath = `circles/${uuidWithoutHyphens}/moderatorprofile.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePath,
          this.avatarImage.type,
        ),
      );
      this.toastService.showSuccess('Uploading Profile Pic');
      await lastValueFrom(
        this.fileService.uploadFile(this.avatarImage, uploadUrl.url),
      );
      request.avatarImage = `https://cdn.anyo.app/${filePath}`;
      this.toastService.showSuccess('Uploading Profile Pic completed');
    }
    this.circles.createCircleModerator(request).subscribe({
      next: (value) => {
        this.toastService.showSuccess('Moderator Created Sucessfully');
        this.moderatorSpinner = false;
        this.router.navigate(['circles/moderators']);
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
        this.moderatorSpinner = false;
      },
    });
  }
}
