import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-input-with-icon',
  templateUrl: './input-with-icon.component.html',
  styleUrls: ['./input-with-icon.component.scss'],
})
export class InputWithIconComponent {
  @ViewChild('box') box!: ElementRef;
  @ViewChild('pass') pass!: ElementRef;
  @Input() placeholder!: string;
  @Output() input = new EventEmitter<string>();
  @Input() border!: string;
  @Input() src!: string;
  @Input() src_2!: string;

  show: boolean = false;

  onKey(event: any) {
    this.input.emit(event);
    this.box.nativeElement.classList = 'inputwithicon ' + this.border;
  }

  toggle(event: any) {
    if (this.pass.nativeElement.type == 'password') {
      this.pass.nativeElement.type = 'input';
      this.show = true;
    } else {
      this.pass.nativeElement.type = 'password';
      this.show = false;
    }
  }
}
