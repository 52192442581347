import { Component } from '@angular/core';
import { TherapistService } from '../../../services/therapist.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { ICountResponse } from '../../../models/IcountResponse';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { TherapistTable } from '../../../models/therapistGridResponse';
import {
  TherapistFilter,
  TherapistGridRequest,
  TherapistSearch,
  TherapistSort,
} from '../../../models/therapistGridRequest';
import { AnyoSortDirection } from 'projects/app-core/src/models/gridRequest';
import * as moment from 'moment';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';

interface CheckBoxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  totalCount: number = 0;
  activeCount: number = 0;
  tableData: TherapistTable[] = [];
  pageSize: number = 10;
  pageSizeOptions = [10, 20];
  therapistTableCurrentPageNo = 1;
  therapistTableTotalCount = 0;
  therapistTableQuery: TherapistGridRequest | undefined;
  therapistTableSpinner: boolean = false;
  globalSearchInput = '';
  therapistNameColumnSearch = '';
  therapistEmailColumnSearch = '';
  statusCheckBoxOptions: CheckBoxOptions[] = [];
  switchLoading: boolean = false;
  isTherapistEmailSearch: boolean = false;
  isTherapistNameSearch: boolean = false;
  isStatusMenu: boolean = false;
  isButtonDisabled: boolean = true;

  constructor(
    private auth: AuthService,
    private therapistService: TherapistService,
    private toastrService: ToastService,
  ) {}

  onInputChange() {
    if (
      this.globalSearchInput ||
      this.therapistNameColumnSearch ||
      this.therapistEmailColumnSearch
    ) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  resetFilter() {
    this.therapistTableQuery = {
      dateFilters: undefined,
      export: false,
      filters: undefined,
      from: undefined,
      globalSearch: [],
      searchQuery: undefined,
      sort: {
        [TherapistSort.THERAPIST_STATUS]: AnyoSortDirection.ASC,
        [TherapistSort.THERAPIST_CREATED_AT]: AnyoSortDirection.DESC,
      },
      to: undefined,
      page: this.therapistTableCurrentPageNo,
      pageSize: this.pageSize,
    };
    this.globalSearchInput = '';
    this.therapistEmailColumnSearch = '';
    this.therapistNameColumnSearch = '';
    this.isButtonDisabled = true;
    this.listTherapists();
    this.isStatusMenu = false;
    this.isTherapistEmailSearch = false;
    this.isTherapistNameSearch = false;
  }

  getCount() {
    this.therapistService.therapistCount().subscribe({
      next: (value) => {
        value.forEach((v: ICountResponse) => {
          this.totalCount += v.count;
          if (v._id === 'active') {
            this.activeCount += v.count;
          }
        });
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  enableDisableTherapist(data: TherapistTable) {
    const id = data.id!;
    const req = {
      isAvailable: !data.isAvailable,
    };
    this.therapistService.enableAndDisableTherapist(id, req).subscribe({
      next: (value) => {
        this.toastrService.showSuccess('Therapist Availability Updated');
        this.listTherapists();
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  timeAgo(timestamp: string) {
    return moment(timestamp, 'x').fromNow();
  }

  listTherapists() {
    this.therapistTableSpinner = true;
    this.therapistService.listTherapist(this.therapistTableQuery!).subscribe({
      next: (value) => {
        this.statusCheckBoxOptions = value.filterOptions.status.map((stat) => {
          return {
            label: stat,
            value: stat,
            checked: false,
          };
        });
        this.therapistTableTotalCount = value.totalRecords;
        // value.data.forEach((data) => {
        //   data.lastSeen = moment(data.lastSeen).format(
        //     'DD-MM-YYYY [day] H [hours] m [minutes]',
        //   );
        // });
        this.tableData = value.data;
        this.therapistTableSpinner = false;
        this.isStatusMenu = false;
        this.isTherapistEmailSearch = false;
        this.isTherapistNameSearch = false;
      },
      error: (error) => {
        this.therapistTableSpinner = false;
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  therapistNameSearchFn() {
    this.therapistNameColumnSearch = this.therapistNameColumnSearch.trim();
    if (this.therapistNameColumnSearch.length) {
      if (!this.therapistTableQuery!.searchQuery) {
        this.therapistTableQuery!.searchQuery = {
          [TherapistSearch.THERAPIST_NAME]: '',
          [TherapistSearch.THERAPIST_EMAIL]: '',
        };
      }
      this.therapistTableQuery!.searchQuery!.THERAPIST_NAME =
        this.therapistNameColumnSearch;
      this.listTherapists();
    }
  }
  therapistEmailSearchFn() {
    this.therapistEmailColumnSearch = this.therapistEmailColumnSearch.trim();
    if (this.therapistEmailColumnSearch.length) {
      if (!this.therapistTableQuery!.searchQuery) {
        this.therapistTableQuery!.searchQuery = {
          [TherapistSearch.THERAPIST_NAME]: '',
          [TherapistSearch.THERAPIST_EMAIL]: '',
        };
      }
      this.therapistTableQuery!.searchQuery!.THERAPIST_EMAIL =
        this.therapistEmailColumnSearch;
      this.listTherapists();
    }
  }

  searchGlobal() {
    if (this.globalSearchInput.length) {
      this.therapistTableQuery?.globalSearch?.push(
        this.globalSearchInput.trim(),
      );
      this.listTherapists();
    }
  }

  nzPageIndexChange(page: number) {
    if (this.therapistTableQuery) {
      this.therapistTableQuery!.page = page;
      this.listTherapists();
    }
  }

  nzPageSizeChange(pageSize: number) {
    this.therapistTableQuery!.pageSize = pageSize;
    this.listTherapists();
  }

  statusFilter(status: any[]) {
    if (status && status.length) {
      if (!this.therapistTableQuery!.filters) {
        this.therapistTableQuery!.filters = {
          [TherapistFilter.THERAPIST_STATUS]: [],
          [TherapistFilter.THERAPIST_RATING]: [],
        };
      }
      this.therapistTableQuery!.filters!.THERAPIST_STATUS = status;
    }
  }

  ngOnInit(): void {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.getCount();
        this.therapistTableQuery = {
          dateFilters: undefined,
          export: false,
          filters: undefined,
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          sort: {
            [TherapistSort.THERAPIST_STATUS]: AnyoSortDirection.ASC,
            [TherapistSort.THERAPIST_CREATED_AT]: AnyoSortDirection.DESC,
          },
          to: undefined,
        };
        this.listTherapists();
      }
    });
  }
}
