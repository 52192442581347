<form #resetPasswordForm="ngForm" (ngSubmit)="resetPassword(resetPasswordForm)">
  <h1 class="set-pass">Set Password</h1>
  <label>Create your password</label>
  <app-input-with-icon
    [placeholder]="placeholder"
    [border]="border"
    [src]="eyeClosedIcon"
    [src_2]="eyeOpenIcon"
    (input)="onKey($event)"
  ></app-input-with-icon>
  <input
    type="hidden"
    name="password"
    [(ngModel)]="password"
    ngModel
    required
  />
  <img class="info" src="../../../assets/icons/info.svg" (click)="show()" />
  <div *ngIf="isShow">
    <app-password-requirements></app-password-requirements>
  </div>
  <label>Confirm Password</label>
  <app-input-with-icon
    [placeholder]="placeholder"
    [border]="border"
    [src]="eyeClosedIcon"
    [src_2]="eyeOpenIcon"
    (input)="validate($event)"
  ></app-input-with-icon>
  <input
    type="hidden"
    name="confirmpassword"
    [(ngModel)]="confirmpassword"
    ngModel
    required
  />
  <button
    class="btn btn-primary btn-block"
    [disabled]="!resetPasswordForm.valid || !isSame"
  >
    {{ buttonValue }}
  </button>
</form>
