import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from '@angular/fire/auth';
import { Permissions, Roles } from './models/roles';
import AuthorizationException from '../app/models/error/authException';
import { AnyoCustomClaims } from './models/AnyoCustomClaims';
import { FirebaseError } from 'firebase/app';
import { AnyoException } from '../app/models/error/anyoException';
import { BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
import { IAnyoUser } from './models/IAnyoUser';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../service/network-utils.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmailRequest, OtpRequest } from '../app/models/common-models';
import { environment } from '../environments/environment';
import { PasswordObject } from './models/permissions';
import { Router } from '@angular/router';
import { ToastService } from '../app/services/toastr.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  basePath = environment.apiBackendBaseUrl;
  currentUser: IAnyoUser | undefined = undefined;
  private authStatusSub = new BehaviorSubject(this.currentUser);
  currentAuthStatus = this.authStatusSub.asObservable();

  constructor(
    private toasterService: ToastService,
    private routerService: Router,
    private fireAuth: AngularFireAuth,
    private api: NetworkUtilsService,
    private http: HttpClient,
    private toast: NzMessageService,
  ) {
    this.authStatusListener();
  }

  async adminAuth() {
    try {
      const credential = await this.fireAuth.signInWithPopup(
        new GoogleAuthProvider(),
      );
      const email = credential.user?.email;
      if (!email || email?.split('@')[1] != 'anyo.app') {
        this.toasterService.showError('Must be anyo email');
        await this.fireAuth.signOut();
      }
      this.routerService.navigate(['/']);
      const claims = (await credential.user?.getIdTokenResult())?.claims as
        | AnyoCustomClaims
        | undefined;
      if (!claims || !claims.roles.includes(Roles.Admin)) {
        this.toasterService.showError('User is not an admin')
        await this.fireAuth.signOut();
      }
    } catch (e) {}
  }
  async signInWithEmailAndPassword(
    email: string,
    password: string,
    role: Roles,
  ) {
    try {
      const userCredential = await this.fireAuth.signInWithEmailAndPassword(
        email,
        password,
      );
      if (!userCredential) {
        throw new AuthorizationException('Invalid credentials passed');
      }
      const userToken = await userCredential.user?.getIdTokenResult();
      if (!userToken) {
        throw new AuthorizationException('Invalid user token');
      }
      const customClaims = userToken.claims as AnyoCustomClaims;
      if (Roles.InterChatTester === role) {
        if (
          userCredential.user?.email?.includes('anyo.app') ||
          customClaims.roles.includes(role)
        )
          return;
      }
      if (
        !customClaims ||
        !customClaims.roles ||
        !customClaims.roles.includes(role)
      ) {
        await this.signOut();
        throw new AuthorizationException('User doesnt have required Role');
      }
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        if (
          e.code === 'auth/wrong-password' ||
          e.code === 'auth/user-not-found'
        ) {
          throw new AuthorizationException('Invalid email or password');
        }
        if (e.code === 'auth/invalid-email') {
          throw new AuthorizationException('Invalid email');
        }
        if (e.code === 'auth/missing-password') {
          throw new AuthorizationException('Invalid Password');
        }
      }
      if (e instanceof AnyoException) {
        throw new AuthorizationException(e.errorBody);
      }
      throw new AuthorizationException('Error while login' + e);
    }
  }

  async signOut() {
    return await this.fireAuth.signOut();
  }
  async getToken() {
    if (this.currentUser) {
      const user = await this.fireAuth.currentUser;
      return await user!.getIdToken(true);
    } else {
      return null;
    }
  }

  authStatusListener() {
    //TODO Detach listener
    this.fireAuth.onAuthStateChanged(async (credential) => {
      if (credential) {
        const token = await credential.getIdTokenResult(true);
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token.token}`,
        });
        const roles: Roles[] = [];
        if (token.claims['roles']) {
          (token.claims['roles'] as string[]).forEach((role) =>
            roles.push(Roles[role as keyof typeof Roles]),
          );
        }
        const additionalPermissions: Permissions[] = [];
        if (token.claims['additionalPermissions']) {
          (token.claims['additionalPermissions'] as string[]).forEach(
            (permission) =>
              additionalPermissions.push(
                Permissions[permission as keyof typeof Permissions],
              ),
          );
        }
        try {
          this.api.get<IAnyoUser>(URL_DICT.getUserByEmail, headers).subscribe(
            async (value) => {
              this.currentUser = {
                userId: value.userId,
                name: value.name,
                gender: value.gender,
                active: value.active,
                roles: value.roles,
                email: credential?.email,
                isEmailVerified: credential.emailVerified,
                customClaims: {
                  roles: roles,
                  additionalPermissions: additionalPermissions,
                },
                token: token.token,
              };
              this.authStatusSub.next(this.currentUser);
            },
            async (error) => {
              console.log('Error ', error);
              await this.signOut();
              this.authStatusSub.next(undefined);
              await this.routerService.navigate(['/login']);
            },
          );
        } catch (e) {
          console.log('Error', e);
          this.authStatusSub.next(undefined);
        }
      } else {
        this.authStatusSub.next(undefined);
      }
    });
  }

  async generateOtp(body: EmailRequest): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.api.post(URL_DICT.generateOtpUrl, {
          email: body.email,
        }),
      );
      return response;
    } catch (e) {
      throw e;
    }
  }

  async verifyOtp(body: OtpRequest): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.api.post(URL_DICT.verifyOtpUrl, {
          email: body.email,
          otp: body.otp,
        }),
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(body: PasswordObject) {
    try {
      const response = await firstValueFrom(
        this.http.post(URL_DICT.resetPassword, body),
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
