<div class="flex flex-col flex-wrap w-3/5 ml-36 mt-24">
  <p class="text-xl font-semibold color heading">Setup Account</p>
  <label class="label-color mb-4">E-Mail Address</label>
  <input
    class="input"
    [(ngModel)]="email"
    placeholder="Enter your Email here"
    ngModel
    required
  />
  <button
    (click)="getOtp()"
    class="btn btn-primary btn-block-setup"
    [disabled]="!email"
  >
    Send OTP
  </button>
</div>
