import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { UserService } from '../../services/user.service';
// import { EmailRequest } from 'app/models/common-models';
import { ActivatedRoute, Router } from '@angular/router';
// import { ToastService } from 'app/services/toastr.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent {
  showotp: boolean = false;
  emailForm!: FormGroup;
  email!: string;
  firstLogin: boolean = false;
  isLogin: boolean = false;

  // constructor(private formBuilder: FormBuilder,
  //   private userService: UserService,
  //   private router: Router,
  //   private toastrService: ToastService) {
  //   this.emailForm = this.formBuilder.group({
  //     email: ['', Validators.required]
  //   });
  //   if (this.router.url.includes('true')) {
  //     this.firstLogin = true;
  //   }
  // }

  showOtp() {
    this.showotp = true;
  }

  generateOtp(emailForm: any) {
    //   let userinfo = emailForm.value.email;
    //   if (userinfo) {
    //     let req: EmailRequest = {
    //       phoneNumber: null,
    //       email: emailForm.value.email,
    //       firstLogin: this.firstLogin,
    //       role: "Admin"
    //     };
    //     if (userinfo.match(/^\d+$/) || !userinfo.includes('@')) {
    //       req = {
    //         email: null,
    //         phoneNumber: emailForm.value.email,
    //         firstLogin: this.firstLogin,
    //         role: "Admin"
    //       };
    //       userinfo = emailForm.value.email;
    //     }
    //     this.userService.generateOtp(req).subscribe({
    //       next: (value) => {
    //         this.toastrService.showSuccess("OTP sent successfully")
    //         this.router.navigate(['/otp/' + userinfo + '/' + this.firstLogin]);
    //       },
    //       error: (err) => {
    //         if(err.error.message === "User Not Found") {
    //           this.toastrService.showError("Invalid email-id/mobile number. Please contact your administrator")
    //         }
    //         else if(err.error.message === "User Have  Allready completed First time setup") {
    //           this.toastrService.showError("First-time setup completed for this account. Login instead.");
    //           this.isLogin = true;
    //         }
    //         else {
    //           this.toastrService.showError(err.error.message);
    //         }
    //       }
    //     })
    //   }
  }

  ngOnInit(): void {}
}
