import {Injectable} from '@angular/core';
import {Action, AngularFirestore,} from '@angular/fire/compat/firestore';
import {lastValueFrom, Observable} from 'rxjs';
import firebase from 'firebase/compat';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Injectable({
  providedIn: 'root',
})
export class AnyoFirestoreService {
  constructor(public db: AngularFirestore) {}
  attachListener<T>(path: string) {
    return this.db.doc(path).snapshotChanges() as Observable<
      Action<DocumentSnapshot<T>>
    >;
  }

  async getDocument<T>(path: string): Promise<T | undefined> {
    const document = await lastValueFrom(await this.db.doc(path).get())
    if (!document) {
      return undefined
    }
    return document.data() as unknown as T
  }
  updateDocument<T>(path: string, data: T) {
    return this.db.doc(path).set(data, { merge: true });
  }

  constructFirestoreDbPath(mainCollection: string, ...subCollection: string[]) {
    return mainCollection + '/' + subCollection.join('/');
  }
}

export class FirestoreCollection {
  public static THERAPISTS = 'therapists';
  public static LISTENERS = 'listeners';
  public static Users = 'users';
  public static CHAT_SESSIONS = 'chatSessions';
  public static CHAT_WAITING_ROOM = 'chatWaitingRoom';
  public static NEW_CHAT_REQUESTS = 'newChatRequests';
  public static REJECTED_REQUESTS = 'rejectedRequests';
  public static FCM_TOKEN = "fcmToken"
  public static TALK_LOGS = "talkLogs"
}
export class FirestoreSubCollection {
  public static MESSAGES = 'messages';
}
