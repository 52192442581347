<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/events']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text pl-2">Add Events</h1>
  </div>
</div>
<nz-spin [nzSpinning]="pageLoading">
  <nz-card>
    <form [formGroup]="validateForm" nz-form>
      <div nz-row nzGutter="16">
        <div nz-col nzSpan="16">
          <nz-form-item>
            <nz-form-label nzFor="title" nzRequired nzSpan="24"
              >Title
            </nz-form-label>
            <nz-form-control nzErrorTip="Please input Title" nzSpan="24">
              <input
                formControlName="eventName"
                id="title"
                nz-input
                placeholder="Title"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label nzRequired nzSpan="24"> Category</nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="selectedCategory"
                id="eventCategory"
                nzAllowClear
                nzPlaceHolder="Select a Event Category"
                nzShowSearch
              >
                <nz-option
                  *ngFor="let category of eventCategories"
                  [nzLabel]="category.label"
                  [nzValue]="category.value"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label nzFor="eventDuration" nzRequired nzSpan="24">
              Duration in Minutes
            </nz-form-label>
            <nz-form-control nzSpan="24">
              <input
                formControlName="eventDuration"
                nz-input
                placeholder="Duration in Minutes"
                type="number"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="mt-5" nz-row nzGutter="10">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzFor="eventDescription" nzRequired nzSpan="24"
              >Description
            </nz-form-label>
            <nz-form-control nzSpan="24">
              <textarea
                formControlName="eventDescription"
                id="eventDescription"
                nz-input
                placeholder="Event Description"
                rows="10"
              ></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzFor="keyPoints" nzRequired nzSpan="24"
              >Key Points
            </nz-form-label>
            <nz-form-control nzSpan="24">
              <textarea
                formControlName="keyPoints"
                id="keyPoints"
                nz-input
                placeholder="Key Points"
                rows="10"
              ></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="mt-5" nz-row nzGutter="16">
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="eventDate" nzRequired nzSpan="24">
              Event Date
            </nz-form-label>
            <nz-form-control>
              <nz-date-picker
                [nzFormat]="dateTimeFormat"
                formControlName="eventDate"
                id="eventDate"
                nzShowTime
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="visibleTime" nzRequired nzSpan="24">
              Visible From Start of Date
            </nz-form-label>
            <nz-form-control>
              <nz-date-picker
                [nzFormat]="dateFormat"
                formControlName="visibleTime"
                id="visibleTime"
                nzShowTime
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="cutOffTime" nzRequired nzSpan="24">
              Registration Cut Off By the end of Selected Date
            </nz-form-label>
            <nz-form-control>
              <nz-date-picker
                [nzFormat]="dateFormat"
                formControlName="registrationCutOff"
                id="cutOffTime"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="4">
          <nz-form-item>
            <nz-form-label nzFor="maximumRegistrations" nzRequired nzSpan="24">
              Max Registration Count
            </nz-form-label>
            <nz-form-control nzSpan="24">
              <input
                formControlName="maximumRegistrations"
                nz-input
                placeholder="Max Registration Count"
                type="number"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="mt-5" nz-row nzGutter="16">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzFor="language" nzRequired>Languages</nz-form-label>
            <nz-form-control nzSpan="24">
              <nz-select
                formControlName="selectedLanguages"
                id="language"
                nzAllowClear
                nzMode="tags"
                nzPlaceHolder="Languages"
                nzShowSearch
              >
                <nz-option
                  *ngFor="let language of languages"
                  [nzLabel]="language"
                  [nzValue]="language"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label nzRequired> Event Payment Types</nz-form-label>
            <nz-form-control nzSpan="24">
              <nz-select
                (ngModelChange)="IsFree($event)"
                formControlName="eventPaymentType"
                id="eventType"
              >
                <nz-option
                  *ngFor="let type of eventPaymentTypes"
                  [nzLabel]="type"
                  [nzValue]="type"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <div class="mt-2">
            <ul>
              <li>
                <span nz-typography><strong>Free</strong></span> - Free for all
                Customers
              </li>
              <li><b>Paid</b> - Paid for all Customers</li>
              <li>
                <b>Retail Paid</b> - Free for Corporate users, Paid for retail
                users
              </li>
              <li>
                <b>Corporate Paid</b> - Free for Retail users, Paid for
                Corporate users
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
    <nz-divider nzOrientation="left" nzText="Images"></nz-divider>
    <div nz-row>
      <div nz-col nzSpan="6">
        <span><b>Upload Images</b></span>
        <br />
        <input
          (change)="uploadImages($event)"
          multiple
          accept=".jpg,.jpeg,.png"
          type="file"
        />
        <div *ngFor="let image of images">
          <p>{{ image.name }}</p>
        </div>
      </div>
      <div nz-col>
        <span><b>Square Image</b></span>
        <br />
        <input
          (change)="uploadSquare($event)"
          accept=".jpg,.jpeg,.png"
          type="file"
        />
      </div>
    </div>
    <nz-divider nzOrientation="left" nzText="Share Details"></nz-divider>
    <div nz-row>
      <div nz-col nzSpan="10">
        <label class="form-label required">Share Message</label>
        <textarea [(ngModel)]="shareMessage" nz-input rows="4"></textarea>
      </div>
      <div nz-col class="pl-2" nzSpan="6">
        <span><b>Upload Share Image</b></span>
        <br />
        <input
          (change)="uploadShareImage($event)"
          accept=".jpg,.jpeg,.png"
          type="file"
        />
      </div>
    </div>

    <nz-divider nzOrientation="left" nzText="Entry Fee Details"></nz-divider>
    <div>
      <div *ngFor="let fee of eventEntryFee" nz-row nzGutter="16">
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzRequired nzSpan="24">Amount</nz-form-label>
            <nz-form-control>
              <nz-input-group nzPrefix="₹" nzSuffix="INR">
                <input
                  [(ngModel)]="fee.amount"
                  nz-input
                  placeholder="Amount"
                  type="number"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          `
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzRequired nzSpan="24">MarkUp</nz-form-label>
            <nz-form-control>
              <nz-input-group nzPrefix="₹" nzSuffix="INR">
                <input
                  [(ngModel)]="fee.markupPrice"
                  nz-input
                  placeholder="Markup Price"
                  type="number"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <nz-divider nzOrientation="left" nzText="Guest Details"></nz-divider>
    <div nz-row nzJustify="end">
      <div nz-col>
        <button (click)="addGuestSpeaker()" nz-button nzType="primary">
          <span nz-icon nzType="download"></span>
          Add Guest Speaker
        </button>
      </div>
    </div>
    <div *ngFor="let speaker of guestSpeakerDetails; index as index">
      <nz-card class="mt-5" nzTitle="Guest Speaker {{ index + 1 }}">
        <div nz-row nzGutter="16">
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired> Name</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.name"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="name"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired> Email</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.email"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="Email"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired> Qualification</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.qualification"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="Qualification"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div class="mt-2" nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label nzRequired> Description</nz-form-label>
              <nz-form-control nzSpan="24">
                <textarea
                  [(ngModel)]="speaker.description"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="description"
                  rows="5"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <nz-divider nzText="Social Media Links"></nz-divider>
        <div nz-row nzGutter="16">
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label> Twitter</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.socialMediaLinks.twitter"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="Twitter"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label> Youtube</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.socialMediaLinks.youtube"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="Youtube"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="mt-2" nz-row nzGutter="16">
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label> Web</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.socialMediaLinks.web"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="Web"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label> Facebook</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.socialMediaLinks.faceBook"
                  [ngModelOptions]="{ standalone: true }"
                  nz-input
                  placeholder="Facebook"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="mt-2" nz-row nzGutter="16">
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label> Linked In</nz-form-label>
              <nz-form-control nzSpan="24">
                <input
                  [(ngModel)]="speaker.socialMediaLinks.linkedIn"
                  nz-input
                  placeholder="LinkedIn"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <div class="mt-2" nz-row nzAlign="bottom" nzJustify="end">
              <button
                (click)="deleteGuestSpeaker(index)"
                nz-button
                nzDanger
                nzType="primary"
              >
                Delete Guest Speaker
              </button>
            </div>
          </div>
        </div>
      </nz-card>
    </div>
    <nz-divider nzOrientation="left" nzText="Presenter Details"></nz-divider>
    <div nz-row>
      <div nz-col nzSpan="8">
        <nz-select
          [(ngModel)]="selectedExperts"
          id="circleExperts"
          nzAllowClear
          nzMode="tags"
          nzPlaceHolder="Experts"
          nzShowSearch
        >
          <nz-option
            *ngFor="let expert of circleExpertsList"
            [nzLabel]="expert.expertId"
            [nzValue]="expert.name"
          ></nz-option>
        </nz-select>
      </div>
    </div>
    <nz-divider></nz-divider>
    <div nz-row>
      <div nz-col>
        <label [(ngModel)]="testEvent" nz-checkbox>Test Event</label>
      </div>
      <div nz-col>
        <label [(ngModel)]="testRealPayment" nz-checkbox
          >Test Real Payment</label
        >
      </div>
    </div>
    <nz-divider></nz-divider>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item nz-row>
          <nz-form-control [nzSpan]="24">
            <button (click)="addEvent()" nz-button nzType="primary">
              Add Event
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </nz-card>
</nz-spin>
