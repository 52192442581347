<div class="partner inputwithicon" #box>
  <input
    class="input"
    type="password"
    (keyup)="onKey($event)"
    [placeholder]="placeholder"
    #pass
  />
  <img *ngIf="!show" class="img" [src]="src" (click)="toggle($event)" />
  <img *ngIf="show" class="img" [src]="src_2" (click)="toggle($event)" />
</div>
