<nav
  class="menu bg-gray-2 p-4 rounded-md"
  [ngClass]="isCollapsed ? 'app-menu-collapsed' : 'app-menu-expanded'"
>
  <section class="menu-section">
    <div *ngIf="!isCollapsed" class="flex justify-center">
      <img
        src="assets/images/logo.svg"
        alt="image"
        class="horizontal-separator mb-8 pb-2 w-3/5"
        [routerLink]="['/']"
      />
    </div>
    <div *ngIf="isCollapsed" class="flex justify-center">
      <img
        src="assets/icons/anyo-icon.svg"
        alt="image"
        class="horizontal-separator mb-8 pb-2"
        [routerLink]="['/']"
      />
    </div>
    <div>
      <div
        class="arrowBtn"
        [ngClass]="isCollapsed ? 'position-collapsed' : 'position-expanded'"
        (click)="collapse()"
      >
        <span class="arrow"></span>
      </div>
    </div>

    <ul class="menu-items" *ngFor="let menu of menuArray; index as i">
      <li (click)="navigateToDashboard(i)">
        <input type="checkbox" [id]="menu.name" class="menu-toggle" />
        <label
          [ngClass]="menu.name === '' ? 'hidden' : 'menu-item'"
          [for]="menu.name"
        >
          <img *ngIf="!isCollapsed" [src]="menu.icon" />
          <span *ngIf="!isCollapsed">{{ menu.name }}</span>
          <span *ngIf="!isCollapsed" class="menu-icon mr-0 ml-auto">
            <img
              *ngIf="!isCollapsed && i != 0"
              src="assets/icons/right-arrow.svg"
            />
          </span>
        </label>
        <div class="menu-item-collapse" *ngFor="let subMenu of menu.subMenu">
          <div *ngIf="!isCollapsed" class="min-h-0">
            <label class="menu-item ml-6" [routerLink]="[subMenu.link]">{{
              subMenu.name
            }}</label>
          </div>
        </div>
      </li>
    </ul>
  </section>
</nav>
