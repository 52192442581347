<form #otpForm="ngForm" (ngSubmit)="verifyOtp(otpForm)">
  <label class="otplabel"
    >We have sent a 4-digit verification code to your e-mail address</label
  >
  <br />
  <label>OTP</label>
  <div class="otp-container">
    <input
      class="input otp-box"
      name="otp_1"
      [(ngModel)]="otp_1"
      type="text"
      maxlength="1"
      (keyup)="focusNext(otp_1, 'otp2')"
      ngModel
      autofocus
      required
      id="otp1"
    />
    <input
      class="input otp-box"
      type="text"
      name="otp_2"
      maxlength="1"
      [(ngModel)]="otp_2"
      (keyup)="focusNext(otp_2, 'otp3')"
      ngModel
      required
      id="otp2"
    />
    <input
      class="input otp-box"
      type="text"
      name="otp_3"
      maxlength="1"
      [(ngModel)]="otp_3"
      (keyup)="focusNext(otp_3, 'otp4')"
      ngModel
      required
      id="otp3"
    />
    <input
      class="input otp-box"
      type="text"
      name="otp_4"
      maxlength="1"
      [(ngModel)]="otp_4"
      (keyup)="focusNext(otp_4, 'verifyBtn')"
      ngModel
      required
      id="otp4"
    />
  </div>
  <div *ngIf="!resendOtp" class="otptimer">
    <a class="a nootp">Haven't received the OTP?</a>
    <span class="timer">00:{{ timeLeft }}</span>
  </div>
  <div *ngIf="resendOtp" class="otptimer">
    <a (click)="resendOtpFn()" class="a nootp point">Resend OTP</a>
  </div>
  <button class="btn btn-primary btn-block" id="verifyBtn">Verify OTP</button>
</form>
