import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BytesGridResponseData,
  BytesGridResponse,
  BytesGridFilterData,
} from '../../../../../admin/src/app/models/bytesGridResponse';
import * as moment from 'moment';

interface CheckBoxOptions {
  label: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-bytes-table',
  templateUrl: './bytes-table.component.html',
  styleUrls: ['./bytes-table.component.scss'],
})
export class BytesTableComponent {
  @Input() inputTableData: BytesGridResponse | undefined;
  @Output() resetFilterEvent = new EventEmitter<void>();
  @Output() pageSizeChangeEvent = new EventEmitter<number>();
  @Output() pageChangeEvent = new EventEmitter<number>();
  @Output() bytesDateSortEvent = new EventEmitter<string | null>();
  @Output() listBytesEvent = new EventEmitter<void>();
  @Output() bytesCategoryFilterEvent = new EventEmitter<string[]>();

  pageSize: number = 10;
  pageSizeOptions = [10, 20, 50, 100];
  listOfData: BytesGridResponseData[] = [];
  bytesCategoryCheckBoxOptions: CheckBoxOptions[] = [];
  selectedByesCategoryFilterStatus: string[] = [];
  isBytesCategoryMenu!: boolean;

  nzPageIndexChange(page: number) {
    this.pageChangeEvent.emit(page);
  }

  nzPageSizeChange(pageSize: number) {
    this.pageSizeChangeEvent.emit(pageSize);
  }

  resetFilter() {
    this.resetFilterEvent.emit();
  }

  convertDateFormat(date: Date) {
    return moment(date, 'x').format('DD/MM/YYYY h:mm a');
  }

  sortBytesDates(sort: string | null) {
    this.bytesDateSortEvent.emit(sort);
  }

  listBytes() {
    this.listBytesEvent.emit();
  }

  setBytesCategoryFilterData(filterOptions?: BytesGridFilterData) {
    this.bytesCategoryCheckBoxOptions = [];
    filterOptions?.categories.forEach((cat) =>
      this.bytesCategoryCheckBoxOptions.push({
        checked: false,
        label: cat,
        value: cat,
      }),
    );
  }

  bytesCategoryFilterFn() {
    this.bytesCategoryFilterEvent.emit(this.selectedByesCategoryFilterStatus);
  }

  bytesCategoryFilterChangeFn($event: string[]) {
    this.selectedByesCategoryFilterStatus = $event;
  }

  resetValues() {
    this.pageSize = 10;
    this.selectedByesCategoryFilterStatus = [];
  }

  checkDeliveredStatus(data: BytesGridResponseData) {
    if (moment(data.date, 'x').toDate() <= moment().toDate()) {
      return true;
    }
    return false;
  }

  checkScheduledStatus(data: BytesGridResponseData) {
    if (moment(data.date, 'x').toDate() <= moment().toDate()) {
      return false;
    }
    return true;
  }
}
