<div id="progress-container">
  <svg id="progress" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
    <circle cx="100" cy="100" r="90"></circle>
    <circle
      id="progress-bar"
      cx="100"
      cy="100"
      r="90"
      [style.stroke]="color"
      [style.strokeDashoffset]="progressOffset"
      [ngClass]="{ timeChange: changeTime }"
    ></circle>
  </svg>
  <div id="timer">{{ timerText }}</div>
</div>
