<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Therapy Plans Subcription</span>
      </div>
      <div nz-col>
        <button
          class="btn-color"
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="resetFilter()"
        >
          Clear filter
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #innerTableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Session Details</span>
      </div>
    </div>
  </ng-template>
  <div class="max-w-1xl mx-auto bg-white p-4 rounded-lg shadow-lg">
    <div class="flex flex-wrap pb-4 justify-start">
      <h1 class="headeradminui-text pl-2">Manage Therapy Plans Subscription</h1>
    </div>
   
    <nz-spin [nzSpinning]="pageLoading">
      <nz-table
        #nestedTable
        [nzTitle]="tableHead"
        [nzScroll]="{ y: '500px' }"
        [nzData]="therapyPlans"
        [nzShowPagination]="true"
        nzFrontPagination="false"
        [nzTotal]="totalRecords"
        [nzPageSize]="pageSize"
        [nzBordered]="true"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="pageSizeOptions"
        [nzPageIndex]="currentPageNo"
        nzTableLayout="fixed"
        (nzPageIndexChange)="nzPageIndexChange($event)"
        (nzPageSizeChange)="nzPageSizeChange($event)"
      >
        <thead>
          <tr>
            <th nzWidth="5%"></th>
            <th>
              User Name
               <!-- <nz-filter-trigger
                [nzActive]="true"
                [nzDropdownMenu]="userNameSearch"
              >
                <span nz-icon nzType="search"></span>
              </nz-filter-trigger> -->
            </th>
            <th>
              Plan Name
              <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="planNameSearch"
            >
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
            </th>
            <th >Purchased date
              <nz-filter-trigger
              [nzActive]="true"
              [nzDropdownMenu]="purchasedAtDateFilterMenu"
            >
              <span nz-icon nzTheme="outline" nzType="schedule"></span>
            </nz-filter-trigger>
            </th>
            <th>Partner ID</th>
            <th>Validity
                <nz-filter-trigger
                [nzActive]="true"
                [nzDropdownMenu]="validTillDateFilterMenu"
              >
                <span nz-icon nzTheme="outline" nzType="schedule"></span>
              </nz-filter-trigger>
            </th>
            <th>
              Payment Type
              <nz-filter-trigger
                [nzActive]="true"
                [nzDropdownMenu]="paymentTypeMenu"
              >
                <span nz-icon nzTheme="outline" nzType="filter-o"></span>
              </nz-filter-trigger>
            </th>
            <th>Verticle</th>
            <th>Concern</th>
            <th>No of Sessions</th>
            <th>Plan value</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of nestedTable.data">
          <tr>
            <td (nzExpandChange)="onExpandChange(data.id!,$event)"
            [nzExpand]="expandSet.has(data.id!)"></td>
            <td
          >{{ data.userDetails.name }}</td>
            <td>{{ data.planName }}</td>
            <td>{{ utils.convertEpochToFormattedDate(data.purchasedAt)}}</td>
            <td>{{ data.partnerId }}</td>
            <td>{{ utils.convertEpochToFormattedDate(data.validTill) }}</td>
            <td [align]="'left'">
              <nz-tag [nzColor]="data.paymentTypeColor">{{
                utils.convertToTitleCase(data.paymentType)
              }}</nz-tag>
            </td>
            <td>{{ data.vertical }}</td>
            <td>{{ data.concern }}</td>
            <td>{{ data.noOfSessions }}</td>
            <td>{{ data.planValue ? data.planValue : 'N/A' }}</td>

           
          </tr>
          <tr [nzExpand]="expandSet.has(data.id!)" >
            <nz-table #innerTable [nzData]="data.sessionDetailslist" nzSize="middle" [nzShowPagination]="false" [nzTitle]="innerTableHead">
              <thead>
                <tr>
                  <th>Therapist Name</th>
                  <th>Therapist Email</th>
                  <th>Session Name</th>
                  <th>Session Description</th>
                  <th>Session Booked</th>
                  <th>Session Completed</th>
                  <th>Session Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of innerTable.data">
                  <td>{{ data.therapistName }}</td>
                  <td>{{ data.therapistEmail }}</td>
                  <td>
                   {{ data.sessionName }}
                  </td>
                  <td>{{ data.description }}</td>
                  <td>{{ data.sessionBooked ? 'Yes' : 'No' }}</td>
                  <td>{{ data.sessionCompleted ? 'Yes' : 'No' }}</td>
                  <td>{{ data.sessionStatus }}</td>
                </tr>
              </tbody>
            </nz-table>
          </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </nz-spin>
  </div>
  
  <nz-dropdown-menu #paymentTypeMenu="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div nz-row>
        <div nz-col>
          <span class="font-bold">Payment Type</span>
          <nz-divider></nz-divider>
          <nz-checkbox-wrapper (nzOnChange)="paymentTypeFilterChangeFn($event)">
            <div *ngFor="let topic of paymentTypeCheckBoxOptions" nz-row>
              <div nz-col>
                <label
                  nz-checkbox
                  [nzValue]="topic.value"
                  [ngModel]="topic.checked"
                  >{{ topic.label }}</label
                >
              </div>
            </div>
          </nz-checkbox-wrapper>
        </div>
      </div>
      <div nz-row nzJustify="end">
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="fetchTherapyPlans()"
          >
            Ok
          </button>
        </div>
  
        <div nz-col>
          <button
            class="m-1 btn-color"
            nz-button
            nzType="primary"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <nz-dropdown-menu #validTillDateFilterMenu="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="ng-row">
        <nz-range-picker
          [(ngModel)]="validTillColumnFilterDate"
          (ngModelChange)="validTillFilterDateChange($event)"
        ></nz-range-picker>
      </div>
      <div nz-row class="mt-5" nzJustify="end">
        <div nz-col class="mr-1">
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="fetchTherapyPlans()"
          >
            Ok
          </button>
        </div>
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <nz-dropdown-menu #purchasedAtDateFilterMenu="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="ng-row">
        <nz-range-picker
          [(ngModel)]="purchasedAtColumnFilterDate"
          (ngModelChange)="purchasedAtFilterDateChange($event)"
        ></nz-range-picker>
      </div>
      <div nz-row class="mt-5" nzJustify="end">
        <div nz-col class="mr-1">
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="fetchTherapyPlans()"
          >
            Ok
          </button>
        </div>
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzType="primary"
            class="btn-color"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <nz-dropdown-menu #planNameSearch="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="m-1" nz-row>
        <div nz-col>
          <input
            [(ngModel)]="planNameColumnSearch"
            nz-input
            placeholder="Search"
            nzSize="small"
          />
        </div>
      </div>
      <div class="m-1" nz-row nzJustify="end">
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchByPlanName()"
          >
            Ok
          </button>
          <button
            class="m-1"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
  <!-- <nz-dropdown-menu #userNameSearch="nzDropdownMenu">
    <div class="bg-white drop-shadow-md m-1 p-2 rounded-md">
      <div class="m-1" nz-row>
        <div nz-col>
          <input
            [(ngModel)]="userNameColumnSearch"
            nz-input
            placeholder="internal PlanName Search"
            nzSize="small"
          />
        </div>
      </div>
      <div class="m-1" nz-row nzJustify="end">
        <div nz-col>
          <button
            class="m-1"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchByUserName()"
          >
            Ok
          </button>
          <button
            class="m-1"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu> -->