import {Injectable} from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from 'projects/app-core/src/service/network-utils.service';
import {BytesCategoryGridRequest} from '../models/bytesCategoriesGridRequest';
import {BytesCategoriesGridResponse} from '../models/bytesCategoriesGridResponse';
import {NewAddBytesRequest} from '../models/newCreateBytesRequest';
import {NewAddBytesCategoryRequest} from '../models/newAddBytesCategoryRequest';
import {BytesGridRequest} from '../models/bytesGridRequest';
import {BytesGridResponse} from '../models/bytesGridResponse';
import {NewUpdateBytesRequest} from '../models/newUpdateBytesRequest';

export interface Bytes {
  _id?: string;
  categoryId: string;
  categoryValue: string;
  isActive: boolean;
  title: string;
  noOfFrames: number;
  frames: Frame[];
  startTime: Date;
  endTime: Date;
}

export interface Frame {
  id: string;
  uid: string;
  value: string;
  textColor: string;
  textBGColor: string;
  showTimeSeconds: number;
}

@Injectable({
  providedIn: 'root',
})
export class BytesService {
  constructor(private api: NetworkUtilsService) {}

  public createBytesCategory(request: NewAddBytesCategoryRequest) {
    return this.api.post(URL_DICT.adminBytesCategoryUrl, request);
  }

  public bulkUploadBytes(request: NewAddBytesRequest[]) {
    return this.api.post(URL_DICT.adminAddNewBytesBulkUrl, request);
  }
  public listBytesCategories(gridRequest: BytesCategoryGridRequest) {
    return this.api.post<BytesCategoriesGridResponse>(
      URL_DICT.adminBytesCategoriesGridUrl,
      gridRequest,
    );
  }

  public createBytes(request: NewAddBytesRequest) {
    return this.api.post(URL_DICT.adminAddNewBytesUrl, request);
  }

  public listBytes(request: BytesGridRequest) {
    return this.api.post<BytesGridResponse>(
      URL_DICT.adminBytesGridUrl,
      request,
    );
  }

  public getByte(byteId: string) {
    return this.api.get<Bytes>(URL_DICT.adminGetByteUrl + `${byteId}`);
  }

  public updateBytes(byteId: string, request: NewUpdateBytesRequest) {
    return this.api.post(URL_DICT.adminGetByteUrl + `${byteId}`, request);
  }
}
