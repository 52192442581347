import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TherapyPlansRoutingModule } from './therapy-plans-routing.module';
import { CreateTherapyPlanComponent } from '../create-therapy-plan/create-therapy-plan.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NzFormControlComponent,
  NzFormDirective,
  NzFormLabelComponent,
} from 'ng-zorro-antd/form';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

import { TherapyPlansListComponent } from '../therapy-plans-list/therapy-plans-list.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { EditTherapyPlanComponent } from '../edit-therapy-plan/edit-therapy-plan.component';
import { AllTherapyPlansListComponent } from '../all-therapy-plans-list/all-therapy-plans-list.component';
import { TherapyPlansSubcriptionComponent } from '../therapy-plans-subcription/therapy-plans-subcription.component';

@NgModule({
  declarations: [CreateTherapyPlanComponent, TherapyPlansListComponent,EditTherapyPlanComponent,AllTherapyPlansListComponent,TherapyPlansSubcriptionComponent],
  imports: [
    NzBadgeModule,
    NzTagModule,
    NzFormControlComponent,
    NzFormLabelComponent,
    NzFormDirective,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TherapyPlansRoutingModule,
    NzInputNumberModule,
    NzAlertModule,
    NzAvatarModule,
    NzTableModule,
    NzDropDownModule,
    NzButtonModule,
    NzIconModule,
    NzTabsModule,
    NzGridModule,
    NzDatePickerModule,
    NzInputModule,
    NzSpinModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSwitchModule,
    NzModalModule,
    NzRadioModule,
    NzSelectModule,
    NzToolTipModule,
    NzAutocompleteModule,
    NzTimePickerModule,
  ],
})
export class TherapyPlansModule {}
