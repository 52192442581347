<div class="navbar header">
  <div class="navbar-start">
    <a class="navbar-item text-black">Hello {{ name }}</a>
  </div>
  <div class="navbar-end">
    <div>
      <img class="info" src="../../../assets/icons/notifications-unread.svg"/>
    </div>
    <div class="avatar avatar-ring avatar-md">
      <div class="dropdown-container">
        <div class="dropdown">
          <label class="btn btn-ghost flex cursor-pointer px-0" tabindex="0">
            <img
              src="../../../assets/icons/default-user.svg"
              alt="avatar"
              class="info"
            />
          </label>
          <div class="dropdown-menu dropdown-menu-bottom-left">
            <a class="dropdown-item text-sm" href="">Switch Role</a>
            <a class="dropdown-item text-sm" href="">Account Details</a>
            <a class="dropdown-item text-sm" href="" (click)="logout()"
            >Logout</a
            >
            <!-- <a tabindex="-1" class="dropdown-item text-sm">Account settings</a>
						<a tabindex="-1" class="dropdown-item text-sm">Subscriptions</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
