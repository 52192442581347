import { GridRequest } from '../../../models/gridRequest';

export interface TalkLogsGridRequest
  extends GridRequest<
    TalkLogSort,
    TalkLogGridFilter,
    TalkLogSearchFilter,
    TalkLogDateFilter
  > {}

export enum TalkLogSort {
  START_TIME = 'START_TIME',
}

export enum TalkLogGridFilter {
  TALK_ID = 'TALK_ID',
  AWS_SESSION_ID = 'AWS_SESSION_ID',
  HOST_USER_ID = 'HOST_USER_ID', // This will be used for filtering sessions by a specific host's userId
}

export enum TalkLogDateFilter {
  START_TIME = 'START_TIME',
}

export enum TalkLogSearchFilter {
  AWS_SESSION_ID = 'AWS_SESSION_ID',
}
