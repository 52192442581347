<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <h1 class="headeradminui-text md:break-keep">Manage Circles</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Circles</h1>
    </div>
  </div>
  <div
    class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
    [routerLink]="['/circles/add']"
  >
    <div>
      <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
    </div>
    <div>
      <span class="">Add New Circles</span>
    </div>
  </div>
</div>
<div class="flex justify-end">
  <div class="w-[40%]">
    <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
      <input
        type="text"
        nz-input
        [(ngModel)]="globalSearchInput"
        placeholder="Search for name "
      />
    </nz-input-group>
  </div>
</div>
<ng-template #suffixButton>
  <button
    nz-button
    nzType="primary"
    nzSize="small"
    nzSearch
    (click)="searchGlobal()"
  >
    Search
  </button>
</ng-template>
<ng-template #tableHead>
  <div nz-row nzJustify="space-between">
    <div nz-col>
      <span class="font-bold">Circles</span>
    </div>
    <div nz-col>
      <button nz-button nzSize="small" nzType="primary" (click)="resetFilter()">
        Clear filter
      </button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSpinning]="circlesSpinner" nzTip="Loading...">
  <nz-table
    #basicTable
    [nzData]="circlesData"
    [nzTitle]="tableHead"
    [nzScroll]="{ y: '500px' }"
    class="mt-5"
    [nzShowPagination]="true"
    nzFrontPagination="false"
    [nzTotal]="circlesTableTotalCount"
    [nzPageSize]="pageSize"
    [nzBordered]="true"
    [nzShowSizeChanger]="true"
    [nzPageSizeOptions]="pageSizeOptions"
    [nzPageIndex]="circlesTableCurrentPageNo"
    nzTableLayout="fixed"
    (nzPageIndexChange)="nzPageIndexChange($event)"
    (nzPageSizeChange)="nzPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>Sub Topics</th>
        <th>No of Members</th>
        <th>No of Posts</th>
        <th>Moderators</th>
        <th>Experts</th>
        <th>Therapists</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data.name }}</td>
        <td>{{ data.circleTopics }}</td>
        <td>{{ data.memberCount }}</td>
        <td>{{ data.noOfPosts }}</td>
        <td>{{ data.moderators }}</td>
        <td>{{ data.experts }}</td>
        <td>{{ data.therapists }}</td>
        <td>
          <div class="flex items-center justify-evenly">
            <span nz-tooltip nzTooltipTitle="View Circle">
              <button
                [routerLink]="['/circles/view/' + data.id]"
                nz-button
                nzType="primary"
                nzShape="circle"
              >
                <span nz-icon nzType="eye" nzTheme="outline"></span>
              </button>
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
