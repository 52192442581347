import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { URL_DICT } from '../service/network-utils.service';

@Injectable()
export class TokenV2Interceptor implements HttpInterceptor {
  ignoreDomain = [
    'anyo-cdn-public-bucket.s3.ap-south-1.amazonaws.com',
    'anyo-circles-bucket.s3.ap-south-1.amazonaws.com',
  ];

  ignoreUrl = [
    URL_DICT.generateOtpUrl,
    URL_DICT.verifyOtpUrl,
    URL_DICT.resetPassword,
  ];

  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      this.ignoreDomain.includes(this.extractDomain(request.url)!) ||
      this.ignoreUrl.some((url) => request.url.includes(url))
    ) {
      return next.handle(request);
    }
    return from(this.getToken(request, next));
  }

  private extractDomain(url: string): string | null {
    try {
      const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
      const matches = url.match(domainRegex);

      if (matches && matches.length >= 2) {
        return matches[1];
      }

      return null;
    } catch (error) {
      console.error('Error extracting domain:', error);
      return null;
    }
  }

  async getToken(request: HttpRequest<any>, next: HttpHandler) {
    const token = await this.auth.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
      });
    }

    return await lastValueFrom(next.handle(request));
  }
}
