<form #emailForm="ngForm" (ngSubmit)="generateOtp(emailForm)">
  <h1>Setup Account</h1>
  <label class="email-label">E-mail</label>
  <input
    class="input username"
    name="email"
    [(ngModel)]="email"
    placeholder="Enter your e-mail or phonenumber"
    ngModel
    required
  />
  <button class="btn btn-primary btn-block" [disabled]="!emailForm.valid">
    Send OTP
  </button>
  <a *ngIf="isLogin" routerLink="/login" class="a signup"
    ><i>Go back to login</i></a
  >
</form>
