<div
  [ngClass]="
    chatSearch
      ? 'inputwithiconadminui px-2 border-2 ml-0 border-slate-200'
      : 'inputwithiconadminui px-2'
  "
>
  <img class="search" src="../../../../assets/icons/search.svg" />
  <div *ngIf="!chatSearch">
    <input
      class="input-adminui"
      type="text"
      placeholder="By Name/Email/Mobile"
    />
  </div>
  <div *ngIf="chatSearch">
    <input class="input-adminui" type="text" placeholder="By Name" />
  </div>
</div>
