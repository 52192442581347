import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionsService } from '../../service/sessions.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';

import { AuthService } from '../../../../auth/auth.service';

import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { AnyoSortDirection } from '../../../../models/gridRequest';
import { IAnyoError } from '../../../../models/errorModal';
import { SessionTableComponent } from 'projects/app-core/src/app/session/components/session-table/session-table.component';
import {
  SessionGridRequest,
  SessionGridTab,
} from '../../models/sessionGridRequest';
import { SessionGridResponse } from '../../models/sessionGridResponse';
import { TherapistService } from 'projects/admin/src/app/services/therapist.service';
import { SessionFeedbackFormSubmitRequest } from '../../models/sessionFeedbackFormSubmitRequest';
import {
  CancelSessionRequest,
  ConfirmSessionRequest,
  UpdateSessionRequest,
} from '../../models/SessionRequest';
import { Permissions } from 'projects/app-core/src/auth/models/roles';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionExportData } from '../../models/SessionExportData';
import { FileUtilsService } from '../../../../service/fileService';
import { SessionGridStatsData } from '../../models/sessionStatsData';
import { AlertBoxComponent } from '../../../components/alert-box/alert-box.component';
import { AppointmentStatus } from '../../models/TherapistAppointment';

interface therapistSelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit {
  @ViewChild('sessionTableComponent')
  sessionTableComponent?: SessionTableComponent;
  @ViewChild('allSessionTableComponent')
  allSessionTableComponent?: SessionTableComponent;
  dateFormat = 'dd/MM/yyyy';
  globalSearchInput: string = '';
  mode: string = 'Therapist';
  id: string = '';
  patientId: string = '';
  dateRange: Date[] | undefined;
  date: string = '';
  time: string = '';
  upcomingDate: string = '';
  upcomingTime: string = '';
  callLink: string = '';
  isAlert: boolean = false;
  confirmAlert: boolean = false;
  confirmHeader: string = 'Confirm Date';
  patientHeader: string = 'Intake Form';
  patient: any = {};
  cancelHeader: string = 'Cancel Appointment';
  cancelBody: string = 'Reason for Cancellation';
  alertSuccess: string = 'Date';
  firstName: string = '';
  lastName: string = '';
  fullNameTherapist: string = '';
  tabs = [SessionGridTab.NEW_APPOINTMENTS, SessionGridTab.ALL];
  selectedTab: number = 0;
  therapistDetailsWithId: any[] = [];
  tableSpinner = false;
  sessionTableQuery: SessionGridRequest | undefined;
  tableData: SessionGridResponse = {
    data: [],
    filterOptions: {
      partners: [],
      therapists: [],
      sessionStatus: [],
      partnerId: [],
    },
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  };
  isAdminPortal = false;
  private pageSize: number = 10;
  sessionCompleted = 'Yes';
  showFeedBackForm = false;
  therapistSelectData: therapistSelectOptions[] | undefined;
  showUpdateButton!: boolean;
  showNewBookingBtn: boolean = true;
  therapistId = '';
  therapistName = '';
  queryUserEmail: string | undefined;
  queryTherapistEmail: string | undefined;
  constructor(
    private toastService: ToastService,
    private auth: AuthService,
    private sessionService: SessionsService,
    private therapistService: TherapistService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fileUtil: FileUtilsService,
  ) {}
  sessionStatsData: SessionGridStatsData = {
    totalSessions: 0,
    confirmedSessions: 0,
    cancelledSessions: 0,
    completedSessions: 0,
    noShowUserSessions: 0,
    noShowTherapistSessions: 0,
    techIssueSessions: 0,
    feedbackPendingSessions: 0,
  };

  getSessionsStats() {
    this.sessionService.sessionGridStats(this.sessionTableQuery!).subscribe({
      next: (value) => {
        const response = this.sessionService.mapSessionGridStatsResp(value);
        this.sessionStatsData = response;
      },
      error: (value) => {
        const errorBody = value.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  ngOnInit() {
    this.tableSpinner = true;
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        this.activatedRoute.queryParams.subscribe((value) => {
          if (value) {
            this.queryUserEmail = value['userId'];
            this.queryTherapistEmail = value['therapistId'];
          }
        });
        const isAdminPortal = this.router.routerState.snapshot.url.includes(
          'therapist/session/admin',
        );
        if (!isAdminPortal) {
          this.isAdminPortal = true;
          this.therapistService.getTherapistByEmail(user.email!).subscribe({
            next: (value) => {
              this.therapistId = value._id!;
              this.therapistName = value.firstName + ' ' + value.lastName;
              this.tableSpinner = false;
              this.toastService.showSuccess('therapist fetched');
            },
            error: (error) => {
              this.tableSpinner = false;
              const errorBody = error.error as IAnyoError;
              this.toastService.showAnyoErrorToast(errorBody.description);
            },
          });
        }

        if (
          user.customClaims?.additionalPermissions.includes(
            Permissions.AdminBookNewSession,
          )
        ) {
          this.showNewBookingBtn = true;
        }
        this.sessionTableQuery = {
          sort: {
            SESSION_DATE: AnyoSortDirection.DESC,
          },
          dateFilters: undefined,
          export: false,
          filters: {
            SESSION_STATUS: [],
            THERAPIST_EMAIL: [],
            USER_ID: [],
            PARTNER_ID: [],
            HIDE_TEST_BOOKING: ['true'],
          },
          from: undefined,
          globalSearch: [],
          page: 1,
          pageSize: this.pageSize,
          searchQuery: undefined,
          tab: SessionGridTab.NEW_APPOINTMENTS,
          to: undefined,
        };
        if (this.queryTherapistEmail) {
          this.sessionTableQuery.filters?.THERAPIST_EMAIL!.push(
            this.queryTherapistEmail,
          );
        }
        if (this.queryUserEmail) {
          this.sessionTableQuery.filters?.USER_ID!.push(this.queryUserEmail);
        }

        await this.listSession();
        this.therapistService.therapistList().subscribe({
          next: (value) => {
            value?.forEach((element: any) => {
              if (element.status === 'active') {
                const obj: any = {};
                obj.id = element._id;
                obj.firstName = element.firstName;
                obj.lastName = element.lastName;
                obj.email = element.email;
                obj.name = element.firstName + element.lastName;
                this.therapistDetailsWithId.push(obj);
              }
            });
            this.therapistSelectData = value.map((cat) => {
              return {
                label: cat.firstName,
                value: cat._id!.toString(),
              };
            });
          },
          error: (err) => {
            console.log(err);
          },
        });
      }
    });
  }

  submitFeedBack($event: any) {
    this.selectedTab === 0
      ? (this.sessionTableComponent!.feedbackFormSubmitLoading = true)
      : (this.allSessionTableComponent!.feedbackFormSubmitLoading = true);
    const request: SessionFeedbackFormSubmitRequest = {
      sessionCompleted: $event.sessionCompleted,
      sessionId: $event.sessionId,
      remarks: $event.remarks,
      sessionStatus: $event.sessionStatus,
    };
    this.sessionService.updateSessionFeedbackFom(request).subscribe({
      next: async (value) => {
        this.toastService.showSuccess('Feedback updated successfully');
        this.selectedTab === 0
          ? (this.sessionTableComponent!.showFeedBackForm = false)
          : (this.allSessionTableComponent!.showFeedBackForm = false);
        this.selectedTab === 0
          ? (this.sessionTableComponent!.feedbackFormSubmitLoading = false)
          : (this.allSessionTableComponent!.feedbackFormSubmitLoading = false);

        await this.listSession();
      },
      error: (error) => {
        this.selectedTab === 0
          ? (this.sessionTableComponent!.feedbackFormSubmitLoading = false)
          : (this.allSessionTableComponent!.feedbackFormSubmitLoading = false);
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  showPatientDetailsFn(id: string) {
    const appointment = this.tableData.data.find((value) => value.id === id);
    if (!appointment) {
      this.toastService.showError('Patient details not found');
    }

    this.patient.name = appointment!.clientDetails.name;
    this.patient.gender = appointment!.clientDetails.gender;
    this.patient.pastTherapyExperience =
      appointment!.clientDetails.pastTherapyExp;
    this.patient.lastSession = '';
    this.patient.pastMedCondition =
      appointment!.clientDetails.pastMedicalConditions;
    if (appointment!.clientDetails.medication) {
      this.patient.isMedication = 'Yes';
      this.patient.medication = appointment!.clientDetails.medication;
    } else {
      this.patient.isMedication = 'No';
      this.patient.medication = 'Nil';
    }
    this.patient.currentSymptoms = appointment!.clientDetails.currentSymptoms;
    this.patientAlert = true;
  }

  close() {
    this.patientAlert = false;
    this.cancelAlert = false;
    this.confirmAlert = false;
  }

  @ViewChild('cancelAppointmentAlertBox')
  cancelAppointmentAlertBox!: AlertBoxComponent;

  cancelAppointment(event: any) {
    this.cancelAppointmentAlertBox.loading = true;
    const request: CancelSessionRequest = {
      reasonForCancellation: [event],
    };

    this.sessionService.cancelAppointment(this.id, request).subscribe({
      next: async (_) => {
        this.cancelAlert = false;
        this.cancelAppointmentAlertBox.loading = false;
        this.toastService.showSuccess('Appointment cancelled successfully');

        await this.listSession();
      },
      error: (error) => {
        this.cancelAppointmentAlertBox.loading = false;
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  @ViewChild('confirmAppointmentAlertBox')
  confirmAppointmentAlertBox!: AlertBoxComponent;

  confirmAppointment(event: any) {
    this.confirmAppointmentAlertBox.loading = true;
    const dateTimeMoment = moment(
      `${event.suggestedDate} ${event.suggestedTime}`,
    );
    const startTimeEpochInSeconds = dateTimeMoment.format('x');

    let request: ConfirmSessionRequest = {
      startTime: startTimeEpochInSeconds!,
      therapistId: [],
    };
    request.therapistId = event.therapistId;
    this.sessionService.confirmAppointment(this.id, request).subscribe({
      next: async (_) => {
        this.toastService.showSuccess('Appointment confirmed successfully');
        this.confirmAlert = false;
        this.confirmAppointmentAlertBox.loading = false;

        await this.listSession();
      },
      error: (error) => {
        this.confirmAppointmentAlertBox.loading = false;
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  updateAppointment(event: any) {
    this.selectedTab === 0
      ? (this.sessionTableComponent!.updateAppointmentSubmitLoading = true)
      : (this.allSessionTableComponent!.updateAppointmentSubmitLoading = true);
    const request: UpdateSessionRequest = {
      startTime: event.startTime,
      therapistId: event.therapistId,
    };
    this.sessionService.updateAppointment(event.sessionId, request).subscribe({
      next: async (_) => {
        this.cancelAlert = false;
        this.toastService.showSuccess('Appointment Updated successfully');
        this.selectedTab === 0
          ? (this.sessionTableComponent!.updateAppointmentSubmitLoading = false)
          : (this.allSessionTableComponent!.updateAppointmentSubmitLoading =
              false);
        this.selectedTab === 0
          ? (this.sessionTableComponent!.visibleUpdateAppointment = false)
          : (this.allSessionTableComponent!.visibleUpdateAppointment = false);
        this.tableSpinner = false;

        await this.listSession();
      },
      error: (error) => {
        this.selectedTab === 0
          ? (this.sessionTableComponent!.updateAppointmentSubmitLoading = false)
          : (this.allSessionTableComponent!.updateAppointmentSubmitLoading =
              false);
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  disabledDate = (current: Date) => {
    return moment(current).isBefore(moment('01/10/2023', 'dd/MM/YYYY'));
  };
  cancelAlert: boolean = false;
  patientAlert: boolean = false;

  async nzPageSizeChange(pageSize: number) {
    this.sessionTableQuery!.pageSize = pageSize;
    await this.listSession();
  }

  async nzPageIndexChange(page: number) {
    if (this.sessionTableQuery) {
      this.sessionTableQuery!.page = page;
      await this.listSession();
    }
  }

  async listSession() {
    try {
      this.tableSpinner = true;
      this.getSessionsStats();

      if (this.sessionTableComponent) {
        this.sessionTableQuery!.filters!.HIDE_TEST_BOOKING = [
          this.hideTestBook
            ? this.hideTestBook.toString()
            : this.hideTestBook.toString(),
        ];
      }

      if (this.allSessionTableComponent) {
        this.sessionTableQuery!.filters!.HIDE_TEST_BOOKING = [
          this.allHideTestBook
            ? this.allHideTestBook.toString()
            : this.allHideTestBook.toString(),
        ];
      }

      const isAdminPortal =
        this.router.routerState.snapshot.url.includes('/admin');
      if (isAdminPortal) {
        this.showUpdateButton = true;

        this.tableData = await lastValueFrom(
          this.sessionService.fetchSessions(this.sessionTableQuery!),
        );
        this.tableSpinner = false;
      } else if (this.router.routerState.snapshot.url.includes('/therapist')) {
        this.showUpdateButton = false;
        this.tableData = await lastValueFrom(
          this.sessionService.fetchSessionsForTherapist(
            this.sessionTableQuery!,
          ),
        );
        this.tableSpinner = false;
      }
      if (this.sessionTableComponent) {
        this.sessionTableComponent.setTherapistFilterData(
          this.tableData.filterOptions,
        );
        this.sessionTableComponent.setSessionStatusFilterData(
          this.tableData.filterOptions,
        );
        this.sessionTableComponent.setPartnerIdFilterData(
          this.tableData.filterOptions,
        );
        this.sessionTableComponent.setPartnerNameFilterData(
          this.tableData.filterOptions,
        );
      }
      if (this.allSessionTableComponent) {
        this.allSessionTableComponent.setTherapistFilterData(
          this.tableData.filterOptions,
        );
        this.allSessionTableComponent.setSessionStatusFilterData(
          this.tableData.filterOptions,
        );
        this.allSessionTableComponent.setPartnerIdFilterData(
          this.tableData.filterOptions,
        );
        this.allSessionTableComponent.setPartnerNameFilterData(
          this.tableData.filterOptions,
        );
      }
    } catch (e) {
      this.tableSpinner = false;
      this.toastService.showError('Something went wrong');
    }
  }

  async searchGlobal() {
    this.sessionTableQuery!.globalSearch = [this.globalSearchInput];
    await this.listSession();
  }

  async dateChange(_: Date[]) {
    if (this.dateRange && this.dateRange.length == 2) {
      this.sessionTableQuery!.from = Number(
        moment(this.dateRange[0]).format('x'),
      );
      this.sessionTableQuery!.to = Number(
        moment(this.dateRange[1]).format('x'),
      );
    }
    await this.listSession();
  }

  async resetFilter() {
    this.globalSearchInput = '';
    this.sessionTableQuery = {
      export: false,
      sort: {
        SESSION_DATE: AnyoSortDirection.DESC,
      },
      filters: {
        THERAPIST_EMAIL: [],
        SESSION_STATUS: [],
        HIDE_TEST_BOOKING: ['true'],
        USER_ID: [],
        PARTNER_ID: [],
      },
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.queryTherapistEmail) {
      this.sessionTableQuery.filters?.THERAPIST_EMAIL!.push(
        this.queryTherapistEmail,
      );
    }
    if (this.queryUserEmail) {
      this.sessionTableQuery.filters?.USER_ID!.push(this.queryUserEmail);
    }

    this.dateRange = [];
    // if (this.dateRange && this.dateRange.length == 2) {
    //   this.sessionTableQuery.from = Number(
    //     moment(this.dateRange[0]).format('X'),
    //   );
    //   this.sessionTableQuery.to = Number(moment(this.dateRange[1]).format('X'));
    // }
    if (this.sessionTableComponent) {
      this.sessionTableComponent!.resetValues();
    }
    if (this.allSessionTableComponent) {
      this.allSessionTableComponent!.resetValues();
    }
    await this.listSession();
  }

  async tabChange($event: NzTabChangeEvent) {
    console.log(this.hideTestBook, this.allHideTestBook, 'working');
    this.selectedTab = $event.index!;
    this.tableData = {
      data: [],
      filterOptions: {
        partners: [],
        therapists: [],
        sessionStatus: [],
        partnerId: [],
      },
      pageNum: 1,
      pageSize: 10,
      totalPages: 0,
      totalRecords: 0,
    };

    this.globalSearchInput = '';
    this.sessionTableQuery = {
      export: false,
      sort: {
        SESSION_DATE: AnyoSortDirection.DESC,
      },
      filters: {
        SESSION_STATUS: [],
        THERAPIST_EMAIL: [],
        HIDE_TEST_BOOKING: ['true'],
        USER_ID: [],
        PARTNER_ID: [],
      },
      globalSearch: [],
      page: 1,
      pageSize: this.pageSize,
      tab: this.tabs[this.selectedTab],
    };
    if (this.queryTherapistEmail) {
      this.sessionTableQuery.filters?.THERAPIST_EMAIL!.push(
        this.queryTherapistEmail,
      );
    }
    if (this.queryUserEmail) {
      this.sessionTableQuery.filters?.USER_ID!.push(this.queryUserEmail);
    }
    if (this.dateRange && this.dateRange.length == 2) {
      this.sessionTableQuery.from = Number(
        moment(this.dateRange[0]).format('X'),
      );
      this.sessionTableQuery.to = Number(moment(this.dateRange[1]).format('X'));
    }
    if (this.sessionTableComponent) {
      this.sessionTableComponent.resetValues();
    }
    if (this.allSessionTableComponent) {
      this.allSessionTableComponent.resetValues();
    }

    if ($event.index == 0) {
      this.sessionTableQuery!.tab = SessionGridTab.NEW_APPOINTMENTS;
    }
    if ($event.index == 1) {
      this.sessionTableQuery!.tab = SessionGridTab.ALL;
    }
    await this.listSession();
  }

  async sessionDateSort($event: string | null) {
    if ($event) {
      this.sessionTableQuery!.sort!.SESSION_DATE =
        $event === 'ascend' ? AnyoSortDirection.ASC : AnyoSortDirection.DESC;
    } else {
      this.sessionTableQuery!.sort!.SESSION_DATE = AnyoSortDirection.ASC;
    }
    await this.listSession();
  }

  async therapistFilterFn($event: string[]) {
    if (!this.sessionTableQuery!.filters) {
      this.sessionTableQuery!.filters = {
        SESSION_STATUS: [],
        THERAPIST_EMAIL: [],
        HIDE_TEST_BOOKING: [],
        USER_ID: [],
        PARTNER_ID: [],
      };
    }
    if ($event && $event.length) {
      this.sessionTableQuery!.filters.THERAPIST_EMAIL = $event;

      await this.listSession();
    }
  }

  async sessionStatusFilter($event: string[]) {
    if (!this.sessionTableQuery!.filters) {
      this.sessionTableQuery!.filters = {
        SESSION_STATUS: [],
        THERAPIST_EMAIL: [],
        HIDE_TEST_BOOKING: [],
        USER_ID: [],
        PARTNER_ID: [],
      };
    }
    if ($event && $event.length) {
      this.sessionTableQuery!.filters.SESSION_STATUS = $event;

      await this.listSession();
    }
  }

  userName: string = '';
  userEmail: string = '';

  cancelAppointmentFn($event: {
    userName: string;
    userEmail: string;
    id: string;
  }) {
    this.id = $event.id;
    this.userEmail = $event.userEmail;
    this.userName = $event.userName;
    this.cancelAlert = true;
  }

  extractRequestedDate(input: string): string | null {
    const dateRegex = /Requested Date\s*:\s*([\d/]+)/;
    const match = input.match(dateRegex);
    return match ? match[1] : null;
  }

  extractRequestedTime(input: string): string | null {
    const timeRegex = /Requested Time\s*:\s*([\d:]+\s*[APM]+)/;
    const match = input.match(timeRegex);
    return match ? match[1] : null;
  }
  confirmAppointmentFn($event: string) {
    const appointment = this.tableData.data.find(
      (value) => value.id === $event,
    );
    if (!appointment) {
      return;
    }
    let text = '';
    appointment.remarks?.map((data) => {
      text = data.remark;
    });
    this.upcomingTime = moment(appointment.sessionStart, 'x').format('HH:mm');
    this.upcomingDate = moment(appointment.sessionDate, 'x').format(
      'YYYY-MM-DD',
    );
    this.id = $event;
    this.fullNameTherapist = appointment.therapistDetails.id;
    this.callLink = appointment.callLink ? appointment.callLink : '';
    const requestedDate = this.extractRequestedDate(text);
    const requestedTime = this.extractRequestedTime(text);
    if (requestedDate) {
      this.upcomingDate = moment(requestedDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD',
      );
    }
    if (requestedTime) {
      this.upcomingTime = moment(requestedTime, 'hh:mm A').format('HH:mm');
    }
    this.confirmAlert = true;
  }

  async reloadTableFn() {
    await this.listSession();
  }

  hideTestBook = true;
  allHideTestBook = true;

  async hideTestBookingFn($event: boolean) {
    if (this.sessionTableComponent) {
      this.hideTestBook = $event;
    }
    if (this.allSessionTableComponent) {
      this.allHideTestBook = $event;
    }
    if (!this.sessionTableQuery!.filters) {
      this.sessionTableQuery!.filters = {
        HIDE_TEST_BOOKING: [],
        SESSION_STATUS: [],
        THERAPIST_EMAIL: [],
        USER_ID: [],
        PARTNER_ID: [],
      };
    }
    this.sessionTableQuery!.filters!.HIDE_TEST_BOOKING = [$event.toString()];
    await this.listSession();
  }

  async filterPartnerIdFn($event: string[]) {
    if (!this.sessionTableQuery!.filters) {
      this.sessionTableQuery!.filters = {
        HIDE_TEST_BOOKING: [],
        SESSION_STATUS: [],
        THERAPIST_EMAIL: [],
        USER_ID: [],
        PARTNER_ID: [],
      };
    }
    this.sessionTableQuery!.filters!.PARTNER_ID = $event;
  }

  async filterPartnerNameFn($event: string[]) {
    if (!this.sessionTableQuery!.filters) {
      this.sessionTableQuery!.filters = {
        HIDE_TEST_BOOKING: [],
        SESSION_STATUS: [],
        THERAPIST_EMAIL: [],
        USER_ID: [],
        PARTNER_ID: [],
      };
    }
    this.sessionTableQuery!.filters!.PARTNER_ID = $event;
  }

  async exportDataFn() {
    this.sessionTableQuery!.export = true;
    this.tableSpinner = true;
    const gridResp = await lastValueFrom(
      this.sessionService.fetchSessions(this.sessionTableQuery!),
    );
    const exportData = gridResp.data.map((value) => {
      const payload: SessionExportData = {
        createdDate: moment(value.appointmentRequestDate, 'x').format(
          'DD/MM/YYYY',
        ),
        email: value.clientDetails.email,
        endTime: moment(value.sessionEnd, 'x').format('hh:mm a'),
        name: value.clientDetails.name,
        partnerId: value.clientDetails.partnerId,
        partnerName: value.clientDetails.partnerName,
        remarks: value.remarks?.map((value1) => value1.remark).join(',') || '',
        sessionDate: moment(value.sessionDate, 'x').format('DD/MM/YYYY'),
        startTime: moment(value.sessionDate, 'x').format('hh:mm a'),
        status: value.sessionStatus,
        therapistName: value.therapistDetails.therapistName,
      };
      return payload;
    });
    try {
      this.fileUtil.exportCSV<SessionExportData>(
        exportData,
        `sessions_export_${moment().format('dd/mm/yyy hh:mm a')}`,
      );
      this.tableSpinner = false;
    } catch (e) {
      this.toastService.showError('Export Error');
      this.tableSpinner = false;
    }
  }

  sessionDateFilter($event: Date[]) {
    if ($event && $event.length == 2) {
      const startDate = moment($event[0]).startOf('day').toDate();
      const endDate = moment($event[1]).endOf('day').toDate();
      const isToday = moment(startDate).isSame(endDate, 'day');
      this.sessionTableQuery = {
        sort: {
          SESSION_DATE: AnyoSortDirection.ASC,
        },
        dateFilters: {
          SESSION_DATE: {
            from: isToday
              ? moment($event[0]).startOf('day').toDate()
              : startDate,
            to: isToday ? moment($event[1]).endOf('day').toDate() : endDate,
          },
        },
        export: false,
        filters: {
          SESSION_STATUS: [],
          THERAPIST_EMAIL: [],
          USER_ID: [],
          PARTNER_ID: [],
          HIDE_TEST_BOOKING: ['true'],
        },
        from: undefined,
        globalSearch: [],
        page: 1,
        pageSize: this.pageSize,
        searchQuery: undefined,
        tab:
          this.selectedTab == 0
            ? SessionGridTab.NEW_APPOINTMENTS
            : SessionGridTab.ALL,
        to: undefined,
      };

      this.listSession();
    }
  }
}
