<div class="flex flex-row justify-between items-center mb-3">
  <div class="flex flex-row md:gap-3 items-center">
    <div class="flex flex-row flex-nowrap md:gap-3 items-center">
      <img
        class="left-arrow clickable"
        src="../../../../assets/icons/left-arrow.svg"
        [routerLink]="['/partner']"
      />
      <h1 class="headeradminui-text md:break-keep">{{ partnerName }}</h1>
    </div>
    <div>
      <img class="separator" src="../../../../assets/icons/separator.svg" />
    </div>
    <div>
      <h1 class="sub-headeradminui-text">{{ totalCount }} Members</h1>
    </div>
  </div>
  <div nz-row nzGutter="10">
    <div nz-col>
      <div
        (click)="check()"
        class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
      >
        <div>
          <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
        </div>
        <div>
          <span class="">Add new member</span>
        </div>
      </div>
    </div>
    <div nz-col>
      <div
        (click)="showUploadDrawerFn()"
        class="flex flex-row justify-between btn custom-btn-primary-adminui gap-1"
      >
        <div>
          <img class="add-icon" src="../../../../assets/icons/add-icon.svg" />
        </div>
        <div>
          <span class="">Bulk Upload Members</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAlert">
  <app-alert-box
    [alertHeader]="alertHeaderDisable"
    [alertBody]="alertBodyDisable"
    (closeAlert)="dialogShow()"
    (isDisable)="deleteMember()"
  ></app-alert-box>
</div>
<div class="flex flex-row justify-between visible md:visible mb-3">
  <div class="flex flex-row justify-between gap-2">
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ totalCount }}</div>
          <div class="xs-text">Total member passes</div>
        </div>
        <div><img src="../../../../assets/icons/subscription-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ totalCorporate }}</div>
          <div class="xs-text">Total corporate member passes</div>
        </div>
        <div><img src="../../../../assets/icons/subscription-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ availablePasses }}</div>
          <div class="xs-text">Available Corporate Passes</div>
        </div>
        <div><img src="../../../../assets/icons/subscription-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ invitedPasses }}</div>
          <div class="xs-text">Corporate Members Invited</div>
        </div>
        <div><img src="../../../../assets/icons/members-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ availedPasses }}</div>
          <div class="xs-text">Corporate Passes Redeemed</div>
        </div>
        <div><img src="../../../../assets/icons/members-icon.svg" /></div>
      </div>
    </div>
  </div>
  <div
    *ngIf="selectedMembersArray && selectedMembersArray.length > 0"
    class="flex justify-end items-center gap-2"
  >
    <div class="mt-auto" (click)="deleteMembers()">
      <div
        class="flex flex-row justify-between btn custom-btn-primary-adminui w-full"
      >
        <div>
          <img class="w-full" src="../../../../assets/icons/bulk-delete.svg" />
        </div>
      </div>
    </div>
    <div class="mt-auto">
      <div class="flex flex-row justify-between btn custom-btn-primary w-full">
        <div>
          <img
            class="w-full"
            src="../../../../assets/icons/resend-invite.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex flex-row justify-between visible md:visible mb-3">
  <div class="flex flex-row justify-between gap-2">
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ totalFamily }}</div>
          <div class="xs-text">Total family member passes</div>
        </div>
        <div><img src="../../../../assets/icons/subscription-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ availableFamilyPasses }}</div>
          <div class="xs-text">Available family Passes</div>
        </div>
        <div><img src="../../../../assets/icons/subscription-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ totalInvitedFamily }}</div>
          <div class="xs-text">family Members Invited</div>
        </div>
        <div><img src="../../../../assets/icons/members-icon.svg" /></div>
      </div>
    </div>
    <div class="info-section flex-1 p-2">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <div class="large-text">{{ totalFamilyMemberPassRedeemed }}</div>
          <div class="xs-text">Family Passes Redeemed</div>
        </div>
        <div><img src="../../../../assets/icons/members-icon.svg" /></div>
      </div>
    </div>
  </div>
</div>
<nz-spin [nzSpinning]="memberTableSpinner">
  <div nz-row>
    <nz-tabset (nzSelectChange)="tabChange($event)">
      <nz-tab nzTitle="Corporate Members">
        <app-member-table
          #coporateMemberTable
          [tableData]="tableData"
          [selectedTab]="selectedTab"
          [partnerName]="partnerName"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (exportMemberTableEvent)="exportMemberTable()"
          (deleteMemberEvent)="deleteMemberAlert($event)"
          (corporateSearchEvent)="corporateCodeSearchFn($event)"
          (codeReedemFilterEvent)="codeRedeemColumnFilterFn($event)"
          (emailSearchEvent)="emailColumnSearchFn($event)"
          (reloadTable)="reload()"
        ></app-member-table>
      </nz-tab>
      <nz-tab nzTitle="Family Members">
        <app-member-table
          #familyMemberTable
          [tableData]="tableData"
          [selectedTab]="selectedTab"
          [partnerName]="partnerName"
          (reloadTable)="reload()"
          (resetFilterEvent)="resetFilter()"
          (pageChangeEvent)="nzPageIndexChange($event)"
          (pageSizeChangeEvent)="nzPageSizeChange($event)"
          (exportMemberTableEvent)="exportMemberTable()"
          (deleteMemberEvent)="deleteMemberAlert($event)"
          (corporateSearchEvent)="corporateCodeSearchFn($event)"
          (codeReedemFilterEvent)="codeRedeemColumnFilterFn($event)"
          (emailSearchEvent)="emailColumnSearchFn($event)"
        ></app-member-table>
      </nz-tab>
    </nz-tabset>
  </div>
</nz-spin>

<nz-drawer
  (nzOnClose)="closeBulkUploadDrawerFn()"
  [nzClosable]="true"
  [nzMaskClosable]="false"
  [nzVisible]="showBulkUploadDrawer"
  nzPlacement="bottom"
  nzSize="large"
  nzTitle="Bulk Upload Members"
>
  <ng-container *nzDrawerContent>
    <nz-card class="min-w-full min-h-[50%]" nzTitle="Bulk Upload Members">
      <div nz-row nzJustify="end">
        <div nz-col>
          <button (click)="downloadTemplate()" nz-button nzType="primary">
            <span nz-icon nzType="download"></span>
            Download Template
          </button>
        </div>
      </div>
      <nz-spin [nzSpinning]="bulkUploadLoading">
        <div class="mt-10" nz-row nzAlign="middle" nzJustify="space-evenly">
          <div nz-col nzSpan="24">
            <div class="flex items-center justify-center w-full">
              <label
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-10 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                for="dropzone-file"
              >
                <div
                  class="flex flex-col items-center justify-center pt-5 pb-6"
                >
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    fill="none"
                    viewBox="0 0 20 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload</span>
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">CSV</p>
                </div>
                <input
                  (change)="csvFileSelected($event)"
                  accept=".csv"
                  class="hidden"
                  id="dropzone-file"
                  type="file"
                />
              </label>
            </div>
          </div>
        </div>
      </nz-spin>
    </nz-card>
  </ng-container>
</nz-drawer>
<!--<app-table [fields]="fields" [actionField]="actionField" [data]="tableData" (call)="call($event)"-->
<!--    (membersArray)="selectedMembers($event)"></app-table>-->
