export interface CreatePostRequestAdmin {
  circleId: string;
  text: string;
  mediaFiles?: {
    fileName: string;
    contentType: MediaType;
    contentLength: number;
  }[];
  topics: string[];
  anonymous?: boolean;
  parentPostId?: string;
  notificationBody?: string;
  notificationTitle?: string;
}

export enum MediaType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
