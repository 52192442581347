import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEventComponent } from './add-event/add-event.component';
import { ManageEventsComponent } from './manage-events/manage-events.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { ManageRegistrationsComponent } from './manage-registrations/manage-registrations.component';
import { NzCardComponent } from 'ng-zorro-antd/card';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NzInputDirective, NzInputGroupComponent } from 'ng-zorro-antd/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import {
  NzFormControlComponent,
  NzFormDirective,
  NzFormLabelComponent,
} from 'ng-zorro-antd/form';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import {
  NzDatePickerComponent,
  NzDatePickerModule,
  NzRangePickerComponent,
} from 'ng-zorro-antd/date-picker';
import { NzTypographyComponent } from 'ng-zorro-antd/typography';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { RouterModule } from '@angular/router';
import { EventsTableComponent } from './events-table/events-table.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  declarations: [
    AddEventComponent,
    ManageEventsComponent,
    EditEventComponent,
    ManageRegistrationsComponent,
    EventsTableComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NzCardComponent,
    NzRowDirective,
    NzSpinComponent,
    NzInputDirective,
    FormsModule,
    NzColDirective,
    NzOptionComponent,
    NzSelectComponent,
    NzFormDirective,
    ReactiveFormsModule,
    NzFormLabelComponent,
    NzDividerComponent,
    NzInputGroupComponent,
    NzDatePickerComponent,
    NzTypographyComponent,
    NzButtonComponent,
    NzIconDirective,
    NzFormControlComponent,
    NzTableModule,
    NzTabsModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzDatePickerModule,
    NzRangePickerComponent,
    NzTagModule,
    RouterModule,
    NzSwitchModule,
  ],
})
export class EventsModule {}
