import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface EventsListsGridRequest
  extends GridRequest<
    EventListSort,
    EventsFilter,
    EventSearch,
    EventListDateFilter
  > {
  tab?: EventsListTab;
  currency?: string;
}

export enum EventsListTab {
  UP_COMING = 'UP_COMING',
  PAST = 'PAST',
  REGISTERED = 'REGISTERED',
  UP_COMING_ADMIN = 'UP_COMING_ADMIN',
}
export enum EventListSort {
  EVENT_DATE = 'EVENT_DATE',
  PRICE = 'PRICE',
}
export enum EventsFilter {
  CATEGORY = 'CATEGORY',
  PRESENTER_ID = 'PRESENTER_ID',
  CIRCLE_ID = 'CIRCLE_ID',
  ACTIVE = 'ACTIVE',
  USER_PERSONALISED = 'USER_PERSONALISED',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
}
export enum EventSearch {
  EVENT_NAME = 'EVENT_NAME',
}
export enum EventListDateFilter {
  EVENT_DATE = 'EVENT_DATE',
  CREATED_DATE = 'CREATED_DATE',
  REGISTRATION_CUT_OFF = 'REGISTRATION_CUT_OFF',
  VISIBLE_FROM = 'VISIBLE_FROM',
}
