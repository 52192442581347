import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateTherapyPlanComponent } from '../create-therapy-plan/create-therapy-plan.component';
import { TherapyPlansListComponent } from '../therapy-plans-list/therapy-plans-list.component';
import { EditTherapyPlanComponent } from '../edit-therapy-plan/edit-therapy-plan.component';
import { AllTherapyPlansListComponent } from '../all-therapy-plans-list/all-therapy-plans-list.component';
import { TherapyPlansSubcriptionComponent } from '../therapy-plans-subcription/therapy-plans-subcription.component';

const routes: Routes = [
  {
    path: 'create',
    component: CreateTherapyPlanComponent,
  },
  {
    path: 'list',
    component: TherapyPlansListComponent,
  },
  {
    path: 'all',
    component: AllTherapyPlansListComponent
  },
  {
    path: 'associate',
    component: TherapyPlansListComponent,
  },
  {
    path: 'edit/:id',
    component: EditTherapyPlanComponent
  },
  {
    path: 'subscriptions',
    component: TherapyPlansSubcriptionComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TherapyPlansRoutingModule {}
