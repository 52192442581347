import { GridRequest } from 'projects/app-core/src/models/gridRequest';

export interface TherapistGridRequest
  extends GridRequest<
    TherapistSort,
    TherapistFilter,
    TherapistSearch,
    TherapistDateFilter
  > {}

export enum TherapistSort {
  THERAPIST_CREATED_AT = 'CREATED_AT',
  THERAPIST_STATUS = 'STATUS',
}

export enum TherapistSearch {
  THERAPIST_EMAIL = 'THERAPIST_EMAIL',
  THERAPIST_NAME = 'THERAPIST_NAME',
}

export enum TherapistDateFilter {
  THERAPIST_NO_OF_SESSIONS = 'THERAPIST_NO_OF_SESSIONS',
}

export enum TherapistFilter {
  THERAPIST_RATING = 'THERAPIST_RATING',
  THERAPIST_STATUS = 'THERAPIST_STATUS',
}
