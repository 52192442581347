<div class="flex flex-col flex-wrap w-3/5 ml-36 mt-24">
  <p class="text-xl font-semibold color heading">Welcome to Anyo!</p>
  <label class="label-color mb-2">E-Mail Address !</label>
  <input
    class="input mb-4"
    name="partner_username"
    placeholder="Enter your Email here"
    [(ngModel)]="username"
    ngModel
    required
  />
  <label class="label-color mb-2">Password</label>
  <app-partner-input-with-icon
    [placeholder]="placeholder"
    [src]="eyeClosedIcon"
    [src_2]="eyeOpenIcon"
    (input)="onKey($event)"
  ></app-partner-input-with-icon>
  <a [routerLink]="forgotpasswordpath" class="flex justify-end a"
    >Forgot password?</a
  >
  <button
    class="btn btn-primary btn-block-login"
    (click)="login()"
    [disabled]="!username || !password"
  >
    <!-- <p *ngIf="!loading">Login</p>
    <app-loader></app-loader> -->Login
  </button>
  <a [routerLink]="registerpath" class="flex justify-center a"
    >First time? Sign up here</a
  >
</div>
