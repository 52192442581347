<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/posts']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headerlistener-text pl-2">Add {{ postCommentTitle }}</h1>
  </div>
</div>
<nz-spin [nzSpinning]="postSpinning" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div [nzGutter]="16" nzAlign="middle" nz-row>
        <div nz-col nzSpan="12" *ngIf="viewPost">
          <label class="form-label required">Feed Text</label>
          <textarea
            class="w-[100%] h-60 mr-1"
            [(ngModel)]="postsText"
            nz-input
            rows="4"
            placeholder="Enter text"
            [disabled]="true"
          ></textarea>
        </div>
        <div nz-col class="pl-4" nzSpan="6" *ngIf="viewPost">
          <button nz-button (click)="openViewMedia()">View Media</button>
        </div>
      </div>
      <div [nzGutter]="16" nz-row>
        <div nz-col nzSpan="8">
          <label class="form-label required">Circle</label>
          <nz-select
            (ngModelChange)="populateSubtopics($event)"
            [(ngModel)]="selectedCircleString"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Circle"
            nzShowSearch
            nzSize="large"
            [nzDisabled]="whenDisplayManyCircles"
          >
            <nz-option
              *ngFor="let circle of circles"
              [nzLabel]="circle.label"
              [nzValue]="circle.value"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzSpan="8" *ngIf="displayMediaUpload">
          <label class="form-label required">Sub Topics</label>
          <nz-select
            [(ngModel)]="selectedSubTopicsArray"
            class="w-[100%] mr-1"
            nzAllowClear
            nzPlaceHolder="Select a Sub Topics"
            nzShowSearch
            nzSize="large"
            nzMode="multiple"
            [nzMaxTagCount]="5"
          >
            <nz-option
              *ngFor="let subTopic of subTopics"
              [nzLabel]="subTopic.label"
              [nzValue]="subTopic.value"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <div [nzGutter]="16" nz-row>
        <div nz-col nzSpan="12">
          <label class="form-label required">Feed Text</label>
          <textarea
            class="w-[100%] h-60 mr-1"
            [(ngModel)]="feedText"
            nz-input
            rows="4"
            placeholder="Enter text"
          ></textarea>
        </div>
      </div>
      <nz-divider nzText="Notifcation Details"></nz-divider>
      <span
        nz-typography><code>Notification details are Optional, If not filled default notification will be sent</code></span>
      <div nz-row>
        <div nz-col>
          <label class="form-label required">Notification Title</label>
          <input [(ngModel)]="notificationTitle" nz-input placeholder="Notification Title" type="text">
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">
          <label class="form-label required">Notification body</label>
          <nz-textarea-count [nzMaxCharacterCount]="100">
          <textarea
            [(ngModel)]="notificationBody"
            class="w-[100%] h-60 mr-1"
            nz-input
            placeholder="Enter text"
            rows="4"
          ></textarea>
          </nz-textarea-count>
        </div>
      </div>
      <nz-divider nzText="Media Details"></nz-divider>
      <div *ngIf="displayMediaUpload" [nzGutter]="16" nz-row>
        <div class="mt-5" nz-col nzSpan="6">
          <span><b class="form-label required">Post's Media Files</b></span>
          <input
            multiple
            (change)="uploadMedia($event)"
            accept=".jpg,.jpeg,.png,.mp4"
            type="file"
          />
        </div>
      </div>
      <div
        class="flex flex-wrap justify-around items-center pr-4 pl-4 pb-5 media-container"
        *ngIf="displayMediaUpload && mediaFiles.length"
      >
        <div *ngFor="let media of images; index as i" class="pt-5">
          <nz-card
            [nzExtra]="deleteImageTemplate"
            nzHoverable
            style="width: 400px"
            [nzCover]="coverTemplate"
          >
            <nz-card-meta [nzTitle]="media.file.name"> </nz-card-meta>
          </nz-card>
          <ng-template #coverTemplate>
            <ng-container *ngIf="media.type === MediaType.IMAGE">
              <img
                class="h-60 object-cover"
                [src]="media.url"
                alt="Selected Image"
              />
            </ng-container>
            <ng-container *ngIf="media.type === MediaType.VIDEO">
              <video controls>
                <source
                  class="h-60 object-cover"
                  [src]="media.url"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </ng-container>
          </ng-template>
          <ng-template #deleteImageTemplate>
            <span
              (click)="deleteImage(i)"
              nz-icon
              nzTheme="outline"
              nzType="delete"
            ></span>
          </ng-template>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col>
          <button
            *ngIf="displayMediaUpload"
            (click)="savePost()"
            nz-button
            nzType="primary"
            class="btn-color"
          >
            Save Post
          </button>
          <button
            *ngIf="!displayMediaUpload"
            (click)="saveComments()"
            nz-button
            nzType="primary"
            class="btn-color"
          >
            Save Comments
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
<nz-drawer
  [nzSize]="'large'"
  [nzVisible]="visibleViewMedia"
  nzPlacement="bottom"
  [nzTitle]="'View Media'"
  (nzOnClose)="closeViewMedia()"
>
  <ng-container *nzDrawerContent>
    <div class="flex flex-wrap justify-around items-center">
      <div *ngFor="let media of media">
        <nz-card nzHoverable [nzCover]="coverTemplate">
          <nz-card-meta
            [nzTitle]="media.type"
            [nzDescription]="media.deleted ? 'deleted' : 'active'"
          ></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <ng-container *ngIf="media.type === MediaType.IMAGE">
            <img
              style="width: 250px"
              class="h-60 object-cover"
              [src]="media.url"
              alt="Selected Image"
            />
          </ng-container>
          <ng-container *ngIf="media.type === MediaType.VIDEO">
            <video controls class="h-60 object-cover" style="width: 400px">
              <source [src]="media.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</nz-drawer>
