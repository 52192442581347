import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-with-icon-therapist',
  templateUrl: './button-with-icon-therapist.component.html',
  styleUrls: ['./button-with-icon-therapist.component.scss'],
})
export class ButtonWithIconTherapistComponent {
  @Input() imageVisible: boolean = false;
  @Input() textButton: string = '';
  @Input() blockImage: boolean = false;
  @Input() reportImage: boolean = false;
  @Input() chatButton: string = '';
  @Input() background: string = '';
}
