import { Injectable } from '@angular/core';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../service/network-utils.service';
import { TalkBroadcastDetails } from '../models/TalkBroadcastDetails';
import {
  AnyoFirestoreService,
  FirestoreCollection,
  FirestoreSubCollection,
} from '../../../service/anyo-firestore.service';
import { TalkLogFirestore } from '../models/TalkLogFirestore';
import { TalkGridRequest } from '../models/TalkGridRequest';
import { TalkGridResponse } from '../models/TalkGridResponse';
import { SessionMessage } from '../models/SessionMessage';
import { TalkLogsGridRequest } from '../models/TalkLogsGridRequest';
import { TalkLogsGridResponse } from '../models/TalkLogsGridResponse';
import { AnyoTalk } from '../models/AnyoTalk';
import { AnyoTalkCreateRequest } from '../models/CreateTalksRequest';
import { MarkTalkLogVisibilityRequest } from '../models/markVisibilityTalkLogRequest';

@Injectable({
  providedIn: 'root',
})
export class TalksService {
  constructor(
    private api: NetworkUtilsService,
    private firestoreDb: AnyoFirestoreService,
  ) {}

  getBroadcastDetails(talkId: string) {
    const url = `${URL_DICT.fetchBroadCastDetailsTalks}?id=${talkId}`;
    return this.api.get<TalkBroadcastDetails>(url);
  }

  getTalksGrid(talkGridRequest: TalkGridRequest) {
    return this.api.post<TalkGridResponse>(
      URL_DICT.talksAdminGridUrl,
      talkGridRequest,
    );
  }

  getLiveSessionListener(talkId: string) {
    return this.firestoreDb.db
      .collection(FirestoreCollection.TALK_LOGS, (ref) =>
        ref
          .where('streamingSessionStarted', '==', true)
          .where('talkId', '==', talkId),
      )
      .snapshotChanges();
  }

  getLiveSessionMessageListener(sessionId: string) {
    return this.firestoreDb.db
      .collection(FirestoreCollection.TALK_LOGS)
      .doc(sessionId)
      .collection(FirestoreSubCollection.MESSAGES, (ref) => {
        ref.orderBy('timeStamp', 'asc');
        return ref;
      })
      .snapshotChanges(['added']);
  }

  async addMessage(sessionId: string, messageDoc: SessionMessage) {
    try {
      const resp = await this.firestoreDb.db
        .collection(FirestoreCollection.TALK_LOGS)
        .doc(sessionId)
        .collection(FirestoreSubCollection.MESSAGES)
        .add(messageDoc);
      console.log('resp', resp);
    } catch (e) {
      throw e;
    }
  }

  async updateCameraState(
    cameraState: boolean,
    sessionId: string,
    userId: string,
  ) {
    const dbPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.TALK_LOGS,
      sessionId,
    );
    const sessionDetails =
      await this.firestoreDb.getDocument<TalkLogFirestore>(dbPath);
    if (!sessionDetails) {
      console.log('Unable to find session details');
      return;
    }
    const index = sessionDetails.hostStreamingDetails.findIndex(
      (value) => value.userId == userId,
    );
    if (index !== -1) {
      sessionDetails.hostStreamingDetails[index].cameraOn = cameraState;
    }
    await this.firestoreDb.updateDocument(dbPath, {
      hostStreamingDetails: sessionDetails.hostStreamingDetails,
    });
  }

  async updateMicState(micState: boolean, sessionId: string, userId: string) {
    const dbPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.TALK_LOGS,
      sessionId,
    );
    const sessionDetails =
      await this.firestoreDb.getDocument<TalkLogFirestore>(dbPath);
    if (!sessionDetails) {
      console.log('Unable to find session details');
      return;
    }
    const index = sessionDetails.hostStreamingDetails.findIndex(
      (value) => value.userId == userId,
    );
    if (index !== -1) {
      sessionDetails.hostStreamingDetails[index].audioOn = micState;
    }
    await this.firestoreDb.updateDocument(dbPath, {
      hostStreamingDetails: sessionDetails.hostStreamingDetails,
    });
  }

  addParticipant(talkId: string) {
    const payload = {
      talkId: talkId,
    };
    return this.api.post(URL_DICT.addParticipant, payload);
  }

  getTalkLogs(talkGridRequest: TalkLogsGridRequest) {
    return this.api.post<TalkLogsGridResponse>(
      URL_DICT.talksLogAdminGridUrl,
      talkGridRequest,
    );
  }

  async updateHostDetailToSession(
    userId: string,
    sessionId: string,
    camera: boolean,
    mic: boolean,
    audioOnly: boolean,
    layout: string,
    startStreaming: boolean,
    commentsEnabled: boolean,
    topic: string,
    description: string,
  ) {
    const dbPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.TALK_LOGS,
      sessionId,
    );
    const sessionDetails =
      await this.firestoreDb.getDocument<TalkLogFirestore>(dbPath);
    if (!sessionDetails) {
      console.log('Unable to find session details');
      return;
    }
    if (!sessionDetails.hostUserIds) {
      sessionDetails!.hostUserIds = [];
    }
    const existingSessionDetailsIndex =
      sessionDetails.hostStreamingDetails.findIndex(
        (value) => value.userId == userId,
      );
    if (existingSessionDetailsIndex != -1) {
      sessionDetails.hostStreamingDetails.splice(
        existingSessionDetailsIndex,
        1,
      );
    }
    sessionDetails.hostUserIds.push(userId);
    sessionDetails.hostUserIds = Array.from(
      new Set(sessionDetails.hostUserIds),
    );
    if (!sessionDetails.hostStreamingDetails) {
      sessionDetails.hostStreamingDetails = [];
    }
    sessionDetails.hostStreamingDetails.push({
      userId: userId,
      cameraOn: camera,
      audioOn: mic,
    });
    await this.firestoreDb.updateDocument(dbPath, {
      audioOnly: audioOnly,
      layout: layout,
      hostStreamingDetails: sessionDetails.hostStreamingDetails,
      hostUserIds: sessionDetails.hostUserIds,
      startStreaming: startStreaming,
      commentsEnabled: commentsEnabled,
      topic: topic,
      talkDescription: description,
    });
  }

  updateTalkNotificationDetails(talkId: string, title: string, body: string) {
    return this.api.post(URL_DICT.updateTalkNotificationDetails, {
      talkId: talkId,
      title: title,
      body: body,
    });
  }

  getTalkDetailsById(talkId: string) {
    return this.api.get<AnyoTalk>(`${URL_DICT.getTalkDetailsById}${talkId}`);
  }

  getTalk(talkId: string) {
    return this.api.get<AnyoTalk>(
      URL_DICT.getTalkDetailsById + 'get/' + `${talkId}`,
    );
  }

  saveTalks(req: AnyoTalkCreateRequest) {
    return this.api.post(URL_DICT.getTalkDetailsById, req);
  }

  updateTalks(req: AnyoTalkCreateRequest, talkId: string) {
    return this.api.post(URL_DICT.getTalkDetailsById + `${talkId}`, req);
  }

  markTalkLogVisibility(request: MarkTalkLogVisibilityRequest) {
    return this.api.post(
      URL_DICT.getTalkDetailsById + 'log/visibility',
      request,
    );
  }
}
