<p>live works!</p>
<textarea [(ngModel)]="token" placeholder="token"></textarea>
<br>
<button (click)="joinStage()" nz-button nzType="primary">Join</button>
<!-- Remote Participants -->
<div class="bg-gray-800 flex justify-center items-center h-screen">
  <div class="container mx-auto p-4">
    <div class="bg-gray-700 rounded-lg overflow-hidden shadow-lg">
      <div class="flex flex-col md:flex-row">
        <!-- Main video -->
        <div class="md:flex-1">
          <video #localVideo autoplay class="w-full" id="localPlayer"></video>
        </div>
        <!-- Participant videos -->
        <div class="flex-1 space-y-4 p-4">
          <div *ngFor="let pa of participants">
            <video autoplay class="w-full rounded" id="video-{{pa.id}}"></video>
          </div>
        </div>
      </div>
      <!-- Controls -->
      <div class="p-4 bg-gray-800 flex justify-between items-center">
        <button (click)=toggleAudio() class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {{ micMuted ? "Enable Mic" : "Disable Mic" }}
        </button>
        <button (click)=leaveStage() class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          End Call
        </button>
        <button (click)=toggleVideo() class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          {{ cameraMuted ? "Enable Camera" : "Disable Camera" }}
        </button>
      </div>
    </div>
  </div>
</div>
