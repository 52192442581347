<div class="flex flex-row flex-nowrap md:gap-3 items-center justify-between">
  <div class="flex flex-wrap w-1/4 justify-start">
    <img
      [routerLink]="['/roles-permission']"
      class="left-arrow clickable"
      src="../../../../assets/icons/left-arrow.svg"
    />
    <h1 class="headeradminui-text pl-2">Add New Admin</h1>
  </div>
</div>
<nz-spin [nzSpinning]="adminSpining" nzTip="Loading...">
  <div class="custom-card mt-2">
    <div class="card-body">
      <div [nzGutter]="16" nz-row nzAlign="middle" nzJustify="space-evenly">
        <div nz-col nzSpan="6">
          <label class="form-label required">Name</label>
          <input
            class="w-[100%] mr-1"
            [(ngModel)]="nameString"
            nz-input
            nzSize="large"
            placeholder="Enter Name"
          />
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">Email</label>
          <input
            class="w-[100%] mr-1"
            [(ngModel)]="emailString"
            nz-input
            nzSize="large"
            placeholder="Enter Email"
          />
        </div>
        <div nz-col nzSpan="6">
          <label class="form-label required">isActive</label>
          <nz-switch [(ngModel)]="isActive"></nz-switch>
        </div>
      </div>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col>
          <button (click)="saveAdmin()" nz-button nzType="primary">
            Save Admin
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
