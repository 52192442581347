import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageCircleComponent } from './manage-circle/manage-circle.component';
import { AddCircleComponent } from './add-circle/add-circle.component';
import { ViewCircleComponent } from './view-circle/view-circle.component';
import { ManageModeratorComponent } from './manage-moderator/manage-moderator.component';
import { AddModeratorComponent } from './add-moderator/add-moderator.component';
import { ViewModeratorComponent } from './view-moderator/view-moderator.component';

const routes: Routes = [
  {
    path: '',
    component: ManageCircleComponent,
  },
  {
    path: 'add',
    component: AddCircleComponent,
  },
  {
    path: 'view/:id',
    component: ViewCircleComponent,
  },
  {
    path: 'moderators',
    component: ManageModeratorComponent,
  },
  {
    path: 'moderators/add',
    component: AddModeratorComponent,
  },
  {
    path: 'moderators/view/:id',
    component: ViewModeratorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CirclesRoutingModule {}
