<div [ngClass]="isFilled ? 'inputwithicon' : 'inputwithicon input-error'" #box>
  <input
    class="input small-text"
    type="text"
    (keyup)="onKey($event)"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    [(ngModel)]="value"
    #pass
  />
  <img
    *ngIf="!show"
    class="cursor-pointer"
    [src]="src"
    (click)="toggle($event)"
  />
  <img
    *ngIf="show"
    class="cursor-pointer"
    [src]="src_2"
    (click)="toggle($event)"
  />
</div>
